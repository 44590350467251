// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: paywall.proto

/* eslint-disable */
import { ParameterValues } from "./networking";
import { AttributedString, Image } from "./presentation";
import { ProgramSummaryContent } from "./products";

export enum Alignment {
  /** noAlignment - So that every meaningful status is not zero, hence exported. */
  noAlignment = "noAlignment",
  left = "left",
  center = "center",
  right = "right",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function alignmentFromJSON(object: any): Alignment {
  switch (object) {
    case 0:
    case "noAlignment":
      return Alignment.noAlignment;
    case 1:
    case "left":
      return Alignment.left;
    case 2:
    case "center":
      return Alignment.center;
    case 3:
    case "right":
      return Alignment.right;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Alignment.UNRECOGNIZED;
  }
}

export function alignmentToJSON(object: Alignment): string {
  switch (object) {
    case Alignment.noAlignment:
      return "noAlignment";
    case Alignment.left:
      return "left";
    case Alignment.center:
      return "center";
    case Alignment.right:
      return "right";
    case Alignment.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Weight {
  noWeight = "noWeight",
  regular = "regular",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function weightFromJSON(object: any): Weight {
  switch (object) {
    case 0:
    case "noWeight":
      return Weight.noWeight;
    case 1:
    case "regular":
      return Weight.regular;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Weight.UNRECOGNIZED;
  }
}

export function weightToJSON(object: Weight): string {
  switch (object) {
    case Weight.noWeight:
      return "noWeight";
    case Weight.regular:
      return "regular";
    case Weight.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum CardColor {
  noColor = "noColor",
  primary = "primary",
  secondary = "secondary",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function cardColorFromJSON(object: any): CardColor {
  switch (object) {
    case 0:
    case "noColor":
      return CardColor.noColor;
    case 1:
    case "primary":
      return CardColor.primary;
    case 2:
    case "secondary":
      return CardColor.secondary;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CardColor.UNRECOGNIZED;
  }
}

export function cardColorToJSON(object: CardColor): string {
  switch (object) {
    case CardColor.noColor:
      return "noColor";
    case CardColor.primary:
      return "primary";
    case CardColor.secondary:
      return "secondary";
    case CardColor.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum CardType {
  /** noCardType - So that every meaningful status is not zero, hence exported. */
  noCardType = "noCardType",
  header = "header",
  text = "text",
  background_text = "background_text",
  price_block = "price_block",
  button = "button",
  payment_safe = "payment_safe",
  image = "image",
  background_text2 = "background_text2",
  payment = "payment",
  styled_text = "styled_text",
  styled_header = "styled_header",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function cardTypeFromJSON(object: any): CardType {
  switch (object) {
    case 0:
    case "noCardType":
      return CardType.noCardType;
    case 1:
    case "header":
      return CardType.header;
    case 2:
    case "text":
      return CardType.text;
    case 3:
    case "background_text":
      return CardType.background_text;
    case 4:
    case "price_block":
      return CardType.price_block;
    case 5:
    case "button":
      return CardType.button;
    case 6:
    case "payment_safe":
      return CardType.payment_safe;
    case 7:
    case "image":
      return CardType.image;
    case 8:
    case "background_text2":
      return CardType.background_text2;
    case 9:
    case "payment":
      return CardType.payment;
    case 10:
    case "styled_text":
      return CardType.styled_text;
    case 11:
    case "styled_header":
      return CardType.styled_header;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CardType.UNRECOGNIZED;
  }
}

export function cardTypeToJSON(object: CardType): string {
  switch (object) {
    case CardType.noCardType:
      return "noCardType";
    case CardType.header:
      return "header";
    case CardType.text:
      return "text";
    case CardType.background_text:
      return "background_text";
    case CardType.price_block:
      return "price_block";
    case CardType.button:
      return "button";
    case CardType.payment_safe:
      return "payment_safe";
    case CardType.image:
      return "image";
    case CardType.background_text2:
      return "background_text2";
    case CardType.payment:
      return "payment";
    case CardType.styled_text:
      return "styled_text";
    case CardType.styled_header:
      return "styled_header";
    case CardType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Bullet {
  noBullet = "noBullet",
  Mark = "Mark",
  Empty = "Empty",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function bulletFromJSON(object: any): Bullet {
  switch (object) {
    case 0:
    case "noBullet":
      return Bullet.noBullet;
    case 1:
    case "Mark":
      return Bullet.Mark;
    case 2:
    case "Empty":
      return Bullet.Empty;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Bullet.UNRECOGNIZED;
  }
}

export function bulletToJSON(object: Bullet): string {
  switch (object) {
    case Bullet.noBullet:
      return "noBullet";
    case Bullet.Mark:
      return "Mark";
    case Bullet.Empty:
      return "Empty";
    case Bullet.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PaymentProviders {
  PAYMENT_PROVIDER_UNSPECIFIED = "PAYMENT_PROVIDER_UNSPECIFIED",
  PAYMENT_PROVIDER_PALTA_CARD = "PAYMENT_PROVIDER_PALTA_CARD",
  PAYMENT_PROVIDER_PALTA_PAYPAL = "PAYMENT_PROVIDER_PALTA_PAYPAL",
  PAYMENT_PROVIDER_STRIPE = "PAYMENT_PROVIDER_STRIPE",
  PAYMENT_PROVIDER_PAYPAL = "PAYMENT_PROVIDER_PAYPAL",
  PAYMENT_PROVIDER_RECURLY = "PAYMENT_PROVIDER_RECURLY",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function paymentProvidersFromJSON(object: any): PaymentProviders {
  switch (object) {
    case 0:
    case "PAYMENT_PROVIDER_UNSPECIFIED":
      return PaymentProviders.PAYMENT_PROVIDER_UNSPECIFIED;
    case 1:
    case "PAYMENT_PROVIDER_PALTA_CARD":
      return PaymentProviders.PAYMENT_PROVIDER_PALTA_CARD;
    case 2:
    case "PAYMENT_PROVIDER_PALTA_PAYPAL":
      return PaymentProviders.PAYMENT_PROVIDER_PALTA_PAYPAL;
    case 3:
    case "PAYMENT_PROVIDER_STRIPE":
      return PaymentProviders.PAYMENT_PROVIDER_STRIPE;
    case 4:
    case "PAYMENT_PROVIDER_PAYPAL":
      return PaymentProviders.PAYMENT_PROVIDER_PAYPAL;
    case 5:
    case "PAYMENT_PROVIDER_RECURLY":
      return PaymentProviders.PAYMENT_PROVIDER_RECURLY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PaymentProviders.UNRECOGNIZED;
  }
}

export function paymentProvidersToJSON(object: PaymentProviders): string {
  switch (object) {
    case PaymentProviders.PAYMENT_PROVIDER_UNSPECIFIED:
      return "PAYMENT_PROVIDER_UNSPECIFIED";
    case PaymentProviders.PAYMENT_PROVIDER_PALTA_CARD:
      return "PAYMENT_PROVIDER_PALTA_CARD";
    case PaymentProviders.PAYMENT_PROVIDER_PALTA_PAYPAL:
      return "PAYMENT_PROVIDER_PALTA_PAYPAL";
    case PaymentProviders.PAYMENT_PROVIDER_STRIPE:
      return "PAYMENT_PROVIDER_STRIPE";
    case PaymentProviders.PAYMENT_PROVIDER_PAYPAL:
      return "PAYMENT_PROVIDER_PAYPAL";
    case PaymentProviders.PAYMENT_PROVIDER_RECURLY:
      return "PAYMENT_PROVIDER_RECURLY";
    case PaymentProviders.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** @deprecated */
export enum PaymentMethods {
  /** noPaymentMethod - So that every meaningful payment method is not zero, hence exported. */
  noPaymentMethod = "noPaymentMethod",
  paypal = "paypal",
  card = "card",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function paymentMethodsFromJSON(object: any): PaymentMethods {
  switch (object) {
    case 0:
    case "noPaymentMethod":
      return PaymentMethods.noPaymentMethod;
    case 1:
    case "paypal":
      return PaymentMethods.paypal;
    case 2:
    case "card":
      return PaymentMethods.card;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PaymentMethods.UNRECOGNIZED;
  }
}

export function paymentMethodsToJSON(object: PaymentMethods): string {
  switch (object) {
    case PaymentMethods.noPaymentMethod:
      return "noPaymentMethod";
    case PaymentMethods.paypal:
      return "paypal";
    case PaymentMethods.card:
      return "card";
    case PaymentMethods.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SkinIssues {
  SKIN_ISSUE_UNSPECIFIED = "SKIN_ISSUE_UNSPECIFIED",
  SKIN_ISSUE_FACE_SHAPE = "SKIN_ISSUE_FACE_SHAPE",
  SKIN_ISSUE_PIGMENTATION = "SKIN_ISSUE_PIGMENTATION",
  SKIN_ISSUE_SWELLING = "SKIN_ISSUE_SWELLING",
  SKIN_ISSUE_HYPERSENSITIVITY = "SKIN_ISSUE_HYPERSENSITIVITY",
  SKIN_ISSUE_DRYNESS = "SKIN_ISSUE_DRYNESS",
  SKIN_ISSUE_ACNE = "SKIN_ISSUE_ACNE",
  SKIN_ISSUE_BLACKHEADS = "SKIN_ISSUE_BLACKHEADS",
  SKIN_ISSUE_OILY = "SKIN_ISSUE_OILY",
  SKIN_ISSUE_POSTACNE = "SKIN_ISSUE_POSTACNE",
  SKIN_ISSUE_WRINKLES = "SKIN_ISSUE_WRINKLES",
  SKIN_ISSUE_REDNESS = "SKIN_ISSUE_REDNESS",
  SKIN_ISSUE_PUFFINESS = "SKIN_ISSUE_PUFFINESS",
  SKIN_ISSUE_UNDER_EYE_ZONE = "SKIN_ISSUE_UNDER_EYE_ZONE",
  SKIN_ISSUE_ENLARGED_PORES = "SKIN_ISSUE_ENLARGED_PORES",
  SKIN_ISSUE_DULLNESS = "SKIN_ISSUE_DULLNESS",
  SKIN_ISSUE_ROUGH_TEXTURE = "SKIN_ISSUE_ROUGH_TEXTURE",
  SKIN_ISSUE_UNEVEN_SKIN_TONE = "SKIN_ISSUE_UNEVEN_SKIN_TONE",
  SKIN_ISSUE_TEXTURAL_IRREGULARITIES = "SKIN_ISSUE_TEXTURAL_IRREGULARITIES",
  SKIN_ISSUE_CROWS_FEET = "SKIN_ISSUE_CROWS_FEET",
  SKIN_ISSUE_DOUBLE_CHIN = "SKIN_ISSUE_DOUBLE_CHIN",
  SKIN_ISSUE_DARK_CIRCLES = "SKIN_ISSUE_DARK_CIRCLES",
  SKIN_ISSUE_SAGGING_SKIN = "SKIN_ISSUE_SAGGING_SKIN",
  SKIN_ISSUE_NONE = "SKIN_ISSUE_NONE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function skinIssuesFromJSON(object: any): SkinIssues {
  switch (object) {
    case 0:
    case "SKIN_ISSUE_UNSPECIFIED":
      return SkinIssues.SKIN_ISSUE_UNSPECIFIED;
    case 1:
    case "SKIN_ISSUE_FACE_SHAPE":
      return SkinIssues.SKIN_ISSUE_FACE_SHAPE;
    case 2:
    case "SKIN_ISSUE_PIGMENTATION":
      return SkinIssues.SKIN_ISSUE_PIGMENTATION;
    case 3:
    case "SKIN_ISSUE_SWELLING":
      return SkinIssues.SKIN_ISSUE_SWELLING;
    case 4:
    case "SKIN_ISSUE_HYPERSENSITIVITY":
      return SkinIssues.SKIN_ISSUE_HYPERSENSITIVITY;
    case 5:
    case "SKIN_ISSUE_DRYNESS":
      return SkinIssues.SKIN_ISSUE_DRYNESS;
    case 6:
    case "SKIN_ISSUE_ACNE":
      return SkinIssues.SKIN_ISSUE_ACNE;
    case 7:
    case "SKIN_ISSUE_BLACKHEADS":
      return SkinIssues.SKIN_ISSUE_BLACKHEADS;
    case 8:
    case "SKIN_ISSUE_OILY":
      return SkinIssues.SKIN_ISSUE_OILY;
    case 9:
    case "SKIN_ISSUE_POSTACNE":
      return SkinIssues.SKIN_ISSUE_POSTACNE;
    case 10:
    case "SKIN_ISSUE_WRINKLES":
      return SkinIssues.SKIN_ISSUE_WRINKLES;
    case 11:
    case "SKIN_ISSUE_REDNESS":
      return SkinIssues.SKIN_ISSUE_REDNESS;
    case 12:
    case "SKIN_ISSUE_PUFFINESS":
      return SkinIssues.SKIN_ISSUE_PUFFINESS;
    case 13:
    case "SKIN_ISSUE_UNDER_EYE_ZONE":
      return SkinIssues.SKIN_ISSUE_UNDER_EYE_ZONE;
    case 14:
    case "SKIN_ISSUE_ENLARGED_PORES":
      return SkinIssues.SKIN_ISSUE_ENLARGED_PORES;
    case 15:
    case "SKIN_ISSUE_DULLNESS":
      return SkinIssues.SKIN_ISSUE_DULLNESS;
    case 16:
    case "SKIN_ISSUE_ROUGH_TEXTURE":
      return SkinIssues.SKIN_ISSUE_ROUGH_TEXTURE;
    case 17:
    case "SKIN_ISSUE_UNEVEN_SKIN_TONE":
      return SkinIssues.SKIN_ISSUE_UNEVEN_SKIN_TONE;
    case 18:
    case "SKIN_ISSUE_TEXTURAL_IRREGULARITIES":
      return SkinIssues.SKIN_ISSUE_TEXTURAL_IRREGULARITIES;
    case 19:
    case "SKIN_ISSUE_CROWS_FEET":
      return SkinIssues.SKIN_ISSUE_CROWS_FEET;
    case 20:
    case "SKIN_ISSUE_DOUBLE_CHIN":
      return SkinIssues.SKIN_ISSUE_DOUBLE_CHIN;
    case 21:
    case "SKIN_ISSUE_DARK_CIRCLES":
      return SkinIssues.SKIN_ISSUE_DARK_CIRCLES;
    case 22:
    case "SKIN_ISSUE_SAGGING_SKIN":
      return SkinIssues.SKIN_ISSUE_SAGGING_SKIN;
    case 23:
    case "SKIN_ISSUE_NONE":
      return SkinIssues.SKIN_ISSUE_NONE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SkinIssues.UNRECOGNIZED;
  }
}

export function skinIssuesToJSON(object: SkinIssues): string {
  switch (object) {
    case SkinIssues.SKIN_ISSUE_UNSPECIFIED:
      return "SKIN_ISSUE_UNSPECIFIED";
    case SkinIssues.SKIN_ISSUE_FACE_SHAPE:
      return "SKIN_ISSUE_FACE_SHAPE";
    case SkinIssues.SKIN_ISSUE_PIGMENTATION:
      return "SKIN_ISSUE_PIGMENTATION";
    case SkinIssues.SKIN_ISSUE_SWELLING:
      return "SKIN_ISSUE_SWELLING";
    case SkinIssues.SKIN_ISSUE_HYPERSENSITIVITY:
      return "SKIN_ISSUE_HYPERSENSITIVITY";
    case SkinIssues.SKIN_ISSUE_DRYNESS:
      return "SKIN_ISSUE_DRYNESS";
    case SkinIssues.SKIN_ISSUE_ACNE:
      return "SKIN_ISSUE_ACNE";
    case SkinIssues.SKIN_ISSUE_BLACKHEADS:
      return "SKIN_ISSUE_BLACKHEADS";
    case SkinIssues.SKIN_ISSUE_OILY:
      return "SKIN_ISSUE_OILY";
    case SkinIssues.SKIN_ISSUE_POSTACNE:
      return "SKIN_ISSUE_POSTACNE";
    case SkinIssues.SKIN_ISSUE_WRINKLES:
      return "SKIN_ISSUE_WRINKLES";
    case SkinIssues.SKIN_ISSUE_REDNESS:
      return "SKIN_ISSUE_REDNESS";
    case SkinIssues.SKIN_ISSUE_PUFFINESS:
      return "SKIN_ISSUE_PUFFINESS";
    case SkinIssues.SKIN_ISSUE_UNDER_EYE_ZONE:
      return "SKIN_ISSUE_UNDER_EYE_ZONE";
    case SkinIssues.SKIN_ISSUE_ENLARGED_PORES:
      return "SKIN_ISSUE_ENLARGED_PORES";
    case SkinIssues.SKIN_ISSUE_DULLNESS:
      return "SKIN_ISSUE_DULLNESS";
    case SkinIssues.SKIN_ISSUE_ROUGH_TEXTURE:
      return "SKIN_ISSUE_ROUGH_TEXTURE";
    case SkinIssues.SKIN_ISSUE_UNEVEN_SKIN_TONE:
      return "SKIN_ISSUE_UNEVEN_SKIN_TONE";
    case SkinIssues.SKIN_ISSUE_TEXTURAL_IRREGULARITIES:
      return "SKIN_ISSUE_TEXTURAL_IRREGULARITIES";
    case SkinIssues.SKIN_ISSUE_CROWS_FEET:
      return "SKIN_ISSUE_CROWS_FEET";
    case SkinIssues.SKIN_ISSUE_DOUBLE_CHIN:
      return "SKIN_ISSUE_DOUBLE_CHIN";
    case SkinIssues.SKIN_ISSUE_DARK_CIRCLES:
      return "SKIN_ISSUE_DARK_CIRCLES";
    case SkinIssues.SKIN_ISSUE_SAGGING_SKIN:
      return "SKIN_ISSUE_SAGGING_SKIN";
    case SkinIssues.SKIN_ISSUE_NONE:
      return "SKIN_ISSUE_NONE";
    case SkinIssues.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SkinSensitivity {
  SKIN_SENSITIVITY_UNSPECIFIED = "SKIN_SENSITIVITY_UNSPECIFIED",
  SKIN_SENSITIVITY_HIGH = "SKIN_SENSITIVITY_HIGH",
  SKIN_SENSITIVITY_MODERATE = "SKIN_SENSITIVITY_MODERATE",
  SKIN_SENSITIVITY_NON_SENSITIVE = "SKIN_SENSITIVITY_NON_SENSITIVE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function skinSensitivityFromJSON(object: any): SkinSensitivity {
  switch (object) {
    case 0:
    case "SKIN_SENSITIVITY_UNSPECIFIED":
      return SkinSensitivity.SKIN_SENSITIVITY_UNSPECIFIED;
    case 1:
    case "SKIN_SENSITIVITY_HIGH":
      return SkinSensitivity.SKIN_SENSITIVITY_HIGH;
    case 2:
    case "SKIN_SENSITIVITY_MODERATE":
      return SkinSensitivity.SKIN_SENSITIVITY_MODERATE;
    case 3:
    case "SKIN_SENSITIVITY_NON_SENSITIVE":
      return SkinSensitivity.SKIN_SENSITIVITY_NON_SENSITIVE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SkinSensitivity.UNRECOGNIZED;
  }
}

export function skinSensitivityToJSON(object: SkinSensitivity): string {
  switch (object) {
    case SkinSensitivity.SKIN_SENSITIVITY_UNSPECIFIED:
      return "SKIN_SENSITIVITY_UNSPECIFIED";
    case SkinSensitivity.SKIN_SENSITIVITY_HIGH:
      return "SKIN_SENSITIVITY_HIGH";
    case SkinSensitivity.SKIN_SENSITIVITY_MODERATE:
      return "SKIN_SENSITIVITY_MODERATE";
    case SkinSensitivity.SKIN_SENSITIVITY_NON_SENSITIVE:
      return "SKIN_SENSITIVITY_NON_SENSITIVE";
    case SkinSensitivity.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SkinType {
  SKIN_TYPE_UNSPECIFIED = "SKIN_TYPE_UNSPECIFIED",
  SKIN_TYPE_DRY = "SKIN_TYPE_DRY",
  SKIN_TYPE_NORMAL = "SKIN_TYPE_NORMAL",
  SKIN_TYPE_OILY = "SKIN_TYPE_OILY",
  SKIN_TYPE_COMBINATION = "SKIN_TYPE_COMBINATION",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function skinTypeFromJSON(object: any): SkinType {
  switch (object) {
    case 0:
    case "SKIN_TYPE_UNSPECIFIED":
      return SkinType.SKIN_TYPE_UNSPECIFIED;
    case 1:
    case "SKIN_TYPE_DRY":
      return SkinType.SKIN_TYPE_DRY;
    case 2:
    case "SKIN_TYPE_NORMAL":
      return SkinType.SKIN_TYPE_NORMAL;
    case 3:
    case "SKIN_TYPE_OILY":
      return SkinType.SKIN_TYPE_OILY;
    case 4:
    case "SKIN_TYPE_COMBINATION":
      return SkinType.SKIN_TYPE_COMBINATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SkinType.UNRECOGNIZED;
  }
}

export function skinTypeToJSON(object: SkinType): string {
  switch (object) {
    case SkinType.SKIN_TYPE_UNSPECIFIED:
      return "SKIN_TYPE_UNSPECIFIED";
    case SkinType.SKIN_TYPE_DRY:
      return "SKIN_TYPE_DRY";
    case SkinType.SKIN_TYPE_NORMAL:
      return "SKIN_TYPE_NORMAL";
    case SkinType.SKIN_TYPE_OILY:
      return "SKIN_TYPE_OILY";
    case SkinType.SKIN_TYPE_COMBINATION:
      return "SKIN_TYPE_COMBINATION";
    case SkinType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PaywallBlocks {
  /** PB_NOTHING - So that every meaningful paywall block is not zero, hence exported. */
  PB_NOTHING = "PB_NOTHING",
  PB_HEADER = "PB_HEADER",
  PB_SUMMARY = "PB_SUMMARY",
  PB_PROGRAM_BENEFITS = "PB_PROGRAM_BENEFITS",
  PB_REVIEWS = "PB_REVIEWS",
  PB_MILESTONES = "PB_MILESTONES",
  /**
   * PB_PAYMENT_POPUP - TODO delete
   *
   * @deprecated
   */
  PB_PAYMENT_POPUP = "PB_PAYMENT_POPUP",
  PB_PAYMENT_FORM = "PB_PAYMENT_FORM",
  PB_APP_SCREENSHOTS = "PB_APP_SCREENSHOTS",
  PB_HEADER_2 = "PB_HEADER_2",
  PB_PROGRAM_2 = "PB_PROGRAM_2",
  PB_MONEYBACK = "PB_MONEYBACK",
  PB_PAYMENT_FORM_WITH_INVOICE = "PB_PAYMENT_FORM_WITH_INVOICE",
  PB_INVOICE = "PB_INVOICE",
  PB_PRICE_SELECT = "PB_PRICE_SELECT",
  PB_PERSONAL_PRODUCTS = "PB_PERSONAL_PRODUCTS",
  PB_FACE_EXERCISES = "PB_FACE_EXERCISES",
  PB_PROGRAM_BENEFITS_BETTER_ME = "PB_PROGRAM_BENEFITS_BETTER_ME",
  PB_PEOPLE_OFTEN_ASK = "PB_PEOPLE_OFTEN_ASK",
  PB_WE_ARE_PALTA = "PB_WE_ARE_PALTA",
  PB_REVIEWS_BETTER_ME = "PB_REVIEWS_BETTER_ME",
  PB_HEADER_PRE_PAYWALL = "PB_HEADER_PRE_PAYWALL",
  PB_PROGRAM_BENEFITS_PRE_PAYWALL = "PB_PROGRAM_BENEFITS_PRE_PAYWALL",
  PB_REVIEWS_PRE_PAYWALL = "PB_REVIEWS_PRE_PAYWALL",
  PB_PRICE_SELECT_POPUP = "PB_PRICE_SELECT_POPUP",
  PB_PRICE_SELECT_POPUP_PRE_PAYWALL = "PB_PRICE_SELECT_POPUP_PRE_PAYWALL",
  PB_INVOICE_PRE_PAYWALL = "PB_INVOICE_PRE_PAYWALL",
  PB_HEADER_GT = "PB_HEADER_GT",
  PB_PROGRAM_BENEFITS_GT = "PB_PROGRAM_BENEFITS_GT",
  PB_HARMFUL_PRODUCTS = "PB_HARMFUL_PRODUCTS",
  PB_HEADER_GT2 = "PB_HEADER_GT2",
  PB_PRICE_SELECT2 = "PB_PRICE_SELECT2",
  PB_PAYMENT_FORM_WITH_STATIC_TEXT = "PB_PAYMENT_FORM_WITH_STATIC_TEXT",
  PB_HEADER_GTI = "PB_HEADER_GTI",
  PB_HOW_TRIAL_WORKS_INVOICE = "PB_HOW_TRIAL_WORKS_INVOICE",
  PB_HOW_TRIAL_WORKS_SHORT_INVOICE = "PB_HOW_TRIAL_WORKS_SHORT_INVOICE",
  PB_PRICE_SELECT_WITH_GOAL_AND_DATE = "PB_PRICE_SELECT_WITH_GOAL_AND_DATE",
  PB_BEFORE_AFTER_BASED_ON_GOAL = "PB_BEFORE_AFTER_BASED_ON_GOAL",
  PB_REVIEWS_WITH_BEFORE_AFTER_PHOTOS = "PB_REVIEWS_WITH_BEFORE_AFTER_PHOTOS",
  PB_PRICE_SELECT_WITH_GOAL_AND_DATE_2 = "PB_PRICE_SELECT_WITH_GOAL_AND_DATE_2",
  PB_PROGRAM_BENEFITS_2 = "PB_PROGRAM_BENEFITS_2",
  PB_MONEYBACK_2 = "PB_MONEYBACK_2",
  PB_PRICE_SELECT_3 = "PB_PRICE_SELECT_3",
  PB_MONEYBACK_INTRO = "PB_MONEYBACK_INTRO",
  PB_UPSALE_SKIP_TRIAL = "PB_UPSALE_SKIP_TRIAL",
  PB_UPSALE_WORKBOOK = "PB_UPSALE_WORKBOOK",
  PB_HEADER_LUVLY = "PB_HEADER_LUVLY",
  PB_PRICE_SELECT_WITH_GOAL_AND_DATE_3 = "PB_PRICE_SELECT_WITH_GOAL_AND_DATE_3",
  PB_PRICE_SELECT_WITH_GOAL_AND_DATE_4 = "PB_PRICE_SELECT_WITH_GOAL_AND_DATE_4",
  PB_HEADER_LUVLY_STATIC = "PB_HEADER_LUVLY_STATIC",
  PB_PAYMENT_FORM_WITH_INVOICE_3_IN_POPUP = "PB_PAYMENT_FORM_WITH_INVOICE_3_IN_POPUP",
  PB_PRICE_SELECT_4 = "PB_PRICE_SELECT_4",
  PB_FACESCAN_RESULTS = "PB_FACESCAN_RESULTS",
  PB_HEADER_WITH_LIST = "PB_HEADER_WITH_LIST",
  PB_HEADER_WITH_PHOTO = "PB_HEADER_WITH_PHOTO",
  PB_TIPS = "PB_TIPS",
  PB_STATIC_CITATION_1 = "PB_STATIC_CITATION_1",
  PB_STATIC_CITATION_2 = "PB_STATIC_CITATION_2",
  PB_STATIC_EXPERTS = "PB_STATIC_EXPERTS",
  PB_PRODUCTS_IMAGES_1 = "PB_PRODUCTS_IMAGES_1",
  PB_PRODUCTS_IMAGES_2 = "PB_PRODUCTS_IMAGES_2",
  PB_STATIC_MAP = "PB_STATIC_MAP",
  PB_PRICE_SELECT_4_1 = "PB_PRICE_SELECT_4_1",
  PB_PRICE_SELECT_4_2 = "PB_PRICE_SELECT_4_2",
  PB_TIMER_FLOAT_4 = "PB_TIMER_FLOAT_4",
  PB_UPSALE_SUBSCRIPTION_ON_EXIT = "PB_UPSALE_SUBSCRIPTION_ON_EXIT",
  PB_IMAGE = "PB_IMAGE",
  PB_HEADER_WITH_LIST_TIMER_CHART = "PB_HEADER_WITH_LIST_TIMER_CHART",
  PB_UPSALE_AI_SCANNER = "PB_UPSALE_AI_SCANNER",
  PB_TIMER_FLOAT_5_TOP = "PB_TIMER_FLOAT_5_TOP",
  PB_TIMER_FLOAT_5_BOTTOM = "PB_TIMER_FLOAT_5_BOTTOM",
  PB_PRICE_SELECT_5 = "PB_PRICE_SELECT_5",
  PB_PRICE_SELECT_6 = "PB_PRICE_SELECT_6",
  PB_PRICE_SELECT_5_2 = "PB_PRICE_SELECT_5_2",
  PB_ENHANCED_PHOTO = "PB_ENHANCED_PHOTO",
  PB_PRICE_SELECT_6_2 = "PB_PRICE_SELECT_6_2",
  PB_PRICE_SELECT_7 = "PB_PRICE_SELECT_7",
  PB_PRICE_SELECT_8 = "PB_PRICE_SELECT_8",
  PB_UPSALE_AI_SCANNER_2 = "PB_UPSALE_AI_SCANNER_2",
  PB_AI_GENERATED_PLAN = "PB_AI_GENERATED_PLAN",
  PB_VIDEO_REVIEWS = "PB_VIDEO_REVIEWS",
  PB_IMAGES_CAROUSEL = "PB_IMAGES_CAROUSEL",
  PB_SUBSCRIPTION_INFO_TEXT = "PB_SUBSCRIPTION_INFO_TEXT",
  PB_SUBSCRIPTION_INFO_TEXT_2 = "PB_SUBSCRIPTION_INFO_TEXT_2",
  PB_IMAGE_WITH_BUTTON = "PB_IMAGE_WITH_BUTTON",
  PB_MONEYBACK_3 = "PB_MONEYBACK_3",
  PB_STATIC_BENEFITS = "PB_STATIC_BENEFITS",
  PB_TIMER_FLOAT_6 = "PB_TIMER_FLOAT_6",
  PB_TIMER_DISCOUNT_4 = "PB_TIMER_DISCOUNT_4",
  PB_STATIC_COPYRIGHT = "PB_STATIC_COPYRIGHT",
  PB_UPSALE_NEW_SUBSCRIPTIONS = "PB_UPSALE_NEW_SUBSCRIPTIONS",
  PB_UPSALE_AI_SCANNER_3 = "PB_UPSALE_AI_SCANNER_3",
  PB_UPSALE_AI_ASSISTANT_1 = "PB_UPSALE_AI_ASSISTANT_1",
  PB_UPSALE_SHOPIFY_PRODUCTS = "PB_UPSALE_SHOPIFY_PRODUCTS",
  PB_BEFORE_AFTER_WITH_TIMER = "PB_BEFORE_AFTER_WITH_TIMER",
  PB_ENHANCED_PHOTO_2 = "PB_ENHANCED_PHOTO_2",
  PB_HEADER_3 = "PB_HEADER_3",
  PB_IMAGE_2 = "PB_IMAGE_2",
  PB_UPSALE_BUNDLE = "PB_UPSALE_BUNDLE",
  PB_UPSALE_AI_ASSISTANT_2 = "PB_UPSALE_AI_ASSISTANT_2",
  PB_HEADER_4 = "PB_HEADER_4",
  PB_HEADER_5 = "PB_HEADER_5",
  PB_ENHANCED_PHOTO_3 = "PB_ENHANCED_PHOTO_3",
  PB_REVIEWS_5 = "PB_REVIEWS_5",
  PB_STATIC_CITATION_3 = "PB_STATIC_CITATION_3",
  PB_IMAGE_3 = "PB_IMAGE_3",
  PB_REVIEWS_6 = "PB_REVIEWS_6",
  PB_PRICE_SELECT_9 = "PB_PRICE_SELECT_9",
  PB_PRICE_SELECT_9_2 = "PB_PRICE_SELECT_9_2",
  PB_STATIC_MEDICAL_ADVISERS = "PB_STATIC_MEDICAL_ADVISERS",
  PB_TIME_LIMITED_OFFER = "PB_TIME_LIMITED_OFFER",
  PB_STATIC_SPANISH_PRICE_COMPARISON = "PB_STATIC_SPANISH_PRICE_COMPARISON",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function paywallBlocksFromJSON(object: any): PaywallBlocks {
  switch (object) {
    case 0:
    case "PB_NOTHING":
      return PaywallBlocks.PB_NOTHING;
    case 1:
    case "PB_HEADER":
      return PaywallBlocks.PB_HEADER;
    case 2:
    case "PB_SUMMARY":
      return PaywallBlocks.PB_SUMMARY;
    case 3:
    case "PB_PROGRAM_BENEFITS":
      return PaywallBlocks.PB_PROGRAM_BENEFITS;
    case 4:
    case "PB_REVIEWS":
      return PaywallBlocks.PB_REVIEWS;
    case 5:
    case "PB_MILESTONES":
      return PaywallBlocks.PB_MILESTONES;
    case 6:
    case "PB_PAYMENT_POPUP":
      return PaywallBlocks.PB_PAYMENT_POPUP;
    case 7:
    case "PB_PAYMENT_FORM":
      return PaywallBlocks.PB_PAYMENT_FORM;
    case 8:
    case "PB_APP_SCREENSHOTS":
      return PaywallBlocks.PB_APP_SCREENSHOTS;
    case 9:
    case "PB_HEADER_2":
      return PaywallBlocks.PB_HEADER_2;
    case 10:
    case "PB_PROGRAM_2":
      return PaywallBlocks.PB_PROGRAM_2;
    case 11:
    case "PB_MONEYBACK":
      return PaywallBlocks.PB_MONEYBACK;
    case 12:
    case "PB_PAYMENT_FORM_WITH_INVOICE":
      return PaywallBlocks.PB_PAYMENT_FORM_WITH_INVOICE;
    case 13:
    case "PB_INVOICE":
      return PaywallBlocks.PB_INVOICE;
    case 14:
    case "PB_PRICE_SELECT":
      return PaywallBlocks.PB_PRICE_SELECT;
    case 15:
    case "PB_PERSONAL_PRODUCTS":
      return PaywallBlocks.PB_PERSONAL_PRODUCTS;
    case 16:
    case "PB_FACE_EXERCISES":
      return PaywallBlocks.PB_FACE_EXERCISES;
    case 17:
    case "PB_PROGRAM_BENEFITS_BETTER_ME":
      return PaywallBlocks.PB_PROGRAM_BENEFITS_BETTER_ME;
    case 18:
    case "PB_PEOPLE_OFTEN_ASK":
      return PaywallBlocks.PB_PEOPLE_OFTEN_ASK;
    case 19:
    case "PB_WE_ARE_PALTA":
      return PaywallBlocks.PB_WE_ARE_PALTA;
    case 20:
    case "PB_REVIEWS_BETTER_ME":
      return PaywallBlocks.PB_REVIEWS_BETTER_ME;
    case 21:
    case "PB_HEADER_PRE_PAYWALL":
      return PaywallBlocks.PB_HEADER_PRE_PAYWALL;
    case 22:
    case "PB_PROGRAM_BENEFITS_PRE_PAYWALL":
      return PaywallBlocks.PB_PROGRAM_BENEFITS_PRE_PAYWALL;
    case 23:
    case "PB_REVIEWS_PRE_PAYWALL":
      return PaywallBlocks.PB_REVIEWS_PRE_PAYWALL;
    case 24:
    case "PB_PRICE_SELECT_POPUP":
      return PaywallBlocks.PB_PRICE_SELECT_POPUP;
    case 25:
    case "PB_PRICE_SELECT_POPUP_PRE_PAYWALL":
      return PaywallBlocks.PB_PRICE_SELECT_POPUP_PRE_PAYWALL;
    case 26:
    case "PB_INVOICE_PRE_PAYWALL":
      return PaywallBlocks.PB_INVOICE_PRE_PAYWALL;
    case 27:
    case "PB_HEADER_GT":
      return PaywallBlocks.PB_HEADER_GT;
    case 28:
    case "PB_PROGRAM_BENEFITS_GT":
      return PaywallBlocks.PB_PROGRAM_BENEFITS_GT;
    case 29:
    case "PB_HARMFUL_PRODUCTS":
      return PaywallBlocks.PB_HARMFUL_PRODUCTS;
    case 30:
    case "PB_HEADER_GT2":
      return PaywallBlocks.PB_HEADER_GT2;
    case 31:
    case "PB_PRICE_SELECT2":
      return PaywallBlocks.PB_PRICE_SELECT2;
    case 32:
    case "PB_PAYMENT_FORM_WITH_STATIC_TEXT":
      return PaywallBlocks.PB_PAYMENT_FORM_WITH_STATIC_TEXT;
    case 33:
    case "PB_HEADER_GTI":
      return PaywallBlocks.PB_HEADER_GTI;
    case 34:
    case "PB_HOW_TRIAL_WORKS_INVOICE":
      return PaywallBlocks.PB_HOW_TRIAL_WORKS_INVOICE;
    case 35:
    case "PB_HOW_TRIAL_WORKS_SHORT_INVOICE":
      return PaywallBlocks.PB_HOW_TRIAL_WORKS_SHORT_INVOICE;
    case 36:
    case "PB_PRICE_SELECT_WITH_GOAL_AND_DATE":
      return PaywallBlocks.PB_PRICE_SELECT_WITH_GOAL_AND_DATE;
    case 37:
    case "PB_BEFORE_AFTER_BASED_ON_GOAL":
      return PaywallBlocks.PB_BEFORE_AFTER_BASED_ON_GOAL;
    case 38:
    case "PB_REVIEWS_WITH_BEFORE_AFTER_PHOTOS":
      return PaywallBlocks.PB_REVIEWS_WITH_BEFORE_AFTER_PHOTOS;
    case 39:
    case "PB_PRICE_SELECT_WITH_GOAL_AND_DATE_2":
      return PaywallBlocks.PB_PRICE_SELECT_WITH_GOAL_AND_DATE_2;
    case 40:
    case "PB_PROGRAM_BENEFITS_2":
      return PaywallBlocks.PB_PROGRAM_BENEFITS_2;
    case 41:
    case "PB_MONEYBACK_2":
      return PaywallBlocks.PB_MONEYBACK_2;
    case 42:
    case "PB_PRICE_SELECT_3":
      return PaywallBlocks.PB_PRICE_SELECT_3;
    case 43:
    case "PB_MONEYBACK_INTRO":
      return PaywallBlocks.PB_MONEYBACK_INTRO;
    case 44:
    case "PB_UPSALE_SKIP_TRIAL":
      return PaywallBlocks.PB_UPSALE_SKIP_TRIAL;
    case 45:
    case "PB_UPSALE_WORKBOOK":
      return PaywallBlocks.PB_UPSALE_WORKBOOK;
    case 46:
    case "PB_HEADER_LUVLY":
      return PaywallBlocks.PB_HEADER_LUVLY;
    case 47:
    case "PB_PRICE_SELECT_WITH_GOAL_AND_DATE_3":
      return PaywallBlocks.PB_PRICE_SELECT_WITH_GOAL_AND_DATE_3;
    case 48:
    case "PB_PRICE_SELECT_WITH_GOAL_AND_DATE_4":
      return PaywallBlocks.PB_PRICE_SELECT_WITH_GOAL_AND_DATE_4;
    case 49:
    case "PB_HEADER_LUVLY_STATIC":
      return PaywallBlocks.PB_HEADER_LUVLY_STATIC;
    case 50:
    case "PB_PAYMENT_FORM_WITH_INVOICE_3_IN_POPUP":
      return PaywallBlocks.PB_PAYMENT_FORM_WITH_INVOICE_3_IN_POPUP;
    case 51:
    case "PB_PRICE_SELECT_4":
      return PaywallBlocks.PB_PRICE_SELECT_4;
    case 52:
    case "PB_FACESCAN_RESULTS":
      return PaywallBlocks.PB_FACESCAN_RESULTS;
    case 53:
    case "PB_HEADER_WITH_LIST":
      return PaywallBlocks.PB_HEADER_WITH_LIST;
    case 54:
    case "PB_HEADER_WITH_PHOTO":
      return PaywallBlocks.PB_HEADER_WITH_PHOTO;
    case 55:
    case "PB_TIPS":
      return PaywallBlocks.PB_TIPS;
    case 56:
    case "PB_STATIC_CITATION_1":
      return PaywallBlocks.PB_STATIC_CITATION_1;
    case 57:
    case "PB_STATIC_CITATION_2":
      return PaywallBlocks.PB_STATIC_CITATION_2;
    case 58:
    case "PB_STATIC_EXPERTS":
      return PaywallBlocks.PB_STATIC_EXPERTS;
    case 59:
    case "PB_PRODUCTS_IMAGES_1":
      return PaywallBlocks.PB_PRODUCTS_IMAGES_1;
    case 60:
    case "PB_PRODUCTS_IMAGES_2":
      return PaywallBlocks.PB_PRODUCTS_IMAGES_2;
    case 61:
    case "PB_STATIC_MAP":
      return PaywallBlocks.PB_STATIC_MAP;
    case 62:
    case "PB_PRICE_SELECT_4_1":
      return PaywallBlocks.PB_PRICE_SELECT_4_1;
    case 63:
    case "PB_PRICE_SELECT_4_2":
      return PaywallBlocks.PB_PRICE_SELECT_4_2;
    case 64:
    case "PB_TIMER_FLOAT_4":
      return PaywallBlocks.PB_TIMER_FLOAT_4;
    case 65:
    case "PB_UPSALE_SUBSCRIPTION_ON_EXIT":
      return PaywallBlocks.PB_UPSALE_SUBSCRIPTION_ON_EXIT;
    case 66:
    case "PB_IMAGE":
      return PaywallBlocks.PB_IMAGE;
    case 67:
    case "PB_HEADER_WITH_LIST_TIMER_CHART":
      return PaywallBlocks.PB_HEADER_WITH_LIST_TIMER_CHART;
    case 68:
    case "PB_UPSALE_AI_SCANNER":
      return PaywallBlocks.PB_UPSALE_AI_SCANNER;
    case 69:
    case "PB_TIMER_FLOAT_5_TOP":
      return PaywallBlocks.PB_TIMER_FLOAT_5_TOP;
    case 70:
    case "PB_TIMER_FLOAT_5_BOTTOM":
      return PaywallBlocks.PB_TIMER_FLOAT_5_BOTTOM;
    case 71:
    case "PB_PRICE_SELECT_5":
      return PaywallBlocks.PB_PRICE_SELECT_5;
    case 72:
    case "PB_PRICE_SELECT_6":
      return PaywallBlocks.PB_PRICE_SELECT_6;
    case 73:
    case "PB_PRICE_SELECT_5_2":
      return PaywallBlocks.PB_PRICE_SELECT_5_2;
    case 74:
    case "PB_ENHANCED_PHOTO":
      return PaywallBlocks.PB_ENHANCED_PHOTO;
    case 75:
    case "PB_PRICE_SELECT_6_2":
      return PaywallBlocks.PB_PRICE_SELECT_6_2;
    case 76:
    case "PB_PRICE_SELECT_7":
      return PaywallBlocks.PB_PRICE_SELECT_7;
    case 77:
    case "PB_PRICE_SELECT_8":
      return PaywallBlocks.PB_PRICE_SELECT_8;
    case 78:
    case "PB_UPSALE_AI_SCANNER_2":
      return PaywallBlocks.PB_UPSALE_AI_SCANNER_2;
    case 79:
    case "PB_AI_GENERATED_PLAN":
      return PaywallBlocks.PB_AI_GENERATED_PLAN;
    case 80:
    case "PB_VIDEO_REVIEWS":
      return PaywallBlocks.PB_VIDEO_REVIEWS;
    case 81:
    case "PB_IMAGES_CAROUSEL":
      return PaywallBlocks.PB_IMAGES_CAROUSEL;
    case 82:
    case "PB_SUBSCRIPTION_INFO_TEXT":
      return PaywallBlocks.PB_SUBSCRIPTION_INFO_TEXT;
    case 83:
    case "PB_SUBSCRIPTION_INFO_TEXT_2":
      return PaywallBlocks.PB_SUBSCRIPTION_INFO_TEXT_2;
    case 84:
    case "PB_IMAGE_WITH_BUTTON":
      return PaywallBlocks.PB_IMAGE_WITH_BUTTON;
    case 85:
    case "PB_MONEYBACK_3":
      return PaywallBlocks.PB_MONEYBACK_3;
    case 86:
    case "PB_STATIC_BENEFITS":
      return PaywallBlocks.PB_STATIC_BENEFITS;
    case 87:
    case "PB_TIMER_FLOAT_6":
      return PaywallBlocks.PB_TIMER_FLOAT_6;
    case 88:
    case "PB_TIMER_DISCOUNT_4":
      return PaywallBlocks.PB_TIMER_DISCOUNT_4;
    case 89:
    case "PB_STATIC_COPYRIGHT":
      return PaywallBlocks.PB_STATIC_COPYRIGHT;
    case 90:
    case "PB_UPSALE_NEW_SUBSCRIPTIONS":
      return PaywallBlocks.PB_UPSALE_NEW_SUBSCRIPTIONS;
    case 91:
    case "PB_UPSALE_AI_SCANNER_3":
      return PaywallBlocks.PB_UPSALE_AI_SCANNER_3;
    case 92:
    case "PB_UPSALE_AI_ASSISTANT_1":
      return PaywallBlocks.PB_UPSALE_AI_ASSISTANT_1;
    case 93:
    case "PB_UPSALE_SHOPIFY_PRODUCTS":
      return PaywallBlocks.PB_UPSALE_SHOPIFY_PRODUCTS;
    case 94:
    case "PB_BEFORE_AFTER_WITH_TIMER":
      return PaywallBlocks.PB_BEFORE_AFTER_WITH_TIMER;
    case 95:
    case "PB_ENHANCED_PHOTO_2":
      return PaywallBlocks.PB_ENHANCED_PHOTO_2;
    case 96:
    case "PB_HEADER_3":
      return PaywallBlocks.PB_HEADER_3;
    case 97:
    case "PB_IMAGE_2":
      return PaywallBlocks.PB_IMAGE_2;
    case 98:
    case "PB_UPSALE_BUNDLE":
      return PaywallBlocks.PB_UPSALE_BUNDLE;
    case 99:
    case "PB_UPSALE_AI_ASSISTANT_2":
      return PaywallBlocks.PB_UPSALE_AI_ASSISTANT_2;
    case 100:
    case "PB_HEADER_4":
      return PaywallBlocks.PB_HEADER_4;
    case 101:
    case "PB_HEADER_5":
      return PaywallBlocks.PB_HEADER_5;
    case 102:
    case "PB_ENHANCED_PHOTO_3":
      return PaywallBlocks.PB_ENHANCED_PHOTO_3;
    case 103:
    case "PB_REVIEWS_5":
      return PaywallBlocks.PB_REVIEWS_5;
    case 104:
    case "PB_STATIC_CITATION_3":
      return PaywallBlocks.PB_STATIC_CITATION_3;
    case 105:
    case "PB_IMAGE_3":
      return PaywallBlocks.PB_IMAGE_3;
    case 106:
    case "PB_REVIEWS_6":
      return PaywallBlocks.PB_REVIEWS_6;
    case 107:
    case "PB_PRICE_SELECT_9":
      return PaywallBlocks.PB_PRICE_SELECT_9;
    case 108:
    case "PB_PRICE_SELECT_9_2":
      return PaywallBlocks.PB_PRICE_SELECT_9_2;
    case 109:
    case "PB_STATIC_MEDICAL_ADVISERS":
      return PaywallBlocks.PB_STATIC_MEDICAL_ADVISERS;
    case 110:
    case "PB_TIME_LIMITED_OFFER":
      return PaywallBlocks.PB_TIME_LIMITED_OFFER;
    case 111:
    case "PB_STATIC_SPANISH_PRICE_COMPARISON":
      return PaywallBlocks.PB_STATIC_SPANISH_PRICE_COMPARISON;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PaywallBlocks.UNRECOGNIZED;
  }
}

export function paywallBlocksToJSON(object: PaywallBlocks): string {
  switch (object) {
    case PaywallBlocks.PB_NOTHING:
      return "PB_NOTHING";
    case PaywallBlocks.PB_HEADER:
      return "PB_HEADER";
    case PaywallBlocks.PB_SUMMARY:
      return "PB_SUMMARY";
    case PaywallBlocks.PB_PROGRAM_BENEFITS:
      return "PB_PROGRAM_BENEFITS";
    case PaywallBlocks.PB_REVIEWS:
      return "PB_REVIEWS";
    case PaywallBlocks.PB_MILESTONES:
      return "PB_MILESTONES";
    case PaywallBlocks.PB_PAYMENT_POPUP:
      return "PB_PAYMENT_POPUP";
    case PaywallBlocks.PB_PAYMENT_FORM:
      return "PB_PAYMENT_FORM";
    case PaywallBlocks.PB_APP_SCREENSHOTS:
      return "PB_APP_SCREENSHOTS";
    case PaywallBlocks.PB_HEADER_2:
      return "PB_HEADER_2";
    case PaywallBlocks.PB_PROGRAM_2:
      return "PB_PROGRAM_2";
    case PaywallBlocks.PB_MONEYBACK:
      return "PB_MONEYBACK";
    case PaywallBlocks.PB_PAYMENT_FORM_WITH_INVOICE:
      return "PB_PAYMENT_FORM_WITH_INVOICE";
    case PaywallBlocks.PB_INVOICE:
      return "PB_INVOICE";
    case PaywallBlocks.PB_PRICE_SELECT:
      return "PB_PRICE_SELECT";
    case PaywallBlocks.PB_PERSONAL_PRODUCTS:
      return "PB_PERSONAL_PRODUCTS";
    case PaywallBlocks.PB_FACE_EXERCISES:
      return "PB_FACE_EXERCISES";
    case PaywallBlocks.PB_PROGRAM_BENEFITS_BETTER_ME:
      return "PB_PROGRAM_BENEFITS_BETTER_ME";
    case PaywallBlocks.PB_PEOPLE_OFTEN_ASK:
      return "PB_PEOPLE_OFTEN_ASK";
    case PaywallBlocks.PB_WE_ARE_PALTA:
      return "PB_WE_ARE_PALTA";
    case PaywallBlocks.PB_REVIEWS_BETTER_ME:
      return "PB_REVIEWS_BETTER_ME";
    case PaywallBlocks.PB_HEADER_PRE_PAYWALL:
      return "PB_HEADER_PRE_PAYWALL";
    case PaywallBlocks.PB_PROGRAM_BENEFITS_PRE_PAYWALL:
      return "PB_PROGRAM_BENEFITS_PRE_PAYWALL";
    case PaywallBlocks.PB_REVIEWS_PRE_PAYWALL:
      return "PB_REVIEWS_PRE_PAYWALL";
    case PaywallBlocks.PB_PRICE_SELECT_POPUP:
      return "PB_PRICE_SELECT_POPUP";
    case PaywallBlocks.PB_PRICE_SELECT_POPUP_PRE_PAYWALL:
      return "PB_PRICE_SELECT_POPUP_PRE_PAYWALL";
    case PaywallBlocks.PB_INVOICE_PRE_PAYWALL:
      return "PB_INVOICE_PRE_PAYWALL";
    case PaywallBlocks.PB_HEADER_GT:
      return "PB_HEADER_GT";
    case PaywallBlocks.PB_PROGRAM_BENEFITS_GT:
      return "PB_PROGRAM_BENEFITS_GT";
    case PaywallBlocks.PB_HARMFUL_PRODUCTS:
      return "PB_HARMFUL_PRODUCTS";
    case PaywallBlocks.PB_HEADER_GT2:
      return "PB_HEADER_GT2";
    case PaywallBlocks.PB_PRICE_SELECT2:
      return "PB_PRICE_SELECT2";
    case PaywallBlocks.PB_PAYMENT_FORM_WITH_STATIC_TEXT:
      return "PB_PAYMENT_FORM_WITH_STATIC_TEXT";
    case PaywallBlocks.PB_HEADER_GTI:
      return "PB_HEADER_GTI";
    case PaywallBlocks.PB_HOW_TRIAL_WORKS_INVOICE:
      return "PB_HOW_TRIAL_WORKS_INVOICE";
    case PaywallBlocks.PB_HOW_TRIAL_WORKS_SHORT_INVOICE:
      return "PB_HOW_TRIAL_WORKS_SHORT_INVOICE";
    case PaywallBlocks.PB_PRICE_SELECT_WITH_GOAL_AND_DATE:
      return "PB_PRICE_SELECT_WITH_GOAL_AND_DATE";
    case PaywallBlocks.PB_BEFORE_AFTER_BASED_ON_GOAL:
      return "PB_BEFORE_AFTER_BASED_ON_GOAL";
    case PaywallBlocks.PB_REVIEWS_WITH_BEFORE_AFTER_PHOTOS:
      return "PB_REVIEWS_WITH_BEFORE_AFTER_PHOTOS";
    case PaywallBlocks.PB_PRICE_SELECT_WITH_GOAL_AND_DATE_2:
      return "PB_PRICE_SELECT_WITH_GOAL_AND_DATE_2";
    case PaywallBlocks.PB_PROGRAM_BENEFITS_2:
      return "PB_PROGRAM_BENEFITS_2";
    case PaywallBlocks.PB_MONEYBACK_2:
      return "PB_MONEYBACK_2";
    case PaywallBlocks.PB_PRICE_SELECT_3:
      return "PB_PRICE_SELECT_3";
    case PaywallBlocks.PB_MONEYBACK_INTRO:
      return "PB_MONEYBACK_INTRO";
    case PaywallBlocks.PB_UPSALE_SKIP_TRIAL:
      return "PB_UPSALE_SKIP_TRIAL";
    case PaywallBlocks.PB_UPSALE_WORKBOOK:
      return "PB_UPSALE_WORKBOOK";
    case PaywallBlocks.PB_HEADER_LUVLY:
      return "PB_HEADER_LUVLY";
    case PaywallBlocks.PB_PRICE_SELECT_WITH_GOAL_AND_DATE_3:
      return "PB_PRICE_SELECT_WITH_GOAL_AND_DATE_3";
    case PaywallBlocks.PB_PRICE_SELECT_WITH_GOAL_AND_DATE_4:
      return "PB_PRICE_SELECT_WITH_GOAL_AND_DATE_4";
    case PaywallBlocks.PB_HEADER_LUVLY_STATIC:
      return "PB_HEADER_LUVLY_STATIC";
    case PaywallBlocks.PB_PAYMENT_FORM_WITH_INVOICE_3_IN_POPUP:
      return "PB_PAYMENT_FORM_WITH_INVOICE_3_IN_POPUP";
    case PaywallBlocks.PB_PRICE_SELECT_4:
      return "PB_PRICE_SELECT_4";
    case PaywallBlocks.PB_FACESCAN_RESULTS:
      return "PB_FACESCAN_RESULTS";
    case PaywallBlocks.PB_HEADER_WITH_LIST:
      return "PB_HEADER_WITH_LIST";
    case PaywallBlocks.PB_HEADER_WITH_PHOTO:
      return "PB_HEADER_WITH_PHOTO";
    case PaywallBlocks.PB_TIPS:
      return "PB_TIPS";
    case PaywallBlocks.PB_STATIC_CITATION_1:
      return "PB_STATIC_CITATION_1";
    case PaywallBlocks.PB_STATIC_CITATION_2:
      return "PB_STATIC_CITATION_2";
    case PaywallBlocks.PB_STATIC_EXPERTS:
      return "PB_STATIC_EXPERTS";
    case PaywallBlocks.PB_PRODUCTS_IMAGES_1:
      return "PB_PRODUCTS_IMAGES_1";
    case PaywallBlocks.PB_PRODUCTS_IMAGES_2:
      return "PB_PRODUCTS_IMAGES_2";
    case PaywallBlocks.PB_STATIC_MAP:
      return "PB_STATIC_MAP";
    case PaywallBlocks.PB_PRICE_SELECT_4_1:
      return "PB_PRICE_SELECT_4_1";
    case PaywallBlocks.PB_PRICE_SELECT_4_2:
      return "PB_PRICE_SELECT_4_2";
    case PaywallBlocks.PB_TIMER_FLOAT_4:
      return "PB_TIMER_FLOAT_4";
    case PaywallBlocks.PB_UPSALE_SUBSCRIPTION_ON_EXIT:
      return "PB_UPSALE_SUBSCRIPTION_ON_EXIT";
    case PaywallBlocks.PB_IMAGE:
      return "PB_IMAGE";
    case PaywallBlocks.PB_HEADER_WITH_LIST_TIMER_CHART:
      return "PB_HEADER_WITH_LIST_TIMER_CHART";
    case PaywallBlocks.PB_UPSALE_AI_SCANNER:
      return "PB_UPSALE_AI_SCANNER";
    case PaywallBlocks.PB_TIMER_FLOAT_5_TOP:
      return "PB_TIMER_FLOAT_5_TOP";
    case PaywallBlocks.PB_TIMER_FLOAT_5_BOTTOM:
      return "PB_TIMER_FLOAT_5_BOTTOM";
    case PaywallBlocks.PB_PRICE_SELECT_5:
      return "PB_PRICE_SELECT_5";
    case PaywallBlocks.PB_PRICE_SELECT_6:
      return "PB_PRICE_SELECT_6";
    case PaywallBlocks.PB_PRICE_SELECT_5_2:
      return "PB_PRICE_SELECT_5_2";
    case PaywallBlocks.PB_ENHANCED_PHOTO:
      return "PB_ENHANCED_PHOTO";
    case PaywallBlocks.PB_PRICE_SELECT_6_2:
      return "PB_PRICE_SELECT_6_2";
    case PaywallBlocks.PB_PRICE_SELECT_7:
      return "PB_PRICE_SELECT_7";
    case PaywallBlocks.PB_PRICE_SELECT_8:
      return "PB_PRICE_SELECT_8";
    case PaywallBlocks.PB_UPSALE_AI_SCANNER_2:
      return "PB_UPSALE_AI_SCANNER_2";
    case PaywallBlocks.PB_AI_GENERATED_PLAN:
      return "PB_AI_GENERATED_PLAN";
    case PaywallBlocks.PB_VIDEO_REVIEWS:
      return "PB_VIDEO_REVIEWS";
    case PaywallBlocks.PB_IMAGES_CAROUSEL:
      return "PB_IMAGES_CAROUSEL";
    case PaywallBlocks.PB_SUBSCRIPTION_INFO_TEXT:
      return "PB_SUBSCRIPTION_INFO_TEXT";
    case PaywallBlocks.PB_SUBSCRIPTION_INFO_TEXT_2:
      return "PB_SUBSCRIPTION_INFO_TEXT_2";
    case PaywallBlocks.PB_IMAGE_WITH_BUTTON:
      return "PB_IMAGE_WITH_BUTTON";
    case PaywallBlocks.PB_MONEYBACK_3:
      return "PB_MONEYBACK_3";
    case PaywallBlocks.PB_STATIC_BENEFITS:
      return "PB_STATIC_BENEFITS";
    case PaywallBlocks.PB_TIMER_FLOAT_6:
      return "PB_TIMER_FLOAT_6";
    case PaywallBlocks.PB_TIMER_DISCOUNT_4:
      return "PB_TIMER_DISCOUNT_4";
    case PaywallBlocks.PB_STATIC_COPYRIGHT:
      return "PB_STATIC_COPYRIGHT";
    case PaywallBlocks.PB_UPSALE_NEW_SUBSCRIPTIONS:
      return "PB_UPSALE_NEW_SUBSCRIPTIONS";
    case PaywallBlocks.PB_UPSALE_AI_SCANNER_3:
      return "PB_UPSALE_AI_SCANNER_3";
    case PaywallBlocks.PB_UPSALE_AI_ASSISTANT_1:
      return "PB_UPSALE_AI_ASSISTANT_1";
    case PaywallBlocks.PB_UPSALE_SHOPIFY_PRODUCTS:
      return "PB_UPSALE_SHOPIFY_PRODUCTS";
    case PaywallBlocks.PB_BEFORE_AFTER_WITH_TIMER:
      return "PB_BEFORE_AFTER_WITH_TIMER";
    case PaywallBlocks.PB_ENHANCED_PHOTO_2:
      return "PB_ENHANCED_PHOTO_2";
    case PaywallBlocks.PB_HEADER_3:
      return "PB_HEADER_3";
    case PaywallBlocks.PB_IMAGE_2:
      return "PB_IMAGE_2";
    case PaywallBlocks.PB_UPSALE_BUNDLE:
      return "PB_UPSALE_BUNDLE";
    case PaywallBlocks.PB_UPSALE_AI_ASSISTANT_2:
      return "PB_UPSALE_AI_ASSISTANT_2";
    case PaywallBlocks.PB_HEADER_4:
      return "PB_HEADER_4";
    case PaywallBlocks.PB_HEADER_5:
      return "PB_HEADER_5";
    case PaywallBlocks.PB_ENHANCED_PHOTO_3:
      return "PB_ENHANCED_PHOTO_3";
    case PaywallBlocks.PB_REVIEWS_5:
      return "PB_REVIEWS_5";
    case PaywallBlocks.PB_STATIC_CITATION_3:
      return "PB_STATIC_CITATION_3";
    case PaywallBlocks.PB_IMAGE_3:
      return "PB_IMAGE_3";
    case PaywallBlocks.PB_REVIEWS_6:
      return "PB_REVIEWS_6";
    case PaywallBlocks.PB_PRICE_SELECT_9:
      return "PB_PRICE_SELECT_9";
    case PaywallBlocks.PB_PRICE_SELECT_9_2:
      return "PB_PRICE_SELECT_9_2";
    case PaywallBlocks.PB_STATIC_MEDICAL_ADVISERS:
      return "PB_STATIC_MEDICAL_ADVISERS";
    case PaywallBlocks.PB_TIME_LIMITED_OFFER:
      return "PB_TIME_LIMITED_OFFER";
    case PaywallBlocks.PB_STATIC_SPANISH_PRICE_COMPARISON:
      return "PB_STATIC_SPANISH_PRICE_COMPARISON";
    case PaywallBlocks.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Padding {
  top: string;
  bottom: string;
}

export interface StyledText {
  text: string;
  color: string;
  style: string;
  url: string;
}

export interface CardAttributes {
  level: number;
  bullet: Bullet;
  weight: Weight;
  size: number;
}

export interface CardAction {
  scroll_to: string;
  show_hidden: boolean;
}

export interface BaseContent {
  header: string;
  text: string;
  link: string;
}

export interface ImageContent {
  image: string;
}

export interface PriceContent {
  text1: string;
  text2: string;
  price1: StyledText[];
  price2: StyledText[];
}

export interface BackgroundTextContent {
  first_text: string;
  second_text: string;
  seconds_timer: number;
  third_text: string;
}

export interface BackgroundTextContent2 {
  seal_link: string;
  text1: string;
  text2: string;
  text3: StyledText[];
}

export interface StyledHeaderContent {
  header: StyledText[];
}

export interface StyledTextContent {
  text: StyledText[];
}

export interface PaymentContent {
  subscription_text1: string;
  subscription_text2: string;
  subscription_text3: string;
  palta_brain_price_point: string;
  pora_app_deeplink: string;
}

export interface Card {
  align: Alignment;
  attributes: CardAttributes | undefined;
  type: CardType;
  action: CardAction | undefined;
  padding: Padding | undefined;
  color: CardColor;
  hidden: boolean;
  id: string;
  description: string;
  content?:
    | { $case: "base_content"; base_content: BaseContent }
    | { $case: "background_text_content"; background_text_content: BackgroundTextContent }
    | { $case: "background_text_content2"; background_text_content2: BackgroundTextContent2 }
    | { $case: "styled_header_content"; styled_header_content: StyledHeaderContent }
    | { $case: "styled_text_content"; styled_text_content: StyledTextContent }
    | { $case: "payment_content"; payment_content: PaymentContent }
    | { $case: "price_content"; price_content: PriceContent }
    | { $case: "image_content"; image_content: ImageContent }
    | undefined;
}

export interface ProgramData {
  cards: Card[];
}

export interface PaywallResponse {
  program_data: ProgramData | undefined;
  email: string;
}

export interface PriceContentV2 {
  /** TODO rename to prices */
  priceses: PriceContentV2_PriceItem[];
  title: string;
  button_text: string;
}

export enum PriceContentV2_PeriodUnit {
  PERIOD_UNIT_UNSPECIFIED = "PERIOD_UNIT_UNSPECIFIED",
  PERIOD_UNIT_DAY = "PERIOD_UNIT_DAY",
  PERIOD_UNIT_WEEK = "PERIOD_UNIT_WEEK",
  PERIOD_UNIT_MONTH = "PERIOD_UNIT_MONTH",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function priceContentV2_PeriodUnitFromJSON(object: any): PriceContentV2_PeriodUnit {
  switch (object) {
    case 0:
    case "PERIOD_UNIT_UNSPECIFIED":
      return PriceContentV2_PeriodUnit.PERIOD_UNIT_UNSPECIFIED;
    case 1:
    case "PERIOD_UNIT_DAY":
      return PriceContentV2_PeriodUnit.PERIOD_UNIT_DAY;
    case 2:
    case "PERIOD_UNIT_WEEK":
      return PriceContentV2_PeriodUnit.PERIOD_UNIT_WEEK;
    case 3:
    case "PERIOD_UNIT_MONTH":
      return PriceContentV2_PeriodUnit.PERIOD_UNIT_MONTH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PriceContentV2_PeriodUnit.UNRECOGNIZED;
  }
}

export function priceContentV2_PeriodUnitToJSON(object: PriceContentV2_PeriodUnit): string {
  switch (object) {
    case PriceContentV2_PeriodUnit.PERIOD_UNIT_UNSPECIFIED:
      return "PERIOD_UNIT_UNSPECIFIED";
    case PriceContentV2_PeriodUnit.PERIOD_UNIT_DAY:
      return "PERIOD_UNIT_DAY";
    case PriceContentV2_PeriodUnit.PERIOD_UNIT_WEEK:
      return "PERIOD_UNIT_WEEK";
    case PriceContentV2_PeriodUnit.PERIOD_UNIT_MONTH:
      return "PERIOD_UNIT_MONTH";
    case PriceContentV2_PeriodUnit.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PriceContentV2_ProviderPricePalta {
  price_id: string;
}

export interface PriceContentV2_ProviderPriceStripe {
  price_id: string;
}

export interface PriceContentV2_ProviderPricePaypal {
  plan_id: string;
}

export interface PriceContentV2_ProviderPriceRecurly {
  plan_id: string;
}

export interface PriceContentV2_PaymentProviderPriceInfo {
  palta_card: PriceContentV2_ProviderPricePalta | undefined;
  palta_paypal: PriceContentV2_ProviderPricePalta | undefined;
  paypal: PriceContentV2_ProviderPricePaypal | undefined;
  stripe: PriceContentV2_ProviderPriceStripe | undefined;
  recurly: PriceContentV2_ProviderPriceRecurly | undefined;
}

export interface PriceContentV2_PriceItem {
  id: string;
  title: string;
  /**
   * use subscription_price
   *
   * @deprecated
   */
  price: string;
  previous_price: string;
  price_per_day: string;
  previous_price_per_day: string;
  /**
   * use default field
   *
   * @deprecated
   */
  popular: boolean;
  subscription_text: string;
  economy_price: string;
  economy_percent: string;
  /**
   * use PaymentFormProperties.compact_form
   *
   * @deprecated
   */
  compact_form: boolean;
  /**
   * use trial_price_cents
   *
   * @deprecated
   */
  trial_price: string;
  /**
   * use price_period_length and price_period_unit
   *
   * @deprecated
   */
  trial_period: string;
  payment_providers: PriceContentV2_PaymentProviderPriceInfo | undefined;
  subscription_period_length: number;
  subscription_price_per_unit_cents: number;
  subscription_period_unit: PriceContentV2_PeriodUnit;
  trial_period_length: number;
  trial_period_unit: PriceContentV2_PeriodUnit;
  trial_price_cents: number;
  subscription_price_cents: number;
  price_diff_to_pay_cents: number;
  price_with_trial_cents: number;
  badge: PriceContentV2_PriceItem_PriceItemBadge | undefined;
  tags: PriceContentV2_PriceItem_PriceItemTag[];
  default: boolean;
  style: PriceContentV2_PriceItem_PriceItemStyle;
  currency_code: string;
}

export enum PriceContentV2_PriceItem_PriceItemStyle {
  STYLE_UNSPECIFIED = "STYLE_UNSPECIFIED",
  STYLE_NORMAL = "STYLE_NORMAL",
  STYLE_SOLD_OUT = "STYLE_SOLD_OUT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function priceContentV2_PriceItem_PriceItemStyleFromJSON(object: any): PriceContentV2_PriceItem_PriceItemStyle {
  switch (object) {
    case 0:
    case "STYLE_UNSPECIFIED":
      return PriceContentV2_PriceItem_PriceItemStyle.STYLE_UNSPECIFIED;
    case 1:
    case "STYLE_NORMAL":
      return PriceContentV2_PriceItem_PriceItemStyle.STYLE_NORMAL;
    case 2:
    case "STYLE_SOLD_OUT":
      return PriceContentV2_PriceItem_PriceItemStyle.STYLE_SOLD_OUT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PriceContentV2_PriceItem_PriceItemStyle.UNRECOGNIZED;
  }
}

export function priceContentV2_PriceItem_PriceItemStyleToJSON(object: PriceContentV2_PriceItem_PriceItemStyle): string {
  switch (object) {
    case PriceContentV2_PriceItem_PriceItemStyle.STYLE_UNSPECIFIED:
      return "STYLE_UNSPECIFIED";
    case PriceContentV2_PriceItem_PriceItemStyle.STYLE_NORMAL:
      return "STYLE_NORMAL";
    case PriceContentV2_PriceItem_PriceItemStyle.STYLE_SOLD_OUT:
      return "STYLE_SOLD_OUT";
    case PriceContentV2_PriceItem_PriceItemStyle.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PriceContentV2_PriceItem_PriceItemBadge {
  title: string;
}

export interface PriceContentV2_PriceItem_PriceItemTag {
  title: string;
  view_variant: string;
}

export interface User {
  email: string;
  user_id: string;
}

export interface Links {
  pora_app_deeplink: string;
}

export interface OfferPopupProperties {
  text: string;
  button_text: string;
  display_timeout: number;
  await_scroll: boolean;
}

export interface PaymentFormProperties {
  /**
   * use PaymentFormProperties.provider_preference
   *
   * @deprecated
   */
  DEPRECATED_default_payment_method: PaymentMethods;
  provider_preference: PaymentProviders[];
  compact_form: boolean;
}

/**
 * Deprecated in https://paltaskincare.atlassian.net/browse/GEN-5131
 * TODO: delete / unused on frontend
 */
export interface CountdownProperties {
  /** @deprecated */
  title: string;
  /** @deprecated */
  minutes: number;
}

export interface Header2Properties {
  title:
    | AttributedString
    | undefined;
  /**
   * TODO: delete
   *
   * @deprecated
   */
  countdown: CountdownProperties | undefined;
}

export interface HeaderPrePaywallProperties {
  main_goal_text: string;
  main_goal_img: string;
}

export interface HeaderGTProperties {
  main_goal_text: string;
  main_goal_img: string;
}

export interface HeaderGT2Properties {
  main_goal_text: string;
  main_goal_img: string;
}

export interface HeaderLuvlyProperties {
  main_goal_text: string;
  main_goal_img: string;
}

export interface Program2Properties {
  title: AttributedString | undefined;
  description: string;
  issues: SkinIssues[];
}

export interface FaceScanResultProperties {
  issues: SkinIssues[];
  scan_url: string;
}

export interface PriceSelectProperties {
  pre_selector_texts: string[];
  button_text: string;
}

export interface WellnessProfile {
  goal: SkinIssues;
  level: WellnessProfile_WellnessLevel;
}

export enum WellnessProfile_WellnessLevel {
  UNKNOWN = "UNKNOWN",
  LOW = "LOW",
  MODERATE = "MODERATE",
  GOOD = "GOOD",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function wellnessProfile_WellnessLevelFromJSON(object: any): WellnessProfile_WellnessLevel {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return WellnessProfile_WellnessLevel.UNKNOWN;
    case 1:
    case "LOW":
      return WellnessProfile_WellnessLevel.LOW;
    case 2:
    case "MODERATE":
      return WellnessProfile_WellnessLevel.MODERATE;
    case 3:
    case "GOOD":
      return WellnessProfile_WellnessLevel.GOOD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WellnessProfile_WellnessLevel.UNRECOGNIZED;
  }
}

export function wellnessProfile_WellnessLevelToJSON(object: WellnessProfile_WellnessLevel): string {
  switch (object) {
    case WellnessProfile_WellnessLevel.UNKNOWN:
      return "UNKNOWN";
    case WellnessProfile_WellnessLevel.LOW:
      return "LOW";
    case WellnessProfile_WellnessLevel.MODERATE:
      return "MODERATE";
    case WellnessProfile_WellnessLevel.GOOD:
      return "GOOD";
    case WellnessProfile_WellnessLevel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface UpsaleSubscriptionProperties {
  upsale_price: PriceContentV2_PriceItem | undefined;
  upsale_price_discounted: PriceContentV2_PriceItem | undefined;
}

export interface UpsaleWorkbookProperties {
  price: PriceContentV2_PriceItem | undefined;
  price_discounted: PriceContentV2_PriceItem | undefined;
}

export interface UpsaleSubscriptionOnExitProperties {
  prices_discounted: PriceContentV2_PriceItem[];
  discount_percent: number;
}

export interface UpsaleAIScannerProperties {
  price: PriceContentV2_PriceItem | undefined;
  price_discounted: PriceContentV2_PriceItem | undefined;
}

export interface UpsaleNewSubscriptionsProperties {
  prices: PriceContentV2_PriceItem[];
  discounted_prices: PriceContentV2_PriceItem[];
  workbook_price: PriceContentV2_PriceItem | undefined;
}

export interface UpsaleBundle {
  prices: PriceContentV2_PriceItem[];
  discounted_prices: PriceContentV2_PriceItem[];
}

export interface EnhancedPhotoProperties {
  status: EnhancedPhotoProperties_EnhancementStatus;
  original_photo_url: string;
  enhanced_photo_url: string;
}

export enum EnhancedPhotoProperties_EnhancementStatus {
  ENHANCEMENT_STATUS_UNKNOWN = "ENHANCEMENT_STATUS_UNKNOWN",
  ENHANCEMENT_STATUS_USER_HAS_NOT_UPLOAD_PHOTO = "ENHANCEMENT_STATUS_USER_HAS_NOT_UPLOAD_PHOTO",
  ENHANCEMENT_STATUS_ERROR = "ENHANCEMENT_STATUS_ERROR",
  ENHANCEMENT_STATUS_SUCCESS = "ENHANCEMENT_STATUS_SUCCESS",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function enhancedPhotoProperties_EnhancementStatusFromJSON(
  object: any,
): EnhancedPhotoProperties_EnhancementStatus {
  switch (object) {
    case 0:
    case "ENHANCEMENT_STATUS_UNKNOWN":
      return EnhancedPhotoProperties_EnhancementStatus.ENHANCEMENT_STATUS_UNKNOWN;
    case 1:
    case "ENHANCEMENT_STATUS_USER_HAS_NOT_UPLOAD_PHOTO":
      return EnhancedPhotoProperties_EnhancementStatus.ENHANCEMENT_STATUS_USER_HAS_NOT_UPLOAD_PHOTO;
    case 2:
    case "ENHANCEMENT_STATUS_ERROR":
      return EnhancedPhotoProperties_EnhancementStatus.ENHANCEMENT_STATUS_ERROR;
    case 3:
    case "ENHANCEMENT_STATUS_SUCCESS":
      return EnhancedPhotoProperties_EnhancementStatus.ENHANCEMENT_STATUS_SUCCESS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EnhancedPhotoProperties_EnhancementStatus.UNRECOGNIZED;
  }
}

export function enhancedPhotoProperties_EnhancementStatusToJSON(
  object: EnhancedPhotoProperties_EnhancementStatus,
): string {
  switch (object) {
    case EnhancedPhotoProperties_EnhancementStatus.ENHANCEMENT_STATUS_UNKNOWN:
      return "ENHANCEMENT_STATUS_UNKNOWN";
    case EnhancedPhotoProperties_EnhancementStatus.ENHANCEMENT_STATUS_USER_HAS_NOT_UPLOAD_PHOTO:
      return "ENHANCEMENT_STATUS_USER_HAS_NOT_UPLOAD_PHOTO";
    case EnhancedPhotoProperties_EnhancementStatus.ENHANCEMENT_STATUS_ERROR:
      return "ENHANCEMENT_STATUS_ERROR";
    case EnhancedPhotoProperties_EnhancementStatus.ENHANCEMENT_STATUS_SUCCESS:
      return "ENHANCEMENT_STATUS_SUCCESS";
    case EnhancedPhotoProperties_EnhancementStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface AISkinCarePlanProperties {
  blocks: AISkinCarePlanProperties_Block[];
}

export interface AISkinCarePlanProperties_Block {
  icon: Image | undefined;
  title: string;
  content: string[];
}

export interface PaywallProperties {
  /**
   * TODO: delete
   *
   * @deprecated
   */
  DEPRECATED_display_invoice: boolean;
  offer_popup_properties: OfferPopupProperties | undefined;
  payment_form_properties: PaymentFormProperties | undefined;
  header2_properties: Header2Properties | undefined;
  program2_properties: Program2Properties | undefined;
  price_select_properties:
    | PriceSelectProperties
    | undefined;
  /**
   * TODO: delete
   *
   * @deprecated
   */
  countdown: CountdownProperties | undefined;
  header_pre_paywall_properties: HeaderPrePaywallProperties | undefined;
  header_gt_properties: HeaderGTProperties | undefined;
  header_gt2_properties: HeaderGT2Properties | undefined;
  wellness_profile:
    | WellnessProfile
    | undefined;
  /** upsale_skip_trial */
  upsale_subscription_properties: UpsaleSubscriptionProperties | undefined;
  upsale_workbook_properties: UpsaleWorkbookProperties | undefined;
  header_luvly_properties: HeaderLuvlyProperties | undefined;
  face_scan_result_properties: FaceScanResultProperties | undefined;
  upsale_subscription_on_exit_properties: UpsaleSubscriptionOnExitProperties | undefined;
  upsale_ai_scanner_properties: UpsaleAIScannerProperties | undefined;
  enhanced_photo_properties: EnhancedPhotoProperties | undefined;
  ai_skincare_plan_properties: AISkinCarePlanProperties | undefined;
  upsale_new_subscriptions_properties: UpsaleNewSubscriptionsProperties | undefined;
  upsale_bundle: UpsaleBundle | undefined;
}

export interface PaywallVariantsPropertiesProperty {
  value: string;
  prices: PriceContentV2_PriceItem[];
}

export interface PaywallVariantsProperties {
  properties: { [key: string]: string };
  properties_extended: { [key: string]: PaywallVariantsPropertiesProperty };
}

export interface PaywallVariantsProperties_PropertiesEntry {
  key: string;
  value: string;
}

export interface PaywallVariantsProperties_PropertiesExtendedEntry {
  key: string;
  value: PaywallVariantsPropertiesProperty | undefined;
}

export interface PaywallV2 {
  price: PriceContentV2 | undefined;
  summary: ProgramSummaryContent | undefined;
  user: User | undefined;
  links:
    | Links
    | undefined;
  /** this one will be deprecated in the future */
  properties: PaywallProperties | undefined;
  analytics_event_parameters: { [key: string]: ParameterValues };
  paywall_blocks: PaywallBlocks[];
  variants_properties: PaywallVariantsProperties | undefined;
}

export interface PaywallV2_AnalyticsEventParametersEntry {
  key: string;
  value: ParameterValues | undefined;
}

export const Padding = {
  fromJSON(object: any): Padding {
    return {
      top: isSet(object.top) ? globalThis.String(object.top) : "",
      bottom: isSet(object.bottom) ? globalThis.String(object.bottom) : "",
    };
  },

  toJSON(message: Padding): unknown {
    const obj: any = {};
    if (message.top !== "") {
      obj.top = message.top;
    }
    if (message.bottom !== "") {
      obj.bottom = message.bottom;
    }
    return obj;
  },
};

export const StyledText = {
  fromJSON(object: any): StyledText {
    return {
      text: isSet(object.text) ? globalThis.String(object.text) : "",
      color: isSet(object.color) ? globalThis.String(object.color) : "",
      style: isSet(object.style) ? globalThis.String(object.style) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
    };
  },

  toJSON(message: StyledText): unknown {
    const obj: any = {};
    if (message.text !== "") {
      obj.text = message.text;
    }
    if (message.color !== "") {
      obj.color = message.color;
    }
    if (message.style !== "") {
      obj.style = message.style;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },
};

export const CardAttributes = {
  fromJSON(object: any): CardAttributes {
    return {
      level: isSet(object.level) ? globalThis.Number(object.level) : 0,
      bullet: isSet(object.bullet) ? bulletFromJSON(object.bullet) : Bullet.noBullet,
      weight: isSet(object.weight) ? weightFromJSON(object.weight) : Weight.noWeight,
      size: isSet(object.size) ? globalThis.Number(object.size) : 0,
    };
  },

  toJSON(message: CardAttributes): unknown {
    const obj: any = {};
    if (message.level !== 0) {
      obj.level = Math.round(message.level);
    }
    if (message.bullet !== Bullet.noBullet) {
      obj.bullet = bulletToJSON(message.bullet);
    }
    if (message.weight !== Weight.noWeight) {
      obj.weight = weightToJSON(message.weight);
    }
    if (message.size !== 0) {
      obj.size = Math.round(message.size);
    }
    return obj;
  },
};

export const CardAction = {
  fromJSON(object: any): CardAction {
    return {
      scroll_to: isSet(object.scroll_to) ? globalThis.String(object.scroll_to) : "",
      show_hidden: isSet(object.show_hidden) ? globalThis.Boolean(object.show_hidden) : false,
    };
  },

  toJSON(message: CardAction): unknown {
    const obj: any = {};
    if (message.scroll_to !== "") {
      obj.scroll_to = message.scroll_to;
    }
    if (message.show_hidden !== false) {
      obj.show_hidden = message.show_hidden;
    }
    return obj;
  },
};

export const BaseContent = {
  fromJSON(object: any): BaseContent {
    return {
      header: isSet(object.header) ? globalThis.String(object.header) : "",
      text: isSet(object.text) ? globalThis.String(object.text) : "",
      link: isSet(object.link) ? globalThis.String(object.link) : "",
    };
  },

  toJSON(message: BaseContent): unknown {
    const obj: any = {};
    if (message.header !== "") {
      obj.header = message.header;
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    if (message.link !== "") {
      obj.link = message.link;
    }
    return obj;
  },
};

export const ImageContent = {
  fromJSON(object: any): ImageContent {
    return { image: isSet(object.image) ? globalThis.String(object.image) : "" };
  },

  toJSON(message: ImageContent): unknown {
    const obj: any = {};
    if (message.image !== "") {
      obj.image = message.image;
    }
    return obj;
  },
};

export const PriceContent = {
  fromJSON(object: any): PriceContent {
    return {
      text1: isSet(object.text1) ? globalThis.String(object.text1) : "",
      text2: isSet(object.text2) ? globalThis.String(object.text2) : "",
      price1: globalThis.Array.isArray(object?.price1) ? object.price1.map((e: any) => StyledText.fromJSON(e)) : [],
      price2: globalThis.Array.isArray(object?.price2) ? object.price2.map((e: any) => StyledText.fromJSON(e)) : [],
    };
  },

  toJSON(message: PriceContent): unknown {
    const obj: any = {};
    if (message.text1 !== "") {
      obj.text1 = message.text1;
    }
    if (message.text2 !== "") {
      obj.text2 = message.text2;
    }
    if (message.price1?.length) {
      obj.price1 = message.price1.map((e) => StyledText.toJSON(e));
    }
    if (message.price2?.length) {
      obj.price2 = message.price2.map((e) => StyledText.toJSON(e));
    }
    return obj;
  },
};

export const BackgroundTextContent = {
  fromJSON(object: any): BackgroundTextContent {
    return {
      first_text: isSet(object.first_text) ? globalThis.String(object.first_text) : "",
      second_text: isSet(object.second_text) ? globalThis.String(object.second_text) : "",
      seconds_timer: isSet(object.seconds_timer) ? globalThis.Number(object.seconds_timer) : 0,
      third_text: isSet(object.third_text) ? globalThis.String(object.third_text) : "",
    };
  },

  toJSON(message: BackgroundTextContent): unknown {
    const obj: any = {};
    if (message.first_text !== "") {
      obj.first_text = message.first_text;
    }
    if (message.second_text !== "") {
      obj.second_text = message.second_text;
    }
    if (message.seconds_timer !== 0) {
      obj.seconds_timer = Math.round(message.seconds_timer);
    }
    if (message.third_text !== "") {
      obj.third_text = message.third_text;
    }
    return obj;
  },
};

export const BackgroundTextContent2 = {
  fromJSON(object: any): BackgroundTextContent2 {
    return {
      seal_link: isSet(object.seal_link) ? globalThis.String(object.seal_link) : "",
      text1: isSet(object.text1) ? globalThis.String(object.text1) : "",
      text2: isSet(object.text2) ? globalThis.String(object.text2) : "",
      text3: globalThis.Array.isArray(object?.text3) ? object.text3.map((e: any) => StyledText.fromJSON(e)) : [],
    };
  },

  toJSON(message: BackgroundTextContent2): unknown {
    const obj: any = {};
    if (message.seal_link !== "") {
      obj.seal_link = message.seal_link;
    }
    if (message.text1 !== "") {
      obj.text1 = message.text1;
    }
    if (message.text2 !== "") {
      obj.text2 = message.text2;
    }
    if (message.text3?.length) {
      obj.text3 = message.text3.map((e) => StyledText.toJSON(e));
    }
    return obj;
  },
};

export const StyledHeaderContent = {
  fromJSON(object: any): StyledHeaderContent {
    return {
      header: globalThis.Array.isArray(object?.header) ? object.header.map((e: any) => StyledText.fromJSON(e)) : [],
    };
  },

  toJSON(message: StyledHeaderContent): unknown {
    const obj: any = {};
    if (message.header?.length) {
      obj.header = message.header.map((e) => StyledText.toJSON(e));
    }
    return obj;
  },
};

export const StyledTextContent = {
  fromJSON(object: any): StyledTextContent {
    return { text: globalThis.Array.isArray(object?.text) ? object.text.map((e: any) => StyledText.fromJSON(e)) : [] };
  },

  toJSON(message: StyledTextContent): unknown {
    const obj: any = {};
    if (message.text?.length) {
      obj.text = message.text.map((e) => StyledText.toJSON(e));
    }
    return obj;
  },
};

export const PaymentContent = {
  fromJSON(object: any): PaymentContent {
    return {
      subscription_text1: isSet(object.subscription_text1) ? globalThis.String(object.subscription_text1) : "",
      subscription_text2: isSet(object.subscription_text2) ? globalThis.String(object.subscription_text2) : "",
      subscription_text3: isSet(object.subscription_text3) ? globalThis.String(object.subscription_text3) : "",
      palta_brain_price_point: isSet(object.palta_brain_price_point)
        ? globalThis.String(object.palta_brain_price_point)
        : "",
      pora_app_deeplink: isSet(object.pora_app_deeplink) ? globalThis.String(object.pora_app_deeplink) : "",
    };
  },

  toJSON(message: PaymentContent): unknown {
    const obj: any = {};
    if (message.subscription_text1 !== "") {
      obj.subscription_text1 = message.subscription_text1;
    }
    if (message.subscription_text2 !== "") {
      obj.subscription_text2 = message.subscription_text2;
    }
    if (message.subscription_text3 !== "") {
      obj.subscription_text3 = message.subscription_text3;
    }
    if (message.palta_brain_price_point !== "") {
      obj.palta_brain_price_point = message.palta_brain_price_point;
    }
    if (message.pora_app_deeplink !== "") {
      obj.pora_app_deeplink = message.pora_app_deeplink;
    }
    return obj;
  },
};

export const Card = {
  fromJSON(object: any): Card {
    return {
      align: isSet(object.align) ? alignmentFromJSON(object.align) : Alignment.noAlignment,
      attributes: isSet(object.attributes) ? CardAttributes.fromJSON(object.attributes) : undefined,
      type: isSet(object.type) ? cardTypeFromJSON(object.type) : CardType.noCardType,
      action: isSet(object.action) ? CardAction.fromJSON(object.action) : undefined,
      padding: isSet(object.padding) ? Padding.fromJSON(object.padding) : undefined,
      color: isSet(object.color) ? cardColorFromJSON(object.color) : CardColor.noColor,
      hidden: isSet(object.hidden) ? globalThis.Boolean(object.hidden) : false,
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      content: isSet(object.base_content)
        ? { $case: "base_content", base_content: BaseContent.fromJSON(object.base_content) }
        : isSet(object.background_text_content)
        ? {
          $case: "background_text_content",
          background_text_content: BackgroundTextContent.fromJSON(object.background_text_content),
        }
        : isSet(object.background_text_content2)
        ? {
          $case: "background_text_content2",
          background_text_content2: BackgroundTextContent2.fromJSON(object.background_text_content2),
        }
        : isSet(object.styled_header_content)
        ? {
          $case: "styled_header_content",
          styled_header_content: StyledHeaderContent.fromJSON(object.styled_header_content),
        }
        : isSet(object.styled_text_content)
        ? { $case: "styled_text_content", styled_text_content: StyledTextContent.fromJSON(object.styled_text_content) }
        : isSet(object.payment_content)
        ? { $case: "payment_content", payment_content: PaymentContent.fromJSON(object.payment_content) }
        : isSet(object.price_content)
        ? { $case: "price_content", price_content: PriceContent.fromJSON(object.price_content) }
        : isSet(object.image_content)
        ? { $case: "image_content", image_content: ImageContent.fromJSON(object.image_content) }
        : undefined,
    };
  },

  toJSON(message: Card): unknown {
    const obj: any = {};
    if (message.align !== Alignment.noAlignment) {
      obj.align = alignmentToJSON(message.align);
    }
    if (message.attributes !== undefined) {
      obj.attributes = CardAttributes.toJSON(message.attributes);
    }
    if (message.type !== CardType.noCardType) {
      obj.type = cardTypeToJSON(message.type);
    }
    if (message.action !== undefined) {
      obj.action = CardAction.toJSON(message.action);
    }
    if (message.padding !== undefined) {
      obj.padding = Padding.toJSON(message.padding);
    }
    if (message.color !== CardColor.noColor) {
      obj.color = cardColorToJSON(message.color);
    }
    if (message.hidden !== false) {
      obj.hidden = message.hidden;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.content?.$case === "base_content") {
      obj.base_content = BaseContent.toJSON(message.content.base_content);
    }
    if (message.content?.$case === "background_text_content") {
      obj.background_text_content = BackgroundTextContent.toJSON(message.content.background_text_content);
    }
    if (message.content?.$case === "background_text_content2") {
      obj.background_text_content2 = BackgroundTextContent2.toJSON(message.content.background_text_content2);
    }
    if (message.content?.$case === "styled_header_content") {
      obj.styled_header_content = StyledHeaderContent.toJSON(message.content.styled_header_content);
    }
    if (message.content?.$case === "styled_text_content") {
      obj.styled_text_content = StyledTextContent.toJSON(message.content.styled_text_content);
    }
    if (message.content?.$case === "payment_content") {
      obj.payment_content = PaymentContent.toJSON(message.content.payment_content);
    }
    if (message.content?.$case === "price_content") {
      obj.price_content = PriceContent.toJSON(message.content.price_content);
    }
    if (message.content?.$case === "image_content") {
      obj.image_content = ImageContent.toJSON(message.content.image_content);
    }
    return obj;
  },
};

export const ProgramData = {
  fromJSON(object: any): ProgramData {
    return { cards: globalThis.Array.isArray(object?.cards) ? object.cards.map((e: any) => Card.fromJSON(e)) : [] };
  },

  toJSON(message: ProgramData): unknown {
    const obj: any = {};
    if (message.cards?.length) {
      obj.cards = message.cards.map((e) => Card.toJSON(e));
    }
    return obj;
  },
};

export const PaywallResponse = {
  fromJSON(object: any): PaywallResponse {
    return {
      program_data: isSet(object.program_data) ? ProgramData.fromJSON(object.program_data) : undefined,
      email: isSet(object.email) ? globalThis.String(object.email) : "",
    };
  },

  toJSON(message: PaywallResponse): unknown {
    const obj: any = {};
    if (message.program_data !== undefined) {
      obj.program_data = ProgramData.toJSON(message.program_data);
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    return obj;
  },
};

export const PriceContentV2 = {
  fromJSON(object: any): PriceContentV2 {
    return {
      priceses: globalThis.Array.isArray(object?.priceses)
        ? object.priceses.map((e: any) => PriceContentV2_PriceItem.fromJSON(e))
        : [],
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      button_text: isSet(object.button_text) ? globalThis.String(object.button_text) : "",
    };
  },

  toJSON(message: PriceContentV2): unknown {
    const obj: any = {};
    if (message.priceses?.length) {
      obj.priceses = message.priceses.map((e) => PriceContentV2_PriceItem.toJSON(e));
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.button_text !== "") {
      obj.button_text = message.button_text;
    }
    return obj;
  },
};

export const PriceContentV2_ProviderPricePalta = {
  fromJSON(object: any): PriceContentV2_ProviderPricePalta {
    return { price_id: isSet(object.price_id) ? globalThis.String(object.price_id) : "" };
  },

  toJSON(message: PriceContentV2_ProviderPricePalta): unknown {
    const obj: any = {};
    if (message.price_id !== "") {
      obj.price_id = message.price_id;
    }
    return obj;
  },
};

export const PriceContentV2_ProviderPriceStripe = {
  fromJSON(object: any): PriceContentV2_ProviderPriceStripe {
    return { price_id: isSet(object.price_id) ? globalThis.String(object.price_id) : "" };
  },

  toJSON(message: PriceContentV2_ProviderPriceStripe): unknown {
    const obj: any = {};
    if (message.price_id !== "") {
      obj.price_id = message.price_id;
    }
    return obj;
  },
};

export const PriceContentV2_ProviderPricePaypal = {
  fromJSON(object: any): PriceContentV2_ProviderPricePaypal {
    return { plan_id: isSet(object.plan_id) ? globalThis.String(object.plan_id) : "" };
  },

  toJSON(message: PriceContentV2_ProviderPricePaypal): unknown {
    const obj: any = {};
    if (message.plan_id !== "") {
      obj.plan_id = message.plan_id;
    }
    return obj;
  },
};

export const PriceContentV2_ProviderPriceRecurly = {
  fromJSON(object: any): PriceContentV2_ProviderPriceRecurly {
    return { plan_id: isSet(object.plan_id) ? globalThis.String(object.plan_id) : "" };
  },

  toJSON(message: PriceContentV2_ProviderPriceRecurly): unknown {
    const obj: any = {};
    if (message.plan_id !== "") {
      obj.plan_id = message.plan_id;
    }
    return obj;
  },
};

export const PriceContentV2_PaymentProviderPriceInfo = {
  fromJSON(object: any): PriceContentV2_PaymentProviderPriceInfo {
    return {
      palta_card: isSet(object.palta_card) ? PriceContentV2_ProviderPricePalta.fromJSON(object.palta_card) : undefined,
      palta_paypal: isSet(object.palta_paypal)
        ? PriceContentV2_ProviderPricePalta.fromJSON(object.palta_paypal)
        : undefined,
      paypal: isSet(object.paypal) ? PriceContentV2_ProviderPricePaypal.fromJSON(object.paypal) : undefined,
      stripe: isSet(object.stripe) ? PriceContentV2_ProviderPriceStripe.fromJSON(object.stripe) : undefined,
      recurly: isSet(object.recurly) ? PriceContentV2_ProviderPriceRecurly.fromJSON(object.recurly) : undefined,
    };
  },

  toJSON(message: PriceContentV2_PaymentProviderPriceInfo): unknown {
    const obj: any = {};
    if (message.palta_card !== undefined) {
      obj.palta_card = PriceContentV2_ProviderPricePalta.toJSON(message.palta_card);
    }
    if (message.palta_paypal !== undefined) {
      obj.palta_paypal = PriceContentV2_ProviderPricePalta.toJSON(message.palta_paypal);
    }
    if (message.paypal !== undefined) {
      obj.paypal = PriceContentV2_ProviderPricePaypal.toJSON(message.paypal);
    }
    if (message.stripe !== undefined) {
      obj.stripe = PriceContentV2_ProviderPriceStripe.toJSON(message.stripe);
    }
    if (message.recurly !== undefined) {
      obj.recurly = PriceContentV2_ProviderPriceRecurly.toJSON(message.recurly);
    }
    return obj;
  },
};

export const PriceContentV2_PriceItem = {
  fromJSON(object: any): PriceContentV2_PriceItem {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      price: isSet(object.price) ? globalThis.String(object.price) : "",
      previous_price: isSet(object.previous_price) ? globalThis.String(object.previous_price) : "",
      price_per_day: isSet(object.price_per_day) ? globalThis.String(object.price_per_day) : "",
      previous_price_per_day: isSet(object.previous_price_per_day)
        ? globalThis.String(object.previous_price_per_day)
        : "",
      popular: isSet(object.popular) ? globalThis.Boolean(object.popular) : false,
      subscription_text: isSet(object.subscription_text) ? globalThis.String(object.subscription_text) : "",
      economy_price: isSet(object.economy_price) ? globalThis.String(object.economy_price) : "",
      economy_percent: isSet(object.economy_percent) ? globalThis.String(object.economy_percent) : "",
      compact_form: isSet(object.compact_form) ? globalThis.Boolean(object.compact_form) : false,
      trial_price: isSet(object.trial_price) ? globalThis.String(object.trial_price) : "",
      trial_period: isSet(object.trial_period) ? globalThis.String(object.trial_period) : "",
      payment_providers: isSet(object.payment_providers)
        ? PriceContentV2_PaymentProviderPriceInfo.fromJSON(object.payment_providers)
        : undefined,
      subscription_period_length: isSet(object.subscription_period_length)
        ? globalThis.Number(object.subscription_period_length)
        : 0,
      subscription_price_per_unit_cents: isSet(object.subscription_price_per_unit_cents)
        ? globalThis.Number(object.subscription_price_per_unit_cents)
        : 0,
      subscription_period_unit: isSet(object.subscription_period_unit)
        ? priceContentV2_PeriodUnitFromJSON(object.subscription_period_unit)
        : PriceContentV2_PeriodUnit.PERIOD_UNIT_UNSPECIFIED,
      trial_period_length: isSet(object.trial_period_length) ? globalThis.Number(object.trial_period_length) : 0,
      trial_period_unit: isSet(object.trial_period_unit)
        ? priceContentV2_PeriodUnitFromJSON(object.trial_period_unit)
        : PriceContentV2_PeriodUnit.PERIOD_UNIT_UNSPECIFIED,
      trial_price_cents: isSet(object.trial_price_cents) ? globalThis.Number(object.trial_price_cents) : 0,
      subscription_price_cents: isSet(object.subscription_price_cents)
        ? globalThis.Number(object.subscription_price_cents)
        : 0,
      price_diff_to_pay_cents: isSet(object.price_diff_to_pay_cents)
        ? globalThis.Number(object.price_diff_to_pay_cents)
        : 0,
      price_with_trial_cents: isSet(object.price_with_trial_cents)
        ? globalThis.Number(object.price_with_trial_cents)
        : 0,
      badge: isSet(object.badge) ? PriceContentV2_PriceItem_PriceItemBadge.fromJSON(object.badge) : undefined,
      tags: globalThis.Array.isArray(object?.tags)
        ? object.tags.map((e: any) => PriceContentV2_PriceItem_PriceItemTag.fromJSON(e))
        : [],
      default: isSet(object.default) ? globalThis.Boolean(object.default) : false,
      style: isSet(object.style)
        ? priceContentV2_PriceItem_PriceItemStyleFromJSON(object.style)
        : PriceContentV2_PriceItem_PriceItemStyle.STYLE_UNSPECIFIED,
      currency_code: isSet(object.currency_code) ? globalThis.String(object.currency_code) : "",
    };
  },

  toJSON(message: PriceContentV2_PriceItem): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.price !== "") {
      obj.price = message.price;
    }
    if (message.previous_price !== "") {
      obj.previous_price = message.previous_price;
    }
    if (message.price_per_day !== "") {
      obj.price_per_day = message.price_per_day;
    }
    if (message.previous_price_per_day !== "") {
      obj.previous_price_per_day = message.previous_price_per_day;
    }
    if (message.popular !== false) {
      obj.popular = message.popular;
    }
    if (message.subscription_text !== "") {
      obj.subscription_text = message.subscription_text;
    }
    if (message.economy_price !== "") {
      obj.economy_price = message.economy_price;
    }
    if (message.economy_percent !== "") {
      obj.economy_percent = message.economy_percent;
    }
    if (message.compact_form !== false) {
      obj.compact_form = message.compact_form;
    }
    if (message.trial_price !== "") {
      obj.trial_price = message.trial_price;
    }
    if (message.trial_period !== "") {
      obj.trial_period = message.trial_period;
    }
    if (message.payment_providers !== undefined) {
      obj.payment_providers = PriceContentV2_PaymentProviderPriceInfo.toJSON(message.payment_providers);
    }
    if (message.subscription_period_length !== 0) {
      obj.subscription_period_length = Math.round(message.subscription_period_length);
    }
    if (message.subscription_price_per_unit_cents !== 0) {
      obj.subscription_price_per_unit_cents = Math.round(message.subscription_price_per_unit_cents);
    }
    if (message.subscription_period_unit !== PriceContentV2_PeriodUnit.PERIOD_UNIT_UNSPECIFIED) {
      obj.subscription_period_unit = priceContentV2_PeriodUnitToJSON(message.subscription_period_unit);
    }
    if (message.trial_period_length !== 0) {
      obj.trial_period_length = Math.round(message.trial_period_length);
    }
    if (message.trial_period_unit !== PriceContentV2_PeriodUnit.PERIOD_UNIT_UNSPECIFIED) {
      obj.trial_period_unit = priceContentV2_PeriodUnitToJSON(message.trial_period_unit);
    }
    if (message.trial_price_cents !== 0) {
      obj.trial_price_cents = Math.round(message.trial_price_cents);
    }
    if (message.subscription_price_cents !== 0) {
      obj.subscription_price_cents = Math.round(message.subscription_price_cents);
    }
    if (message.price_diff_to_pay_cents !== 0) {
      obj.price_diff_to_pay_cents = Math.round(message.price_diff_to_pay_cents);
    }
    if (message.price_with_trial_cents !== 0) {
      obj.price_with_trial_cents = Math.round(message.price_with_trial_cents);
    }
    if (message.badge !== undefined) {
      obj.badge = PriceContentV2_PriceItem_PriceItemBadge.toJSON(message.badge);
    }
    if (message.tags?.length) {
      obj.tags = message.tags.map((e) => PriceContentV2_PriceItem_PriceItemTag.toJSON(e));
    }
    if (message.default !== false) {
      obj.default = message.default;
    }
    if (message.style !== PriceContentV2_PriceItem_PriceItemStyle.STYLE_UNSPECIFIED) {
      obj.style = priceContentV2_PriceItem_PriceItemStyleToJSON(message.style);
    }
    if (message.currency_code !== "") {
      obj.currency_code = message.currency_code;
    }
    return obj;
  },
};

export const PriceContentV2_PriceItem_PriceItemBadge = {
  fromJSON(object: any): PriceContentV2_PriceItem_PriceItemBadge {
    return { title: isSet(object.title) ? globalThis.String(object.title) : "" };
  },

  toJSON(message: PriceContentV2_PriceItem_PriceItemBadge): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    return obj;
  },
};

export const PriceContentV2_PriceItem_PriceItemTag = {
  fromJSON(object: any): PriceContentV2_PriceItem_PriceItemTag {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      view_variant: isSet(object.view_variant) ? globalThis.String(object.view_variant) : "",
    };
  },

  toJSON(message: PriceContentV2_PriceItem_PriceItemTag): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.view_variant !== "") {
      obj.view_variant = message.view_variant;
    }
    return obj;
  },
};

export const User = {
  fromJSON(object: any): User {
    return {
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      user_id: isSet(object.user_id) ? globalThis.String(object.user_id) : "",
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.user_id !== "") {
      obj.user_id = message.user_id;
    }
    return obj;
  },
};

export const Links = {
  fromJSON(object: any): Links {
    return { pora_app_deeplink: isSet(object.pora_app_deeplink) ? globalThis.String(object.pora_app_deeplink) : "" };
  },

  toJSON(message: Links): unknown {
    const obj: any = {};
    if (message.pora_app_deeplink !== "") {
      obj.pora_app_deeplink = message.pora_app_deeplink;
    }
    return obj;
  },
};

export const OfferPopupProperties = {
  fromJSON(object: any): OfferPopupProperties {
    return {
      text: isSet(object.text) ? globalThis.String(object.text) : "",
      button_text: isSet(object.button_text) ? globalThis.String(object.button_text) : "",
      display_timeout: isSet(object.display_timeout) ? globalThis.Number(object.display_timeout) : 0,
      await_scroll: isSet(object.await_scroll) ? globalThis.Boolean(object.await_scroll) : false,
    };
  },

  toJSON(message: OfferPopupProperties): unknown {
    const obj: any = {};
    if (message.text !== "") {
      obj.text = message.text;
    }
    if (message.button_text !== "") {
      obj.button_text = message.button_text;
    }
    if (message.display_timeout !== 0) {
      obj.display_timeout = message.display_timeout;
    }
    if (message.await_scroll !== false) {
      obj.await_scroll = message.await_scroll;
    }
    return obj;
  },
};

export const PaymentFormProperties = {
  fromJSON(object: any): PaymentFormProperties {
    return {
      DEPRECATED_default_payment_method: isSet(object.DEPRECATED_default_payment_method)
        ? paymentMethodsFromJSON(object.DEPRECATED_default_payment_method)
        : PaymentMethods.noPaymentMethod,
      provider_preference: globalThis.Array.isArray(object?.provider_preference)
        ? object.provider_preference.map((e: any) => paymentProvidersFromJSON(e))
        : [],
      compact_form: isSet(object.compact_form) ? globalThis.Boolean(object.compact_form) : false,
    };
  },

  toJSON(message: PaymentFormProperties): unknown {
    const obj: any = {};
    if (message.DEPRECATED_default_payment_method !== PaymentMethods.noPaymentMethod) {
      obj.DEPRECATED_default_payment_method = paymentMethodsToJSON(message.DEPRECATED_default_payment_method);
    }
    if (message.provider_preference?.length) {
      obj.provider_preference = message.provider_preference.map((e) => paymentProvidersToJSON(e));
    }
    if (message.compact_form !== false) {
      obj.compact_form = message.compact_form;
    }
    return obj;
  },
};

export const CountdownProperties = {
  fromJSON(object: any): CountdownProperties {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      minutes: isSet(object.minutes) ? globalThis.Number(object.minutes) : 0,
    };
  },

  toJSON(message: CountdownProperties): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.minutes !== 0) {
      obj.minutes = Math.round(message.minutes);
    }
    return obj;
  },
};

export const Header2Properties = {
  fromJSON(object: any): Header2Properties {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      countdown: isSet(object.countdown) ? CountdownProperties.fromJSON(object.countdown) : undefined,
    };
  },

  toJSON(message: Header2Properties): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.countdown !== undefined) {
      obj.countdown = CountdownProperties.toJSON(message.countdown);
    }
    return obj;
  },
};

export const HeaderPrePaywallProperties = {
  fromJSON(object: any): HeaderPrePaywallProperties {
    return {
      main_goal_text: isSet(object.main_goal_text) ? globalThis.String(object.main_goal_text) : "",
      main_goal_img: isSet(object.main_goal_img) ? globalThis.String(object.main_goal_img) : "",
    };
  },

  toJSON(message: HeaderPrePaywallProperties): unknown {
    const obj: any = {};
    if (message.main_goal_text !== "") {
      obj.main_goal_text = message.main_goal_text;
    }
    if (message.main_goal_img !== "") {
      obj.main_goal_img = message.main_goal_img;
    }
    return obj;
  },
};

export const HeaderGTProperties = {
  fromJSON(object: any): HeaderGTProperties {
    return {
      main_goal_text: isSet(object.main_goal_text) ? globalThis.String(object.main_goal_text) : "",
      main_goal_img: isSet(object.main_goal_img) ? globalThis.String(object.main_goal_img) : "",
    };
  },

  toJSON(message: HeaderGTProperties): unknown {
    const obj: any = {};
    if (message.main_goal_text !== "") {
      obj.main_goal_text = message.main_goal_text;
    }
    if (message.main_goal_img !== "") {
      obj.main_goal_img = message.main_goal_img;
    }
    return obj;
  },
};

export const HeaderGT2Properties = {
  fromJSON(object: any): HeaderGT2Properties {
    return {
      main_goal_text: isSet(object.main_goal_text) ? globalThis.String(object.main_goal_text) : "",
      main_goal_img: isSet(object.main_goal_img) ? globalThis.String(object.main_goal_img) : "",
    };
  },

  toJSON(message: HeaderGT2Properties): unknown {
    const obj: any = {};
    if (message.main_goal_text !== "") {
      obj.main_goal_text = message.main_goal_text;
    }
    if (message.main_goal_img !== "") {
      obj.main_goal_img = message.main_goal_img;
    }
    return obj;
  },
};

export const HeaderLuvlyProperties = {
  fromJSON(object: any): HeaderLuvlyProperties {
    return {
      main_goal_text: isSet(object.main_goal_text) ? globalThis.String(object.main_goal_text) : "",
      main_goal_img: isSet(object.main_goal_img) ? globalThis.String(object.main_goal_img) : "",
    };
  },

  toJSON(message: HeaderLuvlyProperties): unknown {
    const obj: any = {};
    if (message.main_goal_text !== "") {
      obj.main_goal_text = message.main_goal_text;
    }
    if (message.main_goal_img !== "") {
      obj.main_goal_img = message.main_goal_img;
    }
    return obj;
  },
};

export const Program2Properties = {
  fromJSON(object: any): Program2Properties {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      issues: globalThis.Array.isArray(object?.issues) ? object.issues.map((e: any) => skinIssuesFromJSON(e)) : [],
    };
  },

  toJSON(message: Program2Properties): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.issues?.length) {
      obj.issues = message.issues.map((e) => skinIssuesToJSON(e));
    }
    return obj;
  },
};

export const FaceScanResultProperties = {
  fromJSON(object: any): FaceScanResultProperties {
    return {
      issues: globalThis.Array.isArray(object?.issues) ? object.issues.map((e: any) => skinIssuesFromJSON(e)) : [],
      scan_url: isSet(object.scan_url) ? globalThis.String(object.scan_url) : "",
    };
  },

  toJSON(message: FaceScanResultProperties): unknown {
    const obj: any = {};
    if (message.issues?.length) {
      obj.issues = message.issues.map((e) => skinIssuesToJSON(e));
    }
    if (message.scan_url !== "") {
      obj.scan_url = message.scan_url;
    }
    return obj;
  },
};

export const PriceSelectProperties = {
  fromJSON(object: any): PriceSelectProperties {
    return {
      pre_selector_texts: globalThis.Array.isArray(object?.pre_selector_texts)
        ? object.pre_selector_texts.map((e: any) => globalThis.String(e))
        : [],
      button_text: isSet(object.button_text) ? globalThis.String(object.button_text) : "",
    };
  },

  toJSON(message: PriceSelectProperties): unknown {
    const obj: any = {};
    if (message.pre_selector_texts?.length) {
      obj.pre_selector_texts = message.pre_selector_texts;
    }
    if (message.button_text !== "") {
      obj.button_text = message.button_text;
    }
    return obj;
  },
};

export const WellnessProfile = {
  fromJSON(object: any): WellnessProfile {
    return {
      goal: isSet(object.goal) ? skinIssuesFromJSON(object.goal) : SkinIssues.SKIN_ISSUE_UNSPECIFIED,
      level: isSet(object.level)
        ? wellnessProfile_WellnessLevelFromJSON(object.level)
        : WellnessProfile_WellnessLevel.UNKNOWN,
    };
  },

  toJSON(message: WellnessProfile): unknown {
    const obj: any = {};
    if (message.goal !== SkinIssues.SKIN_ISSUE_UNSPECIFIED) {
      obj.goal = skinIssuesToJSON(message.goal);
    }
    if (message.level !== WellnessProfile_WellnessLevel.UNKNOWN) {
      obj.level = wellnessProfile_WellnessLevelToJSON(message.level);
    }
    return obj;
  },
};

export const UpsaleSubscriptionProperties = {
  fromJSON(object: any): UpsaleSubscriptionProperties {
    return {
      upsale_price: isSet(object.upsale_price) ? PriceContentV2_PriceItem.fromJSON(object.upsale_price) : undefined,
      upsale_price_discounted: isSet(object.upsale_price_discounted)
        ? PriceContentV2_PriceItem.fromJSON(object.upsale_price_discounted)
        : undefined,
    };
  },

  toJSON(message: UpsaleSubscriptionProperties): unknown {
    const obj: any = {};
    if (message.upsale_price !== undefined) {
      obj.upsale_price = PriceContentV2_PriceItem.toJSON(message.upsale_price);
    }
    if (message.upsale_price_discounted !== undefined) {
      obj.upsale_price_discounted = PriceContentV2_PriceItem.toJSON(message.upsale_price_discounted);
    }
    return obj;
  },
};

export const UpsaleWorkbookProperties = {
  fromJSON(object: any): UpsaleWorkbookProperties {
    return {
      price: isSet(object.price) ? PriceContentV2_PriceItem.fromJSON(object.price) : undefined,
      price_discounted: isSet(object.price_discounted)
        ? PriceContentV2_PriceItem.fromJSON(object.price_discounted)
        : undefined,
    };
  },

  toJSON(message: UpsaleWorkbookProperties): unknown {
    const obj: any = {};
    if (message.price !== undefined) {
      obj.price = PriceContentV2_PriceItem.toJSON(message.price);
    }
    if (message.price_discounted !== undefined) {
      obj.price_discounted = PriceContentV2_PriceItem.toJSON(message.price_discounted);
    }
    return obj;
  },
};

export const UpsaleSubscriptionOnExitProperties = {
  fromJSON(object: any): UpsaleSubscriptionOnExitProperties {
    return {
      prices_discounted: globalThis.Array.isArray(object?.prices_discounted)
        ? object.prices_discounted.map((e: any) => PriceContentV2_PriceItem.fromJSON(e))
        : [],
      discount_percent: isSet(object.discount_percent) ? globalThis.Number(object.discount_percent) : 0,
    };
  },

  toJSON(message: UpsaleSubscriptionOnExitProperties): unknown {
    const obj: any = {};
    if (message.prices_discounted?.length) {
      obj.prices_discounted = message.prices_discounted.map((e) => PriceContentV2_PriceItem.toJSON(e));
    }
    if (message.discount_percent !== 0) {
      obj.discount_percent = Math.round(message.discount_percent);
    }
    return obj;
  },
};

export const UpsaleAIScannerProperties = {
  fromJSON(object: any): UpsaleAIScannerProperties {
    return {
      price: isSet(object.price) ? PriceContentV2_PriceItem.fromJSON(object.price) : undefined,
      price_discounted: isSet(object.price_discounted)
        ? PriceContentV2_PriceItem.fromJSON(object.price_discounted)
        : undefined,
    };
  },

  toJSON(message: UpsaleAIScannerProperties): unknown {
    const obj: any = {};
    if (message.price !== undefined) {
      obj.price = PriceContentV2_PriceItem.toJSON(message.price);
    }
    if (message.price_discounted !== undefined) {
      obj.price_discounted = PriceContentV2_PriceItem.toJSON(message.price_discounted);
    }
    return obj;
  },
};

export const UpsaleNewSubscriptionsProperties = {
  fromJSON(object: any): UpsaleNewSubscriptionsProperties {
    return {
      prices: globalThis.Array.isArray(object?.prices)
        ? object.prices.map((e: any) => PriceContentV2_PriceItem.fromJSON(e))
        : [],
      discounted_prices: globalThis.Array.isArray(object?.discounted_prices)
        ? object.discounted_prices.map((e: any) => PriceContentV2_PriceItem.fromJSON(e))
        : [],
      workbook_price: isSet(object.workbook_price)
        ? PriceContentV2_PriceItem.fromJSON(object.workbook_price)
        : undefined,
    };
  },

  toJSON(message: UpsaleNewSubscriptionsProperties): unknown {
    const obj: any = {};
    if (message.prices?.length) {
      obj.prices = message.prices.map((e) => PriceContentV2_PriceItem.toJSON(e));
    }
    if (message.discounted_prices?.length) {
      obj.discounted_prices = message.discounted_prices.map((e) => PriceContentV2_PriceItem.toJSON(e));
    }
    if (message.workbook_price !== undefined) {
      obj.workbook_price = PriceContentV2_PriceItem.toJSON(message.workbook_price);
    }
    return obj;
  },
};

export const UpsaleBundle = {
  fromJSON(object: any): UpsaleBundle {
    return {
      prices: globalThis.Array.isArray(object?.prices)
        ? object.prices.map((e: any) => PriceContentV2_PriceItem.fromJSON(e))
        : [],
      discounted_prices: globalThis.Array.isArray(object?.discounted_prices)
        ? object.discounted_prices.map((e: any) => PriceContentV2_PriceItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpsaleBundle): unknown {
    const obj: any = {};
    if (message.prices?.length) {
      obj.prices = message.prices.map((e) => PriceContentV2_PriceItem.toJSON(e));
    }
    if (message.discounted_prices?.length) {
      obj.discounted_prices = message.discounted_prices.map((e) => PriceContentV2_PriceItem.toJSON(e));
    }
    return obj;
  },
};

export const EnhancedPhotoProperties = {
  fromJSON(object: any): EnhancedPhotoProperties {
    return {
      status: isSet(object.status)
        ? enhancedPhotoProperties_EnhancementStatusFromJSON(object.status)
        : EnhancedPhotoProperties_EnhancementStatus.ENHANCEMENT_STATUS_UNKNOWN,
      original_photo_url: isSet(object.original_photo_url) ? globalThis.String(object.original_photo_url) : "",
      enhanced_photo_url: isSet(object.enhanced_photo_url) ? globalThis.String(object.enhanced_photo_url) : "",
    };
  },

  toJSON(message: EnhancedPhotoProperties): unknown {
    const obj: any = {};
    if (message.status !== EnhancedPhotoProperties_EnhancementStatus.ENHANCEMENT_STATUS_UNKNOWN) {
      obj.status = enhancedPhotoProperties_EnhancementStatusToJSON(message.status);
    }
    if (message.original_photo_url !== "") {
      obj.original_photo_url = message.original_photo_url;
    }
    if (message.enhanced_photo_url !== "") {
      obj.enhanced_photo_url = message.enhanced_photo_url;
    }
    return obj;
  },
};

export const AISkinCarePlanProperties = {
  fromJSON(object: any): AISkinCarePlanProperties {
    return {
      blocks: globalThis.Array.isArray(object?.blocks)
        ? object.blocks.map((e: any) => AISkinCarePlanProperties_Block.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AISkinCarePlanProperties): unknown {
    const obj: any = {};
    if (message.blocks?.length) {
      obj.blocks = message.blocks.map((e) => AISkinCarePlanProperties_Block.toJSON(e));
    }
    return obj;
  },
};

export const AISkinCarePlanProperties_Block = {
  fromJSON(object: any): AISkinCarePlanProperties_Block {
    return {
      icon: isSet(object.icon) ? Image.fromJSON(object.icon) : undefined,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      content: globalThis.Array.isArray(object?.content) ? object.content.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: AISkinCarePlanProperties_Block): unknown {
    const obj: any = {};
    if (message.icon !== undefined) {
      obj.icon = Image.toJSON(message.icon);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.content?.length) {
      obj.content = message.content;
    }
    return obj;
  },
};

export const PaywallProperties = {
  fromJSON(object: any): PaywallProperties {
    return {
      DEPRECATED_display_invoice: isSet(object.DEPRECATED_display_invoice)
        ? globalThis.Boolean(object.DEPRECATED_display_invoice)
        : false,
      offer_popup_properties: isSet(object.offer_popup_properties)
        ? OfferPopupProperties.fromJSON(object.offer_popup_properties)
        : undefined,
      payment_form_properties: isSet(object.payment_form_properties)
        ? PaymentFormProperties.fromJSON(object.payment_form_properties)
        : undefined,
      header2_properties: isSet(object.header2_properties)
        ? Header2Properties.fromJSON(object.header2_properties)
        : undefined,
      program2_properties: isSet(object.program2_properties)
        ? Program2Properties.fromJSON(object.program2_properties)
        : undefined,
      price_select_properties: isSet(object.price_select_properties)
        ? PriceSelectProperties.fromJSON(object.price_select_properties)
        : undefined,
      countdown: isSet(object.countdown) ? CountdownProperties.fromJSON(object.countdown) : undefined,
      header_pre_paywall_properties: isSet(object.header_pre_paywall_properties)
        ? HeaderPrePaywallProperties.fromJSON(object.header_pre_paywall_properties)
        : undefined,
      header_gt_properties: isSet(object.header_gt_properties)
        ? HeaderGTProperties.fromJSON(object.header_gt_properties)
        : undefined,
      header_gt2_properties: isSet(object.header_gt2_properties)
        ? HeaderGT2Properties.fromJSON(object.header_gt2_properties)
        : undefined,
      wellness_profile: isSet(object.wellness_profile) ? WellnessProfile.fromJSON(object.wellness_profile) : undefined,
      upsale_subscription_properties: isSet(object.upsale_subscription_properties)
        ? UpsaleSubscriptionProperties.fromJSON(object.upsale_subscription_properties)
        : undefined,
      upsale_workbook_properties: isSet(object.upsale_workbook_properties)
        ? UpsaleWorkbookProperties.fromJSON(object.upsale_workbook_properties)
        : undefined,
      header_luvly_properties: isSet(object.header_luvly_properties)
        ? HeaderLuvlyProperties.fromJSON(object.header_luvly_properties)
        : undefined,
      face_scan_result_properties: isSet(object.face_scan_result_properties)
        ? FaceScanResultProperties.fromJSON(object.face_scan_result_properties)
        : undefined,
      upsale_subscription_on_exit_properties: isSet(object.upsale_subscription_on_exit_properties)
        ? UpsaleSubscriptionOnExitProperties.fromJSON(object.upsale_subscription_on_exit_properties)
        : undefined,
      upsale_ai_scanner_properties: isSet(object.upsale_ai_scanner_properties)
        ? UpsaleAIScannerProperties.fromJSON(object.upsale_ai_scanner_properties)
        : undefined,
      enhanced_photo_properties: isSet(object.enhanced_photo_properties)
        ? EnhancedPhotoProperties.fromJSON(object.enhanced_photo_properties)
        : undefined,
      ai_skincare_plan_properties: isSet(object.ai_skincare_plan_properties)
        ? AISkinCarePlanProperties.fromJSON(object.ai_skincare_plan_properties)
        : undefined,
      upsale_new_subscriptions_properties: isSet(object.upsale_new_subscriptions_properties)
        ? UpsaleNewSubscriptionsProperties.fromJSON(object.upsale_new_subscriptions_properties)
        : undefined,
      upsale_bundle: isSet(object.upsale_bundle) ? UpsaleBundle.fromJSON(object.upsale_bundle) : undefined,
    };
  },

  toJSON(message: PaywallProperties): unknown {
    const obj: any = {};
    if (message.DEPRECATED_display_invoice !== false) {
      obj.DEPRECATED_display_invoice = message.DEPRECATED_display_invoice;
    }
    if (message.offer_popup_properties !== undefined) {
      obj.offer_popup_properties = OfferPopupProperties.toJSON(message.offer_popup_properties);
    }
    if (message.payment_form_properties !== undefined) {
      obj.payment_form_properties = PaymentFormProperties.toJSON(message.payment_form_properties);
    }
    if (message.header2_properties !== undefined) {
      obj.header2_properties = Header2Properties.toJSON(message.header2_properties);
    }
    if (message.program2_properties !== undefined) {
      obj.program2_properties = Program2Properties.toJSON(message.program2_properties);
    }
    if (message.price_select_properties !== undefined) {
      obj.price_select_properties = PriceSelectProperties.toJSON(message.price_select_properties);
    }
    if (message.countdown !== undefined) {
      obj.countdown = CountdownProperties.toJSON(message.countdown);
    }
    if (message.header_pre_paywall_properties !== undefined) {
      obj.header_pre_paywall_properties = HeaderPrePaywallProperties.toJSON(message.header_pre_paywall_properties);
    }
    if (message.header_gt_properties !== undefined) {
      obj.header_gt_properties = HeaderGTProperties.toJSON(message.header_gt_properties);
    }
    if (message.header_gt2_properties !== undefined) {
      obj.header_gt2_properties = HeaderGT2Properties.toJSON(message.header_gt2_properties);
    }
    if (message.wellness_profile !== undefined) {
      obj.wellness_profile = WellnessProfile.toJSON(message.wellness_profile);
    }
    if (message.upsale_subscription_properties !== undefined) {
      obj.upsale_subscription_properties = UpsaleSubscriptionProperties.toJSON(message.upsale_subscription_properties);
    }
    if (message.upsale_workbook_properties !== undefined) {
      obj.upsale_workbook_properties = UpsaleWorkbookProperties.toJSON(message.upsale_workbook_properties);
    }
    if (message.header_luvly_properties !== undefined) {
      obj.header_luvly_properties = HeaderLuvlyProperties.toJSON(message.header_luvly_properties);
    }
    if (message.face_scan_result_properties !== undefined) {
      obj.face_scan_result_properties = FaceScanResultProperties.toJSON(message.face_scan_result_properties);
    }
    if (message.upsale_subscription_on_exit_properties !== undefined) {
      obj.upsale_subscription_on_exit_properties = UpsaleSubscriptionOnExitProperties.toJSON(
        message.upsale_subscription_on_exit_properties,
      );
    }
    if (message.upsale_ai_scanner_properties !== undefined) {
      obj.upsale_ai_scanner_properties = UpsaleAIScannerProperties.toJSON(message.upsale_ai_scanner_properties);
    }
    if (message.enhanced_photo_properties !== undefined) {
      obj.enhanced_photo_properties = EnhancedPhotoProperties.toJSON(message.enhanced_photo_properties);
    }
    if (message.ai_skincare_plan_properties !== undefined) {
      obj.ai_skincare_plan_properties = AISkinCarePlanProperties.toJSON(message.ai_skincare_plan_properties);
    }
    if (message.upsale_new_subscriptions_properties !== undefined) {
      obj.upsale_new_subscriptions_properties = UpsaleNewSubscriptionsProperties.toJSON(
        message.upsale_new_subscriptions_properties,
      );
    }
    if (message.upsale_bundle !== undefined) {
      obj.upsale_bundle = UpsaleBundle.toJSON(message.upsale_bundle);
    }
    return obj;
  },
};

export const PaywallVariantsPropertiesProperty = {
  fromJSON(object: any): PaywallVariantsPropertiesProperty {
    return {
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      prices: globalThis.Array.isArray(object?.prices)
        ? object.prices.map((e: any) => PriceContentV2_PriceItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PaywallVariantsPropertiesProperty): unknown {
    const obj: any = {};
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.prices?.length) {
      obj.prices = message.prices.map((e) => PriceContentV2_PriceItem.toJSON(e));
    }
    return obj;
  },
};

export const PaywallVariantsProperties = {
  fromJSON(object: any): PaywallVariantsProperties {
    return {
      properties: isObject(object.properties)
        ? Object.entries(object.properties).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      properties_extended: isObject(object.properties_extended)
        ? Object.entries(object.properties_extended).reduce<{ [key: string]: PaywallVariantsPropertiesProperty }>(
          (acc, [key, value]) => {
            acc[key] = PaywallVariantsPropertiesProperty.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
    };
  },

  toJSON(message: PaywallVariantsProperties): unknown {
    const obj: any = {};
    if (message.properties) {
      const entries = Object.entries(message.properties);
      if (entries.length > 0) {
        obj.properties = {};
        entries.forEach(([k, v]) => {
          obj.properties[k] = v;
        });
      }
    }
    if (message.properties_extended) {
      const entries = Object.entries(message.properties_extended);
      if (entries.length > 0) {
        obj.properties_extended = {};
        entries.forEach(([k, v]) => {
          obj.properties_extended[k] = PaywallVariantsPropertiesProperty.toJSON(v);
        });
      }
    }
    return obj;
  },
};

export const PaywallVariantsProperties_PropertiesEntry = {
  fromJSON(object: any): PaywallVariantsProperties_PropertiesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: PaywallVariantsProperties_PropertiesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },
};

export const PaywallVariantsProperties_PropertiesExtendedEntry = {
  fromJSON(object: any): PaywallVariantsProperties_PropertiesExtendedEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? PaywallVariantsPropertiesProperty.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: PaywallVariantsProperties_PropertiesExtendedEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = PaywallVariantsPropertiesProperty.toJSON(message.value);
    }
    return obj;
  },
};

export const PaywallV2 = {
  fromJSON(object: any): PaywallV2 {
    return {
      price: isSet(object.price) ? PriceContentV2.fromJSON(object.price) : undefined,
      summary: isSet(object.summary) ? ProgramSummaryContent.fromJSON(object.summary) : undefined,
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      links: isSet(object.links) ? Links.fromJSON(object.links) : undefined,
      properties: isSet(object.properties) ? PaywallProperties.fromJSON(object.properties) : undefined,
      analytics_event_parameters: isObject(object.analytics_event_parameters)
        ? Object.entries(object.analytics_event_parameters).reduce<{ [key: string]: ParameterValues }>(
          (acc, [key, value]) => {
            acc[key] = ParameterValues.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
      paywall_blocks: globalThis.Array.isArray(object?.paywall_blocks)
        ? object.paywall_blocks.map((e: any) => paywallBlocksFromJSON(e))
        : [],
      variants_properties: isSet(object.variants_properties)
        ? PaywallVariantsProperties.fromJSON(object.variants_properties)
        : undefined,
    };
  },

  toJSON(message: PaywallV2): unknown {
    const obj: any = {};
    if (message.price !== undefined) {
      obj.price = PriceContentV2.toJSON(message.price);
    }
    if (message.summary !== undefined) {
      obj.summary = ProgramSummaryContent.toJSON(message.summary);
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.links !== undefined) {
      obj.links = Links.toJSON(message.links);
    }
    if (message.properties !== undefined) {
      obj.properties = PaywallProperties.toJSON(message.properties);
    }
    if (message.analytics_event_parameters) {
      const entries = Object.entries(message.analytics_event_parameters);
      if (entries.length > 0) {
        obj.analytics_event_parameters = {};
        entries.forEach(([k, v]) => {
          obj.analytics_event_parameters[k] = ParameterValues.toJSON(v);
        });
      }
    }
    if (message.paywall_blocks?.length) {
      obj.paywall_blocks = message.paywall_blocks.map((e) => paywallBlocksToJSON(e));
    }
    if (message.variants_properties !== undefined) {
      obj.variants_properties = PaywallVariantsProperties.toJSON(message.variants_properties);
    }
    return obj;
  },
};

export const PaywallV2_AnalyticsEventParametersEntry = {
  fromJSON(object: any): PaywallV2_AnalyticsEventParametersEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? ParameterValues.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: PaywallV2_AnalyticsEventParametersEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = ParameterValues.toJSON(message.value);
    }
    return obj;
  },
};

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
