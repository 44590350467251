// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: actions.proto

/* eslint-disable */
import { FaceCameraScreen } from "./face_camera";
import { Any } from "./google/protobuf/any";
import { SheetDetent } from "./ios_presentation";
import { Request } from "./networking";
import { PersonalizationScreen } from "./personalization";
import {
  AttributedString,
  Color,
  FilledButton,
  HorizontalAlignment,
  horizontalAlignmentFromJSON,
  horizontalAlignmentToJSON,
  Tag,
} from "./presentation";
import { SkinDiaryScreen } from "./skin_diary_checklist";
import { VideoInstructionScreen } from "./video_instruction";

export interface ActionEmpty {
}

export interface ActionOpenStories {
  stories_id: string;
  context_name: string;
}

export interface ActionOpenSettingsScreen {
  has_skin_id: boolean;
  has_personalization: boolean;
  settings_screen: SettingsScreen | undefined;
  account_deletion_message: AccountDeletionMessage | undefined;
}

export interface AccountDeletionMessage {
  title: string;
  text: string;
  delete_button_text: string;
  cancel_button_text: string;
}

export interface ActionStartFaceScanning {
  screen: FaceCameraScreen | undefined;
}

export interface ActionPullGetEndpoint {
  endpoint: string;
  params: { [key: string]: string };
  id: string;
}

export interface ActionPullGetEndpoint_ParamsEntry {
  key: string;
  value: string;
}

export interface ActionOpenWebView {
  url: string;
}

export interface ActionOpenSafariViewController {
  url: string;
}

export interface ActionOpenQuiz {
  test_id: string;
  is_closable_when_initial_fetching_failed: boolean;
  params: { [key: string]: string };
  presentation_style: ActionOpenQuiz_PresentationStyle;
}

export enum ActionOpenQuiz_PresentationStyle {
  NONE = "NONE",
  FULL_SCREEN = "FULL_SCREEN",
  PAGE_SHEET = "PAGE_SHEET",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function actionOpenQuiz_PresentationStyleFromJSON(object: any): ActionOpenQuiz_PresentationStyle {
  switch (object) {
    case 0:
    case "NONE":
      return ActionOpenQuiz_PresentationStyle.NONE;
    case 1:
    case "FULL_SCREEN":
      return ActionOpenQuiz_PresentationStyle.FULL_SCREEN;
    case 2:
    case "PAGE_SHEET":
      return ActionOpenQuiz_PresentationStyle.PAGE_SHEET;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ActionOpenQuiz_PresentationStyle.UNRECOGNIZED;
  }
}

export function actionOpenQuiz_PresentationStyleToJSON(object: ActionOpenQuiz_PresentationStyle): string {
  switch (object) {
    case ActionOpenQuiz_PresentationStyle.NONE:
      return "NONE";
    case ActionOpenQuiz_PresentationStyle.FULL_SCREEN:
      return "FULL_SCREEN";
    case ActionOpenQuiz_PresentationStyle.PAGE_SHEET:
      return "PAGE_SHEET";
    case ActionOpenQuiz_PresentationStyle.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ActionOpenQuiz_ParamsEntry {
  key: string;
  value: string;
}

export interface ActionOpenPopup {
  popup: Popup | undefined;
}

export interface ActionSequence {
  actions: ActionType[];
}

export interface ActionOpenRecentScans {
  screen_title: string;
  footer_action: ActionType | undefined;
  time_constraint?: { $case: "full_time"; full_time: boolean } | {
    $case: "time_limit";
    time_limit: ActionOpenRecentScans_TimeLimit;
  } | undefined;
}

export interface ActionOpenRecentScans_TimeLimit {
  start_timestamp: number;
  end_timestamp: number;
}

export interface ActionMailTo {
  email: string;
  body: string;
  subject: string;
  analytics_label: string;
}

export interface ActionOpenVideo {
  video_link: string;
  completion_action: ActionType | undefined;
  context_id: string;
  context_name: string;
}

export interface ActionOpenVideoPlayerWithChapters {
  player_id: string;
  completion_action: ActionType | undefined;
  context_name: string;
}

export interface ActionOpenActionableGrid {
  id: string;
  screen: Any | undefined;
}

export interface ImageProcessingParameters {
  /** The maximum dimension of the image in pixels. */
  max_dimension: number;
  /** 0.0 - 1.0 */
  compression_quality: number;
}

export interface ProductsSegmentationParameters {
  /** Enables realtime camera frame processing */
  enable_runtime_segmentation: boolean;
  /** If enabled, erases photo background before OCR */
  enable_background_cropping: boolean;
  /** If enabled, the app shows an animation on the photo during the segmentation process */
  enable_segmentation_animation: boolean;
}

export interface ActionOpenMyProducts {
  is_intro_finished: boolean;
  open_intro_action:
    | ActionType
    | undefined;
  /** @deprecated */
  variant: ActionOpenMyProducts_Variant;
  /**
   * use tabs instead
   *
   * @deprecated
   */
  variants: ActionOpenMyProducts_Variant[];
  /**
   * use selected_tab_index instead
   *
   * @deprecated
   */
  selected_tab: ActionOpenMyProducts_Variant;
  /**
   * use open_product_scanner_action (ActionOpenProductScanner) instead
   *
   * @deprecated
   */
  image_processing_params:
    | ImageProcessingParameters
    | undefined;
  /**
   * use open_product_scanner_action (ActionOpenProductScanner) instead
   *
   * @deprecated
   */
  segmentation_params: ProductsSegmentationParameters | undefined;
  open_product_scanner_action: ActionType | undefined;
  screen_title: string;
  /** @deprecated */
  should_show_new_recommendations_screen: boolean;
  tabs: ActionOpenMyProducts_Tab[];
  selected_tab_index: number;
}

export enum ActionOpenMyProducts_Variant {
  VARIANT_NONE = "VARIANT_NONE",
  /** @deprecated */
  VARIANT_ALL_TABS = "VARIANT_ALL_TABS",
  VARIANT_SCANS_HISTORY = "VARIANT_SCANS_HISTORY",
  VARIANT_RECOMMENDATIONS = "VARIANT_RECOMMENDATIONS",
  VARIANT_OWN = "VARIANT_OWN",
  VARIANT_WISHLIST = "VARIANT_WISHLIST",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function actionOpenMyProducts_VariantFromJSON(object: any): ActionOpenMyProducts_Variant {
  switch (object) {
    case 0:
    case "VARIANT_NONE":
      return ActionOpenMyProducts_Variant.VARIANT_NONE;
    case 1:
    case "VARIANT_ALL_TABS":
      return ActionOpenMyProducts_Variant.VARIANT_ALL_TABS;
    case 2:
    case "VARIANT_SCANS_HISTORY":
      return ActionOpenMyProducts_Variant.VARIANT_SCANS_HISTORY;
    case 3:
    case "VARIANT_RECOMMENDATIONS":
      return ActionOpenMyProducts_Variant.VARIANT_RECOMMENDATIONS;
    case 4:
    case "VARIANT_OWN":
      return ActionOpenMyProducts_Variant.VARIANT_OWN;
    case 5:
    case "VARIANT_WISHLIST":
      return ActionOpenMyProducts_Variant.VARIANT_WISHLIST;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ActionOpenMyProducts_Variant.UNRECOGNIZED;
  }
}

export function actionOpenMyProducts_VariantToJSON(object: ActionOpenMyProducts_Variant): string {
  switch (object) {
    case ActionOpenMyProducts_Variant.VARIANT_NONE:
      return "VARIANT_NONE";
    case ActionOpenMyProducts_Variant.VARIANT_ALL_TABS:
      return "VARIANT_ALL_TABS";
    case ActionOpenMyProducts_Variant.VARIANT_SCANS_HISTORY:
      return "VARIANT_SCANS_HISTORY";
    case ActionOpenMyProducts_Variant.VARIANT_RECOMMENDATIONS:
      return "VARIANT_RECOMMENDATIONS";
    case ActionOpenMyProducts_Variant.VARIANT_OWN:
      return "VARIANT_OWN";
    case ActionOpenMyProducts_Variant.VARIANT_WISHLIST:
      return "VARIANT_WISHLIST";
    case ActionOpenMyProducts_Variant.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ActionOpenMyProducts_Tab {
  title: AttributedString | undefined;
  variant: ActionOpenMyProducts_Variant;
}

export interface ActionOpenProductScanner {
  image_processing_params: ImageProcessingParameters | undefined;
  sample: ActionOpenProductScanner_Sample | undefined;
  segmentation_params: ProductsSegmentationParameters | undefined;
  search_variants_count: number;
  video_instruction: VideoInstructionScreen | undefined;
  should_show_instructions_on_appear: boolean;
  trial_state: TrialState | undefined;
}

export interface ActionOpenProductScanner_Sample {
  background_video: string;
  scan_my_products_visible: boolean;
  close_button_visible: boolean;
  /**
   * Nested serialized data should be of type `ProductScreen`
   * `Any` is used here to avoid circular dependency.
   * WARNING: Do NOT use `Any` for any other purpose!
   */
  product_screen: Any | undefined;
}

export interface ActionAskPushPermission {
  granted_action: ActionType | undefined;
  denied_action: ActionType | undefined;
}

export interface ActionOpenAppSettings {
}

export interface ActionShowReviewForm {
  success_action: ActionType | undefined;
  failure_action: ActionType | undefined;
}

/**
 * CAUTION: READ BEFORE MODIFYING
 *
 * This protobuf uses the "Any" type for the "payload" field. This is risky, as the Any type can accept
 * any type of data. Without explicit checks on the data type, unanticipated or malicious input can
 * potentially crash the system or lead to misinterpretation of data.
 *
 * If you're planning to work with this code, refrain from extending this protobuf with further use of
 * the Any type. Define explicit, tightly scoped message types instead, for better compile-time safety.
 *
 * The PayloadType enum provides some safety by indicating the expected payload type, but it's not foolproof.
 * Be cautious when working with this code and prioritize security, predictability, and maintainability.
 */
export interface ActionOpenDynamic {
  /** WARNING: Usage of Any type */
  payload: Any | undefined;
  payload_type: ActionOpenDynamic_PayloadType;
}

export enum ActionOpenDynamic_PayloadType {
  PAYLOAD_TYPE_NONE = "PAYLOAD_TYPE_NONE",
  /** PAYLOAD_TYPE_RITUAL_OVERVIEW - Nested serialized data should be of type `ritual_overview.RitualOverviewScreen` */
  PAYLOAD_TYPE_RITUAL_OVERVIEW = "PAYLOAD_TYPE_RITUAL_OVERVIEW",
  /** PAYLOAD_TYPE_PRODUCT_SCREEN - Nested serialized data should be of type `products.ActionOpenProductScreenPayload` */
  PAYLOAD_TYPE_PRODUCT_SCREEN = "PAYLOAD_TYPE_PRODUCT_SCREEN",
  /** PAYLOAD_TYPE_COURSE_OVERVIEW - Nested serialized data should be of type `course_overview.CourseOverviewScreen` */
  PAYLOAD_TYPE_COURSE_OVERVIEW = "PAYLOAD_TYPE_COURSE_OVERVIEW",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function actionOpenDynamic_PayloadTypeFromJSON(object: any): ActionOpenDynamic_PayloadType {
  switch (object) {
    case 0:
    case "PAYLOAD_TYPE_NONE":
      return ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_NONE;
    case 1:
    case "PAYLOAD_TYPE_RITUAL_OVERVIEW":
      return ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_RITUAL_OVERVIEW;
    case 2:
    case "PAYLOAD_TYPE_PRODUCT_SCREEN":
      return ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_PRODUCT_SCREEN;
    case 3:
    case "PAYLOAD_TYPE_COURSE_OVERVIEW":
      return ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_COURSE_OVERVIEW;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ActionOpenDynamic_PayloadType.UNRECOGNIZED;
  }
}

export function actionOpenDynamic_PayloadTypeToJSON(object: ActionOpenDynamic_PayloadType): string {
  switch (object) {
    case ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_NONE:
      return "PAYLOAD_TYPE_NONE";
    case ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_RITUAL_OVERVIEW:
      return "PAYLOAD_TYPE_RITUAL_OVERVIEW";
    case ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_PRODUCT_SCREEN:
      return "PAYLOAD_TYPE_PRODUCT_SCREEN";
    case ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_COURSE_OVERVIEW:
      return "PAYLOAD_TYPE_COURSE_OVERVIEW";
    case ActionOpenDynamic_PayloadType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ActionOpenPersonalization {
  screen: PersonalizationScreen | undefined;
}

export interface ActionOpenSkinID {
  scan_report_id: string;
  analytics_label: string;
}

export interface ActionGoToTargetScreen {
  target_screen: ActionGoToTargetScreen_TargetScreen;
}

export enum ActionGoToTargetScreen_TargetScreen {
  TARGET_SCREEN_NONE = "TARGET_SCREEN_NONE",
  TARGET_SCREEN_HOME = "TARGET_SCREEN_HOME",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function actionGoToTargetScreen_TargetScreenFromJSON(object: any): ActionGoToTargetScreen_TargetScreen {
  switch (object) {
    case 0:
    case "TARGET_SCREEN_NONE":
      return ActionGoToTargetScreen_TargetScreen.TARGET_SCREEN_NONE;
    case 1:
    case "TARGET_SCREEN_HOME":
      return ActionGoToTargetScreen_TargetScreen.TARGET_SCREEN_HOME;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ActionGoToTargetScreen_TargetScreen.UNRECOGNIZED;
  }
}

export function actionGoToTargetScreen_TargetScreenToJSON(object: ActionGoToTargetScreen_TargetScreen): string {
  switch (object) {
    case ActionGoToTargetScreen_TargetScreen.TARGET_SCREEN_NONE:
      return "TARGET_SCREEN_NONE";
    case ActionGoToTargetScreen_TargetScreen.TARGET_SCREEN_HOME:
      return "TARGET_SCREEN_HOME";
    case ActionGoToTargetScreen_TargetScreen.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ActionOpenAssistant {
  assistant_id: string;
  screen_title: AttributedString | undefined;
  sheet_detents: SheetDetent[];
  analytics_params: { [key: string]: string };
  extra_request_params: { [key: string]: string };
}

export interface ActionOpenAssistant_AnalyticsParamsEntry {
  key: string;
  value: string;
}

export interface ActionOpenAssistant_ExtraRequestParamsEntry {
  key: string;
  value: string;
}

export interface ActionOpenAssistantHome {
  lazy_request: ActionOpenAssistantHome_AssistantHomeRequest | undefined;
}

export interface ActionOpenAssistantHome_AssistantHomeRequest {
  request: Request | undefined;
  url: string;
  assistant_home_id: string;
  extra_params: { [key: string]: string };
  analytics_params: { [key: string]: string };
}

export interface ActionOpenAssistantHome_AssistantHomeRequest_ExtraParamsEntry {
  key: string;
  value: string;
}

export interface ActionOpenAssistantHome_AssistantHomeRequest_AnalyticsParamsEntry {
  key: string;
  value: string;
}

export interface ActionOpenFabMenu {
  menu: ActionOpenFabMenu_FABMenu | undefined;
}

export interface ActionOpenFabMenu_FABMenu {
  items: ActionOpenFabMenu_FABMenu_Item[];
  title: string;
}

export interface ActionOpenFabMenu_FABMenu_Item {
  title: string;
  image: string;
  subtitle: string;
  action: ActionType | undefined;
  is_selected: boolean;
  selected_image: string;
  selected_title: string;
  selected_title_color: Color | undefined;
  trial_state: TrialState | undefined;
}

export interface ActionOpenURL {
  url: string;
}

export interface ActionOpenLoadingScreen {
  loading_message: AttributedString | undefined;
  display_duration: number;
}

export interface ActionOpenSkinDiaryChecklist {
  screen: SkinDiaryScreen | undefined;
  save_button: ActionOpenSkinDiaryChecklist_SaveButton | undefined;
}

export interface ActionOpenSkinDiaryChecklist_SaveButton {
  title: string;
  /** skin_diary_checklist.SkinDiarySaveRequest will be send to this endpoint as body of POST */
  endpoint: string;
  /** If not set, the screen just closes */
  next_action: ActionType | undefined;
}

export interface ActionType {
  action?:
    | { $case: "empty"; empty: ActionEmpty }
    | { $case: "open_web_view"; open_web_view: ActionOpenWebView }
    | { $case: "open_settings_screen"; open_settings_screen: ActionOpenSettingsScreen }
    | { $case: "start_face_scanning"; start_face_scanning: ActionStartFaceScanning }
    | { $case: "open_quiz"; open_quiz: ActionOpenQuiz }
    | { $case: "open_recent_scans"; open_recent_scans: ActionOpenRecentScans }
    | { $case: "mail_to"; mail_to: ActionMailTo }
    | { $case: "pull_get_endpoint"; pull_get_endpoint: ActionPullGetEndpoint }
    | { $case: "open_stories"; open_stories: ActionOpenStories }
    | { $case: "open_video"; open_video: ActionOpenVideo }
    | { $case: "open_video_player_with_chapters"; open_video_player_with_chapters: ActionOpenVideoPlayerWithChapters }
    | { $case: "open_popup"; open_popup: ActionOpenPopup }
    | { $case: "sequence"; sequence: ActionSequence }
    | { $case: "open_my_products"; open_my_products: ActionOpenMyProducts }
    | { $case: "open_product_scanner"; open_product_scanner: ActionOpenProductScanner }
    | { $case: "show_review_form"; show_review_form: ActionShowReviewForm }
    | { $case: "open_dynamic"; open_dynamic: ActionOpenDynamic }
    | { $case: "ask_push_permission"; ask_push_permission: ActionAskPushPermission }
    | { $case: "open_personalization"; open_personalization: ActionOpenPersonalization }
    | { $case: "open_skin_id"; open_skin_id: ActionOpenSkinID }
    | { $case: "go_to_target_screen"; go_to_target_screen: ActionGoToTargetScreen }
    | { $case: "open_assistant"; open_assistant: ActionOpenAssistant }
    | { $case: "open_app_settings"; open_app_settings: ActionOpenAppSettings }
    | { $case: "open_fab_menu"; open_fab_menu: ActionOpenFabMenu }
    | { $case: "go_to_day_offset"; go_to_day_offset: ActionGoToDayOffset }
    | { $case: "open_url"; open_url: ActionOpenURL }
    | { $case: "fetch_next"; fetch_next: ActionFetchNext }
    | { $case: "open_actionable_grid"; open_actionable_grid: ActionOpenActionableGrid }
    | { $case: "send_message_to_assistant"; send_message_to_assistant: ActionSendMessageToAssistant }
    | { $case: "open_safari_view_controller"; open_safari_view_controller: ActionOpenSafariViewController }
    | { $case: "open_loading_screen"; open_loading_screen: ActionOpenLoadingScreen }
    | { $case: "open_skin_diary_checklist"; open_skin_diary_checklist: ActionOpenSkinDiaryChecklist }
    | { $case: "open_assistant_home"; open_assistant_home: ActionOpenAssistantHome }
    | { $case: "go_to_screen"; go_to_screen: ActionGoToScreen }
    | undefined;
  display_if_previous_incomplete: boolean;
}

export interface ActionGoToDayOffset {
  offset: number;
}

export interface ScopedAction {
  action: ActionType | undefined;
  perform_scope: ScopedAction_PerformScope;
}

/**
 * If performer is set to EXTERNAL, the action will be performed by the external context (i.e Scanner or Home)
 * If performer is set to CURRENT, the action will be performed by the current context
 */
export enum ScopedAction_PerformScope {
  PERFORM_SCOPE_NONE = "PERFORM_SCOPE_NONE",
  PERFORM_SCOPE_CURRENT = "PERFORM_SCOPE_CURRENT",
  PERFORM_SCOPE_EXTERNAL = "PERFORM_SCOPE_EXTERNAL",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function scopedAction_PerformScopeFromJSON(object: any): ScopedAction_PerformScope {
  switch (object) {
    case 0:
    case "PERFORM_SCOPE_NONE":
      return ScopedAction_PerformScope.PERFORM_SCOPE_NONE;
    case 1:
    case "PERFORM_SCOPE_CURRENT":
      return ScopedAction_PerformScope.PERFORM_SCOPE_CURRENT;
    case 2:
    case "PERFORM_SCOPE_EXTERNAL":
      return ScopedAction_PerformScope.PERFORM_SCOPE_EXTERNAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ScopedAction_PerformScope.UNRECOGNIZED;
  }
}

export function scopedAction_PerformScopeToJSON(object: ScopedAction_PerformScope): string {
  switch (object) {
    case ScopedAction_PerformScope.PERFORM_SCOPE_NONE:
      return "PERFORM_SCOPE_NONE";
    case ScopedAction_PerformScope.PERFORM_SCOPE_CURRENT:
      return "PERFORM_SCOPE_CURRENT";
    case ScopedAction_PerformScope.PERFORM_SCOPE_EXTERNAL:
      return "PERFORM_SCOPE_EXTERNAL";
    case ScopedAction_PerformScope.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Action type that triggers `SendToAssistantMessageRequest` in current assistant context
 * ⚠️ Do NOT send this action outside of assistant scope, otherwise it will be ignored by the client.
 */
export interface ActionSendMessageToAssistant {
  /** The text of the message that should be displayed in assistant UI as if it was sent from the user */
  user_message_text: string;
  /** Generic set of parameters */
  params: { [key: string]: string };
}

export interface ActionSendMessageToAssistant_ParamsEntry {
  key: string;
  value: string;
}

export interface ActionFetchNext {
  next_action_id: string;
}

export interface ActionFetchNextRequest {
  request: Request | undefined;
  next_action_id: string;
}

export interface ActionFetchNextResponse {
  next_action: ActionType | undefined;
}

export interface Popup {
  id: string;
  content: Popup_Content | undefined;
  hides_close_button: boolean;
  close_action: ActionType | undefined;
}

export interface Popup_Content {
  content?: { $case: "alert"; alert: Popup_Content_Alert } | { $case: "dialog"; dialog: Popup_Content_Dialog } | {
    $case: "signed_message";
    signed_message: PopupContentSignedMessage;
  } | undefined;
}

export interface Popup_Content_Dialog {
  title: string;
  message: string;
  avatar_url: string;
  call_to_action: Popup_Content_Dialog_CallToAction | undefined;
}

export interface Popup_Content_Dialog_CallToAction {
  content?: { $case: "buttons"; buttons: Popup_Content_Dialog_CallToAction_Buttons } | undefined;
}

export interface Popup_Content_Dialog_CallToAction_Buttons {
  buttons: Popup_Content_Dialog_CallToAction_Buttons_Button[];
}

export interface Popup_Content_Dialog_CallToAction_Buttons_Button {
  title: string;
  action: ActionType | undefined;
}

export interface Popup_Content_Alert {
  title: string;
  /**
   * DEPRECATED: Use `attributed_message` instead
   *
   * @deprecated
   */
  message: string;
  buttons: Popup_Content_Alert_Button[];
  header: Popup_Content_Alert_Header | undefined;
  tag: Tag | undefined;
  content_toggle: Popup_Content_Alert_ContentToggle | undefined;
  footer: Popup_Content_Alert_Footer | undefined;
  horizontal_alignment: HorizontalAlignment;
  attributed_message:
    | AttributedString
    | undefined;
  /**
   * Use `header` instead of `image`
   *
   * @deprecated
   */
  image: Popup_Content_Alert_Image | undefined;
}

export interface Popup_Content_Alert_Footer {
  text:
    | AttributedString
    | undefined;
  /**
   * Maps markdown URLs from the footer text to actions
   * If the user taps on a URL, the corresponding action will be triggered
   * If URL is not in the map, the URL will be opened in the web view
   */
  urls_to_actions: { [key: string]: ScopedAction };
}

export interface Popup_Content_Alert_Footer_UrlsToActionsEntry {
  key: string;
  value: ScopedAction | undefined;
}

export interface Popup_Content_Alert_Header {
  header?: { $case: "image"; image: Popup_Content_Alert_Header_Image } | {
    $case: "loader";
    loader: Popup_Content_Alert_Header_Loader;
  } | undefined;
}

export interface Popup_Content_Alert_Header_Image {
  source: string;
  size: Popup_Content_Alert_Header_Image_Size;
  aspect_ratio: Popup_Content_Alert_Header_Image_AspectRatio | undefined;
  emoji: string;
}

export enum Popup_Content_Alert_Header_Image_Size {
  SIZE_NONE = "SIZE_NONE",
  SIZE_SMALL = "SIZE_SMALL",
  SIZE_MEDIUM = "SIZE_MEDIUM",
  SIZE_LARGE = "SIZE_LARGE",
  SIZE_FULLFILLED_WIDTH = "SIZE_FULLFILLED_WIDTH",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function popup_Content_Alert_Header_Image_SizeFromJSON(object: any): Popup_Content_Alert_Header_Image_Size {
  switch (object) {
    case 0:
    case "SIZE_NONE":
      return Popup_Content_Alert_Header_Image_Size.SIZE_NONE;
    case 1:
    case "SIZE_SMALL":
      return Popup_Content_Alert_Header_Image_Size.SIZE_SMALL;
    case 2:
    case "SIZE_MEDIUM":
      return Popup_Content_Alert_Header_Image_Size.SIZE_MEDIUM;
    case 3:
    case "SIZE_LARGE":
      return Popup_Content_Alert_Header_Image_Size.SIZE_LARGE;
    case 4:
    case "SIZE_FULLFILLED_WIDTH":
      return Popup_Content_Alert_Header_Image_Size.SIZE_FULLFILLED_WIDTH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Popup_Content_Alert_Header_Image_Size.UNRECOGNIZED;
  }
}

export function popup_Content_Alert_Header_Image_SizeToJSON(object: Popup_Content_Alert_Header_Image_Size): string {
  switch (object) {
    case Popup_Content_Alert_Header_Image_Size.SIZE_NONE:
      return "SIZE_NONE";
    case Popup_Content_Alert_Header_Image_Size.SIZE_SMALL:
      return "SIZE_SMALL";
    case Popup_Content_Alert_Header_Image_Size.SIZE_MEDIUM:
      return "SIZE_MEDIUM";
    case Popup_Content_Alert_Header_Image_Size.SIZE_LARGE:
      return "SIZE_LARGE";
    case Popup_Content_Alert_Header_Image_Size.SIZE_FULLFILLED_WIDTH:
      return "SIZE_FULLFILLED_WIDTH";
    case Popup_Content_Alert_Header_Image_Size.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Popup_Content_Alert_Header_Image_AspectRatio {
  width_to_height_ratio: number;
  preset: Popup_Content_Alert_Header_Image_AspectRatio_Preset;
}

export enum Popup_Content_Alert_Header_Image_AspectRatio_Preset {
  PRESET_NONE = "PRESET_NONE",
  /** PRESET_SQUARE - PRESET_FILL = 2; */
  PRESET_SQUARE = "PRESET_SQUARE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function popup_Content_Alert_Header_Image_AspectRatio_PresetFromJSON(
  object: any,
): Popup_Content_Alert_Header_Image_AspectRatio_Preset {
  switch (object) {
    case 0:
    case "PRESET_NONE":
      return Popup_Content_Alert_Header_Image_AspectRatio_Preset.PRESET_NONE;
    case 1:
    case "PRESET_SQUARE":
      return Popup_Content_Alert_Header_Image_AspectRatio_Preset.PRESET_SQUARE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Popup_Content_Alert_Header_Image_AspectRatio_Preset.UNRECOGNIZED;
  }
}

export function popup_Content_Alert_Header_Image_AspectRatio_PresetToJSON(
  object: Popup_Content_Alert_Header_Image_AspectRatio_Preset,
): string {
  switch (object) {
    case Popup_Content_Alert_Header_Image_AspectRatio_Preset.PRESET_NONE:
      return "PRESET_NONE";
    case Popup_Content_Alert_Header_Image_AspectRatio_Preset.PRESET_SQUARE:
      return "PRESET_SQUARE";
    case Popup_Content_Alert_Header_Image_AspectRatio_Preset.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Popup_Content_Alert_Header_Loader {
}

export interface Popup_Content_Alert_ContentToggle {
  text: AttributedString | undefined;
  is_toggle_on: boolean;
}

export interface Popup_Content_Alert_Button {
  button: FilledButton | undefined;
  scoped_action:
    | ScopedAction
    | undefined;
  /** @deprecated */
  title: string;
  /** @deprecated */
  action: ActionType | undefined;
}

/**
 * Use `Header` instead of `Image`
 *
 * @deprecated
 */
export interface Popup_Content_Alert_Image {
  link: string;
  size: Popup_Content_Alert_Image_Size;
}

export enum Popup_Content_Alert_Image_Size {
  NONE = "NONE",
  SMALL = "SMALL",
  TALL = "TALL",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function popup_Content_Alert_Image_SizeFromJSON(object: any): Popup_Content_Alert_Image_Size {
  switch (object) {
    case 0:
    case "NONE":
      return Popup_Content_Alert_Image_Size.NONE;
    case 1:
    case "SMALL":
      return Popup_Content_Alert_Image_Size.SMALL;
    case 2:
    case "TALL":
      return Popup_Content_Alert_Image_Size.TALL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Popup_Content_Alert_Image_Size.UNRECOGNIZED;
  }
}

export function popup_Content_Alert_Image_SizeToJSON(object: Popup_Content_Alert_Image_Size): string {
  switch (object) {
    case Popup_Content_Alert_Image_Size.NONE:
      return "NONE";
    case Popup_Content_Alert_Image_Size.SMALL:
      return "SMALL";
    case Popup_Content_Alert_Image_Size.TALL:
      return "TALL";
    case Popup_Content_Alert_Image_Size.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PopupContentSignedMessage {
  avatar_url: string;
  title: string;
  message: string;
  signature_url: string;
  buttons: PopupContentSignedMessage_Button[];
}

export interface PopupContentSignedMessage_Button {
  button_style: FilledButton | undefined;
  action: ActionType | undefined;
}

export interface SettingsScreenRequest {
  request: Request | undefined;
  face_scan_ids: string[];
}

export interface SettingsScreen {
  title: string;
  sections: SettingsScreen_Section[];
  account_deletion_message: AccountDeletionMessage | undefined;
  avatar_url: string;
  calendar: SettingsScreen_SkinCalendar | undefined;
}

export interface SettingsScreen_Section {
  title: string;
  items: SettingsScreen_Section_Item[];
}

export interface SettingsScreen_Section_Item {
  title: AttributedString | undefined;
  action: SettingsScreen_Section_Item_Action | undefined;
  analytics_label: string;
  left_icon: string;
}

/** This wrapper message is needed to easily add more action types in the future */
export interface SettingsScreen_Section_Item_Action {
  action?: { $case: "generic_action"; generic_action: SettingsScreen_Section_Item_Action_Generic } | undefined;
}

export interface SettingsScreen_Section_Item_Action_Generic {
  action: ActionType | undefined;
  performer: SettingsScreen_Section_Item_Action_Generic_Performer;
}

export enum SettingsScreen_Section_Item_Action_Generic_Performer {
  PERFORMER_NONE = "PERFORMER_NONE",
  PERFORMER_SETTINGS = "PERFORMER_SETTINGS",
  PERFORMER_EXTERNAL = "PERFORMER_EXTERNAL",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function settingsScreen_Section_Item_Action_Generic_PerformerFromJSON(
  object: any,
): SettingsScreen_Section_Item_Action_Generic_Performer {
  switch (object) {
    case 0:
    case "PERFORMER_NONE":
      return SettingsScreen_Section_Item_Action_Generic_Performer.PERFORMER_NONE;
    case 1:
    case "PERFORMER_SETTINGS":
      return SettingsScreen_Section_Item_Action_Generic_Performer.PERFORMER_SETTINGS;
    case 2:
    case "PERFORMER_EXTERNAL":
      return SettingsScreen_Section_Item_Action_Generic_Performer.PERFORMER_EXTERNAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SettingsScreen_Section_Item_Action_Generic_Performer.UNRECOGNIZED;
  }
}

export function settingsScreen_Section_Item_Action_Generic_PerformerToJSON(
  object: SettingsScreen_Section_Item_Action_Generic_Performer,
): string {
  switch (object) {
    case SettingsScreen_Section_Item_Action_Generic_Performer.PERFORMER_NONE:
      return "PERFORMER_NONE";
    case SettingsScreen_Section_Item_Action_Generic_Performer.PERFORMER_SETTINGS:
      return "PERFORMER_SETTINGS";
    case SettingsScreen_Section_Item_Action_Generic_Performer.PERFORMER_EXTERNAL:
      return "PERFORMER_EXTERNAL";
    case SettingsScreen_Section_Item_Action_Generic_Performer.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SettingsScreen_SkinCalendar {
  items: SettingsScreen_SkinCalendar_CalendarItem[];
}

export interface SettingsScreen_SkinCalendar_CalendarItem {
  date: number;
  color?: string | undefined;
  action: ActionPullGetEndpoint | undefined;
}

export interface SkinJournalEntry {
  scan_key?: AttributedString | undefined;
  entries: SkinJournalEntry_JournalField[];
  image: string;
  image_placeholder: string;
  image_date: string;
  skin_feeling_color: string;
  header_title: AttributedString | undefined;
}

export interface SkinJournalEntry_JournalField {
  key: AttributedString | undefined;
  value: AttributedString | undefined;
  right_icon: string;
  is_vertical: boolean;
}

export interface ActionGoToScreen {
  screen: ActionType | undefined;
}

export interface TrialState {
  state?:
    | { $case: "active"; active: TrialState_Active }
    | { $case: "expired"; expired: TrialState_Expired }
    | undefined;
}

export interface TrialState_Active {
  title: string;
  action: ActionType | undefined;
}

export interface TrialState_Expired {
  title: string;
  subtitle: string;
  action: ActionType | undefined;
  bottom_button: TrialState_Expired_Button | undefined;
}

export interface TrialState_Expired_Button {
  button: FilledButton | undefined;
  action: ActionType | undefined;
}

export const ActionEmpty = {
  fromJSON(_: any): ActionEmpty {
    return {};
  },

  toJSON(_: ActionEmpty): unknown {
    const obj: any = {};
    return obj;
  },
};

export const ActionOpenStories = {
  fromJSON(object: any): ActionOpenStories {
    return {
      stories_id: isSet(object.stories_id) ? globalThis.String(object.stories_id) : "",
      context_name: isSet(object.context_name) ? globalThis.String(object.context_name) : "",
    };
  },

  toJSON(message: ActionOpenStories): unknown {
    const obj: any = {};
    if (message.stories_id !== "") {
      obj.stories_id = message.stories_id;
    }
    if (message.context_name !== "") {
      obj.context_name = message.context_name;
    }
    return obj;
  },
};

export const ActionOpenSettingsScreen = {
  fromJSON(object: any): ActionOpenSettingsScreen {
    return {
      has_skin_id: isSet(object.has_skin_id) ? globalThis.Boolean(object.has_skin_id) : false,
      has_personalization: isSet(object.has_personalization) ? globalThis.Boolean(object.has_personalization) : false,
      settings_screen: isSet(object.settings_screen) ? SettingsScreen.fromJSON(object.settings_screen) : undefined,
      account_deletion_message: isSet(object.account_deletion_message)
        ? AccountDeletionMessage.fromJSON(object.account_deletion_message)
        : undefined,
    };
  },

  toJSON(message: ActionOpenSettingsScreen): unknown {
    const obj: any = {};
    if (message.has_skin_id !== false) {
      obj.has_skin_id = message.has_skin_id;
    }
    if (message.has_personalization !== false) {
      obj.has_personalization = message.has_personalization;
    }
    if (message.settings_screen !== undefined) {
      obj.settings_screen = SettingsScreen.toJSON(message.settings_screen);
    }
    if (message.account_deletion_message !== undefined) {
      obj.account_deletion_message = AccountDeletionMessage.toJSON(message.account_deletion_message);
    }
    return obj;
  },
};

export const AccountDeletionMessage = {
  fromJSON(object: any): AccountDeletionMessage {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      text: isSet(object.text) ? globalThis.String(object.text) : "",
      delete_button_text: isSet(object.delete_button_text) ? globalThis.String(object.delete_button_text) : "",
      cancel_button_text: isSet(object.cancel_button_text) ? globalThis.String(object.cancel_button_text) : "",
    };
  },

  toJSON(message: AccountDeletionMessage): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    if (message.delete_button_text !== "") {
      obj.delete_button_text = message.delete_button_text;
    }
    if (message.cancel_button_text !== "") {
      obj.cancel_button_text = message.cancel_button_text;
    }
    return obj;
  },
};

export const ActionStartFaceScanning = {
  fromJSON(object: any): ActionStartFaceScanning {
    return { screen: isSet(object.screen) ? FaceCameraScreen.fromJSON(object.screen) : undefined };
  },

  toJSON(message: ActionStartFaceScanning): unknown {
    const obj: any = {};
    if (message.screen !== undefined) {
      obj.screen = FaceCameraScreen.toJSON(message.screen);
    }
    return obj;
  },
};

export const ActionPullGetEndpoint = {
  fromJSON(object: any): ActionPullGetEndpoint {
    return {
      endpoint: isSet(object.endpoint) ? globalThis.String(object.endpoint) : "",
      params: isObject(object.params)
        ? Object.entries(object.params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      id: isSet(object.id) ? globalThis.String(object.id) : "",
    };
  },

  toJSON(message: ActionPullGetEndpoint): unknown {
    const obj: any = {};
    if (message.endpoint !== "") {
      obj.endpoint = message.endpoint;
    }
    if (message.params) {
      const entries = Object.entries(message.params);
      if (entries.length > 0) {
        obj.params = {};
        entries.forEach(([k, v]) => {
          obj.params[k] = v;
        });
      }
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },
};

export const ActionPullGetEndpoint_ParamsEntry = {
  fromJSON(object: any): ActionPullGetEndpoint_ParamsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ActionPullGetEndpoint_ParamsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },
};

export const ActionOpenWebView = {
  fromJSON(object: any): ActionOpenWebView {
    return { url: isSet(object.url) ? globalThis.String(object.url) : "" };
  },

  toJSON(message: ActionOpenWebView): unknown {
    const obj: any = {};
    if (message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },
};

export const ActionOpenSafariViewController = {
  fromJSON(object: any): ActionOpenSafariViewController {
    return { url: isSet(object.url) ? globalThis.String(object.url) : "" };
  },

  toJSON(message: ActionOpenSafariViewController): unknown {
    const obj: any = {};
    if (message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },
};

export const ActionOpenQuiz = {
  fromJSON(object: any): ActionOpenQuiz {
    return {
      test_id: isSet(object.test_id) ? globalThis.String(object.test_id) : "",
      is_closable_when_initial_fetching_failed: isSet(object.is_closable_when_initial_fetching_failed)
        ? globalThis.Boolean(object.is_closable_when_initial_fetching_failed)
        : false,
      params: isObject(object.params)
        ? Object.entries(object.params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      presentation_style: isSet(object.presentation_style)
        ? actionOpenQuiz_PresentationStyleFromJSON(object.presentation_style)
        : ActionOpenQuiz_PresentationStyle.NONE,
    };
  },

  toJSON(message: ActionOpenQuiz): unknown {
    const obj: any = {};
    if (message.test_id !== "") {
      obj.test_id = message.test_id;
    }
    if (message.is_closable_when_initial_fetching_failed !== false) {
      obj.is_closable_when_initial_fetching_failed = message.is_closable_when_initial_fetching_failed;
    }
    if (message.params) {
      const entries = Object.entries(message.params);
      if (entries.length > 0) {
        obj.params = {};
        entries.forEach(([k, v]) => {
          obj.params[k] = v;
        });
      }
    }
    if (message.presentation_style !== ActionOpenQuiz_PresentationStyle.NONE) {
      obj.presentation_style = actionOpenQuiz_PresentationStyleToJSON(message.presentation_style);
    }
    return obj;
  },
};

export const ActionOpenQuiz_ParamsEntry = {
  fromJSON(object: any): ActionOpenQuiz_ParamsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ActionOpenQuiz_ParamsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },
};

export const ActionOpenPopup = {
  fromJSON(object: any): ActionOpenPopup {
    return { popup: isSet(object.popup) ? Popup.fromJSON(object.popup) : undefined };
  },

  toJSON(message: ActionOpenPopup): unknown {
    const obj: any = {};
    if (message.popup !== undefined) {
      obj.popup = Popup.toJSON(message.popup);
    }
    return obj;
  },
};

export const ActionSequence = {
  fromJSON(object: any): ActionSequence {
    return {
      actions: globalThis.Array.isArray(object?.actions) ? object.actions.map((e: any) => ActionType.fromJSON(e)) : [],
    };
  },

  toJSON(message: ActionSequence): unknown {
    const obj: any = {};
    if (message.actions?.length) {
      obj.actions = message.actions.map((e) => ActionType.toJSON(e));
    }
    return obj;
  },
};

export const ActionOpenRecentScans = {
  fromJSON(object: any): ActionOpenRecentScans {
    return {
      screen_title: isSet(object.screen_title) ? globalThis.String(object.screen_title) : "",
      footer_action: isSet(object.footer_action) ? ActionType.fromJSON(object.footer_action) : undefined,
      time_constraint: isSet(object.full_time)
        ? { $case: "full_time", full_time: globalThis.Boolean(object.full_time) }
        : isSet(object.time_limit)
        ? { $case: "time_limit", time_limit: ActionOpenRecentScans_TimeLimit.fromJSON(object.time_limit) }
        : undefined,
    };
  },

  toJSON(message: ActionOpenRecentScans): unknown {
    const obj: any = {};
    if (message.screen_title !== "") {
      obj.screen_title = message.screen_title;
    }
    if (message.footer_action !== undefined) {
      obj.footer_action = ActionType.toJSON(message.footer_action);
    }
    if (message.time_constraint?.$case === "full_time") {
      obj.full_time = message.time_constraint.full_time;
    }
    if (message.time_constraint?.$case === "time_limit") {
      obj.time_limit = ActionOpenRecentScans_TimeLimit.toJSON(message.time_constraint.time_limit);
    }
    return obj;
  },
};

export const ActionOpenRecentScans_TimeLimit = {
  fromJSON(object: any): ActionOpenRecentScans_TimeLimit {
    return {
      start_timestamp: isSet(object.start_timestamp) ? globalThis.Number(object.start_timestamp) : 0,
      end_timestamp: isSet(object.end_timestamp) ? globalThis.Number(object.end_timestamp) : 0,
    };
  },

  toJSON(message: ActionOpenRecentScans_TimeLimit): unknown {
    const obj: any = {};
    if (message.start_timestamp !== 0) {
      obj.start_timestamp = Math.round(message.start_timestamp);
    }
    if (message.end_timestamp !== 0) {
      obj.end_timestamp = Math.round(message.end_timestamp);
    }
    return obj;
  },
};

export const ActionMailTo = {
  fromJSON(object: any): ActionMailTo {
    return {
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      body: isSet(object.body) ? globalThis.String(object.body) : "",
      subject: isSet(object.subject) ? globalThis.String(object.subject) : "",
      analytics_label: isSet(object.analytics_label) ? globalThis.String(object.analytics_label) : "",
    };
  },

  toJSON(message: ActionMailTo): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.body !== "") {
      obj.body = message.body;
    }
    if (message.subject !== "") {
      obj.subject = message.subject;
    }
    if (message.analytics_label !== "") {
      obj.analytics_label = message.analytics_label;
    }
    return obj;
  },
};

export const ActionOpenVideo = {
  fromJSON(object: any): ActionOpenVideo {
    return {
      video_link: isSet(object.video_link) ? globalThis.String(object.video_link) : "",
      completion_action: isSet(object.completion_action) ? ActionType.fromJSON(object.completion_action) : undefined,
      context_id: isSet(object.context_id) ? globalThis.String(object.context_id) : "",
      context_name: isSet(object.context_name) ? globalThis.String(object.context_name) : "",
    };
  },

  toJSON(message: ActionOpenVideo): unknown {
    const obj: any = {};
    if (message.video_link !== "") {
      obj.video_link = message.video_link;
    }
    if (message.completion_action !== undefined) {
      obj.completion_action = ActionType.toJSON(message.completion_action);
    }
    if (message.context_id !== "") {
      obj.context_id = message.context_id;
    }
    if (message.context_name !== "") {
      obj.context_name = message.context_name;
    }
    return obj;
  },
};

export const ActionOpenVideoPlayerWithChapters = {
  fromJSON(object: any): ActionOpenVideoPlayerWithChapters {
    return {
      player_id: isSet(object.player_id) ? globalThis.String(object.player_id) : "",
      completion_action: isSet(object.completion_action) ? ActionType.fromJSON(object.completion_action) : undefined,
      context_name: isSet(object.context_name) ? globalThis.String(object.context_name) : "",
    };
  },

  toJSON(message: ActionOpenVideoPlayerWithChapters): unknown {
    const obj: any = {};
    if (message.player_id !== "") {
      obj.player_id = message.player_id;
    }
    if (message.completion_action !== undefined) {
      obj.completion_action = ActionType.toJSON(message.completion_action);
    }
    if (message.context_name !== "") {
      obj.context_name = message.context_name;
    }
    return obj;
  },
};

export const ActionOpenActionableGrid = {
  fromJSON(object: any): ActionOpenActionableGrid {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      screen: isSet(object.screen) ? Any.fromJSON(object.screen) : undefined,
    };
  },

  toJSON(message: ActionOpenActionableGrid): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.screen !== undefined) {
      obj.screen = Any.toJSON(message.screen);
    }
    return obj;
  },
};

export const ImageProcessingParameters = {
  fromJSON(object: any): ImageProcessingParameters {
    return {
      max_dimension: isSet(object.max_dimension) ? globalThis.Number(object.max_dimension) : 0,
      compression_quality: isSet(object.compression_quality) ? globalThis.Number(object.compression_quality) : 0,
    };
  },

  toJSON(message: ImageProcessingParameters): unknown {
    const obj: any = {};
    if (message.max_dimension !== 0) {
      obj.max_dimension = Math.round(message.max_dimension);
    }
    if (message.compression_quality !== 0) {
      obj.compression_quality = message.compression_quality;
    }
    return obj;
  },
};

export const ProductsSegmentationParameters = {
  fromJSON(object: any): ProductsSegmentationParameters {
    return {
      enable_runtime_segmentation: isSet(object.enable_runtime_segmentation)
        ? globalThis.Boolean(object.enable_runtime_segmentation)
        : false,
      enable_background_cropping: isSet(object.enable_background_cropping)
        ? globalThis.Boolean(object.enable_background_cropping)
        : false,
      enable_segmentation_animation: isSet(object.enable_segmentation_animation)
        ? globalThis.Boolean(object.enable_segmentation_animation)
        : false,
    };
  },

  toJSON(message: ProductsSegmentationParameters): unknown {
    const obj: any = {};
    if (message.enable_runtime_segmentation !== false) {
      obj.enable_runtime_segmentation = message.enable_runtime_segmentation;
    }
    if (message.enable_background_cropping !== false) {
      obj.enable_background_cropping = message.enable_background_cropping;
    }
    if (message.enable_segmentation_animation !== false) {
      obj.enable_segmentation_animation = message.enable_segmentation_animation;
    }
    return obj;
  },
};

export const ActionOpenMyProducts = {
  fromJSON(object: any): ActionOpenMyProducts {
    return {
      is_intro_finished: isSet(object.is_intro_finished) ? globalThis.Boolean(object.is_intro_finished) : false,
      open_intro_action: isSet(object.open_intro_action) ? ActionType.fromJSON(object.open_intro_action) : undefined,
      variant: isSet(object.variant)
        ? actionOpenMyProducts_VariantFromJSON(object.variant)
        : ActionOpenMyProducts_Variant.VARIANT_NONE,
      variants: globalThis.Array.isArray(object?.variants)
        ? object.variants.map((e: any) => actionOpenMyProducts_VariantFromJSON(e))
        : [],
      selected_tab: isSet(object.selected_tab)
        ? actionOpenMyProducts_VariantFromJSON(object.selected_tab)
        : ActionOpenMyProducts_Variant.VARIANT_NONE,
      image_processing_params: isSet(object.image_processing_params)
        ? ImageProcessingParameters.fromJSON(object.image_processing_params)
        : undefined,
      segmentation_params: isSet(object.segmentation_params)
        ? ProductsSegmentationParameters.fromJSON(object.segmentation_params)
        : undefined,
      open_product_scanner_action: isSet(object.open_product_scanner_action)
        ? ActionType.fromJSON(object.open_product_scanner_action)
        : undefined,
      screen_title: isSet(object.screen_title) ? globalThis.String(object.screen_title) : "",
      should_show_new_recommendations_screen: isSet(object.should_show_new_recommendations_screen)
        ? globalThis.Boolean(object.should_show_new_recommendations_screen)
        : false,
      tabs: globalThis.Array.isArray(object?.tabs)
        ? object.tabs.map((e: any) => ActionOpenMyProducts_Tab.fromJSON(e))
        : [],
      selected_tab_index: isSet(object.selected_tab_index) ? globalThis.Number(object.selected_tab_index) : 0,
    };
  },

  toJSON(message: ActionOpenMyProducts): unknown {
    const obj: any = {};
    if (message.is_intro_finished !== false) {
      obj.is_intro_finished = message.is_intro_finished;
    }
    if (message.open_intro_action !== undefined) {
      obj.open_intro_action = ActionType.toJSON(message.open_intro_action);
    }
    if (message.variant !== ActionOpenMyProducts_Variant.VARIANT_NONE) {
      obj.variant = actionOpenMyProducts_VariantToJSON(message.variant);
    }
    if (message.variants?.length) {
      obj.variants = message.variants.map((e) => actionOpenMyProducts_VariantToJSON(e));
    }
    if (message.selected_tab !== ActionOpenMyProducts_Variant.VARIANT_NONE) {
      obj.selected_tab = actionOpenMyProducts_VariantToJSON(message.selected_tab);
    }
    if (message.image_processing_params !== undefined) {
      obj.image_processing_params = ImageProcessingParameters.toJSON(message.image_processing_params);
    }
    if (message.segmentation_params !== undefined) {
      obj.segmentation_params = ProductsSegmentationParameters.toJSON(message.segmentation_params);
    }
    if (message.open_product_scanner_action !== undefined) {
      obj.open_product_scanner_action = ActionType.toJSON(message.open_product_scanner_action);
    }
    if (message.screen_title !== "") {
      obj.screen_title = message.screen_title;
    }
    if (message.should_show_new_recommendations_screen !== false) {
      obj.should_show_new_recommendations_screen = message.should_show_new_recommendations_screen;
    }
    if (message.tabs?.length) {
      obj.tabs = message.tabs.map((e) => ActionOpenMyProducts_Tab.toJSON(e));
    }
    if (message.selected_tab_index !== 0) {
      obj.selected_tab_index = Math.round(message.selected_tab_index);
    }
    return obj;
  },
};

export const ActionOpenMyProducts_Tab = {
  fromJSON(object: any): ActionOpenMyProducts_Tab {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      variant: isSet(object.variant)
        ? actionOpenMyProducts_VariantFromJSON(object.variant)
        : ActionOpenMyProducts_Variant.VARIANT_NONE,
    };
  },

  toJSON(message: ActionOpenMyProducts_Tab): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.variant !== ActionOpenMyProducts_Variant.VARIANT_NONE) {
      obj.variant = actionOpenMyProducts_VariantToJSON(message.variant);
    }
    return obj;
  },
};

export const ActionOpenProductScanner = {
  fromJSON(object: any): ActionOpenProductScanner {
    return {
      image_processing_params: isSet(object.image_processing_params)
        ? ImageProcessingParameters.fromJSON(object.image_processing_params)
        : undefined,
      sample: isSet(object.sample) ? ActionOpenProductScanner_Sample.fromJSON(object.sample) : undefined,
      segmentation_params: isSet(object.segmentation_params)
        ? ProductsSegmentationParameters.fromJSON(object.segmentation_params)
        : undefined,
      search_variants_count: isSet(object.search_variants_count) ? globalThis.Number(object.search_variants_count) : 0,
      video_instruction: isSet(object.video_instruction)
        ? VideoInstructionScreen.fromJSON(object.video_instruction)
        : undefined,
      should_show_instructions_on_appear: isSet(object.should_show_instructions_on_appear)
        ? globalThis.Boolean(object.should_show_instructions_on_appear)
        : false,
      trial_state: isSet(object.trial_state) ? TrialState.fromJSON(object.trial_state) : undefined,
    };
  },

  toJSON(message: ActionOpenProductScanner): unknown {
    const obj: any = {};
    if (message.image_processing_params !== undefined) {
      obj.image_processing_params = ImageProcessingParameters.toJSON(message.image_processing_params);
    }
    if (message.sample !== undefined) {
      obj.sample = ActionOpenProductScanner_Sample.toJSON(message.sample);
    }
    if (message.segmentation_params !== undefined) {
      obj.segmentation_params = ProductsSegmentationParameters.toJSON(message.segmentation_params);
    }
    if (message.search_variants_count !== 0) {
      obj.search_variants_count = Math.round(message.search_variants_count);
    }
    if (message.video_instruction !== undefined) {
      obj.video_instruction = VideoInstructionScreen.toJSON(message.video_instruction);
    }
    if (message.should_show_instructions_on_appear !== false) {
      obj.should_show_instructions_on_appear = message.should_show_instructions_on_appear;
    }
    if (message.trial_state !== undefined) {
      obj.trial_state = TrialState.toJSON(message.trial_state);
    }
    return obj;
  },
};

export const ActionOpenProductScanner_Sample = {
  fromJSON(object: any): ActionOpenProductScanner_Sample {
    return {
      background_video: isSet(object.background_video) ? globalThis.String(object.background_video) : "",
      scan_my_products_visible: isSet(object.scan_my_products_visible)
        ? globalThis.Boolean(object.scan_my_products_visible)
        : false,
      close_button_visible: isSet(object.close_button_visible)
        ? globalThis.Boolean(object.close_button_visible)
        : false,
      product_screen: isSet(object.product_screen) ? Any.fromJSON(object.product_screen) : undefined,
    };
  },

  toJSON(message: ActionOpenProductScanner_Sample): unknown {
    const obj: any = {};
    if (message.background_video !== "") {
      obj.background_video = message.background_video;
    }
    if (message.scan_my_products_visible !== false) {
      obj.scan_my_products_visible = message.scan_my_products_visible;
    }
    if (message.close_button_visible !== false) {
      obj.close_button_visible = message.close_button_visible;
    }
    if (message.product_screen !== undefined) {
      obj.product_screen = Any.toJSON(message.product_screen);
    }
    return obj;
  },
};

export const ActionAskPushPermission = {
  fromJSON(object: any): ActionAskPushPermission {
    return {
      granted_action: isSet(object.granted_action) ? ActionType.fromJSON(object.granted_action) : undefined,
      denied_action: isSet(object.denied_action) ? ActionType.fromJSON(object.denied_action) : undefined,
    };
  },

  toJSON(message: ActionAskPushPermission): unknown {
    const obj: any = {};
    if (message.granted_action !== undefined) {
      obj.granted_action = ActionType.toJSON(message.granted_action);
    }
    if (message.denied_action !== undefined) {
      obj.denied_action = ActionType.toJSON(message.denied_action);
    }
    return obj;
  },
};

export const ActionOpenAppSettings = {
  fromJSON(_: any): ActionOpenAppSettings {
    return {};
  },

  toJSON(_: ActionOpenAppSettings): unknown {
    const obj: any = {};
    return obj;
  },
};

export const ActionShowReviewForm = {
  fromJSON(object: any): ActionShowReviewForm {
    return {
      success_action: isSet(object.success_action) ? ActionType.fromJSON(object.success_action) : undefined,
      failure_action: isSet(object.failure_action) ? ActionType.fromJSON(object.failure_action) : undefined,
    };
  },

  toJSON(message: ActionShowReviewForm): unknown {
    const obj: any = {};
    if (message.success_action !== undefined) {
      obj.success_action = ActionType.toJSON(message.success_action);
    }
    if (message.failure_action !== undefined) {
      obj.failure_action = ActionType.toJSON(message.failure_action);
    }
    return obj;
  },
};

export const ActionOpenDynamic = {
  fromJSON(object: any): ActionOpenDynamic {
    return {
      payload: isSet(object.payload) ? Any.fromJSON(object.payload) : undefined,
      payload_type: isSet(object.payload_type)
        ? actionOpenDynamic_PayloadTypeFromJSON(object.payload_type)
        : ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_NONE,
    };
  },

  toJSON(message: ActionOpenDynamic): unknown {
    const obj: any = {};
    if (message.payload !== undefined) {
      obj.payload = Any.toJSON(message.payload);
    }
    if (message.payload_type !== ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_NONE) {
      obj.payload_type = actionOpenDynamic_PayloadTypeToJSON(message.payload_type);
    }
    return obj;
  },
};

export const ActionOpenPersonalization = {
  fromJSON(object: any): ActionOpenPersonalization {
    return { screen: isSet(object.screen) ? PersonalizationScreen.fromJSON(object.screen) : undefined };
  },

  toJSON(message: ActionOpenPersonalization): unknown {
    const obj: any = {};
    if (message.screen !== undefined) {
      obj.screen = PersonalizationScreen.toJSON(message.screen);
    }
    return obj;
  },
};

export const ActionOpenSkinID = {
  fromJSON(object: any): ActionOpenSkinID {
    return {
      scan_report_id: isSet(object.scan_report_id) ? globalThis.String(object.scan_report_id) : "",
      analytics_label: isSet(object.analytics_label) ? globalThis.String(object.analytics_label) : "",
    };
  },

  toJSON(message: ActionOpenSkinID): unknown {
    const obj: any = {};
    if (message.scan_report_id !== "") {
      obj.scan_report_id = message.scan_report_id;
    }
    if (message.analytics_label !== "") {
      obj.analytics_label = message.analytics_label;
    }
    return obj;
  },
};

export const ActionGoToTargetScreen = {
  fromJSON(object: any): ActionGoToTargetScreen {
    return {
      target_screen: isSet(object.target_screen)
        ? actionGoToTargetScreen_TargetScreenFromJSON(object.target_screen)
        : ActionGoToTargetScreen_TargetScreen.TARGET_SCREEN_NONE,
    };
  },

  toJSON(message: ActionGoToTargetScreen): unknown {
    const obj: any = {};
    if (message.target_screen !== ActionGoToTargetScreen_TargetScreen.TARGET_SCREEN_NONE) {
      obj.target_screen = actionGoToTargetScreen_TargetScreenToJSON(message.target_screen);
    }
    return obj;
  },
};

export const ActionOpenAssistant = {
  fromJSON(object: any): ActionOpenAssistant {
    return {
      assistant_id: isSet(object.assistant_id) ? globalThis.String(object.assistant_id) : "",
      screen_title: isSet(object.screen_title) ? AttributedString.fromJSON(object.screen_title) : undefined,
      sheet_detents: globalThis.Array.isArray(object?.sheet_detents)
        ? object.sheet_detents.map((e: any) => SheetDetent.fromJSON(e))
        : [],
      analytics_params: isObject(object.analytics_params)
        ? Object.entries(object.analytics_params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      extra_request_params: isObject(object.extra_request_params)
        ? Object.entries(object.extra_request_params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ActionOpenAssistant): unknown {
    const obj: any = {};
    if (message.assistant_id !== "") {
      obj.assistant_id = message.assistant_id;
    }
    if (message.screen_title !== undefined) {
      obj.screen_title = AttributedString.toJSON(message.screen_title);
    }
    if (message.sheet_detents?.length) {
      obj.sheet_detents = message.sheet_detents.map((e) => SheetDetent.toJSON(e));
    }
    if (message.analytics_params) {
      const entries = Object.entries(message.analytics_params);
      if (entries.length > 0) {
        obj.analytics_params = {};
        entries.forEach(([k, v]) => {
          obj.analytics_params[k] = v;
        });
      }
    }
    if (message.extra_request_params) {
      const entries = Object.entries(message.extra_request_params);
      if (entries.length > 0) {
        obj.extra_request_params = {};
        entries.forEach(([k, v]) => {
          obj.extra_request_params[k] = v;
        });
      }
    }
    return obj;
  },
};

export const ActionOpenAssistant_AnalyticsParamsEntry = {
  fromJSON(object: any): ActionOpenAssistant_AnalyticsParamsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ActionOpenAssistant_AnalyticsParamsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },
};

export const ActionOpenAssistant_ExtraRequestParamsEntry = {
  fromJSON(object: any): ActionOpenAssistant_ExtraRequestParamsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ActionOpenAssistant_ExtraRequestParamsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },
};

export const ActionOpenAssistantHome = {
  fromJSON(object: any): ActionOpenAssistantHome {
    return {
      lazy_request: isSet(object.lazy_request)
        ? ActionOpenAssistantHome_AssistantHomeRequest.fromJSON(object.lazy_request)
        : undefined,
    };
  },

  toJSON(message: ActionOpenAssistantHome): unknown {
    const obj: any = {};
    if (message.lazy_request !== undefined) {
      obj.lazy_request = ActionOpenAssistantHome_AssistantHomeRequest.toJSON(message.lazy_request);
    }
    return obj;
  },
};

export const ActionOpenAssistantHome_AssistantHomeRequest = {
  fromJSON(object: any): ActionOpenAssistantHome_AssistantHomeRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      assistant_home_id: isSet(object.assistant_home_id) ? globalThis.String(object.assistant_home_id) : "",
      extra_params: isObject(object.extra_params)
        ? Object.entries(object.extra_params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      analytics_params: isObject(object.analytics_params)
        ? Object.entries(object.analytics_params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ActionOpenAssistantHome_AssistantHomeRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.assistant_home_id !== "") {
      obj.assistant_home_id = message.assistant_home_id;
    }
    if (message.extra_params) {
      const entries = Object.entries(message.extra_params);
      if (entries.length > 0) {
        obj.extra_params = {};
        entries.forEach(([k, v]) => {
          obj.extra_params[k] = v;
        });
      }
    }
    if (message.analytics_params) {
      const entries = Object.entries(message.analytics_params);
      if (entries.length > 0) {
        obj.analytics_params = {};
        entries.forEach(([k, v]) => {
          obj.analytics_params[k] = v;
        });
      }
    }
    return obj;
  },
};

export const ActionOpenAssistantHome_AssistantHomeRequest_ExtraParamsEntry = {
  fromJSON(object: any): ActionOpenAssistantHome_AssistantHomeRequest_ExtraParamsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ActionOpenAssistantHome_AssistantHomeRequest_ExtraParamsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },
};

export const ActionOpenAssistantHome_AssistantHomeRequest_AnalyticsParamsEntry = {
  fromJSON(object: any): ActionOpenAssistantHome_AssistantHomeRequest_AnalyticsParamsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ActionOpenAssistantHome_AssistantHomeRequest_AnalyticsParamsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },
};

export const ActionOpenFabMenu = {
  fromJSON(object: any): ActionOpenFabMenu {
    return { menu: isSet(object.menu) ? ActionOpenFabMenu_FABMenu.fromJSON(object.menu) : undefined };
  },

  toJSON(message: ActionOpenFabMenu): unknown {
    const obj: any = {};
    if (message.menu !== undefined) {
      obj.menu = ActionOpenFabMenu_FABMenu.toJSON(message.menu);
    }
    return obj;
  },
};

export const ActionOpenFabMenu_FABMenu = {
  fromJSON(object: any): ActionOpenFabMenu_FABMenu {
    return {
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => ActionOpenFabMenu_FABMenu_Item.fromJSON(e))
        : [],
      title: isSet(object.title) ? globalThis.String(object.title) : "",
    };
  },

  toJSON(message: ActionOpenFabMenu_FABMenu): unknown {
    const obj: any = {};
    if (message.items?.length) {
      obj.items = message.items.map((e) => ActionOpenFabMenu_FABMenu_Item.toJSON(e));
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    return obj;
  },
};

export const ActionOpenFabMenu_FABMenu_Item = {
  fromJSON(object: any): ActionOpenFabMenu_FABMenu_Item {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
      is_selected: isSet(object.is_selected) ? globalThis.Boolean(object.is_selected) : false,
      selected_image: isSet(object.selected_image) ? globalThis.String(object.selected_image) : "",
      selected_title: isSet(object.selected_title) ? globalThis.String(object.selected_title) : "",
      selected_title_color: isSet(object.selected_title_color)
        ? Color.fromJSON(object.selected_title_color)
        : undefined,
      trial_state: isSet(object.trial_state) ? TrialState.fromJSON(object.trial_state) : undefined,
    };
  },

  toJSON(message: ActionOpenFabMenu_FABMenu_Item): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    if (message.is_selected !== false) {
      obj.is_selected = message.is_selected;
    }
    if (message.selected_image !== "") {
      obj.selected_image = message.selected_image;
    }
    if (message.selected_title !== "") {
      obj.selected_title = message.selected_title;
    }
    if (message.selected_title_color !== undefined) {
      obj.selected_title_color = Color.toJSON(message.selected_title_color);
    }
    if (message.trial_state !== undefined) {
      obj.trial_state = TrialState.toJSON(message.trial_state);
    }
    return obj;
  },
};

export const ActionOpenURL = {
  fromJSON(object: any): ActionOpenURL {
    return { url: isSet(object.url) ? globalThis.String(object.url) : "" };
  },

  toJSON(message: ActionOpenURL): unknown {
    const obj: any = {};
    if (message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },
};

export const ActionOpenLoadingScreen = {
  fromJSON(object: any): ActionOpenLoadingScreen {
    return {
      loading_message: isSet(object.loading_message) ? AttributedString.fromJSON(object.loading_message) : undefined,
      display_duration: isSet(object.display_duration) ? globalThis.Number(object.display_duration) : 0,
    };
  },

  toJSON(message: ActionOpenLoadingScreen): unknown {
    const obj: any = {};
    if (message.loading_message !== undefined) {
      obj.loading_message = AttributedString.toJSON(message.loading_message);
    }
    if (message.display_duration !== 0) {
      obj.display_duration = Math.round(message.display_duration);
    }
    return obj;
  },
};

export const ActionOpenSkinDiaryChecklist = {
  fromJSON(object: any): ActionOpenSkinDiaryChecklist {
    return {
      screen: isSet(object.screen) ? SkinDiaryScreen.fromJSON(object.screen) : undefined,
      save_button: isSet(object.save_button)
        ? ActionOpenSkinDiaryChecklist_SaveButton.fromJSON(object.save_button)
        : undefined,
    };
  },

  toJSON(message: ActionOpenSkinDiaryChecklist): unknown {
    const obj: any = {};
    if (message.screen !== undefined) {
      obj.screen = SkinDiaryScreen.toJSON(message.screen);
    }
    if (message.save_button !== undefined) {
      obj.save_button = ActionOpenSkinDiaryChecklist_SaveButton.toJSON(message.save_button);
    }
    return obj;
  },
};

export const ActionOpenSkinDiaryChecklist_SaveButton = {
  fromJSON(object: any): ActionOpenSkinDiaryChecklist_SaveButton {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      endpoint: isSet(object.endpoint) ? globalThis.String(object.endpoint) : "",
      next_action: isSet(object.next_action) ? ActionType.fromJSON(object.next_action) : undefined,
    };
  },

  toJSON(message: ActionOpenSkinDiaryChecklist_SaveButton): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.endpoint !== "") {
      obj.endpoint = message.endpoint;
    }
    if (message.next_action !== undefined) {
      obj.next_action = ActionType.toJSON(message.next_action);
    }
    return obj;
  },
};

export const ActionType = {
  fromJSON(object: any): ActionType {
    return {
      action: isSet(object.empty)
        ? { $case: "empty", empty: ActionEmpty.fromJSON(object.empty) }
        : isSet(object.open_web_view)
        ? { $case: "open_web_view", open_web_view: ActionOpenWebView.fromJSON(object.open_web_view) }
        : isSet(object.open_settings_screen)
        ? {
          $case: "open_settings_screen",
          open_settings_screen: ActionOpenSettingsScreen.fromJSON(object.open_settings_screen),
        }
        : isSet(object.start_face_scanning)
        ? {
          $case: "start_face_scanning",
          start_face_scanning: ActionStartFaceScanning.fromJSON(object.start_face_scanning),
        }
        : isSet(object.open_quiz)
        ? { $case: "open_quiz", open_quiz: ActionOpenQuiz.fromJSON(object.open_quiz) }
        : isSet(object.open_recent_scans)
        ? { $case: "open_recent_scans", open_recent_scans: ActionOpenRecentScans.fromJSON(object.open_recent_scans) }
        : isSet(object.mail_to)
        ? { $case: "mail_to", mail_to: ActionMailTo.fromJSON(object.mail_to) }
        : isSet(object.pull_get_endpoint)
        ? { $case: "pull_get_endpoint", pull_get_endpoint: ActionPullGetEndpoint.fromJSON(object.pull_get_endpoint) }
        : isSet(object.open_stories)
        ? { $case: "open_stories", open_stories: ActionOpenStories.fromJSON(object.open_stories) }
        : isSet(object.open_video)
        ? { $case: "open_video", open_video: ActionOpenVideo.fromJSON(object.open_video) }
        : isSet(object.open_video_player_with_chapters)
        ? {
          $case: "open_video_player_with_chapters",
          open_video_player_with_chapters: ActionOpenVideoPlayerWithChapters.fromJSON(
            object.open_video_player_with_chapters,
          ),
        }
        : isSet(object.open_popup)
        ? { $case: "open_popup", open_popup: ActionOpenPopup.fromJSON(object.open_popup) }
        : isSet(object.sequence)
        ? { $case: "sequence", sequence: ActionSequence.fromJSON(object.sequence) }
        : isSet(object.open_my_products)
        ? { $case: "open_my_products", open_my_products: ActionOpenMyProducts.fromJSON(object.open_my_products) }
        : isSet(object.open_product_scanner)
        ? {
          $case: "open_product_scanner",
          open_product_scanner: ActionOpenProductScanner.fromJSON(object.open_product_scanner),
        }
        : isSet(object.show_review_form)
        ? { $case: "show_review_form", show_review_form: ActionShowReviewForm.fromJSON(object.show_review_form) }
        : isSet(object.open_dynamic)
        ? { $case: "open_dynamic", open_dynamic: ActionOpenDynamic.fromJSON(object.open_dynamic) }
        : isSet(object.ask_push_permission)
        ? {
          $case: "ask_push_permission",
          ask_push_permission: ActionAskPushPermission.fromJSON(object.ask_push_permission),
        }
        : isSet(object.open_personalization)
        ? {
          $case: "open_personalization",
          open_personalization: ActionOpenPersonalization.fromJSON(object.open_personalization),
        }
        : isSet(object.open_skin_id)
        ? { $case: "open_skin_id", open_skin_id: ActionOpenSkinID.fromJSON(object.open_skin_id) }
        : isSet(object.go_to_target_screen)
        ? {
          $case: "go_to_target_screen",
          go_to_target_screen: ActionGoToTargetScreen.fromJSON(object.go_to_target_screen),
        }
        : isSet(object.open_assistant)
        ? { $case: "open_assistant", open_assistant: ActionOpenAssistant.fromJSON(object.open_assistant) }
        : isSet(object.open_app_settings)
        ? { $case: "open_app_settings", open_app_settings: ActionOpenAppSettings.fromJSON(object.open_app_settings) }
        : isSet(object.open_fab_menu)
        ? { $case: "open_fab_menu", open_fab_menu: ActionOpenFabMenu.fromJSON(object.open_fab_menu) }
        : isSet(object.go_to_day_offset)
        ? { $case: "go_to_day_offset", go_to_day_offset: ActionGoToDayOffset.fromJSON(object.go_to_day_offset) }
        : isSet(object.open_url)
        ? { $case: "open_url", open_url: ActionOpenURL.fromJSON(object.open_url) }
        : isSet(object.fetch_next)
        ? { $case: "fetch_next", fetch_next: ActionFetchNext.fromJSON(object.fetch_next) }
        : isSet(object.open_actionable_grid)
        ? {
          $case: "open_actionable_grid",
          open_actionable_grid: ActionOpenActionableGrid.fromJSON(object.open_actionable_grid),
        }
        : isSet(object.send_message_to_assistant)
        ? {
          $case: "send_message_to_assistant",
          send_message_to_assistant: ActionSendMessageToAssistant.fromJSON(object.send_message_to_assistant),
        }
        : isSet(object.open_safari_view_controller)
        ? {
          $case: "open_safari_view_controller",
          open_safari_view_controller: ActionOpenSafariViewController.fromJSON(object.open_safari_view_controller),
        }
        : isSet(object.open_loading_screen)
        ? {
          $case: "open_loading_screen",
          open_loading_screen: ActionOpenLoadingScreen.fromJSON(object.open_loading_screen),
        }
        : isSet(object.open_skin_diary_checklist)
        ? {
          $case: "open_skin_diary_checklist",
          open_skin_diary_checklist: ActionOpenSkinDiaryChecklist.fromJSON(object.open_skin_diary_checklist),
        }
        : isSet(object.open_assistant_home)
        ? {
          $case: "open_assistant_home",
          open_assistant_home: ActionOpenAssistantHome.fromJSON(object.open_assistant_home),
        }
        : isSet(object.go_to_screen)
        ? { $case: "go_to_screen", go_to_screen: ActionGoToScreen.fromJSON(object.go_to_screen) }
        : undefined,
      display_if_previous_incomplete: isSet(object.display_if_previous_incomplete)
        ? globalThis.Boolean(object.display_if_previous_incomplete)
        : false,
    };
  },

  toJSON(message: ActionType): unknown {
    const obj: any = {};
    if (message.action?.$case === "empty") {
      obj.empty = ActionEmpty.toJSON(message.action.empty);
    }
    if (message.action?.$case === "open_web_view") {
      obj.open_web_view = ActionOpenWebView.toJSON(message.action.open_web_view);
    }
    if (message.action?.$case === "open_settings_screen") {
      obj.open_settings_screen = ActionOpenSettingsScreen.toJSON(message.action.open_settings_screen);
    }
    if (message.action?.$case === "start_face_scanning") {
      obj.start_face_scanning = ActionStartFaceScanning.toJSON(message.action.start_face_scanning);
    }
    if (message.action?.$case === "open_quiz") {
      obj.open_quiz = ActionOpenQuiz.toJSON(message.action.open_quiz);
    }
    if (message.action?.$case === "open_recent_scans") {
      obj.open_recent_scans = ActionOpenRecentScans.toJSON(message.action.open_recent_scans);
    }
    if (message.action?.$case === "mail_to") {
      obj.mail_to = ActionMailTo.toJSON(message.action.mail_to);
    }
    if (message.action?.$case === "pull_get_endpoint") {
      obj.pull_get_endpoint = ActionPullGetEndpoint.toJSON(message.action.pull_get_endpoint);
    }
    if (message.action?.$case === "open_stories") {
      obj.open_stories = ActionOpenStories.toJSON(message.action.open_stories);
    }
    if (message.action?.$case === "open_video") {
      obj.open_video = ActionOpenVideo.toJSON(message.action.open_video);
    }
    if (message.action?.$case === "open_video_player_with_chapters") {
      obj.open_video_player_with_chapters = ActionOpenVideoPlayerWithChapters.toJSON(
        message.action.open_video_player_with_chapters,
      );
    }
    if (message.action?.$case === "open_popup") {
      obj.open_popup = ActionOpenPopup.toJSON(message.action.open_popup);
    }
    if (message.action?.$case === "sequence") {
      obj.sequence = ActionSequence.toJSON(message.action.sequence);
    }
    if (message.action?.$case === "open_my_products") {
      obj.open_my_products = ActionOpenMyProducts.toJSON(message.action.open_my_products);
    }
    if (message.action?.$case === "open_product_scanner") {
      obj.open_product_scanner = ActionOpenProductScanner.toJSON(message.action.open_product_scanner);
    }
    if (message.action?.$case === "show_review_form") {
      obj.show_review_form = ActionShowReviewForm.toJSON(message.action.show_review_form);
    }
    if (message.action?.$case === "open_dynamic") {
      obj.open_dynamic = ActionOpenDynamic.toJSON(message.action.open_dynamic);
    }
    if (message.action?.$case === "ask_push_permission") {
      obj.ask_push_permission = ActionAskPushPermission.toJSON(message.action.ask_push_permission);
    }
    if (message.action?.$case === "open_personalization") {
      obj.open_personalization = ActionOpenPersonalization.toJSON(message.action.open_personalization);
    }
    if (message.action?.$case === "open_skin_id") {
      obj.open_skin_id = ActionOpenSkinID.toJSON(message.action.open_skin_id);
    }
    if (message.action?.$case === "go_to_target_screen") {
      obj.go_to_target_screen = ActionGoToTargetScreen.toJSON(message.action.go_to_target_screen);
    }
    if (message.action?.$case === "open_assistant") {
      obj.open_assistant = ActionOpenAssistant.toJSON(message.action.open_assistant);
    }
    if (message.action?.$case === "open_app_settings") {
      obj.open_app_settings = ActionOpenAppSettings.toJSON(message.action.open_app_settings);
    }
    if (message.action?.$case === "open_fab_menu") {
      obj.open_fab_menu = ActionOpenFabMenu.toJSON(message.action.open_fab_menu);
    }
    if (message.action?.$case === "go_to_day_offset") {
      obj.go_to_day_offset = ActionGoToDayOffset.toJSON(message.action.go_to_day_offset);
    }
    if (message.action?.$case === "open_url") {
      obj.open_url = ActionOpenURL.toJSON(message.action.open_url);
    }
    if (message.action?.$case === "fetch_next") {
      obj.fetch_next = ActionFetchNext.toJSON(message.action.fetch_next);
    }
    if (message.action?.$case === "open_actionable_grid") {
      obj.open_actionable_grid = ActionOpenActionableGrid.toJSON(message.action.open_actionable_grid);
    }
    if (message.action?.$case === "send_message_to_assistant") {
      obj.send_message_to_assistant = ActionSendMessageToAssistant.toJSON(message.action.send_message_to_assistant);
    }
    if (message.action?.$case === "open_safari_view_controller") {
      obj.open_safari_view_controller = ActionOpenSafariViewController.toJSON(
        message.action.open_safari_view_controller,
      );
    }
    if (message.action?.$case === "open_loading_screen") {
      obj.open_loading_screen = ActionOpenLoadingScreen.toJSON(message.action.open_loading_screen);
    }
    if (message.action?.$case === "open_skin_diary_checklist") {
      obj.open_skin_diary_checklist = ActionOpenSkinDiaryChecklist.toJSON(message.action.open_skin_diary_checklist);
    }
    if (message.action?.$case === "open_assistant_home") {
      obj.open_assistant_home = ActionOpenAssistantHome.toJSON(message.action.open_assistant_home);
    }
    if (message.action?.$case === "go_to_screen") {
      obj.go_to_screen = ActionGoToScreen.toJSON(message.action.go_to_screen);
    }
    if (message.display_if_previous_incomplete !== false) {
      obj.display_if_previous_incomplete = message.display_if_previous_incomplete;
    }
    return obj;
  },
};

export const ActionGoToDayOffset = {
  fromJSON(object: any): ActionGoToDayOffset {
    return { offset: isSet(object.offset) ? globalThis.Number(object.offset) : 0 };
  },

  toJSON(message: ActionGoToDayOffset): unknown {
    const obj: any = {};
    if (message.offset !== 0) {
      obj.offset = Math.round(message.offset);
    }
    return obj;
  },
};

export const ScopedAction = {
  fromJSON(object: any): ScopedAction {
    return {
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
      perform_scope: isSet(object.perform_scope)
        ? scopedAction_PerformScopeFromJSON(object.perform_scope)
        : ScopedAction_PerformScope.PERFORM_SCOPE_NONE,
    };
  },

  toJSON(message: ScopedAction): unknown {
    const obj: any = {};
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    if (message.perform_scope !== ScopedAction_PerformScope.PERFORM_SCOPE_NONE) {
      obj.perform_scope = scopedAction_PerformScopeToJSON(message.perform_scope);
    }
    return obj;
  },
};

export const ActionSendMessageToAssistant = {
  fromJSON(object: any): ActionSendMessageToAssistant {
    return {
      user_message_text: isSet(object.user_message_text) ? globalThis.String(object.user_message_text) : "",
      params: isObject(object.params)
        ? Object.entries(object.params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ActionSendMessageToAssistant): unknown {
    const obj: any = {};
    if (message.user_message_text !== "") {
      obj.user_message_text = message.user_message_text;
    }
    if (message.params) {
      const entries = Object.entries(message.params);
      if (entries.length > 0) {
        obj.params = {};
        entries.forEach(([k, v]) => {
          obj.params[k] = v;
        });
      }
    }
    return obj;
  },
};

export const ActionSendMessageToAssistant_ParamsEntry = {
  fromJSON(object: any): ActionSendMessageToAssistant_ParamsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ActionSendMessageToAssistant_ParamsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },
};

export const ActionFetchNext = {
  fromJSON(object: any): ActionFetchNext {
    return { next_action_id: isSet(object.next_action_id) ? globalThis.String(object.next_action_id) : "" };
  },

  toJSON(message: ActionFetchNext): unknown {
    const obj: any = {};
    if (message.next_action_id !== "") {
      obj.next_action_id = message.next_action_id;
    }
    return obj;
  },
};

export const ActionFetchNextRequest = {
  fromJSON(object: any): ActionFetchNextRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      next_action_id: isSet(object.next_action_id) ? globalThis.String(object.next_action_id) : "",
    };
  },

  toJSON(message: ActionFetchNextRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.next_action_id !== "") {
      obj.next_action_id = message.next_action_id;
    }
    return obj;
  },
};

export const ActionFetchNextResponse = {
  fromJSON(object: any): ActionFetchNextResponse {
    return { next_action: isSet(object.next_action) ? ActionType.fromJSON(object.next_action) : undefined };
  },

  toJSON(message: ActionFetchNextResponse): unknown {
    const obj: any = {};
    if (message.next_action !== undefined) {
      obj.next_action = ActionType.toJSON(message.next_action);
    }
    return obj;
  },
};

export const Popup = {
  fromJSON(object: any): Popup {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      content: isSet(object.content) ? Popup_Content.fromJSON(object.content) : undefined,
      hides_close_button: isSet(object.hides_close_button) ? globalThis.Boolean(object.hides_close_button) : false,
      close_action: isSet(object.close_action) ? ActionType.fromJSON(object.close_action) : undefined,
    };
  },

  toJSON(message: Popup): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.content !== undefined) {
      obj.content = Popup_Content.toJSON(message.content);
    }
    if (message.hides_close_button !== false) {
      obj.hides_close_button = message.hides_close_button;
    }
    if (message.close_action !== undefined) {
      obj.close_action = ActionType.toJSON(message.close_action);
    }
    return obj;
  },
};

export const Popup_Content = {
  fromJSON(object: any): Popup_Content {
    return {
      content: isSet(object.alert)
        ? { $case: "alert", alert: Popup_Content_Alert.fromJSON(object.alert) }
        : isSet(object.dialog)
        ? { $case: "dialog", dialog: Popup_Content_Dialog.fromJSON(object.dialog) }
        : isSet(object.signed_message)
        ? { $case: "signed_message", signed_message: PopupContentSignedMessage.fromJSON(object.signed_message) }
        : undefined,
    };
  },

  toJSON(message: Popup_Content): unknown {
    const obj: any = {};
    if (message.content?.$case === "alert") {
      obj.alert = Popup_Content_Alert.toJSON(message.content.alert);
    }
    if (message.content?.$case === "dialog") {
      obj.dialog = Popup_Content_Dialog.toJSON(message.content.dialog);
    }
    if (message.content?.$case === "signed_message") {
      obj.signed_message = PopupContentSignedMessage.toJSON(message.content.signed_message);
    }
    return obj;
  },
};

export const Popup_Content_Dialog = {
  fromJSON(object: any): Popup_Content_Dialog {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      avatar_url: isSet(object.avatar_url) ? globalThis.String(object.avatar_url) : "",
      call_to_action: isSet(object.call_to_action)
        ? Popup_Content_Dialog_CallToAction.fromJSON(object.call_to_action)
        : undefined,
    };
  },

  toJSON(message: Popup_Content_Dialog): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.avatar_url !== "") {
      obj.avatar_url = message.avatar_url;
    }
    if (message.call_to_action !== undefined) {
      obj.call_to_action = Popup_Content_Dialog_CallToAction.toJSON(message.call_to_action);
    }
    return obj;
  },
};

export const Popup_Content_Dialog_CallToAction = {
  fromJSON(object: any): Popup_Content_Dialog_CallToAction {
    return {
      content: isSet(object.buttons)
        ? { $case: "buttons", buttons: Popup_Content_Dialog_CallToAction_Buttons.fromJSON(object.buttons) }
        : undefined,
    };
  },

  toJSON(message: Popup_Content_Dialog_CallToAction): unknown {
    const obj: any = {};
    if (message.content?.$case === "buttons") {
      obj.buttons = Popup_Content_Dialog_CallToAction_Buttons.toJSON(message.content.buttons);
    }
    return obj;
  },
};

export const Popup_Content_Dialog_CallToAction_Buttons = {
  fromJSON(object: any): Popup_Content_Dialog_CallToAction_Buttons {
    return {
      buttons: globalThis.Array.isArray(object?.buttons)
        ? object.buttons.map((e: any) => Popup_Content_Dialog_CallToAction_Buttons_Button.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Popup_Content_Dialog_CallToAction_Buttons): unknown {
    const obj: any = {};
    if (message.buttons?.length) {
      obj.buttons = message.buttons.map((e) => Popup_Content_Dialog_CallToAction_Buttons_Button.toJSON(e));
    }
    return obj;
  },
};

export const Popup_Content_Dialog_CallToAction_Buttons_Button = {
  fromJSON(object: any): Popup_Content_Dialog_CallToAction_Buttons_Button {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: Popup_Content_Dialog_CallToAction_Buttons_Button): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    return obj;
  },
};

export const Popup_Content_Alert = {
  fromJSON(object: any): Popup_Content_Alert {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      buttons: globalThis.Array.isArray(object?.buttons)
        ? object.buttons.map((e: any) => Popup_Content_Alert_Button.fromJSON(e))
        : [],
      header: isSet(object.header) ? Popup_Content_Alert_Header.fromJSON(object.header) : undefined,
      tag: isSet(object.tag) ? Tag.fromJSON(object.tag) : undefined,
      content_toggle: isSet(object.content_toggle)
        ? Popup_Content_Alert_ContentToggle.fromJSON(object.content_toggle)
        : undefined,
      footer: isSet(object.footer) ? Popup_Content_Alert_Footer.fromJSON(object.footer) : undefined,
      horizontal_alignment: isSet(object.horizontal_alignment)
        ? horizontalAlignmentFromJSON(object.horizontal_alignment)
        : HorizontalAlignment.HORIZONTAL_ALIGNMENT_NONE,
      attributed_message: isSet(object.attributed_message)
        ? AttributedString.fromJSON(object.attributed_message)
        : undefined,
      image: isSet(object.image) ? Popup_Content_Alert_Image.fromJSON(object.image) : undefined,
    };
  },

  toJSON(message: Popup_Content_Alert): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.buttons?.length) {
      obj.buttons = message.buttons.map((e) => Popup_Content_Alert_Button.toJSON(e));
    }
    if (message.header !== undefined) {
      obj.header = Popup_Content_Alert_Header.toJSON(message.header);
    }
    if (message.tag !== undefined) {
      obj.tag = Tag.toJSON(message.tag);
    }
    if (message.content_toggle !== undefined) {
      obj.content_toggle = Popup_Content_Alert_ContentToggle.toJSON(message.content_toggle);
    }
    if (message.footer !== undefined) {
      obj.footer = Popup_Content_Alert_Footer.toJSON(message.footer);
    }
    if (message.horizontal_alignment !== HorizontalAlignment.HORIZONTAL_ALIGNMENT_NONE) {
      obj.horizontal_alignment = horizontalAlignmentToJSON(message.horizontal_alignment);
    }
    if (message.attributed_message !== undefined) {
      obj.attributed_message = AttributedString.toJSON(message.attributed_message);
    }
    if (message.image !== undefined) {
      obj.image = Popup_Content_Alert_Image.toJSON(message.image);
    }
    return obj;
  },
};

export const Popup_Content_Alert_Footer = {
  fromJSON(object: any): Popup_Content_Alert_Footer {
    return {
      text: isSet(object.text) ? AttributedString.fromJSON(object.text) : undefined,
      urls_to_actions: isObject(object.urls_to_actions)
        ? Object.entries(object.urls_to_actions).reduce<{ [key: string]: ScopedAction }>((acc, [key, value]) => {
          acc[key] = ScopedAction.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: Popup_Content_Alert_Footer): unknown {
    const obj: any = {};
    if (message.text !== undefined) {
      obj.text = AttributedString.toJSON(message.text);
    }
    if (message.urls_to_actions) {
      const entries = Object.entries(message.urls_to_actions);
      if (entries.length > 0) {
        obj.urls_to_actions = {};
        entries.forEach(([k, v]) => {
          obj.urls_to_actions[k] = ScopedAction.toJSON(v);
        });
      }
    }
    return obj;
  },
};

export const Popup_Content_Alert_Footer_UrlsToActionsEntry = {
  fromJSON(object: any): Popup_Content_Alert_Footer_UrlsToActionsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? ScopedAction.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: Popup_Content_Alert_Footer_UrlsToActionsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = ScopedAction.toJSON(message.value);
    }
    return obj;
  },
};

export const Popup_Content_Alert_Header = {
  fromJSON(object: any): Popup_Content_Alert_Header {
    return {
      header: isSet(object.image)
        ? { $case: "image", image: Popup_Content_Alert_Header_Image.fromJSON(object.image) }
        : isSet(object.loader)
        ? { $case: "loader", loader: Popup_Content_Alert_Header_Loader.fromJSON(object.loader) }
        : undefined,
    };
  },

  toJSON(message: Popup_Content_Alert_Header): unknown {
    const obj: any = {};
    if (message.header?.$case === "image") {
      obj.image = Popup_Content_Alert_Header_Image.toJSON(message.header.image);
    }
    if (message.header?.$case === "loader") {
      obj.loader = Popup_Content_Alert_Header_Loader.toJSON(message.header.loader);
    }
    return obj;
  },
};

export const Popup_Content_Alert_Header_Image = {
  fromJSON(object: any): Popup_Content_Alert_Header_Image {
    return {
      source: isSet(object.source) ? globalThis.String(object.source) : "",
      size: isSet(object.size)
        ? popup_Content_Alert_Header_Image_SizeFromJSON(object.size)
        : Popup_Content_Alert_Header_Image_Size.SIZE_NONE,
      aspect_ratio: isSet(object.aspect_ratio)
        ? Popup_Content_Alert_Header_Image_AspectRatio.fromJSON(object.aspect_ratio)
        : undefined,
      emoji: isSet(object.emoji) ? globalThis.String(object.emoji) : "",
    };
  },

  toJSON(message: Popup_Content_Alert_Header_Image): unknown {
    const obj: any = {};
    if (message.source !== "") {
      obj.source = message.source;
    }
    if (message.size !== Popup_Content_Alert_Header_Image_Size.SIZE_NONE) {
      obj.size = popup_Content_Alert_Header_Image_SizeToJSON(message.size);
    }
    if (message.aspect_ratio !== undefined) {
      obj.aspect_ratio = Popup_Content_Alert_Header_Image_AspectRatio.toJSON(message.aspect_ratio);
    }
    if (message.emoji !== "") {
      obj.emoji = message.emoji;
    }
    return obj;
  },
};

export const Popup_Content_Alert_Header_Image_AspectRatio = {
  fromJSON(object: any): Popup_Content_Alert_Header_Image_AspectRatio {
    return {
      width_to_height_ratio: isSet(object.width_to_height_ratio) ? globalThis.Number(object.width_to_height_ratio) : 0,
      preset: isSet(object.preset)
        ? popup_Content_Alert_Header_Image_AspectRatio_PresetFromJSON(object.preset)
        : Popup_Content_Alert_Header_Image_AspectRatio_Preset.PRESET_NONE,
    };
  },

  toJSON(message: Popup_Content_Alert_Header_Image_AspectRatio): unknown {
    const obj: any = {};
    if (message.width_to_height_ratio !== 0) {
      obj.width_to_height_ratio = message.width_to_height_ratio;
    }
    if (message.preset !== Popup_Content_Alert_Header_Image_AspectRatio_Preset.PRESET_NONE) {
      obj.preset = popup_Content_Alert_Header_Image_AspectRatio_PresetToJSON(message.preset);
    }
    return obj;
  },
};

export const Popup_Content_Alert_Header_Loader = {
  fromJSON(_: any): Popup_Content_Alert_Header_Loader {
    return {};
  },

  toJSON(_: Popup_Content_Alert_Header_Loader): unknown {
    const obj: any = {};
    return obj;
  },
};

export const Popup_Content_Alert_ContentToggle = {
  fromJSON(object: any): Popup_Content_Alert_ContentToggle {
    return {
      text: isSet(object.text) ? AttributedString.fromJSON(object.text) : undefined,
      is_toggle_on: isSet(object.is_toggle_on) ? globalThis.Boolean(object.is_toggle_on) : false,
    };
  },

  toJSON(message: Popup_Content_Alert_ContentToggle): unknown {
    const obj: any = {};
    if (message.text !== undefined) {
      obj.text = AttributedString.toJSON(message.text);
    }
    if (message.is_toggle_on !== false) {
      obj.is_toggle_on = message.is_toggle_on;
    }
    return obj;
  },
};

export const Popup_Content_Alert_Button = {
  fromJSON(object: any): Popup_Content_Alert_Button {
    return {
      button: isSet(object.button) ? FilledButton.fromJSON(object.button) : undefined,
      scoped_action: isSet(object.scoped_action) ? ScopedAction.fromJSON(object.scoped_action) : undefined,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: Popup_Content_Alert_Button): unknown {
    const obj: any = {};
    if (message.button !== undefined) {
      obj.button = FilledButton.toJSON(message.button);
    }
    if (message.scoped_action !== undefined) {
      obj.scoped_action = ScopedAction.toJSON(message.scoped_action);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    return obj;
  },
};

export const Popup_Content_Alert_Image = {
  fromJSON(object: any): Popup_Content_Alert_Image {
    return {
      link: isSet(object.link) ? globalThis.String(object.link) : "",
      size: isSet(object.size)
        ? popup_Content_Alert_Image_SizeFromJSON(object.size)
        : Popup_Content_Alert_Image_Size.NONE,
    };
  },

  toJSON(message: Popup_Content_Alert_Image): unknown {
    const obj: any = {};
    if (message.link !== "") {
      obj.link = message.link;
    }
    if (message.size !== Popup_Content_Alert_Image_Size.NONE) {
      obj.size = popup_Content_Alert_Image_SizeToJSON(message.size);
    }
    return obj;
  },
};

export const PopupContentSignedMessage = {
  fromJSON(object: any): PopupContentSignedMessage {
    return {
      avatar_url: isSet(object.avatar_url) ? globalThis.String(object.avatar_url) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      signature_url: isSet(object.signature_url) ? globalThis.String(object.signature_url) : "",
      buttons: globalThis.Array.isArray(object?.buttons)
        ? object.buttons.map((e: any) => PopupContentSignedMessage_Button.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PopupContentSignedMessage): unknown {
    const obj: any = {};
    if (message.avatar_url !== "") {
      obj.avatar_url = message.avatar_url;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.signature_url !== "") {
      obj.signature_url = message.signature_url;
    }
    if (message.buttons?.length) {
      obj.buttons = message.buttons.map((e) => PopupContentSignedMessage_Button.toJSON(e));
    }
    return obj;
  },
};

export const PopupContentSignedMessage_Button = {
  fromJSON(object: any): PopupContentSignedMessage_Button {
    return {
      button_style: isSet(object.button_style) ? FilledButton.fromJSON(object.button_style) : undefined,
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: PopupContentSignedMessage_Button): unknown {
    const obj: any = {};
    if (message.button_style !== undefined) {
      obj.button_style = FilledButton.toJSON(message.button_style);
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    return obj;
  },
};

export const SettingsScreenRequest = {
  fromJSON(object: any): SettingsScreenRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      face_scan_ids: globalThis.Array.isArray(object?.face_scan_ids)
        ? object.face_scan_ids.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: SettingsScreenRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.face_scan_ids?.length) {
      obj.face_scan_ids = message.face_scan_ids;
    }
    return obj;
  },
};

export const SettingsScreen = {
  fromJSON(object: any): SettingsScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      sections: globalThis.Array.isArray(object?.sections)
        ? object.sections.map((e: any) => SettingsScreen_Section.fromJSON(e))
        : [],
      account_deletion_message: isSet(object.account_deletion_message)
        ? AccountDeletionMessage.fromJSON(object.account_deletion_message)
        : undefined,
      avatar_url: isSet(object.avatar_url) ? globalThis.String(object.avatar_url) : "",
      calendar: isSet(object.calendar) ? SettingsScreen_SkinCalendar.fromJSON(object.calendar) : undefined,
    };
  },

  toJSON(message: SettingsScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.sections?.length) {
      obj.sections = message.sections.map((e) => SettingsScreen_Section.toJSON(e));
    }
    if (message.account_deletion_message !== undefined) {
      obj.account_deletion_message = AccountDeletionMessage.toJSON(message.account_deletion_message);
    }
    if (message.avatar_url !== "") {
      obj.avatar_url = message.avatar_url;
    }
    if (message.calendar !== undefined) {
      obj.calendar = SettingsScreen_SkinCalendar.toJSON(message.calendar);
    }
    return obj;
  },
};

export const SettingsScreen_Section = {
  fromJSON(object: any): SettingsScreen_Section {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => SettingsScreen_Section_Item.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SettingsScreen_Section): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.items?.length) {
      obj.items = message.items.map((e) => SettingsScreen_Section_Item.toJSON(e));
    }
    return obj;
  },
};

export const SettingsScreen_Section_Item = {
  fromJSON(object: any): SettingsScreen_Section_Item {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      action: isSet(object.action) ? SettingsScreen_Section_Item_Action.fromJSON(object.action) : undefined,
      analytics_label: isSet(object.analytics_label) ? globalThis.String(object.analytics_label) : "",
      left_icon: isSet(object.left_icon) ? globalThis.String(object.left_icon) : "",
    };
  },

  toJSON(message: SettingsScreen_Section_Item): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.action !== undefined) {
      obj.action = SettingsScreen_Section_Item_Action.toJSON(message.action);
    }
    if (message.analytics_label !== "") {
      obj.analytics_label = message.analytics_label;
    }
    if (message.left_icon !== "") {
      obj.left_icon = message.left_icon;
    }
    return obj;
  },
};

export const SettingsScreen_Section_Item_Action = {
  fromJSON(object: any): SettingsScreen_Section_Item_Action {
    return {
      action: isSet(object.generic_action)
        ? {
          $case: "generic_action",
          generic_action: SettingsScreen_Section_Item_Action_Generic.fromJSON(object.generic_action),
        }
        : undefined,
    };
  },

  toJSON(message: SettingsScreen_Section_Item_Action): unknown {
    const obj: any = {};
    if (message.action?.$case === "generic_action") {
      obj.generic_action = SettingsScreen_Section_Item_Action_Generic.toJSON(message.action.generic_action);
    }
    return obj;
  },
};

export const SettingsScreen_Section_Item_Action_Generic = {
  fromJSON(object: any): SettingsScreen_Section_Item_Action_Generic {
    return {
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
      performer: isSet(object.performer)
        ? settingsScreen_Section_Item_Action_Generic_PerformerFromJSON(object.performer)
        : SettingsScreen_Section_Item_Action_Generic_Performer.PERFORMER_NONE,
    };
  },

  toJSON(message: SettingsScreen_Section_Item_Action_Generic): unknown {
    const obj: any = {};
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    if (message.performer !== SettingsScreen_Section_Item_Action_Generic_Performer.PERFORMER_NONE) {
      obj.performer = settingsScreen_Section_Item_Action_Generic_PerformerToJSON(message.performer);
    }
    return obj;
  },
};

export const SettingsScreen_SkinCalendar = {
  fromJSON(object: any): SettingsScreen_SkinCalendar {
    return {
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => SettingsScreen_SkinCalendar_CalendarItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SettingsScreen_SkinCalendar): unknown {
    const obj: any = {};
    if (message.items?.length) {
      obj.items = message.items.map((e) => SettingsScreen_SkinCalendar_CalendarItem.toJSON(e));
    }
    return obj;
  },
};

export const SettingsScreen_SkinCalendar_CalendarItem = {
  fromJSON(object: any): SettingsScreen_SkinCalendar_CalendarItem {
    return {
      date: isSet(object.date) ? globalThis.Number(object.date) : 0,
      color: isSet(object.color) ? globalThis.String(object.color) : undefined,
      action: isSet(object.action) ? ActionPullGetEndpoint.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: SettingsScreen_SkinCalendar_CalendarItem): unknown {
    const obj: any = {};
    if (message.date !== 0) {
      obj.date = Math.round(message.date);
    }
    if (message.color !== undefined) {
      obj.color = message.color;
    }
    if (message.action !== undefined) {
      obj.action = ActionPullGetEndpoint.toJSON(message.action);
    }
    return obj;
  },
};

export const SkinJournalEntry = {
  fromJSON(object: any): SkinJournalEntry {
    return {
      scan_key: isSet(object.scan_key) ? AttributedString.fromJSON(object.scan_key) : undefined,
      entries: globalThis.Array.isArray(object?.entries)
        ? object.entries.map((e: any) => SkinJournalEntry_JournalField.fromJSON(e))
        : [],
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      image_placeholder: isSet(object.image_placeholder) ? globalThis.String(object.image_placeholder) : "",
      image_date: isSet(object.image_date) ? globalThis.String(object.image_date) : "",
      skin_feeling_color: isSet(object.skin_feeling_color) ? globalThis.String(object.skin_feeling_color) : "",
      header_title: isSet(object.header_title) ? AttributedString.fromJSON(object.header_title) : undefined,
    };
  },

  toJSON(message: SkinJournalEntry): unknown {
    const obj: any = {};
    if (message.scan_key !== undefined) {
      obj.scan_key = AttributedString.toJSON(message.scan_key);
    }
    if (message.entries?.length) {
      obj.entries = message.entries.map((e) => SkinJournalEntry_JournalField.toJSON(e));
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.image_placeholder !== "") {
      obj.image_placeholder = message.image_placeholder;
    }
    if (message.image_date !== "") {
      obj.image_date = message.image_date;
    }
    if (message.skin_feeling_color !== "") {
      obj.skin_feeling_color = message.skin_feeling_color;
    }
    if (message.header_title !== undefined) {
      obj.header_title = AttributedString.toJSON(message.header_title);
    }
    return obj;
  },
};

export const SkinJournalEntry_JournalField = {
  fromJSON(object: any): SkinJournalEntry_JournalField {
    return {
      key: isSet(object.key) ? AttributedString.fromJSON(object.key) : undefined,
      value: isSet(object.value) ? AttributedString.fromJSON(object.value) : undefined,
      right_icon: isSet(object.right_icon) ? globalThis.String(object.right_icon) : "",
      is_vertical: isSet(object.is_vertical) ? globalThis.Boolean(object.is_vertical) : false,
    };
  },

  toJSON(message: SkinJournalEntry_JournalField): unknown {
    const obj: any = {};
    if (message.key !== undefined) {
      obj.key = AttributedString.toJSON(message.key);
    }
    if (message.value !== undefined) {
      obj.value = AttributedString.toJSON(message.value);
    }
    if (message.right_icon !== "") {
      obj.right_icon = message.right_icon;
    }
    if (message.is_vertical !== false) {
      obj.is_vertical = message.is_vertical;
    }
    return obj;
  },
};

export const ActionGoToScreen = {
  fromJSON(object: any): ActionGoToScreen {
    return { screen: isSet(object.screen) ? ActionType.fromJSON(object.screen) : undefined };
  },

  toJSON(message: ActionGoToScreen): unknown {
    const obj: any = {};
    if (message.screen !== undefined) {
      obj.screen = ActionType.toJSON(message.screen);
    }
    return obj;
  },
};

export const TrialState = {
  fromJSON(object: any): TrialState {
    return {
      state: isSet(object.active)
        ? { $case: "active", active: TrialState_Active.fromJSON(object.active) }
        : isSet(object.expired)
        ? { $case: "expired", expired: TrialState_Expired.fromJSON(object.expired) }
        : undefined,
    };
  },

  toJSON(message: TrialState): unknown {
    const obj: any = {};
    if (message.state?.$case === "active") {
      obj.active = TrialState_Active.toJSON(message.state.active);
    }
    if (message.state?.$case === "expired") {
      obj.expired = TrialState_Expired.toJSON(message.state.expired);
    }
    return obj;
  },
};

export const TrialState_Active = {
  fromJSON(object: any): TrialState_Active {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: TrialState_Active): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    return obj;
  },
};

export const TrialState_Expired = {
  fromJSON(object: any): TrialState_Expired {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
      bottom_button: isSet(object.bottom_button) ? TrialState_Expired_Button.fromJSON(object.bottom_button) : undefined,
    };
  },

  toJSON(message: TrialState_Expired): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    if (message.bottom_button !== undefined) {
      obj.bottom_button = TrialState_Expired_Button.toJSON(message.bottom_button);
    }
    return obj;
  },
};

export const TrialState_Expired_Button = {
  fromJSON(object: any): TrialState_Expired_Button {
    return {
      button: isSet(object.button) ? FilledButton.fromJSON(object.button) : undefined,
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: TrialState_Expired_Button): unknown {
    const obj: any = {};
    if (message.button !== undefined) {
      obj.button = FilledButton.toJSON(message.button);
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    return obj;
  },
};

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
