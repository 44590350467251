// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: skin_id.proto

/* eslint-disable */
import { AttributedString } from "./presentation";

export interface SummaryListItem {
  /** @deprecated */
  image: string;
  title: string;
  description: string;
  /** @deprecated */
  badge: string;
}

export interface WebScanSkinIDScreen {
  title: AttributedString | undefined;
  subtitle: AttributedString | undefined;
  summary: WebScanSkinIDScreen_Summary | undefined;
  footer_button_title: string;
  footer_button_quiz_id: string;
}

export interface WebScanSkinIDScreen_Summary {
  items: WebScanSkinIDScreen_Summary_Item[];
}

export interface WebScanSkinIDScreen_Summary_Item {
  id: string;
  title: string;
  value: WebScanSkinIDScreen_Summary_Item_Value | undefined;
}

export interface WebScanSkinIDScreen_Summary_Item_Value {
  value?: { $case: "answer"; answer: WebScanSkinIDScreen_Summary_Item_Value_Answer } | {
    $case: "web_scan";
    web_scan: WebScanSkinIDScreen_Summary_Item_Value_WebScan;
  } | undefined;
}

export interface WebScanSkinIDScreen_Summary_Item_Value_Answer {
  answer: string;
  is_secondary: boolean;
  /** The id of interview quiz that should be opened when the item is clicked. */
  quiz_id: string;
}

export interface WebScanSkinIDScreen_Summary_Item_Value_WebScan {
  content?: { $case: "resource"; resource: string } | { $case: "scan_id"; scan_id: string } | undefined;
}

export interface SkinIDScreen {
  scan_report_id: string;
  title: AttributedString | undefined;
  summary_items: SummaryListItem[];
  visualizations: IssueSubtypeVisualization[];
  view_report_button_title: string;
  bottom_button_title: string;
}

export interface XYPoint {
  x: number;
  y: number;
}

export interface NormalizedXYPoint {
  x: number;
  y: number;
}

export interface NormalizedRect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface Polygon {
  points: XYPoint[];
  normalized_points: NormalizedXYPoint[];
  hex_color: string;
  area: number;
}

export interface Polygons {
  polygons: Polygon[];
}

export interface BinaryMask {
  mask: string;
  hex_color: string;
  render_mode: BinaryMask_RenderMode;
  mask_rect: NormalizedRect | undefined;
}

export enum BinaryMask_RenderMode {
  /** NONE - Equal RAW_IMAGE */
  NONE = "NONE",
  RAW_IMAGE = "RAW_IMAGE",
  ANIMATED_HEATMAP = "ANIMATED_HEATMAP",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function binaryMask_RenderModeFromJSON(object: any): BinaryMask_RenderMode {
  switch (object) {
    case 0:
    case "NONE":
      return BinaryMask_RenderMode.NONE;
    case 1:
    case "RAW_IMAGE":
      return BinaryMask_RenderMode.RAW_IMAGE;
    case 2:
    case "ANIMATED_HEATMAP":
      return BinaryMask_RenderMode.ANIMATED_HEATMAP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BinaryMask_RenderMode.UNRECOGNIZED;
  }
}

export function binaryMask_RenderModeToJSON(object: BinaryMask_RenderMode): string {
  switch (object) {
    case BinaryMask_RenderMode.NONE:
      return "NONE";
    case BinaryMask_RenderMode.RAW_IMAGE:
      return "RAW_IMAGE";
    case BinaryMask_RenderMode.ANIMATED_HEATMAP:
      return "ANIMATED_HEATMAP";
    case BinaryMask_RenderMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface BinaryMasks {
  binary_masks: BinaryMask[];
}

export interface IssueSubtypeVisualization {
  id: string;
  visualization?:
    | { $case: "polygons"; polygons: Polygons }
    | { $case: "binary_masks"; binary_masks: BinaryMasks }
    | undefined;
}

export const SummaryListItem = {
  fromJSON(object: any): SummaryListItem {
    return {
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      badge: isSet(object.badge) ? globalThis.String(object.badge) : "",
    };
  },

  toJSON(message: SummaryListItem): unknown {
    const obj: any = {};
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.badge !== "") {
      obj.badge = message.badge;
    }
    return obj;
  },
};

export const WebScanSkinIDScreen = {
  fromJSON(object: any): WebScanSkinIDScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      subtitle: isSet(object.subtitle) ? AttributedString.fromJSON(object.subtitle) : undefined,
      summary: isSet(object.summary) ? WebScanSkinIDScreen_Summary.fromJSON(object.summary) : undefined,
      footer_button_title: isSet(object.footer_button_title) ? globalThis.String(object.footer_button_title) : "",
      footer_button_quiz_id: isSet(object.footer_button_quiz_id) ? globalThis.String(object.footer_button_quiz_id) : "",
    };
  },

  toJSON(message: WebScanSkinIDScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.subtitle !== undefined) {
      obj.subtitle = AttributedString.toJSON(message.subtitle);
    }
    if (message.summary !== undefined) {
      obj.summary = WebScanSkinIDScreen_Summary.toJSON(message.summary);
    }
    if (message.footer_button_title !== "") {
      obj.footer_button_title = message.footer_button_title;
    }
    if (message.footer_button_quiz_id !== "") {
      obj.footer_button_quiz_id = message.footer_button_quiz_id;
    }
    return obj;
  },
};

export const WebScanSkinIDScreen_Summary = {
  fromJSON(object: any): WebScanSkinIDScreen_Summary {
    return {
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => WebScanSkinIDScreen_Summary_Item.fromJSON(e))
        : [],
    };
  },

  toJSON(message: WebScanSkinIDScreen_Summary): unknown {
    const obj: any = {};
    if (message.items?.length) {
      obj.items = message.items.map((e) => WebScanSkinIDScreen_Summary_Item.toJSON(e));
    }
    return obj;
  },
};

export const WebScanSkinIDScreen_Summary_Item = {
  fromJSON(object: any): WebScanSkinIDScreen_Summary_Item {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      value: isSet(object.value) ? WebScanSkinIDScreen_Summary_Item_Value.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: WebScanSkinIDScreen_Summary_Item): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.value !== undefined) {
      obj.value = WebScanSkinIDScreen_Summary_Item_Value.toJSON(message.value);
    }
    return obj;
  },
};

export const WebScanSkinIDScreen_Summary_Item_Value = {
  fromJSON(object: any): WebScanSkinIDScreen_Summary_Item_Value {
    return {
      value: isSet(object.answer)
        ? { $case: "answer", answer: WebScanSkinIDScreen_Summary_Item_Value_Answer.fromJSON(object.answer) }
        : isSet(object.web_scan)
        ? { $case: "web_scan", web_scan: WebScanSkinIDScreen_Summary_Item_Value_WebScan.fromJSON(object.web_scan) }
        : undefined,
    };
  },

  toJSON(message: WebScanSkinIDScreen_Summary_Item_Value): unknown {
    const obj: any = {};
    if (message.value?.$case === "answer") {
      obj.answer = WebScanSkinIDScreen_Summary_Item_Value_Answer.toJSON(message.value.answer);
    }
    if (message.value?.$case === "web_scan") {
      obj.web_scan = WebScanSkinIDScreen_Summary_Item_Value_WebScan.toJSON(message.value.web_scan);
    }
    return obj;
  },
};

export const WebScanSkinIDScreen_Summary_Item_Value_Answer = {
  fromJSON(object: any): WebScanSkinIDScreen_Summary_Item_Value_Answer {
    return {
      answer: isSet(object.answer) ? globalThis.String(object.answer) : "",
      is_secondary: isSet(object.is_secondary) ? globalThis.Boolean(object.is_secondary) : false,
      quiz_id: isSet(object.quiz_id) ? globalThis.String(object.quiz_id) : "",
    };
  },

  toJSON(message: WebScanSkinIDScreen_Summary_Item_Value_Answer): unknown {
    const obj: any = {};
    if (message.answer !== "") {
      obj.answer = message.answer;
    }
    if (message.is_secondary !== false) {
      obj.is_secondary = message.is_secondary;
    }
    if (message.quiz_id !== "") {
      obj.quiz_id = message.quiz_id;
    }
    return obj;
  },
};

export const WebScanSkinIDScreen_Summary_Item_Value_WebScan = {
  fromJSON(object: any): WebScanSkinIDScreen_Summary_Item_Value_WebScan {
    return {
      content: isSet(object.resource)
        ? { $case: "resource", resource: globalThis.String(object.resource) }
        : isSet(object.scan_id)
        ? { $case: "scan_id", scan_id: globalThis.String(object.scan_id) }
        : undefined,
    };
  },

  toJSON(message: WebScanSkinIDScreen_Summary_Item_Value_WebScan): unknown {
    const obj: any = {};
    if (message.content?.$case === "resource") {
      obj.resource = message.content.resource;
    }
    if (message.content?.$case === "scan_id") {
      obj.scan_id = message.content.scan_id;
    }
    return obj;
  },
};

export const SkinIDScreen = {
  fromJSON(object: any): SkinIDScreen {
    return {
      scan_report_id: isSet(object.scan_report_id) ? globalThis.String(object.scan_report_id) : "",
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      summary_items: globalThis.Array.isArray(object?.summary_items)
        ? object.summary_items.map((e: any) => SummaryListItem.fromJSON(e))
        : [],
      visualizations: globalThis.Array.isArray(object?.visualizations)
        ? object.visualizations.map((e: any) => IssueSubtypeVisualization.fromJSON(e))
        : [],
      view_report_button_title: isSet(object.view_report_button_title)
        ? globalThis.String(object.view_report_button_title)
        : "",
      bottom_button_title: isSet(object.bottom_button_title) ? globalThis.String(object.bottom_button_title) : "",
    };
  },

  toJSON(message: SkinIDScreen): unknown {
    const obj: any = {};
    if (message.scan_report_id !== "") {
      obj.scan_report_id = message.scan_report_id;
    }
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.summary_items?.length) {
      obj.summary_items = message.summary_items.map((e) => SummaryListItem.toJSON(e));
    }
    if (message.visualizations?.length) {
      obj.visualizations = message.visualizations.map((e) => IssueSubtypeVisualization.toJSON(e));
    }
    if (message.view_report_button_title !== "") {
      obj.view_report_button_title = message.view_report_button_title;
    }
    if (message.bottom_button_title !== "") {
      obj.bottom_button_title = message.bottom_button_title;
    }
    return obj;
  },
};

export const XYPoint = {
  fromJSON(object: any): XYPoint {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
    };
  },

  toJSON(message: XYPoint): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = Math.round(message.x);
    }
    if (message.y !== 0) {
      obj.y = Math.round(message.y);
    }
    return obj;
  },
};

export const NormalizedXYPoint = {
  fromJSON(object: any): NormalizedXYPoint {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
    };
  },

  toJSON(message: NormalizedXYPoint): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = message.x;
    }
    if (message.y !== 0) {
      obj.y = message.y;
    }
    return obj;
  },
};

export const NormalizedRect = {
  fromJSON(object: any): NormalizedRect {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
      width: isSet(object.width) ? globalThis.Number(object.width) : 0,
      height: isSet(object.height) ? globalThis.Number(object.height) : 0,
    };
  },

  toJSON(message: NormalizedRect): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = message.x;
    }
    if (message.y !== 0) {
      obj.y = message.y;
    }
    if (message.width !== 0) {
      obj.width = message.width;
    }
    if (message.height !== 0) {
      obj.height = message.height;
    }
    return obj;
  },
};

export const Polygon = {
  fromJSON(object: any): Polygon {
    return {
      points: globalThis.Array.isArray(object?.points) ? object.points.map((e: any) => XYPoint.fromJSON(e)) : [],
      normalized_points: globalThis.Array.isArray(object?.normalized_points)
        ? object.normalized_points.map((e: any) => NormalizedXYPoint.fromJSON(e))
        : [],
      hex_color: isSet(object.hex_color) ? globalThis.String(object.hex_color) : "",
      area: isSet(object.area) ? globalThis.Number(object.area) : 0,
    };
  },

  toJSON(message: Polygon): unknown {
    const obj: any = {};
    if (message.points?.length) {
      obj.points = message.points.map((e) => XYPoint.toJSON(e));
    }
    if (message.normalized_points?.length) {
      obj.normalized_points = message.normalized_points.map((e) => NormalizedXYPoint.toJSON(e));
    }
    if (message.hex_color !== "") {
      obj.hex_color = message.hex_color;
    }
    if (message.area !== 0) {
      obj.area = message.area;
    }
    return obj;
  },
};

export const Polygons = {
  fromJSON(object: any): Polygons {
    return {
      polygons: globalThis.Array.isArray(object?.polygons) ? object.polygons.map((e: any) => Polygon.fromJSON(e)) : [],
    };
  },

  toJSON(message: Polygons): unknown {
    const obj: any = {};
    if (message.polygons?.length) {
      obj.polygons = message.polygons.map((e) => Polygon.toJSON(e));
    }
    return obj;
  },
};

export const BinaryMask = {
  fromJSON(object: any): BinaryMask {
    return {
      mask: isSet(object.mask) ? globalThis.String(object.mask) : "",
      hex_color: isSet(object.hex_color) ? globalThis.String(object.hex_color) : "",
      render_mode: isSet(object.render_mode)
        ? binaryMask_RenderModeFromJSON(object.render_mode)
        : BinaryMask_RenderMode.NONE,
      mask_rect: isSet(object.mask_rect) ? NormalizedRect.fromJSON(object.mask_rect) : undefined,
    };
  },

  toJSON(message: BinaryMask): unknown {
    const obj: any = {};
    if (message.mask !== "") {
      obj.mask = message.mask;
    }
    if (message.hex_color !== "") {
      obj.hex_color = message.hex_color;
    }
    if (message.render_mode !== BinaryMask_RenderMode.NONE) {
      obj.render_mode = binaryMask_RenderModeToJSON(message.render_mode);
    }
    if (message.mask_rect !== undefined) {
      obj.mask_rect = NormalizedRect.toJSON(message.mask_rect);
    }
    return obj;
  },
};

export const BinaryMasks = {
  fromJSON(object: any): BinaryMasks {
    return {
      binary_masks: globalThis.Array.isArray(object?.binary_masks)
        ? object.binary_masks.map((e: any) => BinaryMask.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BinaryMasks): unknown {
    const obj: any = {};
    if (message.binary_masks?.length) {
      obj.binary_masks = message.binary_masks.map((e) => BinaryMask.toJSON(e));
    }
    return obj;
  },
};

export const IssueSubtypeVisualization = {
  fromJSON(object: any): IssueSubtypeVisualization {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      visualization: isSet(object.polygons)
        ? { $case: "polygons", polygons: Polygons.fromJSON(object.polygons) }
        : isSet(object.binary_masks)
        ? { $case: "binary_masks", binary_masks: BinaryMasks.fromJSON(object.binary_masks) }
        : undefined,
    };
  },

  toJSON(message: IssueSubtypeVisualization): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.visualization?.$case === "polygons") {
      obj.polygons = Polygons.toJSON(message.visualization.polygons);
    }
    if (message.visualization?.$case === "binary_masks") {
      obj.binary_masks = BinaryMasks.toJSON(message.visualization.binary_masks);
    }
    return obj;
  },
};

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
