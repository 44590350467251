import React, { FC, ReactNode, useCallback } from "react"
import {
  Box,
  Heading,
  Link,
  Tag,
  Text,
  LinkBox,
  LinkOverlay,
  Flex,
  TagLabel,
} from "@chakra-ui/react"

import {
  SubscriptionOrigin,
  SubscriptionStatusInfo_SubscriptionStatus as SubscriptionStatus,
  SubscriptionStatusInfo,
} from "~/generated/interview_service"
import { NextPageCb } from "~/hooks/useNextQuiz"

import { AppleIcon, ChevronIcon } from "./Icons"
import { formatPrice, formatDate, noop } from "~/utils"
import { VFlex } from "~/components/shared/VFlex"
import { HeaderLogo } from "./HeaderLogo"
import dayjs from "dayjs"

const END_OF_TODAY = dayjs().endOf("day").toDate() // TODO use date in UTC

const Header: FC<{ children: ReactNode }> = ({ children }) => (
  <Box bgColor="white" px={6} pb={6} borderBottomRadius="24px">
    <HeaderLogo mt={4} mb="96px" />
    {children}
  </Box>
)

const ContactItem = () => (
  <Link
    px={6}
    py={6}
    textStyle="Subtitle/Secondary"
    textColor="Base/basePrimary"
    href="mailto:care@lovi.care"
    display="flex"
    gap={4}
    justifyContent="space-between"
    alignItems="center"
  >
    <Box flex={1}>Contact support</Box>
    <ChevronIcon color="Base/baseDisabled" />
  </Link>
)

const SubscriptionsListItemBox: FC<{ children: ReactNode }> = ({ children }) => (
  <LinkBox
    display="flex"
    flexDirection="column"
    gap={1}
    bgColor="white"
    borderRadius="brand24"
    padding={4}
    boxShadow="LoviShort"
    mx={2}
    cursor="pointer"
  >
    {children}
  </LinkBox>
)

const StatusText: FC<{ status: SubscriptionStatus }> = ({ status }) => {
  const color =
    {
      [SubscriptionStatus.ACTIVE]: "Other/Green",
      [SubscriptionStatus.INACTIVE]: "Base/baseDisabled",
      [SubscriptionStatus.UNKNOWN]: "Base/baseDisabled",
      [SubscriptionStatus.UNRECOGNIZED]: "Base/baseDisabled",
    }[status] ?? "Base/baseDisabled"
  const text =
    {
      [SubscriptionStatus.ACTIVE]: "Active",
      [SubscriptionStatus.INACTIVE]: "Cancelled",
      [SubscriptionStatus.UNKNOWN]: "",
      [SubscriptionStatus.UNRECOGNIZED]: "",
    }[status] ?? ""
  return (
    <Text as="span" color={color}>
      {text}
    </Text>
  )
}

const SubscriptionsListItem: FC<{
  title: string
  status: SubscriptionStatus
  description?: string
  children?: ReactNode
  onCancel?: () => void
}> = ({ title, status, description, children, onCancel }) => (
  <SubscriptionsListItemBox>
    {title && (
      <Box textStyle="Subtitle/Secondary">
        <Text as="span" sx={{ textTransform: "capitalize" }}>
          {title}
        </Text>{" "}
        <StatusText status={status} />
      </Box>
    )}
    {description && (
      <Box textStyle="Paragraph/Primary" color="Base/baseSecondary">
        {description}
      </Box>
    )}
    {children}
    {onCancel && (
      <LinkOverlay onClick={onCancel} pt={4} textStyle="Subtitle/Secondary" color="Other/Error">
        Cancel subscription
      </LinkOverlay>
    )}
  </SubscriptionsListItemBox>
)

const AppStoreSubscription = () => (
  <VFlex gap={1} padding={3} bgColor="Base/neutralSecondary" borderRadius="brand16">
    <Flex textStyle="Subtitle/Tertiary" gap={1} justifyContent="space-between" alignItems="center">
      <Box>Where to manage it?</Box>
      <AppleIcon width="11px" height="14px" />
    </Flex>
    <Box textStyle="Paragraph/Secondary">
      Subscriptions made through the&nbsp;AppStore can&nbsp;be managed in&nbsp;the{" "}
      <LinkOverlay href="https://apps.apple.com/account/subscriptions" rel="noopener noreferrer">
        Subscriptions&nbsp;section
      </LinkOverlay>{" "}
      in&nbsp;your Apple&nbsp;ID account.
    </Box>
  </VFlex>
)

/* Maybe move to theme */
const EmailTag: FC<{ children: ReactNode }> = ({ children }) => (
  <Tag
    color="Base/baseSecondary"
    bgColor="Base/neutralSecondary"
    px={2}
    py={1.5}
    borderRadius="brand24"
    w="fit-content"
  >
    <TagLabel textStyle="Header/Quaternary">{children}</TagLabel>
  </Tag>
)

const formatSubscriptionDate = (s: string) => formatDate("FULL_SHORT")(new Date(s))

const generateDescription = ({
  subscription_status,
  next_charge_at,
  subscription_price_per_unit_cents,
  currency_code,
}: SubscriptionStatusInfo) => {
  try {
    if (subscription_status === SubscriptionStatus.ACTIVE) {
      // It will renew on 18 Nov 2022, you will be charged €14.99
      const result: string[] = []
      if (next_charge_at) {
        result.push(`It will renew on ${formatSubscriptionDate(next_charge_at)}`)
      }
      if (currency_code && subscription_price_per_unit_cents) {
        result.push(
          `you will be charged ${formatPrice(
            currency_code,
            subscription_price_per_unit_cents / 100
          )}`
        )
      }
      return result.join(", ")
    }
    if (subscription_status === SubscriptionStatus.INACTIVE) {
      if (new Date(next_charge_at) > END_OF_TODAY) {
        // Active till 21 Jul 2023, no more charges
        return `Active till ${formatSubscriptionDate(next_charge_at)}, no more charges`
      }
      // Expired on 27 Jun 2023
      return `Expired on ${formatSubscriptionDate(next_charge_at)}`
    }
  } catch (e) {
    console.error(e)
  }
  return undefined
}

const isAppleSubscription = (sub: SubscriptionStatusInfo) =>
  sub.origin === SubscriptionOrigin.SUBSCRIPTION_ORIGIN_APPLE_VIA_REVENUE_CAT
const isActive = (sub: SubscriptionStatusInfo) =>
  sub.subscription_status === SubscriptionStatus.ACTIVE

export const SubscriptionStatusPage: FC<{
  email: string
  subscriptionsList: SubscriptionStatusInfo[]
  next: NextPageCb
}> = ({ email, next: onNext = noop, subscriptionsList = [] }) => {
  const onCancel = useCallback(
    (sub: SubscriptionStatusInfo) => {
      const { action_data } = sub
      if (!isAppleSubscription(sub) && isActive(sub) && action_data) {
        return () => onNext([action_data.title], [action_data])
      }
      return undefined
    },
    [onNext]
  )

  return (
    <VFlex h="full" bgColor="Base/neutralSecondary" gap={2}>
      <Header>
        <VFlex gap={2}>
          {email && <EmailTag>{email}</EmailTag>}
          <Heading as="h1" size="Header/Primary">
            Subscriptions
          </Heading>
        </VFlex>
      </Header>
      {subscriptionsList.map((sub, i) => (
        <SubscriptionsListItem
          key={i}
          title={sub.title.replaceAll("_", " ")}
          status={sub.subscription_status}
          description={generateDescription(sub)}
          onCancel={onCancel(sub)}
        >
          {isAppleSubscription(sub) && <AppStoreSubscription />}
        </SubscriptionsListItem>
      ))}
      <VFlex bgColor="white" borderTopRadius="brand24" flexGrow={1}>
        <ContactItem />
      </VFlex>
    </VFlex>
  )
}
