// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: products.proto

/* eslint-disable */
import { ActionOpenMyProducts, ActionOpenProductScanner, ActionSequence, ActionType, TrialState } from "./actions";
import { Request } from "./networking";
import {
  AttributedString,
  Color,
  FilledButton,
  Image,
  ImageContentMode,
  imageContentModeFromJSON,
  imageContentModeToJSON,
  SnackBar,
  Tag,
} from "./presentation";
import { ProductIngredientInfoScreen, ProductIngredientsListInfoScreen } from "./products_ingredients_list";
import { SummaryListItem } from "./skin_id";

export enum ProductQualification {
  /** PRODUCT_QUALIFICATION_NONE - So that every meaningful status is not zero, hence exported. */
  PRODUCT_QUALIFICATION_NONE = "PRODUCT_QUALIFICATION_NONE",
  EXCELLENT = "EXCELLENT",
  GOOD = "GOOD",
  QUESTIONABLE = "QUESTIONABLE",
  BAD = "BAD",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productQualificationFromJSON(object: any): ProductQualification {
  switch (object) {
    case 0:
    case "PRODUCT_QUALIFICATION_NONE":
      return ProductQualification.PRODUCT_QUALIFICATION_NONE;
    case 1:
    case "EXCELLENT":
      return ProductQualification.EXCELLENT;
    case 2:
    case "GOOD":
      return ProductQualification.GOOD;
    case 3:
    case "QUESTIONABLE":
      return ProductQualification.QUESTIONABLE;
    case 4:
    case "BAD":
      return ProductQualification.BAD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductQualification.UNRECOGNIZED;
  }
}

export function productQualificationToJSON(object: ProductQualification): string {
  switch (object) {
    case ProductQualification.PRODUCT_QUALIFICATION_NONE:
      return "PRODUCT_QUALIFICATION_NONE";
    case ProductQualification.EXCELLENT:
      return "EXCELLENT";
    case ProductQualification.GOOD:
      return "GOOD";
    case ProductQualification.QUESTIONABLE:
      return "QUESTIONABLE";
    case ProductQualification.BAD:
      return "BAD";
    case ProductQualification.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ProductKeyFunctionType {
  PRODUCT_KEY_FUNCTION_TYPE_NONE = "PRODUCT_KEY_FUNCTION_TYPE_NONE",
  PRODUCT_KEY_FUNCTION_TYPE_BE_AWARE = "PRODUCT_KEY_FUNCTION_TYPE_BE_AWARE",
  PRODUCT_KEY_FUNCTION_TYPE_GOOD_FOR = "PRODUCT_KEY_FUNCTION_TYPE_GOOD_FOR",
  PRODUCT_KEY_FUNCTION_TYPE_DOES_NOT_AFFECT = "PRODUCT_KEY_FUNCTION_TYPE_DOES_NOT_AFFECT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productKeyFunctionTypeFromJSON(object: any): ProductKeyFunctionType {
  switch (object) {
    case 0:
    case "PRODUCT_KEY_FUNCTION_TYPE_NONE":
      return ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE;
    case 1:
    case "PRODUCT_KEY_FUNCTION_TYPE_BE_AWARE":
      return ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_BE_AWARE;
    case 2:
    case "PRODUCT_KEY_FUNCTION_TYPE_GOOD_FOR":
      return ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_GOOD_FOR;
    case 3:
    case "PRODUCT_KEY_FUNCTION_TYPE_DOES_NOT_AFFECT":
      return ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_DOES_NOT_AFFECT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductKeyFunctionType.UNRECOGNIZED;
  }
}

export function productKeyFunctionTypeToJSON(object: ProductKeyFunctionType): string {
  switch (object) {
    case ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE:
      return "PRODUCT_KEY_FUNCTION_TYPE_NONE";
    case ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_BE_AWARE:
      return "PRODUCT_KEY_FUNCTION_TYPE_BE_AWARE";
    case ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_GOOD_FOR:
      return "PRODUCT_KEY_FUNCTION_TYPE_GOOD_FOR";
    case ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_DOES_NOT_AFFECT:
      return "PRODUCT_KEY_FUNCTION_TYPE_DOES_NOT_AFFECT";
    case ProductKeyFunctionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** / Will be deprecated in the future. Please avoid reusing it */
export interface ProductPoraScore {
  /** @deprecated */
  scale:
    | ProductPoraScore_Scale
    | undefined;
  /** 0 - 100 */
  value: number;
  colors: ProductPoraScore_Colors | undefined;
  title: string;
}

export interface ProductPoraScore_Colors {
  background: Color | undefined;
  foreground: Color | undefined;
}

/** @deprecated */
export interface ProductPoraScore_Scale {
  items: ProductPoraScore_Scale_Item[];
}

/** @deprecated */
export interface ProductPoraScore_Scale_Item {
  /** 0 - 100 */
  from_value: number;
  /** 0 - 100 */
  to_value: number;
}

export interface MyProductsScreenRequest {
  request: Request | undefined;
}

export interface MyProductsScreenResponse {
  /** TODO: Remove this field when the My Products screen is supported as a standalone entity */
  my_products: ActionOpenMyProducts | undefined;
}

export interface OpenProductScannerRequest {
  request: Request | undefined;
}

export interface OpenProductScannerResponse {
  product_scanner: ActionOpenProductScanner | undefined;
}

export interface OpenFaceScannerRequest {
  request: Request | undefined;
}

export interface OpenFaceScannerResponse {
  face_scanner: ActionSequence | undefined;
}

export interface ProductsRecognitionRequest {
  request: Request | undefined;
  image: Image | undefined;
  forced_ingredients: string;
  /** Unique ID of the user photo sent by client */
  user_photo_id: string;
  ocr_result: ProductsRecognitionRequest_OCR | undefined;
  forced_product_text: string;
  search_variants_count: number;
  forced_product_url: string;
  forced_product_brand: string;
  forced_product_name: string;
  forced_product_image: string;
  forced_product_step: string;
  forced_product_description: string;
  forced_product_price: string;
  forced_product_rating: string;
  forced_product_reviews_count: string;
  skip_alternatives: boolean;
  evaluations_request_id: string;
  forced_request_id: string;
}

export interface ProductsRecognitionRequest_OCR {
  lines: ProductsRecognitionRequest_OCR_Line[];
  recognition_duration: number;
  barcode: ProductsRecognitionRequest_OCR_Barcode | undefined;
}

export interface ProductsRecognitionRequest_OCR_Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface ProductsRecognitionRequest_OCR_Line {
  text: string;
  confidence: number;
  bounding_box: ProductsRecognitionRequest_OCR_Rect | undefined;
  cluster_id: number;
}

export interface ProductsRecognitionRequest_OCR_Barcode {
  code: string;
  symbology: string;
  recognition_duration: number;
}

export interface ProductsRecognitionRequests {
  requests: ProductsRecognitionRequest[];
}

export interface ProductRecognitionError {
  error_title: string;
  error_text: string;
  user_action: ProductRecognitionError_UserAction;
  next_step_request: ProductRecognitionErrorNextStepRequest | undefined;
}

export enum ProductRecognitionError_UserAction {
  USER_ACTION_NOTHING = "USER_ACTION_NOTHING",
  USER_ACTION_RETRY = "USER_ACTION_RETRY",
  USER_ACTION_NEW_PHOTO = "USER_ACTION_NEW_PHOTO",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productRecognitionError_UserActionFromJSON(object: any): ProductRecognitionError_UserAction {
  switch (object) {
    case 0:
    case "USER_ACTION_NOTHING":
      return ProductRecognitionError_UserAction.USER_ACTION_NOTHING;
    case 1:
    case "USER_ACTION_RETRY":
      return ProductRecognitionError_UserAction.USER_ACTION_RETRY;
    case 2:
    case "USER_ACTION_NEW_PHOTO":
      return ProductRecognitionError_UserAction.USER_ACTION_NEW_PHOTO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductRecognitionError_UserAction.UNRECOGNIZED;
  }
}

export function productRecognitionError_UserActionToJSON(object: ProductRecognitionError_UserAction): string {
  switch (object) {
    case ProductRecognitionError_UserAction.USER_ACTION_NOTHING:
      return "USER_ACTION_NOTHING";
    case ProductRecognitionError_UserAction.USER_ACTION_RETRY:
      return "USER_ACTION_RETRY";
    case ProductRecognitionError_UserAction.USER_ACTION_NEW_PHOTO:
      return "USER_ACTION_NEW_PHOTO";
    case ProductRecognitionError_UserAction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ProductRecognitionErrorNextStepRequest {
  request: Request | undefined;
  product_scan_id: string;
}

export interface ProductRecognitionErrorNextStepResponse {
  action: ActionType | undefined;
}

export interface ProductRecognitionDetectedObject {
  name: string;
  score: number;
}

export interface ProductsRecognitionResponse {
  response?: { $case: "product_screen"; product_screen: ProductScreen } | {
    $case: "error";
    error: ProductRecognitionError;
  } | undefined;
  detected_objects: ProductRecognitionDetectedObject[];
  search_variants: ProductsRecognitionResponse_SearchVariants | undefined;
  request_id: string;
  trial_state: TrialState | undefined;
}

export interface ProductsRecognitionResponse_SearchVariants {
  navigation_title: string;
  items_title: string;
  items: ProductCard[];
  top_button: ProductsRecognitionResponse_SearchVariants_Button | undefined;
  paywall: ProductsRecognitionResponse_SearchVariants_Paywall | undefined;
}

export interface ProductsRecognitionResponse_SearchVariants_Button {
  title: string;
  action: ActionType | undefined;
}

export interface ProductsRecognitionResponse_SearchVariants_Paywall {
  action: ActionType | undefined;
  is_strict: boolean;
}

export interface ProgramSummaryContent {
  title: string;
  subtitle: string;
  scan_id: string;
  list: SummaryListItem[];
  bubble_text: AttributedString | undefined;
  is_expanded: boolean;
  footer_button: ProgramSummaryContent_FooterButton | undefined;
}

export interface ProgramSummaryContent_FooterButton {
  icon: AttributedString | undefined;
  title: AttributedString | undefined;
  action: ActionType | undefined;
}

export interface ProductCard {
  qualification: ProductQualification;
  qualification_title: string;
  title: string;
  brand: string;
  image: string;
  rating: string;
  /**
   * use tags_v2 instead
   *
   * @deprecated
   */
  tags: string[];
  origin_link: string;
  product_id: string;
  product_screen: ProductScreen | undefined;
  pora_score: ProductPoraScore | undefined;
  tags_v2: Tag[];
}

export interface ProductsSection {
  title: string;
  qualification_title: string;
  step_title: string;
  products: ProductCard[];
  info_bubble: ProductsSection_Bubble | undefined;
  footer_button_title: string;
}

export interface ProductsSection_Bubble {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  buttons: ProductsSection_Bubble_Button[];
}

export interface ProductsSection_Bubble_Button {
  title: AttributedString | undefined;
  action: ActionType | undefined;
}

export interface ProductsSectionsGroup {
  title: string;
  url_title: string;
  url: string;
  /**
   * use sections_v2 instead
   *
   * @deprecated
   */
  sections: ProductsSection[];
  sections_v2: ProductsSectionsGroup_Section[];
}

export interface ProductsSectionsGroup_Section {
  section?:
    | { $case: "products"; products: ProductsSection }
    | { $case: "alternatives"; alternatives: ProductsSectionsGroup_Alternatives }
    | { $case: "info"; info: ProductsSectionsGroup_Info }
    | undefined;
}

export interface ProductsSectionsGroup_Alternatives {
  id: string;
  title: AttributedString | undefined;
  products: ProductCard[];
  should_show_section: boolean;
}

export interface ProductsSectionsGroup_Info {
  title: AttributedString | undefined;
  bubble_text: AttributedString | undefined;
}

export interface ProductsListScreen {
  summary:
    | ProgramSummaryContent
    | undefined;
  /**
   * use Segment's section_groups instead
   *
   * @deprecated
   */
  section_groups: ProductsSectionsGroup[];
  title: string;
  segments: ProductsListScreen_Segment[];
  selected_segment_index: number;
  bottom_button_title: string;
  is_close_button_available: boolean;
}

export interface ProductsListScreen_Segment {
  title: AttributedString | undefined;
  image: string;
  section_groups: ProductsSectionsGroup[];
  segment_header_image: string;
  segment_header_title: AttributedString | undefined;
}

export interface ProductsRecommendationsScreen {
  section_groups: ProductsSectionsGroup[];
}

export interface ProductKeyFunctionInfoScreen {
  function_type: ProductKeyFunctionType;
  title: AttributedString | undefined;
  subtitle: string;
  description: string;
}

export interface ProductInfoScreen {
  title: AttributedString | undefined;
  subtitle: AttributedString | undefined;
  description: AttributedString | undefined;
}

export interface ProductStepInfoScreen {
  nav_bar: ProductStepInfoScreen_NavBar | undefined;
  header: ProductStepInfoScreen_Header | undefined;
  shortcuts: ProductStepInfoScreen_Shortcuts | undefined;
  sections: ProductStepInfoScreen_Section[];
}

export interface ProductStepInfoScreen_NavBar {
  title: string;
  image: string;
}

export interface ProductStepInfoScreen_Header {
  title: string;
  scale: ProductStepInfoScreen_Header_Scale | undefined;
}

export interface ProductStepInfoScreen_Header_Scale {
  steps_count: number;
  filled_steps_count: number;
  value: string;
}

export interface ProductStepInfoScreen_Shortcuts {
  shortcuts: ProductStepInfoScreen_Shortcuts_Shortcut[];
}

export interface ProductStepInfoScreen_Shortcuts_Shortcut {
  title: string;
  target_section_index: number;
}

export interface ProductStepInfoScreen_Section {
  section?: { $case: "ingredients"; ingredients: ProductScreen_Section_Ingredients } | {
    $case: "info";
    info: ProductStepInfoScreen_Section_Info;
  } | undefined;
}

export interface ProductStepInfoScreen_Section_Info {
  title: string;
  info: AttributedString | undefined;
  line_limit: number;
  lazy_request_data: LazyProductStepInfoSectionRequestData | undefined;
}

export interface ProductAlternativesScreen {
  title: string;
  products: ProductAlternativesScreen_Product[];
  lazy_request: ProductScreenAlternativesRequest | undefined;
}

export interface ProductAlternativesScreen_Product {
  brand: string;
  title: string;
  price: string;
  image: string;
  tags: Tag[];
  product_screen: ProductScreen | undefined;
  pora_score: ProductPoraScore | undefined;
  product_id: string;
}

export interface ProductStoresInfoScreen {
  title: string;
  stores: ProductStoresInfoScreen_Store[];
}

export interface ProductStoresInfoScreen_Store {
  image_url: string;
  name: string;
  price: string;
  web_page_url: string;
  info: ProductStoresInfoScreen_Store_Info | undefined;
}

export interface ProductStoresInfoScreen_Store_Info {
  info?:
    | { $case: "rating"; rating: ProductStoresInfoScreen_Store_Rating }
    | { $case: "text"; text: string }
    | undefined;
}

export interface ProductStoresInfoScreen_Store_Rating {
  value: string;
  subtitle: string;
  fade: boolean;
}

/** Use instead of ProductPoraScoreInfoScreen */
export interface ProductFitScoreInfoScreen {
  nav_bar: ProductFitScoreInfoScreen_NavBar | undefined;
  fit_score: ProductPoraScore | undefined;
  title: AttributedString | undefined;
  tags: AttributedString[];
  sections: ProductFitScoreInfoScreen_Section[];
  score: ProductScreen_Section_TilesHeader_ProductScore | undefined;
}

export interface ProductFitScoreInfoScreen_NavBar {
  title: string;
  image: string;
}

export interface ProductFitScoreInfoScreen_Section {
  section?:
    | { $case: "what_is_score"; what_is_score: ProductFitScoreInfoScreen_Section_WhatIsScore }
    | { $case: "approved_by"; approved_by: ProductFitScoreInfoScreen_Section_ApprovedBy }
    | { $case: "general_info"; general_info: ProductFitScoreInfoScreen_Section_GeneralInfo }
    | { $case: "sources"; sources: ProductFitScoreInfoScreen_Section_Sources }
    | { $case: "example"; example: ProductFitScoreInfoScreen_Section_Example }
    | undefined;
}

export interface ProductFitScoreInfoScreen_Section_WhatIsScore {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  evaluations: ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation[];
}

export interface ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  icon: string;
}

export interface ProductFitScoreInfoScreen_Section_ApprovedBy {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  avatar_image: string;
  signature_image: string;
}

export interface ProductFitScoreInfoScreen_Section_GeneralInfo {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
}

export interface ProductFitScoreInfoScreen_Section_Sources {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  sources: ProductFitScoreInfoScreen_Section_Sources_Source[];
}

export interface ProductFitScoreInfoScreen_Section_Sources_Source {
  image: string;
  title: AttributedString | undefined;
  url: string;
}

export interface ProductFitScoreInfoScreen_Section_Example {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  product_card: ProductFitScoreInfoScreen_Section_Example_ProductCard | undefined;
  score_scale: ProductFitScoreInfoScreen_Section_Example_ScoreScale | undefined;
}

export interface ProductFitScoreInfoScreen_Section_Example_ProductCard {
  title: AttributedString | undefined;
  fit_score: ProductPoraScore | undefined;
  image: string;
  description_title: AttributedString | undefined;
  description: AttributedString | undefined;
}

export interface ProductFitScoreInfoScreen_Section_Example_ScoreScale {
  segments: ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment[];
}

export interface ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment {
  color: Color | undefined;
  label: AttributedString | undefined;
  value: number;
}

/**
 * This screen is deprecated. Use ProductFitScoreInfoScreen instead.
 *
 * @deprecated
 */
export interface ProductPoraScoreInfoScreen {
  nav_bar: ProductPoraScoreInfoScreen_NavBar | undefined;
  pora_score: ProductPoraScore | undefined;
  description_title: string;
  description: ProductPoraScoreInfoScreen_Description | undefined;
}

export interface ProductPoraScoreInfoScreen_NavBar {
  title: string;
  image: string;
}

export interface ProductPoraScoreInfoScreen_Description {
  items: ProductPoraScoreInfoScreen_Description_Item[];
}

export interface ProductPoraScoreInfoScreen_Description_Item {
  item?: { $case: "string"; string: AttributedString } | {
    $case: "scale";
    scale: ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale;
  } | undefined;
}

export interface ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale {
  segments: ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment[];
}

export interface ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment {
  color: Color | undefined;
  label: string;
}

export interface ActionOpenProductScreenPayload {
  product_id: string;
  product_screen: ProductScreen | undefined;
}

export interface ProductScreenRequest {
  request: Request | undefined;
  product_id: string;
}

export interface LazyProductSectionRequestData {
  data?:
    | { $case: "info"; info: LazyProductSectionRequestData_InfoRequestData }
    | { $case: "key_functions"; key_functions: LazyProductSectionRequestData_KeyFunctionsRequestData }
    | { $case: "header_data"; header_data: LazyProductSectionRequestData_HeaderDataRequest }
    | { $case: "alternatives_data"; alternatives_data: LazyProductSectionRequestData_AlternativesDataRequest }
    | undefined;
}

export interface LazyProductSectionRequestData_InfoRequestData {
  section_title: string;
  prompt: string;
}

export interface LazyProductSectionRequestData_KeyFunctionsRequestData {
  product_id: string;
}

export interface LazyProductSectionRequestData_HeaderDataRequest {
  product_id: string;
}

/** Use ProductAlternativesScreen's lazy_request instead */
export interface LazyProductSectionRequestData_AlternativesDataRequest {
  product_id: string;
}

export interface LazyProductStepInfoSectionRequestData {
  data?: { $case: "info"; info: LazyProductStepInfoSectionRequestData_InfoRequestData } | undefined;
}

export interface LazyProductStepInfoSectionRequestData_InfoRequestData {
  section_title: string;
  prompt: string;
}

export interface LazyProductSectionRequest {
  request: Request | undefined;
  data: LazyProductSectionRequestData | undefined;
}

export interface LazyProductStepInfoSectionRequest {
  request: Request | undefined;
  data: LazyProductStepInfoSectionRequestData | undefined;
}

export interface LazyProductSectionResponse {
  response?:
    | { $case: "section"; section: ProductScreen_Section }
    | { $case: "error_message"; error_message: string }
    | undefined;
}

export interface LazyProductStepInfoSectionResponse {
  response?: { $case: "section"; section: ProductStepInfoScreen_Section } | {
    $case: "error_message";
    error_message: string;
  } | undefined;
}

export interface ProductScreenHeaderKeyFunctionsRequest {
  request: Request | undefined;
  product_id: string;
}

export interface ProductScreenHeaderPoraScoreRequest {
  request: Request | undefined;
  product_id: string;
}

export interface ProductScreenHeaderQuickSummaryRequest {
  request: Request | undefined;
  url: string;
}

export interface ProductScreenAlternativesRequest {
  request: Request | undefined;
  url: string;
}

export interface DeleteScanRequest {
  request: Request | undefined;
  id: string;
  category: string;
}

export interface DeleteScanResponse {
  deleted_id: string;
}

export interface ProductsScanHistoryRequest {
  request: Request | undefined;
  start_index: number;
  category: string;
}

export interface ProductsScanHistoryResponse {
  /**
   * use sections instead
   *
   * @deprecated
   */
  history_items: ProductsScanHistoryItem[];
  /**
   * The backend is now serving all the content
   *
   * @deprecated
   */
  next_page_start_index: number;
  /**
   * The backend is now serving all the content
   *
   * @deprecated
   */
  has_more: boolean;
  /**
   * use items_total_count in Section
   *
   * @deprecated
   */
  items_total_count: string;
  sections: ProductsScanHistoryResponse_Section[];
}

export interface ProductsScanHistoryResponse_Section {
  section_title: string;
  items: ProductsScanHistoryItem[];
}

export interface ProductsScanHistoryItem {
  id: string;
  image: string;
  qualification: ProductQualification;
  qualification_title: string;
  title: string;
  subtitle: string;
  /** @deprecated */
  time_ago: string;
  action: ActionType | undefined;
  is_disabled: boolean;
  footer_title: string;
  pora_score: ProductPoraScore | undefined;
  image_content_mode: ImageContentMode;
  tags: Tag[];
}

export interface ProductScreen {
  sections: ProductScreen_Section[];
  /** id of scanned product. Use it after scanning to fetch product screen */
  id: string;
  buttons: ProductScreen_Button[];
  is_go_home_available: boolean;
  go_home_post_action:
    | ActionType
    | undefined;
  /** id of product. Use it anywhere to fetch product screen */
  product_id: string;
  analytics_data: ProductScreen_AnalyticsData | undefined;
  is_closable_by_swipe: boolean;
  snack_bar: SnackBar | undefined;
  assistant_button: ImageButton | undefined;
  alternativesScreen:
    | ProductAlternativesScreen
    | undefined;
  /** @deprecated */
  report_quiz_id: string;
  report_action: ActionType | undefined;
  generation_timestamp: string;
}

export interface ProductScreen_Section {
  section?:
    | { $case: "header"; header: ProductScreen_Section_Header }
    | { $case: "ratings_and_prices"; ratings_and_prices: ProductScreen_Section_RatingsAndPrices }
    | { $case: "info"; info: ProductScreen_Section_Info }
    | { $case: "list_with_icons"; list_with_icons: ProductScreen_Section_ListWithIcons }
    | { $case: "skin_id_match"; skin_id_match: ProductScreen_Section_SkinIDMatch }
    | { $case: "video_tutorial"; video_tutorial: ProductScreen_Section_VideoTutorial }
    | { $case: "alternatives"; alternatives: ProductScreen_Section_Alternatives }
    | { $case: "tiles_header"; tiles_header: ProductScreen_Section_TilesHeader }
    | { $case: "key_functions"; key_functions: ProductScreen_Section_KeyFunctions }
    | { $case: "ingredients"; ingredients: ProductScreen_Section_Ingredients }
    | { $case: "DEPRECATED_header"; DEPRECATED_header: ProductScreen_Section_Header }
    | { $case: "people_say"; people_say: ProductScreen_Section_PeopleSay }
    | undefined;
}

export interface ProductScreen_Section_Header {
  image_urls: string[];
  brand: string;
  title: string;
  subtitle: ProductScreen_Section_Header_Subtitle | undefined;
}

export interface ProductScreen_Section_Header_Subtitle {
  items: ProductScreen_Section_Header_Subtitle_Item[];
}

export interface ProductScreen_Section_Header_Subtitle_Item {
  item?: { $case: "text"; text: string } | { $case: "rating"; rating: string } | undefined;
}

export interface ProductScreen_Section_SkinIDMatch {
  items: ProductScreen_Section_SkinIDMatch_Item[];
  header: ProductScreen_Section_SkinIDMatch_Header | undefined;
}

export interface ProductScreen_Section_SkinIDMatch_Header {
  qualification: ProductQualification;
  title: string;
  message: string;
}

export interface ProductScreen_Section_SkinIDMatch_Item {
  qualification: ProductQualification;
  title: string;
  content: ProductScreen_Section_SkinIDMatch_Item_Content | undefined;
}

export interface ProductScreen_Section_SkinIDMatch_Item_Content {
  content?: { $case: "list"; list: ProductScreen_Section_SkinIDMatch_Item_Content_List } | {
    $case: "banners";
    banners: ProductScreen_Section_SkinIDMatch_Item_Content_Banners;
  } | undefined;
}

export interface ProductScreen_Section_SkinIDMatch_Item_Content_List {
  items: ProductScreen_Section_SkinIDMatch_Item_Content_List_Item[];
}

export interface ProductScreen_Section_SkinIDMatch_Item_Content_List_Item {
  icon: string;
  text: string;
}

export interface ProductScreen_Section_SkinIDMatch_Item_Content_Banners {
  banners: ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner[];
}

export interface ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner {
  title: string;
  items: ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item[];
}

export interface ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item {
  icon: string;
  text: string;
}

export interface ProductScreen_Section_RatingsAndPrices {
  title: string;
  average_rating: ProductScreen_Section_RatingsAndPrices_AverageRating | undefined;
  stores: ProductScreen_Section_RatingsAndPrices_Store[];
}

export interface ProductScreen_Section_RatingsAndPrices_AverageRating {
  value: string;
  subtitle: string;
}

export interface ProductScreen_Section_RatingsAndPrices_Store {
  image_url: string;
  name: string;
  price: string;
  DEPRECATED_rating: string;
  DEPRECATED_rating_subtitle: string;
  web_page_url: string;
  qualification?: { $case: "rating"; rating: ProductScreen_Section_RatingsAndPrices_Store_Rating } | {
    $case: "text";
    text: string;
  } | undefined;
}

export interface ProductScreen_Section_RatingsAndPrices_Store_Rating {
  value: string;
  subtitle: string;
  fade: boolean;
}

export interface ProductScreen_Section_Info {
  title: string;
  DEPRECATED_info: string;
  attributed_info: AttributedString | undefined;
  lazy_request_data: LazyProductSectionRequestData | undefined;
  button: ProductScreen_Section_Info_Button | undefined;
}

export interface ProductScreen_Section_Info_Button {
  title: string;
  info_screen: ProductInfoScreen | undefined;
}

export interface ProductScreen_Section_ListWithIcons {
  title: string;
  items: ProductScreen_Section_ListWithIcons_Item[];
}

export interface ProductScreen_Section_ListWithIcons_Item {
  icon: string;
  text: string;
}

export interface ProductScreen_Section_VideoTutorial {
  section_title: string;
  tutorial_title: string;
  image_url: string;
  tags: Tag[];
  action: ActionType | undefined;
}

export interface ProductScreen_Section_PeopleSay {
  section_title: string;
  rating: AttributedString | undefined;
  rating_description: AttributedString | undefined;
  description_title: AttributedString | undefined;
  good_reviews: ProductScreen_Section_PeopleSay_Review | undefined;
  bad_reviews: ProductScreen_Section_PeopleSay_Review | undefined;
}

export interface ProductScreen_Section_PeopleSay_Review {
  title: AttributedString | undefined;
  reviews: AttributedString[];
}

/**
 * Use ProductAlternativesScreen instead
 *
 * @deprecated
 */
export interface ProductScreen_Section_Alternatives {
  title: string;
  cards: ProductScreen_Section_Alternatives_Card[];
  lazy_request_data: LazyProductSectionRequestData | undefined;
  subtitle: string;
}

export interface ProductScreen_Section_Alternatives_Card {
  brand: string;
  title: string;
  price: string;
  image: string;
  tags: Tag[];
  product_screen: ProductScreen | undefined;
  pora_score: ProductPoraScore | undefined;
  save_button: ImageButton | undefined;
  should_show_save_loading: boolean;
  should_disable_save_button: boolean;
  product_id: string;
}

export interface ProductScreen_Section_TilesHeader {
  images:
    | ProductScreen_Section_TilesHeader_Images
    | undefined;
  /** Optional. If absent, make a dedicated request to fetch them */
  key_functions: ProductScreen_Section_TilesHeader_KeyFunctions | undefined;
  title:
    | ProductScreen_Section_TilesHeader_Title
    | undefined;
  /** @deprecated */
  shortcuts: ProductScreen_Section_TilesHeader_Shortcut[];
  /**
   * Deprecated, use `grid` instead
   *
   * @deprecated
   */
  shortcut_rows: ProductScreen_Section_TilesHeader_Shortcuts | undefined;
  product_id: string;
  pora_score: ProductScreen_Section_TilesHeader_Score | undefined;
  text_review: ProductScreen_Section_TilesHeader_TextReview | undefined;
  assistant_summary: ProductScreen_Section_TilesHeader_AssistantSummary | undefined;
  grid: ProductScreen_Section_TilesHeader_Grid | undefined;
  claim: ProductScreen_Section_TilesHeader_ClaimBlock | undefined;
}

export interface ProductScreen_Section_TilesHeader_ClaimBlock {
  title: AttributedString | undefined;
  tags: ProductScreen_Section_TilesHeader_ClaimBlock_Tag[];
}

export interface ProductScreen_Section_TilesHeader_ClaimBlock_Tag {
  image: string;
  title: AttributedString | undefined;
  should_decorate_with_leaves: boolean;
  /** used to colorize `image` */
  accent_color: string;
  details: ProductScreen_Section_TilesHeader_ClaimBlock_Tag_Details | undefined;
}

export interface ProductScreen_Section_TilesHeader_ClaimBlock_Tag_Details {
  image: string;
  /** used to colorize `image` */
  accent_color: string;
  title: string;
  section_title: string;
  section_description: string;
}

export interface ProductScreen_Section_TilesHeader_TextReview {
  title: string;
  image: string;
  text: string;
  action: ActionType | undefined;
  lazy_request: ProductScreenHeaderQuickSummaryRequest | undefined;
}

export interface ProductScreen_Section_TilesHeader_Score {
  /**
   * Deprecated, use `ProductPoraScore` instead
   *
   * @deprecated
   */
  DEPRECATED_score:
    | ProductPoraScore
    | undefined;
  /**
   * Deprecated, use `ProductFitScoreInfoScreen` instead
   *
   * @deprecated
   */
  info_screen: ProductPoraScoreInfoScreen | undefined;
  should_load: boolean;
  score_info_screen: ProductFitScoreInfoScreen | undefined;
  score: ProductScreen_Section_TilesHeader_ProductScore | undefined;
}

export interface ProductScreen_Section_TilesHeader_ProductScore {
  fit_for_you_button:
    | ProductScreen_Section_TilesHeader_ProductScore_FitForYouScore
    | undefined;
  /** optional */
  main_tags:
    | ProductScreen_Section_TilesHeader_ProductScore_MainTags
    | undefined;
  /** optional */
  sub_tags:
    | ProductScreen_Section_TilesHeader_ProductScore_SubTags
    | undefined;
  /** optional */
  background_color: string;
}

export interface ProductScreen_Section_TilesHeader_ProductScore_FitForYouScore {
  title: AttributedString | undefined;
  foreground_color: string;
  background_color: string;
}

export interface ProductScreen_Section_TilesHeader_ProductScore_MainTags {
  is_scrollable: boolean;
  tags: ProductScreen_Section_TilesHeader_ProductScore_MainTags_Tag[];
}

export interface ProductScreen_Section_TilesHeader_ProductScore_MainTags_Tag {
  image: string;
  title: string;
  subtitle: string;
  /** used to colorize `image` */
  accent_color: string;
}

export interface ProductScreen_Section_TilesHeader_ProductScore_SubTags {
  is_scrollable: boolean;
  tags: ProductScreen_Section_TilesHeader_ProductScore_SubTags_Tag[];
}

export interface ProductScreen_Section_TilesHeader_ProductScore_SubTags_Tag {
  image: string;
  title: string;
  /** used to colorize `image` */
  accent_color: string;
  /** optional */
  background_color: string;
  title_color: string;
}

export interface ProductScreen_Section_TilesHeader_Images {
  product_image: string;
  /**
   * Unique ID of the user photo sent by client
   * Absent if the product was not scanned
   */
  user_photo_id: string;
}

export interface ProductScreen_Section_TilesHeader_KeyFunctions {
  /** @deprecated */
  be_aware_tags: string[];
  /** @deprecated */
  good_for_tags: string[];
  attributed_be_aware_tags: AttributedString[];
  attributed_good_for_tags: AttributedString[];
}

export interface ProductScreen_Section_TilesHeader_Title {
  product_type: AttributedString | undefined;
  text: AttributedString | undefined;
}

export interface ProductScreen_Section_TilesHeader_Grid {
  items: ProductScreen_Section_TilesHeader_Grid_Item[];
}

export interface ProductScreen_Section_TilesHeader_Grid_Item {
  item?: { $case: "shortcuts_row"; shortcuts_row: ProductScreen_Section_TilesHeader_Shortcuts_Row } | {
    $case: "assistant_summary";
    assistant_summary: ProductScreen_Section_TilesHeader_AssistantSummary;
  } | undefined;
}

export interface ProductScreen_Section_TilesHeader_Shortcuts {
  rows: ProductScreen_Section_TilesHeader_Shortcuts_Row[];
}

export interface ProductScreen_Section_TilesHeader_Shortcuts_Row {
  shortcuts: ProductScreen_Section_TilesHeader_Shortcut[];
  style: ProductScreen_Section_TilesHeader_Shortcuts_Row_Style;
}

export enum ProductScreen_Section_TilesHeader_Shortcuts_Row_Style {
  STYLE_NONE = "STYLE_NONE",
  STYLE_MEDIUM = "STYLE_MEDIUM",
  STYLE_LARGE = "STYLE_LARGE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productScreen_Section_TilesHeader_Shortcuts_Row_StyleFromJSON(
  object: any,
): ProductScreen_Section_TilesHeader_Shortcuts_Row_Style {
  switch (object) {
    case 0:
    case "STYLE_NONE":
      return ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.STYLE_NONE;
    case 1:
    case "STYLE_MEDIUM":
      return ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.STYLE_MEDIUM;
    case 2:
    case "STYLE_LARGE":
      return ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.STYLE_LARGE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.UNRECOGNIZED;
  }
}

export function productScreen_Section_TilesHeader_Shortcuts_Row_StyleToJSON(
  object: ProductScreen_Section_TilesHeader_Shortcuts_Row_Style,
): string {
  switch (object) {
    case ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.STYLE_NONE:
      return "STYLE_NONE";
    case ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.STYLE_MEDIUM:
      return "STYLE_MEDIUM";
    case ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.STYLE_LARGE:
      return "STYLE_LARGE";
    case ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ProductScreen_Section_TilesHeader_Shortcut {
  shortcut?: { $case: "empty"; empty: ProductScreen_Section_TilesHeader_Shortcut_Empty } | {
    $case: "content";
    content: ProductScreen_Section_TilesHeader_Shortcut_Content;
  } | undefined;
}

export interface ProductScreen_Section_TilesHeader_Shortcut_Empty {
  message: string;
}

export interface ProductScreen_Section_TilesHeader_Shortcut_Content {
  content?:
    | { $case: "rating"; rating: ProductScreen_Section_TilesHeader_Shortcut_Content_Rating }
    | { $case: "tutorial"; tutorial: ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial }
    | { $case: "prices"; prices: ProductScreen_Section_TilesHeader_Shortcut_Content_Prices }
    | { $case: "alternatives"; alternatives: ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives }
    | { $case: "ingredients"; ingredients: ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients }
    | { $case: "reviewSummary"; reviewSummary: ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary }
    | undefined;
}

export interface ProductScreen_Section_TilesHeader_Shortcut_Content_Rating {
  value: string;
  accented: boolean;
  subtitle: string;
  info_screen: ProductStoresInfoScreen | undefined;
}

export interface ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial {
  image: string;
  title: string;
  tag: Tag | undefined;
  video_title: string;
  action: ActionType | undefined;
}

export interface ProductScreen_Section_TilesHeader_Shortcut_Content_Prices {
  title: string;
  subtitle: string;
  tag: AttributedString | undefined;
  info_screen: ProductStoresInfoScreen | undefined;
}

export interface ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives {
  preview_images: string[];
}

export interface ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients {
  title:
    | AttributedString
    | undefined;
  /** @deprecated */
  subtitle: string;
  items: ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item[];
  /** @deprecated */
  ingredients_list: ProductScreen_Section_Ingredients | undefined;
  ingredients_list_info_screen: ProductIngredientsListInfoScreen | undefined;
}

export interface ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item {
  title: AttributedString | undefined;
  risk_color: Color | undefined;
}

export interface ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary {
  image: string;
  title: AttributedString | undefined;
  subtitle: AttributedString | undefined;
  action: ActionType | undefined;
}

export interface ProductScreen_Section_TilesHeader_AssistantSummary {
  title: AttributedString | undefined;
  message: AttributedString | undefined;
  buttons: ProductScreen_Section_TilesHeader_AssistantSummary_Button[];
  /** Return 0 if no limit */
  default_buttons_max_count: number;
  more_button_title: AttributedString | undefined;
}

export interface ProductScreen_Section_TilesHeader_AssistantSummary_Button {
  title: AttributedString | undefined;
  is_primary: boolean;
  action: ActionType | undefined;
}

export interface ProductScreen_Section_KeyFunctions {
  title: string;
  subtitle: string;
  sections: ProductScreen_Section_KeyFunctions_Section[];
  lazy_request_data: LazyProductSectionRequestData | undefined;
}

export interface ProductScreen_Section_KeyFunctions_Section {
  /** @deprecated */
  title: string;
  /** @deprecated */
  functions_type: ProductKeyFunctionType;
  /** @deprecated */
  functions: ProductScreen_Section_KeyFunctions_Function[];
  is_always_visible: boolean;
  content?: { $case: "tags"; tags: ProductScreen_Section_KeyFunctions_Section_Tags } | {
    $case: "scales";
    scales: ProductScreen_Section_KeyFunctions_Section_Scales;
  } | undefined;
}

export interface ProductScreen_Section_KeyFunctions_Section_Tags {
  title: string;
  functions_type: ProductKeyFunctionType;
  functions: ProductScreen_Section_KeyFunctions_Function[];
}

export interface ProductScreen_Section_KeyFunctions_Section_Scales {
  scales: ProductScreen_Section_KeyFunctions_Section_Scales_Scale[];
  limit: number;
}

export interface ProductScreen_Section_KeyFunctions_Section_Scales_Scale {
  label: string;
  value: string;
  steps_count: number;
  filled_steps_count: number;
  info_screen: ProductStepInfoScreen | undefined;
}

export interface ProductScreen_Section_KeyFunctions_Function {
  /** @deprecated */
  title: string;
  info_screen: ProductKeyFunctionInfoScreen | undefined;
  attributed_title: AttributedString | undefined;
}

export interface ProductScreen_Section_Ingredients {
  title: string;
  ingredients: ProductScreen_Section_Ingredients_Ingredient[];
  is_always_visible: boolean;
  ingredients_count_to_show: number;
}

export interface ProductScreen_Section_Ingredients_Ingredient {
  title: string;
  info_screen: ProductIngredientInfoScreen | undefined;
  risk_title: string;
  risk_color: Color | undefined;
}

export interface ProductScreen_Button {
  /** @deprecated */
  title: string;
  /** @deprecated */
  deprecated_action:
    | ActionType
    | undefined;
  /** @deprecated */
  is_active: boolean;
  /** @deprecated */
  style: ProductScreen_Button_Style;
  action: ProductScreen_Button_Action | undefined;
  filled_button: FilledButton | undefined;
  analytics_params: { [key: string]: string };
}

export enum ProductScreen_Button_Style {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productScreen_Button_StyleFromJSON(object: any): ProductScreen_Button_Style {
  switch (object) {
    case 0:
    case "PRIMARY":
      return ProductScreen_Button_Style.PRIMARY;
    case 1:
    case "SECONDARY":
      return ProductScreen_Button_Style.SECONDARY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductScreen_Button_Style.UNRECOGNIZED;
  }
}

export function productScreen_Button_StyleToJSON(object: ProductScreen_Button_Style): string {
  switch (object) {
    case ProductScreen_Button_Style.PRIMARY:
      return "PRIMARY";
    case ProductScreen_Button_Style.SECONDARY:
      return "SECONDARY";
    case ProductScreen_Button_Style.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ProductScreen_Button_Action {
  action?: { $case: "close"; close: ProductScreen_Button_Action_Close } | {
    $case: "dynamic";
    dynamic: ProductScreen_Button_Action_Dynamic;
  } | undefined;
}

export enum ProductScreen_Button_Action_TargetScreen {
  TARGET_SCREEN_NONE = "TARGET_SCREEN_NONE",
  TARGET_SCREEN_HOME = "TARGET_SCREEN_HOME",
  /** @deprecated */
  TARGET_SCREEN_PRODUCTS = "TARGET_SCREEN_PRODUCTS",
  TARGET_SCREEN_ANY = "TARGET_SCREEN_ANY",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productScreen_Button_Action_TargetScreenFromJSON(
  object: any,
): ProductScreen_Button_Action_TargetScreen {
  switch (object) {
    case 0:
    case "TARGET_SCREEN_NONE":
      return ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_NONE;
    case 1:
    case "TARGET_SCREEN_HOME":
      return ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_HOME;
    case 2:
    case "TARGET_SCREEN_PRODUCTS":
      return ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_PRODUCTS;
    case 3:
    case "TARGET_SCREEN_ANY":
      return ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_ANY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductScreen_Button_Action_TargetScreen.UNRECOGNIZED;
  }
}

export function productScreen_Button_Action_TargetScreenToJSON(
  object: ProductScreen_Button_Action_TargetScreen,
): string {
  switch (object) {
    case ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_NONE:
      return "TARGET_SCREEN_NONE";
    case ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_HOME:
      return "TARGET_SCREEN_HOME";
    case ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_PRODUCTS:
      return "TARGET_SCREEN_PRODUCTS";
    case ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_ANY:
      return "TARGET_SCREEN_ANY";
    case ProductScreen_Button_Action_TargetScreen.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ProductScreen_Button_Action_Close {
}

export interface ProductScreen_Button_Action_Dynamic {
  action: ActionType | undefined;
  target_screen: ProductScreen_Button_Action_TargetScreen;
}

export interface ProductScreen_Button_AnalyticsParamsEntry {
  key: string;
  value: string;
}

export interface ProductScreen_AnalyticsData {
  name: string;
  brand: string;
  expert_analysis_used: boolean;
  extra_params: { [key: string]: string };
}

export interface ProductScreen_AnalyticsData_ExtraParamsEntry {
  key: string;
  value: string;
}

export interface ProductScreens {
  product_screens: ProductScreen[];
}

export interface ProductsSearchScreen {
  promo: ProductsSearchScreen_PromoText | undefined;
  productCount: number;
  evolutionActions: ProductsSearchScreen_Action[];
  video: string;
  buttonText: string;
}

export interface ProductsSearchScreen_PromoText {
  header: AttributedString | undefined;
  description: AttributedString | undefined;
}

export interface ProductsSearchScreen_Particles {
  density: number;
  scale: number;
}

export interface ProductsSearchScreen_ProductCounter {
  count: number;
  duration: number;
}

export interface ProductsSearchScreen_Action {
  action?:
    | { $case: "born"; born: ProductsSearchScreen_Action_Born }
    | { $case: "blast"; blast: ProductsSearchScreen_Action_Blast }
    | { $case: "finalMove"; finalMove: ProductsSearchScreen_Action_FinalMove }
    | undefined;
}

export interface ProductsSearchScreen_Action_Born {
  particles: ProductsSearchScreen_Particles | undefined;
  delay: number;
  sleep: number;
}

export interface ProductsSearchScreen_Action_Blast {
  promo: ProductsSearchScreen_PromoText | undefined;
  particles: ProductsSearchScreen_Particles | undefined;
  counter: ProductsSearchScreen_ProductCounter | undefined;
  waveLifetime: number;
  sleep: number;
}

export interface ProductsSearchScreen_Action_FinalMove {
  promo: ProductsSearchScreen_PromoText | undefined;
  productCount: number;
  particlesScale: number;
  duration: number;
}

export interface SubscriptionManagementPageData {
  provider: string;
  subscription_id: string;
  user_id: string;
}

export interface EncryptedSubscriptionManagementPageData {
  encrypted_smp: string;
}

export interface ImageButton {
  /**
   * Use resources instead
   *
   * @deprecated
   */
  image: string;
  action: ActionType | undefined;
  resources: ImageButton_Resource[];
  /** animation do not start for this duration */
  before_animation_delay: number;
}

export interface ImageButton_Resource {
  name: string;
  /** from 0 to 100 % */
  appear_probability: number;
}

export interface ProductPageReport {
  product_id: string;
  user_id: string;
  comment: string;
  problem_tags: ProductPageReport_ProblemTag[];
  screenshot: Image | undefined;
  reasons: string[];
}

export enum ProductPageReport_ProblemTag {
  PROBLEM_LEVEL_NONE = "PROBLEM_LEVEL_NONE",
  PROBLEM_LEVEL_DANGEROUS = "PROBLEM_LEVEL_DANGEROUS",
  PROBLEM_LEVEL_NON_DANGEROUS = "PROBLEM_LEVEL_NON_DANGEROUS",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productPageReport_ProblemTagFromJSON(object: any): ProductPageReport_ProblemTag {
  switch (object) {
    case 0:
    case "PROBLEM_LEVEL_NONE":
      return ProductPageReport_ProblemTag.PROBLEM_LEVEL_NONE;
    case 1:
    case "PROBLEM_LEVEL_DANGEROUS":
      return ProductPageReport_ProblemTag.PROBLEM_LEVEL_DANGEROUS;
    case 2:
    case "PROBLEM_LEVEL_NON_DANGEROUS":
      return ProductPageReport_ProblemTag.PROBLEM_LEVEL_NON_DANGEROUS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductPageReport_ProblemTag.UNRECOGNIZED;
  }
}

export function productPageReport_ProblemTagToJSON(object: ProductPageReport_ProblemTag): string {
  switch (object) {
    case ProductPageReport_ProblemTag.PROBLEM_LEVEL_NONE:
      return "PROBLEM_LEVEL_NONE";
    case ProductPageReport_ProblemTag.PROBLEM_LEVEL_DANGEROUS:
      return "PROBLEM_LEVEL_DANGEROUS";
    case ProductPageReport_ProblemTag.PROBLEM_LEVEL_NON_DANGEROUS:
      return "PROBLEM_LEVEL_NON_DANGEROUS";
    case ProductPageReport_ProblemTag.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ProductPageReportRequest {
  request: Request | undefined;
  report: ProductPageReport | undefined;
}

export const ProductPoraScore = {
  fromJSON(object: any): ProductPoraScore {
    return {
      scale: isSet(object.scale) ? ProductPoraScore_Scale.fromJSON(object.scale) : undefined,
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
      colors: isSet(object.colors) ? ProductPoraScore_Colors.fromJSON(object.colors) : undefined,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
    };
  },

  toJSON(message: ProductPoraScore): unknown {
    const obj: any = {};
    if (message.scale !== undefined) {
      obj.scale = ProductPoraScore_Scale.toJSON(message.scale);
    }
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    if (message.colors !== undefined) {
      obj.colors = ProductPoraScore_Colors.toJSON(message.colors);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    return obj;
  },
};

export const ProductPoraScore_Colors = {
  fromJSON(object: any): ProductPoraScore_Colors {
    return {
      background: isSet(object.background) ? Color.fromJSON(object.background) : undefined,
      foreground: isSet(object.foreground) ? Color.fromJSON(object.foreground) : undefined,
    };
  },

  toJSON(message: ProductPoraScore_Colors): unknown {
    const obj: any = {};
    if (message.background !== undefined) {
      obj.background = Color.toJSON(message.background);
    }
    if (message.foreground !== undefined) {
      obj.foreground = Color.toJSON(message.foreground);
    }
    return obj;
  },
};

export const ProductPoraScore_Scale = {
  fromJSON(object: any): ProductPoraScore_Scale {
    return {
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductPoraScore_Scale_Item.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductPoraScore_Scale): unknown {
    const obj: any = {};
    if (message.items?.length) {
      obj.items = message.items.map((e) => ProductPoraScore_Scale_Item.toJSON(e));
    }
    return obj;
  },
};

export const ProductPoraScore_Scale_Item = {
  fromJSON(object: any): ProductPoraScore_Scale_Item {
    return {
      from_value: isSet(object.from_value) ? globalThis.Number(object.from_value) : 0,
      to_value: isSet(object.to_value) ? globalThis.Number(object.to_value) : 0,
    };
  },

  toJSON(message: ProductPoraScore_Scale_Item): unknown {
    const obj: any = {};
    if (message.from_value !== 0) {
      obj.from_value = Math.round(message.from_value);
    }
    if (message.to_value !== 0) {
      obj.to_value = Math.round(message.to_value);
    }
    return obj;
  },
};

export const MyProductsScreenRequest = {
  fromJSON(object: any): MyProductsScreenRequest {
    return { request: isSet(object.request) ? Request.fromJSON(object.request) : undefined };
  },

  toJSON(message: MyProductsScreenRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    return obj;
  },
};

export const MyProductsScreenResponse = {
  fromJSON(object: any): MyProductsScreenResponse {
    return { my_products: isSet(object.my_products) ? ActionOpenMyProducts.fromJSON(object.my_products) : undefined };
  },

  toJSON(message: MyProductsScreenResponse): unknown {
    const obj: any = {};
    if (message.my_products !== undefined) {
      obj.my_products = ActionOpenMyProducts.toJSON(message.my_products);
    }
    return obj;
  },
};

export const OpenProductScannerRequest = {
  fromJSON(object: any): OpenProductScannerRequest {
    return { request: isSet(object.request) ? Request.fromJSON(object.request) : undefined };
  },

  toJSON(message: OpenProductScannerRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    return obj;
  },
};

export const OpenProductScannerResponse = {
  fromJSON(object: any): OpenProductScannerResponse {
    return {
      product_scanner: isSet(object.product_scanner)
        ? ActionOpenProductScanner.fromJSON(object.product_scanner)
        : undefined,
    };
  },

  toJSON(message: OpenProductScannerResponse): unknown {
    const obj: any = {};
    if (message.product_scanner !== undefined) {
      obj.product_scanner = ActionOpenProductScanner.toJSON(message.product_scanner);
    }
    return obj;
  },
};

export const OpenFaceScannerRequest = {
  fromJSON(object: any): OpenFaceScannerRequest {
    return { request: isSet(object.request) ? Request.fromJSON(object.request) : undefined };
  },

  toJSON(message: OpenFaceScannerRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    return obj;
  },
};

export const OpenFaceScannerResponse = {
  fromJSON(object: any): OpenFaceScannerResponse {
    return { face_scanner: isSet(object.face_scanner) ? ActionSequence.fromJSON(object.face_scanner) : undefined };
  },

  toJSON(message: OpenFaceScannerResponse): unknown {
    const obj: any = {};
    if (message.face_scanner !== undefined) {
      obj.face_scanner = ActionSequence.toJSON(message.face_scanner);
    }
    return obj;
  },
};

export const ProductsRecognitionRequest = {
  fromJSON(object: any): ProductsRecognitionRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
      forced_ingredients: isSet(object.forced_ingredients) ? globalThis.String(object.forced_ingredients) : "",
      user_photo_id: isSet(object.user_photo_id) ? globalThis.String(object.user_photo_id) : "",
      ocr_result: isSet(object.ocr_result) ? ProductsRecognitionRequest_OCR.fromJSON(object.ocr_result) : undefined,
      forced_product_text: isSet(object.forced_product_text) ? globalThis.String(object.forced_product_text) : "",
      search_variants_count: isSet(object.search_variants_count) ? globalThis.Number(object.search_variants_count) : 0,
      forced_product_url: isSet(object.forced_product_url) ? globalThis.String(object.forced_product_url) : "",
      forced_product_brand: isSet(object.forced_product_brand) ? globalThis.String(object.forced_product_brand) : "",
      forced_product_name: isSet(object.forced_product_name) ? globalThis.String(object.forced_product_name) : "",
      forced_product_image: isSet(object.forced_product_image) ? globalThis.String(object.forced_product_image) : "",
      forced_product_step: isSet(object.forced_product_step) ? globalThis.String(object.forced_product_step) : "",
      forced_product_description: isSet(object.forced_product_description)
        ? globalThis.String(object.forced_product_description)
        : "",
      forced_product_price: isSet(object.forced_product_price) ? globalThis.String(object.forced_product_price) : "",
      forced_product_rating: isSet(object.forced_product_rating) ? globalThis.String(object.forced_product_rating) : "",
      forced_product_reviews_count: isSet(object.forced_product_reviews_count)
        ? globalThis.String(object.forced_product_reviews_count)
        : "",
      skip_alternatives: isSet(object.skip_alternatives) ? globalThis.Boolean(object.skip_alternatives) : false,
      evaluations_request_id: isSet(object.evaluations_request_id)
        ? globalThis.String(object.evaluations_request_id)
        : "",
      forced_request_id: isSet(object.forced_request_id) ? globalThis.String(object.forced_request_id) : "",
    };
  },

  toJSON(message: ProductsRecognitionRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.image !== undefined) {
      obj.image = Image.toJSON(message.image);
    }
    if (message.forced_ingredients !== "") {
      obj.forced_ingredients = message.forced_ingredients;
    }
    if (message.user_photo_id !== "") {
      obj.user_photo_id = message.user_photo_id;
    }
    if (message.ocr_result !== undefined) {
      obj.ocr_result = ProductsRecognitionRequest_OCR.toJSON(message.ocr_result);
    }
    if (message.forced_product_text !== "") {
      obj.forced_product_text = message.forced_product_text;
    }
    if (message.search_variants_count !== 0) {
      obj.search_variants_count = Math.round(message.search_variants_count);
    }
    if (message.forced_product_url !== "") {
      obj.forced_product_url = message.forced_product_url;
    }
    if (message.forced_product_brand !== "") {
      obj.forced_product_brand = message.forced_product_brand;
    }
    if (message.forced_product_name !== "") {
      obj.forced_product_name = message.forced_product_name;
    }
    if (message.forced_product_image !== "") {
      obj.forced_product_image = message.forced_product_image;
    }
    if (message.forced_product_step !== "") {
      obj.forced_product_step = message.forced_product_step;
    }
    if (message.forced_product_description !== "") {
      obj.forced_product_description = message.forced_product_description;
    }
    if (message.forced_product_price !== "") {
      obj.forced_product_price = message.forced_product_price;
    }
    if (message.forced_product_rating !== "") {
      obj.forced_product_rating = message.forced_product_rating;
    }
    if (message.forced_product_reviews_count !== "") {
      obj.forced_product_reviews_count = message.forced_product_reviews_count;
    }
    if (message.skip_alternatives !== false) {
      obj.skip_alternatives = message.skip_alternatives;
    }
    if (message.evaluations_request_id !== "") {
      obj.evaluations_request_id = message.evaluations_request_id;
    }
    if (message.forced_request_id !== "") {
      obj.forced_request_id = message.forced_request_id;
    }
    return obj;
  },
};

export const ProductsRecognitionRequest_OCR = {
  fromJSON(object: any): ProductsRecognitionRequest_OCR {
    return {
      lines: globalThis.Array.isArray(object?.lines)
        ? object.lines.map((e: any) => ProductsRecognitionRequest_OCR_Line.fromJSON(e))
        : [],
      recognition_duration: isSet(object.recognition_duration) ? globalThis.Number(object.recognition_duration) : 0,
      barcode: isSet(object.barcode) ? ProductsRecognitionRequest_OCR_Barcode.fromJSON(object.barcode) : undefined,
    };
  },

  toJSON(message: ProductsRecognitionRequest_OCR): unknown {
    const obj: any = {};
    if (message.lines?.length) {
      obj.lines = message.lines.map((e) => ProductsRecognitionRequest_OCR_Line.toJSON(e));
    }
    if (message.recognition_duration !== 0) {
      obj.recognition_duration = message.recognition_duration;
    }
    if (message.barcode !== undefined) {
      obj.barcode = ProductsRecognitionRequest_OCR_Barcode.toJSON(message.barcode);
    }
    return obj;
  },
};

export const ProductsRecognitionRequest_OCR_Rect = {
  fromJSON(object: any): ProductsRecognitionRequest_OCR_Rect {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
      width: isSet(object.width) ? globalThis.Number(object.width) : 0,
      height: isSet(object.height) ? globalThis.Number(object.height) : 0,
    };
  },

  toJSON(message: ProductsRecognitionRequest_OCR_Rect): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = message.x;
    }
    if (message.y !== 0) {
      obj.y = message.y;
    }
    if (message.width !== 0) {
      obj.width = message.width;
    }
    if (message.height !== 0) {
      obj.height = message.height;
    }
    return obj;
  },
};

export const ProductsRecognitionRequest_OCR_Line = {
  fromJSON(object: any): ProductsRecognitionRequest_OCR_Line {
    return {
      text: isSet(object.text) ? globalThis.String(object.text) : "",
      confidence: isSet(object.confidence) ? globalThis.Number(object.confidence) : 0,
      bounding_box: isSet(object.bounding_box)
        ? ProductsRecognitionRequest_OCR_Rect.fromJSON(object.bounding_box)
        : undefined,
      cluster_id: isSet(object.cluster_id) ? globalThis.Number(object.cluster_id) : 0,
    };
  },

  toJSON(message: ProductsRecognitionRequest_OCR_Line): unknown {
    const obj: any = {};
    if (message.text !== "") {
      obj.text = message.text;
    }
    if (message.confidence !== 0) {
      obj.confidence = message.confidence;
    }
    if (message.bounding_box !== undefined) {
      obj.bounding_box = ProductsRecognitionRequest_OCR_Rect.toJSON(message.bounding_box);
    }
    if (message.cluster_id !== 0) {
      obj.cluster_id = Math.round(message.cluster_id);
    }
    return obj;
  },
};

export const ProductsRecognitionRequest_OCR_Barcode = {
  fromJSON(object: any): ProductsRecognitionRequest_OCR_Barcode {
    return {
      code: isSet(object.code) ? globalThis.String(object.code) : "",
      symbology: isSet(object.symbology) ? globalThis.String(object.symbology) : "",
      recognition_duration: isSet(object.recognition_duration) ? globalThis.Number(object.recognition_duration) : 0,
    };
  },

  toJSON(message: ProductsRecognitionRequest_OCR_Barcode): unknown {
    const obj: any = {};
    if (message.code !== "") {
      obj.code = message.code;
    }
    if (message.symbology !== "") {
      obj.symbology = message.symbology;
    }
    if (message.recognition_duration !== 0) {
      obj.recognition_duration = message.recognition_duration;
    }
    return obj;
  },
};

export const ProductsRecognitionRequests = {
  fromJSON(object: any): ProductsRecognitionRequests {
    return {
      requests: globalThis.Array.isArray(object?.requests)
        ? object.requests.map((e: any) => ProductsRecognitionRequest.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductsRecognitionRequests): unknown {
    const obj: any = {};
    if (message.requests?.length) {
      obj.requests = message.requests.map((e) => ProductsRecognitionRequest.toJSON(e));
    }
    return obj;
  },
};

export const ProductRecognitionError = {
  fromJSON(object: any): ProductRecognitionError {
    return {
      error_title: isSet(object.error_title) ? globalThis.String(object.error_title) : "",
      error_text: isSet(object.error_text) ? globalThis.String(object.error_text) : "",
      user_action: isSet(object.user_action)
        ? productRecognitionError_UserActionFromJSON(object.user_action)
        : ProductRecognitionError_UserAction.USER_ACTION_NOTHING,
      next_step_request: isSet(object.next_step_request)
        ? ProductRecognitionErrorNextStepRequest.fromJSON(object.next_step_request)
        : undefined,
    };
  },

  toJSON(message: ProductRecognitionError): unknown {
    const obj: any = {};
    if (message.error_title !== "") {
      obj.error_title = message.error_title;
    }
    if (message.error_text !== "") {
      obj.error_text = message.error_text;
    }
    if (message.user_action !== ProductRecognitionError_UserAction.USER_ACTION_NOTHING) {
      obj.user_action = productRecognitionError_UserActionToJSON(message.user_action);
    }
    if (message.next_step_request !== undefined) {
      obj.next_step_request = ProductRecognitionErrorNextStepRequest.toJSON(message.next_step_request);
    }
    return obj;
  },
};

export const ProductRecognitionErrorNextStepRequest = {
  fromJSON(object: any): ProductRecognitionErrorNextStepRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      product_scan_id: isSet(object.product_scan_id) ? globalThis.String(object.product_scan_id) : "",
    };
  },

  toJSON(message: ProductRecognitionErrorNextStepRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.product_scan_id !== "") {
      obj.product_scan_id = message.product_scan_id;
    }
    return obj;
  },
};

export const ProductRecognitionErrorNextStepResponse = {
  fromJSON(object: any): ProductRecognitionErrorNextStepResponse {
    return { action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined };
  },

  toJSON(message: ProductRecognitionErrorNextStepResponse): unknown {
    const obj: any = {};
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    return obj;
  },
};

export const ProductRecognitionDetectedObject = {
  fromJSON(object: any): ProductRecognitionDetectedObject {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      score: isSet(object.score) ? globalThis.Number(object.score) : 0,
    };
  },

  toJSON(message: ProductRecognitionDetectedObject): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.score !== 0) {
      obj.score = message.score;
    }
    return obj;
  },
};

export const ProductsRecognitionResponse = {
  fromJSON(object: any): ProductsRecognitionResponse {
    return {
      response: isSet(object.product_screen)
        ? { $case: "product_screen", product_screen: ProductScreen.fromJSON(object.product_screen) }
        : isSet(object.error)
        ? { $case: "error", error: ProductRecognitionError.fromJSON(object.error) }
        : undefined,
      detected_objects: globalThis.Array.isArray(object?.detected_objects)
        ? object.detected_objects.map((e: any) => ProductRecognitionDetectedObject.fromJSON(e))
        : [],
      search_variants: isSet(object.search_variants)
        ? ProductsRecognitionResponse_SearchVariants.fromJSON(object.search_variants)
        : undefined,
      request_id: isSet(object.request_id) ? globalThis.String(object.request_id) : "",
      trial_state: isSet(object.trial_state) ? TrialState.fromJSON(object.trial_state) : undefined,
    };
  },

  toJSON(message: ProductsRecognitionResponse): unknown {
    const obj: any = {};
    if (message.response?.$case === "product_screen") {
      obj.product_screen = ProductScreen.toJSON(message.response.product_screen);
    }
    if (message.response?.$case === "error") {
      obj.error = ProductRecognitionError.toJSON(message.response.error);
    }
    if (message.detected_objects?.length) {
      obj.detected_objects = message.detected_objects.map((e) => ProductRecognitionDetectedObject.toJSON(e));
    }
    if (message.search_variants !== undefined) {
      obj.search_variants = ProductsRecognitionResponse_SearchVariants.toJSON(message.search_variants);
    }
    if (message.request_id !== "") {
      obj.request_id = message.request_id;
    }
    if (message.trial_state !== undefined) {
      obj.trial_state = TrialState.toJSON(message.trial_state);
    }
    return obj;
  },
};

export const ProductsRecognitionResponse_SearchVariants = {
  fromJSON(object: any): ProductsRecognitionResponse_SearchVariants {
    return {
      navigation_title: isSet(object.navigation_title) ? globalThis.String(object.navigation_title) : "",
      items_title: isSet(object.items_title) ? globalThis.String(object.items_title) : "",
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductCard.fromJSON(e))
        : [],
      top_button: isSet(object.top_button)
        ? ProductsRecognitionResponse_SearchVariants_Button.fromJSON(object.top_button)
        : undefined,
      paywall: isSet(object.paywall)
        ? ProductsRecognitionResponse_SearchVariants_Paywall.fromJSON(object.paywall)
        : undefined,
    };
  },

  toJSON(message: ProductsRecognitionResponse_SearchVariants): unknown {
    const obj: any = {};
    if (message.navigation_title !== "") {
      obj.navigation_title = message.navigation_title;
    }
    if (message.items_title !== "") {
      obj.items_title = message.items_title;
    }
    if (message.items?.length) {
      obj.items = message.items.map((e) => ProductCard.toJSON(e));
    }
    if (message.top_button !== undefined) {
      obj.top_button = ProductsRecognitionResponse_SearchVariants_Button.toJSON(message.top_button);
    }
    if (message.paywall !== undefined) {
      obj.paywall = ProductsRecognitionResponse_SearchVariants_Paywall.toJSON(message.paywall);
    }
    return obj;
  },
};

export const ProductsRecognitionResponse_SearchVariants_Button = {
  fromJSON(object: any): ProductsRecognitionResponse_SearchVariants_Button {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: ProductsRecognitionResponse_SearchVariants_Button): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    return obj;
  },
};

export const ProductsRecognitionResponse_SearchVariants_Paywall = {
  fromJSON(object: any): ProductsRecognitionResponse_SearchVariants_Paywall {
    return {
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
      is_strict: isSet(object.is_strict) ? globalThis.Boolean(object.is_strict) : false,
    };
  },

  toJSON(message: ProductsRecognitionResponse_SearchVariants_Paywall): unknown {
    const obj: any = {};
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    if (message.is_strict !== false) {
      obj.is_strict = message.is_strict;
    }
    return obj;
  },
};

export const ProgramSummaryContent = {
  fromJSON(object: any): ProgramSummaryContent {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
      scan_id: isSet(object.scan_id) ? globalThis.String(object.scan_id) : "",
      list: globalThis.Array.isArray(object?.list) ? object.list.map((e: any) => SummaryListItem.fromJSON(e)) : [],
      bubble_text: isSet(object.bubble_text) ? AttributedString.fromJSON(object.bubble_text) : undefined,
      is_expanded: isSet(object.is_expanded) ? globalThis.Boolean(object.is_expanded) : false,
      footer_button: isSet(object.footer_button)
        ? ProgramSummaryContent_FooterButton.fromJSON(object.footer_button)
        : undefined,
    };
  },

  toJSON(message: ProgramSummaryContent): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    if (message.scan_id !== "") {
      obj.scan_id = message.scan_id;
    }
    if (message.list?.length) {
      obj.list = message.list.map((e) => SummaryListItem.toJSON(e));
    }
    if (message.bubble_text !== undefined) {
      obj.bubble_text = AttributedString.toJSON(message.bubble_text);
    }
    if (message.is_expanded !== false) {
      obj.is_expanded = message.is_expanded;
    }
    if (message.footer_button !== undefined) {
      obj.footer_button = ProgramSummaryContent_FooterButton.toJSON(message.footer_button);
    }
    return obj;
  },
};

export const ProgramSummaryContent_FooterButton = {
  fromJSON(object: any): ProgramSummaryContent_FooterButton {
    return {
      icon: isSet(object.icon) ? AttributedString.fromJSON(object.icon) : undefined,
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: ProgramSummaryContent_FooterButton): unknown {
    const obj: any = {};
    if (message.icon !== undefined) {
      obj.icon = AttributedString.toJSON(message.icon);
    }
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    return obj;
  },
};

export const ProductCard = {
  fromJSON(object: any): ProductCard {
    return {
      qualification: isSet(object.qualification)
        ? productQualificationFromJSON(object.qualification)
        : ProductQualification.PRODUCT_QUALIFICATION_NONE,
      qualification_title: isSet(object.qualification_title) ? globalThis.String(object.qualification_title) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      brand: isSet(object.brand) ? globalThis.String(object.brand) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      rating: isSet(object.rating) ? globalThis.String(object.rating) : "",
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [],
      origin_link: isSet(object.origin_link) ? globalThis.String(object.origin_link) : "",
      product_id: isSet(object.product_id) ? globalThis.String(object.product_id) : "",
      product_screen: isSet(object.product_screen) ? ProductScreen.fromJSON(object.product_screen) : undefined,
      pora_score: isSet(object.pora_score) ? ProductPoraScore.fromJSON(object.pora_score) : undefined,
      tags_v2: globalThis.Array.isArray(object?.tags_v2) ? object.tags_v2.map((e: any) => Tag.fromJSON(e)) : [],
    };
  },

  toJSON(message: ProductCard): unknown {
    const obj: any = {};
    if (message.qualification !== ProductQualification.PRODUCT_QUALIFICATION_NONE) {
      obj.qualification = productQualificationToJSON(message.qualification);
    }
    if (message.qualification_title !== "") {
      obj.qualification_title = message.qualification_title;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.brand !== "") {
      obj.brand = message.brand;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.rating !== "") {
      obj.rating = message.rating;
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    if (message.origin_link !== "") {
      obj.origin_link = message.origin_link;
    }
    if (message.product_id !== "") {
      obj.product_id = message.product_id;
    }
    if (message.product_screen !== undefined) {
      obj.product_screen = ProductScreen.toJSON(message.product_screen);
    }
    if (message.pora_score !== undefined) {
      obj.pora_score = ProductPoraScore.toJSON(message.pora_score);
    }
    if (message.tags_v2?.length) {
      obj.tags_v2 = message.tags_v2.map((e) => Tag.toJSON(e));
    }
    return obj;
  },
};

export const ProductsSection = {
  fromJSON(object: any): ProductsSection {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      qualification_title: isSet(object.qualification_title) ? globalThis.String(object.qualification_title) : "",
      step_title: isSet(object.step_title) ? globalThis.String(object.step_title) : "",
      products: globalThis.Array.isArray(object?.products)
        ? object.products.map((e: any) => ProductCard.fromJSON(e))
        : [],
      info_bubble: isSet(object.info_bubble) ? ProductsSection_Bubble.fromJSON(object.info_bubble) : undefined,
      footer_button_title: isSet(object.footer_button_title) ? globalThis.String(object.footer_button_title) : "",
    };
  },

  toJSON(message: ProductsSection): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.qualification_title !== "") {
      obj.qualification_title = message.qualification_title;
    }
    if (message.step_title !== "") {
      obj.step_title = message.step_title;
    }
    if (message.products?.length) {
      obj.products = message.products.map((e) => ProductCard.toJSON(e));
    }
    if (message.info_bubble !== undefined) {
      obj.info_bubble = ProductsSection_Bubble.toJSON(message.info_bubble);
    }
    if (message.footer_button_title !== "") {
      obj.footer_button_title = message.footer_button_title;
    }
    return obj;
  },
};

export const ProductsSection_Bubble = {
  fromJSON(object: any): ProductsSection_Bubble {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      buttons: globalThis.Array.isArray(object?.buttons)
        ? object.buttons.map((e: any) => ProductsSection_Bubble_Button.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductsSection_Bubble): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.buttons?.length) {
      obj.buttons = message.buttons.map((e) => ProductsSection_Bubble_Button.toJSON(e));
    }
    return obj;
  },
};

export const ProductsSection_Bubble_Button = {
  fromJSON(object: any): ProductsSection_Bubble_Button {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: ProductsSection_Bubble_Button): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    return obj;
  },
};

export const ProductsSectionsGroup = {
  fromJSON(object: any): ProductsSectionsGroup {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      url_title: isSet(object.url_title) ? globalThis.String(object.url_title) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      sections: globalThis.Array.isArray(object?.sections)
        ? object.sections.map((e: any) => ProductsSection.fromJSON(e))
        : [],
      sections_v2: globalThis.Array.isArray(object?.sections_v2)
        ? object.sections_v2.map((e: any) => ProductsSectionsGroup_Section.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductsSectionsGroup): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.url_title !== "") {
      obj.url_title = message.url_title;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.sections?.length) {
      obj.sections = message.sections.map((e) => ProductsSection.toJSON(e));
    }
    if (message.sections_v2?.length) {
      obj.sections_v2 = message.sections_v2.map((e) => ProductsSectionsGroup_Section.toJSON(e));
    }
    return obj;
  },
};

export const ProductsSectionsGroup_Section = {
  fromJSON(object: any): ProductsSectionsGroup_Section {
    return {
      section: isSet(object.products)
        ? { $case: "products", products: ProductsSection.fromJSON(object.products) }
        : isSet(object.alternatives)
        ? { $case: "alternatives", alternatives: ProductsSectionsGroup_Alternatives.fromJSON(object.alternatives) }
        : isSet(object.info)
        ? { $case: "info", info: ProductsSectionsGroup_Info.fromJSON(object.info) }
        : undefined,
    };
  },

  toJSON(message: ProductsSectionsGroup_Section): unknown {
    const obj: any = {};
    if (message.section?.$case === "products") {
      obj.products = ProductsSection.toJSON(message.section.products);
    }
    if (message.section?.$case === "alternatives") {
      obj.alternatives = ProductsSectionsGroup_Alternatives.toJSON(message.section.alternatives);
    }
    if (message.section?.$case === "info") {
      obj.info = ProductsSectionsGroup_Info.toJSON(message.section.info);
    }
    return obj;
  },
};

export const ProductsSectionsGroup_Alternatives = {
  fromJSON(object: any): ProductsSectionsGroup_Alternatives {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      products: globalThis.Array.isArray(object?.products)
        ? object.products.map((e: any) => ProductCard.fromJSON(e))
        : [],
      should_show_section: isSet(object.should_show_section) ? globalThis.Boolean(object.should_show_section) : false,
    };
  },

  toJSON(message: ProductsSectionsGroup_Alternatives): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.products?.length) {
      obj.products = message.products.map((e) => ProductCard.toJSON(e));
    }
    if (message.should_show_section !== false) {
      obj.should_show_section = message.should_show_section;
    }
    return obj;
  },
};

export const ProductsSectionsGroup_Info = {
  fromJSON(object: any): ProductsSectionsGroup_Info {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      bubble_text: isSet(object.bubble_text) ? AttributedString.fromJSON(object.bubble_text) : undefined,
    };
  },

  toJSON(message: ProductsSectionsGroup_Info): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.bubble_text !== undefined) {
      obj.bubble_text = AttributedString.toJSON(message.bubble_text);
    }
    return obj;
  },
};

export const ProductsListScreen = {
  fromJSON(object: any): ProductsListScreen {
    return {
      summary: isSet(object.summary) ? ProgramSummaryContent.fromJSON(object.summary) : undefined,
      section_groups: globalThis.Array.isArray(object?.section_groups)
        ? object.section_groups.map((e: any) => ProductsSectionsGroup.fromJSON(e))
        : [],
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      segments: globalThis.Array.isArray(object?.segments)
        ? object.segments.map((e: any) => ProductsListScreen_Segment.fromJSON(e))
        : [],
      selected_segment_index: isSet(object.selected_segment_index)
        ? globalThis.Number(object.selected_segment_index)
        : 0,
      bottom_button_title: isSet(object.bottom_button_title) ? globalThis.String(object.bottom_button_title) : "",
      is_close_button_available: isSet(object.is_close_button_available)
        ? globalThis.Boolean(object.is_close_button_available)
        : false,
    };
  },

  toJSON(message: ProductsListScreen): unknown {
    const obj: any = {};
    if (message.summary !== undefined) {
      obj.summary = ProgramSummaryContent.toJSON(message.summary);
    }
    if (message.section_groups?.length) {
      obj.section_groups = message.section_groups.map((e) => ProductsSectionsGroup.toJSON(e));
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.segments?.length) {
      obj.segments = message.segments.map((e) => ProductsListScreen_Segment.toJSON(e));
    }
    if (message.selected_segment_index !== 0) {
      obj.selected_segment_index = Math.round(message.selected_segment_index);
    }
    if (message.bottom_button_title !== "") {
      obj.bottom_button_title = message.bottom_button_title;
    }
    if (message.is_close_button_available !== false) {
      obj.is_close_button_available = message.is_close_button_available;
    }
    return obj;
  },
};

export const ProductsListScreen_Segment = {
  fromJSON(object: any): ProductsListScreen_Segment {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      section_groups: globalThis.Array.isArray(object?.section_groups)
        ? object.section_groups.map((e: any) => ProductsSectionsGroup.fromJSON(e))
        : [],
      segment_header_image: isSet(object.segment_header_image) ? globalThis.String(object.segment_header_image) : "",
      segment_header_title: isSet(object.segment_header_title)
        ? AttributedString.fromJSON(object.segment_header_title)
        : undefined,
    };
  },

  toJSON(message: ProductsListScreen_Segment): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.section_groups?.length) {
      obj.section_groups = message.section_groups.map((e) => ProductsSectionsGroup.toJSON(e));
    }
    if (message.segment_header_image !== "") {
      obj.segment_header_image = message.segment_header_image;
    }
    if (message.segment_header_title !== undefined) {
      obj.segment_header_title = AttributedString.toJSON(message.segment_header_title);
    }
    return obj;
  },
};

export const ProductsRecommendationsScreen = {
  fromJSON(object: any): ProductsRecommendationsScreen {
    return {
      section_groups: globalThis.Array.isArray(object?.section_groups)
        ? object.section_groups.map((e: any) => ProductsSectionsGroup.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductsRecommendationsScreen): unknown {
    const obj: any = {};
    if (message.section_groups?.length) {
      obj.section_groups = message.section_groups.map((e) => ProductsSectionsGroup.toJSON(e));
    }
    return obj;
  },
};

export const ProductKeyFunctionInfoScreen = {
  fromJSON(object: any): ProductKeyFunctionInfoScreen {
    return {
      function_type: isSet(object.function_type)
        ? productKeyFunctionTypeFromJSON(object.function_type)
        : ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE,
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
    };
  },

  toJSON(message: ProductKeyFunctionInfoScreen): unknown {
    const obj: any = {};
    if (message.function_type !== ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE) {
      obj.function_type = productKeyFunctionTypeToJSON(message.function_type);
    }
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    return obj;
  },
};

export const ProductInfoScreen = {
  fromJSON(object: any): ProductInfoScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      subtitle: isSet(object.subtitle) ? AttributedString.fromJSON(object.subtitle) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
    };
  },

  toJSON(message: ProductInfoScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.subtitle !== undefined) {
      obj.subtitle = AttributedString.toJSON(message.subtitle);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    return obj;
  },
};

export const ProductStepInfoScreen = {
  fromJSON(object: any): ProductStepInfoScreen {
    return {
      nav_bar: isSet(object.nav_bar) ? ProductStepInfoScreen_NavBar.fromJSON(object.nav_bar) : undefined,
      header: isSet(object.header) ? ProductStepInfoScreen_Header.fromJSON(object.header) : undefined,
      shortcuts: isSet(object.shortcuts) ? ProductStepInfoScreen_Shortcuts.fromJSON(object.shortcuts) : undefined,
      sections: globalThis.Array.isArray(object?.sections)
        ? object.sections.map((e: any) => ProductStepInfoScreen_Section.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductStepInfoScreen): unknown {
    const obj: any = {};
    if (message.nav_bar !== undefined) {
      obj.nav_bar = ProductStepInfoScreen_NavBar.toJSON(message.nav_bar);
    }
    if (message.header !== undefined) {
      obj.header = ProductStepInfoScreen_Header.toJSON(message.header);
    }
    if (message.shortcuts !== undefined) {
      obj.shortcuts = ProductStepInfoScreen_Shortcuts.toJSON(message.shortcuts);
    }
    if (message.sections?.length) {
      obj.sections = message.sections.map((e) => ProductStepInfoScreen_Section.toJSON(e));
    }
    return obj;
  },
};

export const ProductStepInfoScreen_NavBar = {
  fromJSON(object: any): ProductStepInfoScreen_NavBar {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
    };
  },

  toJSON(message: ProductStepInfoScreen_NavBar): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    return obj;
  },
};

export const ProductStepInfoScreen_Header = {
  fromJSON(object: any): ProductStepInfoScreen_Header {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      scale: isSet(object.scale) ? ProductStepInfoScreen_Header_Scale.fromJSON(object.scale) : undefined,
    };
  },

  toJSON(message: ProductStepInfoScreen_Header): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.scale !== undefined) {
      obj.scale = ProductStepInfoScreen_Header_Scale.toJSON(message.scale);
    }
    return obj;
  },
};

export const ProductStepInfoScreen_Header_Scale = {
  fromJSON(object: any): ProductStepInfoScreen_Header_Scale {
    return {
      steps_count: isSet(object.steps_count) ? globalThis.Number(object.steps_count) : 0,
      filled_steps_count: isSet(object.filled_steps_count) ? globalThis.Number(object.filled_steps_count) : 0,
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ProductStepInfoScreen_Header_Scale): unknown {
    const obj: any = {};
    if (message.steps_count !== 0) {
      obj.steps_count = Math.round(message.steps_count);
    }
    if (message.filled_steps_count !== 0) {
      obj.filled_steps_count = Math.round(message.filled_steps_count);
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },
};

export const ProductStepInfoScreen_Shortcuts = {
  fromJSON(object: any): ProductStepInfoScreen_Shortcuts {
    return {
      shortcuts: globalThis.Array.isArray(object?.shortcuts)
        ? object.shortcuts.map((e: any) => ProductStepInfoScreen_Shortcuts_Shortcut.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductStepInfoScreen_Shortcuts): unknown {
    const obj: any = {};
    if (message.shortcuts?.length) {
      obj.shortcuts = message.shortcuts.map((e) => ProductStepInfoScreen_Shortcuts_Shortcut.toJSON(e));
    }
    return obj;
  },
};

export const ProductStepInfoScreen_Shortcuts_Shortcut = {
  fromJSON(object: any): ProductStepInfoScreen_Shortcuts_Shortcut {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      target_section_index: isSet(object.target_section_index) ? globalThis.Number(object.target_section_index) : 0,
    };
  },

  toJSON(message: ProductStepInfoScreen_Shortcuts_Shortcut): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.target_section_index !== 0) {
      obj.target_section_index = Math.round(message.target_section_index);
    }
    return obj;
  },
};

export const ProductStepInfoScreen_Section = {
  fromJSON(object: any): ProductStepInfoScreen_Section {
    return {
      section: isSet(object.ingredients)
        ? { $case: "ingredients", ingredients: ProductScreen_Section_Ingredients.fromJSON(object.ingredients) }
        : isSet(object.info)
        ? { $case: "info", info: ProductStepInfoScreen_Section_Info.fromJSON(object.info) }
        : undefined,
    };
  },

  toJSON(message: ProductStepInfoScreen_Section): unknown {
    const obj: any = {};
    if (message.section?.$case === "ingredients") {
      obj.ingredients = ProductScreen_Section_Ingredients.toJSON(message.section.ingredients);
    }
    if (message.section?.$case === "info") {
      obj.info = ProductStepInfoScreen_Section_Info.toJSON(message.section.info);
    }
    return obj;
  },
};

export const ProductStepInfoScreen_Section_Info = {
  fromJSON(object: any): ProductStepInfoScreen_Section_Info {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      info: isSet(object.info) ? AttributedString.fromJSON(object.info) : undefined,
      line_limit: isSet(object.line_limit) ? globalThis.Number(object.line_limit) : 0,
      lazy_request_data: isSet(object.lazy_request_data)
        ? LazyProductStepInfoSectionRequestData.fromJSON(object.lazy_request_data)
        : undefined,
    };
  },

  toJSON(message: ProductStepInfoScreen_Section_Info): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.info !== undefined) {
      obj.info = AttributedString.toJSON(message.info);
    }
    if (message.line_limit !== 0) {
      obj.line_limit = Math.round(message.line_limit);
    }
    if (message.lazy_request_data !== undefined) {
      obj.lazy_request_data = LazyProductStepInfoSectionRequestData.toJSON(message.lazy_request_data);
    }
    return obj;
  },
};

export const ProductAlternativesScreen = {
  fromJSON(object: any): ProductAlternativesScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      products: globalThis.Array.isArray(object?.products)
        ? object.products.map((e: any) => ProductAlternativesScreen_Product.fromJSON(e))
        : [],
      lazy_request: isSet(object.lazy_request)
        ? ProductScreenAlternativesRequest.fromJSON(object.lazy_request)
        : undefined,
    };
  },

  toJSON(message: ProductAlternativesScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.products?.length) {
      obj.products = message.products.map((e) => ProductAlternativesScreen_Product.toJSON(e));
    }
    if (message.lazy_request !== undefined) {
      obj.lazy_request = ProductScreenAlternativesRequest.toJSON(message.lazy_request);
    }
    return obj;
  },
};

export const ProductAlternativesScreen_Product = {
  fromJSON(object: any): ProductAlternativesScreen_Product {
    return {
      brand: isSet(object.brand) ? globalThis.String(object.brand) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      price: isSet(object.price) ? globalThis.String(object.price) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => Tag.fromJSON(e)) : [],
      product_screen: isSet(object.product_screen) ? ProductScreen.fromJSON(object.product_screen) : undefined,
      pora_score: isSet(object.pora_score) ? ProductPoraScore.fromJSON(object.pora_score) : undefined,
      product_id: isSet(object.product_id) ? globalThis.String(object.product_id) : "",
    };
  },

  toJSON(message: ProductAlternativesScreen_Product): unknown {
    const obj: any = {};
    if (message.brand !== "") {
      obj.brand = message.brand;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.price !== "") {
      obj.price = message.price;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.tags?.length) {
      obj.tags = message.tags.map((e) => Tag.toJSON(e));
    }
    if (message.product_screen !== undefined) {
      obj.product_screen = ProductScreen.toJSON(message.product_screen);
    }
    if (message.pora_score !== undefined) {
      obj.pora_score = ProductPoraScore.toJSON(message.pora_score);
    }
    if (message.product_id !== "") {
      obj.product_id = message.product_id;
    }
    return obj;
  },
};

export const ProductStoresInfoScreen = {
  fromJSON(object: any): ProductStoresInfoScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      stores: globalThis.Array.isArray(object?.stores)
        ? object.stores.map((e: any) => ProductStoresInfoScreen_Store.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductStoresInfoScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.stores?.length) {
      obj.stores = message.stores.map((e) => ProductStoresInfoScreen_Store.toJSON(e));
    }
    return obj;
  },
};

export const ProductStoresInfoScreen_Store = {
  fromJSON(object: any): ProductStoresInfoScreen_Store {
    return {
      image_url: isSet(object.image_url) ? globalThis.String(object.image_url) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      price: isSet(object.price) ? globalThis.String(object.price) : "",
      web_page_url: isSet(object.web_page_url) ? globalThis.String(object.web_page_url) : "",
      info: isSet(object.info) ? ProductStoresInfoScreen_Store_Info.fromJSON(object.info) : undefined,
    };
  },

  toJSON(message: ProductStoresInfoScreen_Store): unknown {
    const obj: any = {};
    if (message.image_url !== "") {
      obj.image_url = message.image_url;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.price !== "") {
      obj.price = message.price;
    }
    if (message.web_page_url !== "") {
      obj.web_page_url = message.web_page_url;
    }
    if (message.info !== undefined) {
      obj.info = ProductStoresInfoScreen_Store_Info.toJSON(message.info);
    }
    return obj;
  },
};

export const ProductStoresInfoScreen_Store_Info = {
  fromJSON(object: any): ProductStoresInfoScreen_Store_Info {
    return {
      info: isSet(object.rating)
        ? { $case: "rating", rating: ProductStoresInfoScreen_Store_Rating.fromJSON(object.rating) }
        : isSet(object.text)
        ? { $case: "text", text: globalThis.String(object.text) }
        : undefined,
    };
  },

  toJSON(message: ProductStoresInfoScreen_Store_Info): unknown {
    const obj: any = {};
    if (message.info?.$case === "rating") {
      obj.rating = ProductStoresInfoScreen_Store_Rating.toJSON(message.info.rating);
    }
    if (message.info?.$case === "text") {
      obj.text = message.info.text;
    }
    return obj;
  },
};

export const ProductStoresInfoScreen_Store_Rating = {
  fromJSON(object: any): ProductStoresInfoScreen_Store_Rating {
    return {
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
      fade: isSet(object.fade) ? globalThis.Boolean(object.fade) : false,
    };
  },

  toJSON(message: ProductStoresInfoScreen_Store_Rating): unknown {
    const obj: any = {};
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    if (message.fade !== false) {
      obj.fade = message.fade;
    }
    return obj;
  },
};

export const ProductFitScoreInfoScreen = {
  fromJSON(object: any): ProductFitScoreInfoScreen {
    return {
      nav_bar: isSet(object.nav_bar) ? ProductFitScoreInfoScreen_NavBar.fromJSON(object.nav_bar) : undefined,
      fit_score: isSet(object.fit_score) ? ProductPoraScore.fromJSON(object.fit_score) : undefined,
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => AttributedString.fromJSON(e)) : [],
      sections: globalThis.Array.isArray(object?.sections)
        ? object.sections.map((e: any) => ProductFitScoreInfoScreen_Section.fromJSON(e))
        : [],
      score: isSet(object.score) ? ProductScreen_Section_TilesHeader_ProductScore.fromJSON(object.score) : undefined,
    };
  },

  toJSON(message: ProductFitScoreInfoScreen): unknown {
    const obj: any = {};
    if (message.nav_bar !== undefined) {
      obj.nav_bar = ProductFitScoreInfoScreen_NavBar.toJSON(message.nav_bar);
    }
    if (message.fit_score !== undefined) {
      obj.fit_score = ProductPoraScore.toJSON(message.fit_score);
    }
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.tags?.length) {
      obj.tags = message.tags.map((e) => AttributedString.toJSON(e));
    }
    if (message.sections?.length) {
      obj.sections = message.sections.map((e) => ProductFitScoreInfoScreen_Section.toJSON(e));
    }
    if (message.score !== undefined) {
      obj.score = ProductScreen_Section_TilesHeader_ProductScore.toJSON(message.score);
    }
    return obj;
  },
};

export const ProductFitScoreInfoScreen_NavBar = {
  fromJSON(object: any): ProductFitScoreInfoScreen_NavBar {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_NavBar): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    return obj;
  },
};

export const ProductFitScoreInfoScreen_Section = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section {
    return {
      section: isSet(object.what_is_score)
        ? {
          $case: "what_is_score",
          what_is_score: ProductFitScoreInfoScreen_Section_WhatIsScore.fromJSON(object.what_is_score),
        }
        : isSet(object.approved_by)
        ? {
          $case: "approved_by",
          approved_by: ProductFitScoreInfoScreen_Section_ApprovedBy.fromJSON(object.approved_by),
        }
        : isSet(object.general_info)
        ? {
          $case: "general_info",
          general_info: ProductFitScoreInfoScreen_Section_GeneralInfo.fromJSON(object.general_info),
        }
        : isSet(object.sources)
        ? { $case: "sources", sources: ProductFitScoreInfoScreen_Section_Sources.fromJSON(object.sources) }
        : isSet(object.example)
        ? { $case: "example", example: ProductFitScoreInfoScreen_Section_Example.fromJSON(object.example) }
        : undefined,
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section): unknown {
    const obj: any = {};
    if (message.section?.$case === "what_is_score") {
      obj.what_is_score = ProductFitScoreInfoScreen_Section_WhatIsScore.toJSON(message.section.what_is_score);
    }
    if (message.section?.$case === "approved_by") {
      obj.approved_by = ProductFitScoreInfoScreen_Section_ApprovedBy.toJSON(message.section.approved_by);
    }
    if (message.section?.$case === "general_info") {
      obj.general_info = ProductFitScoreInfoScreen_Section_GeneralInfo.toJSON(message.section.general_info);
    }
    if (message.section?.$case === "sources") {
      obj.sources = ProductFitScoreInfoScreen_Section_Sources.toJSON(message.section.sources);
    }
    if (message.section?.$case === "example") {
      obj.example = ProductFitScoreInfoScreen_Section_Example.toJSON(message.section.example);
    }
    return obj;
  },
};

export const ProductFitScoreInfoScreen_Section_WhatIsScore = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_WhatIsScore {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      evaluations: globalThis.Array.isArray(object?.evaluations)
        ? object.evaluations.map((e: any) => ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_WhatIsScore): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.evaluations?.length) {
      obj.evaluations = message.evaluations.map((e) =>
        ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation.toJSON(e)
      );
    }
    return obj;
  },
};

export const ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      icon: isSet(object.icon) ? globalThis.String(object.icon) : "",
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.icon !== "") {
      obj.icon = message.icon;
    }
    return obj;
  },
};

export const ProductFitScoreInfoScreen_Section_ApprovedBy = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_ApprovedBy {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      avatar_image: isSet(object.avatar_image) ? globalThis.String(object.avatar_image) : "",
      signature_image: isSet(object.signature_image) ? globalThis.String(object.signature_image) : "",
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_ApprovedBy): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.avatar_image !== "") {
      obj.avatar_image = message.avatar_image;
    }
    if (message.signature_image !== "") {
      obj.signature_image = message.signature_image;
    }
    return obj;
  },
};

export const ProductFitScoreInfoScreen_Section_GeneralInfo = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_GeneralInfo {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_GeneralInfo): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    return obj;
  },
};

export const ProductFitScoreInfoScreen_Section_Sources = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_Sources {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      sources: globalThis.Array.isArray(object?.sources)
        ? object.sources.map((e: any) => ProductFitScoreInfoScreen_Section_Sources_Source.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_Sources): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.sources?.length) {
      obj.sources = message.sources.map((e) => ProductFitScoreInfoScreen_Section_Sources_Source.toJSON(e));
    }
    return obj;
  },
};

export const ProductFitScoreInfoScreen_Section_Sources_Source = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_Sources_Source {
    return {
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      url: isSet(object.url) ? globalThis.String(object.url) : "",
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_Sources_Source): unknown {
    const obj: any = {};
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },
};

export const ProductFitScoreInfoScreen_Section_Example = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_Example {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      product_card: isSet(object.product_card)
        ? ProductFitScoreInfoScreen_Section_Example_ProductCard.fromJSON(object.product_card)
        : undefined,
      score_scale: isSet(object.score_scale)
        ? ProductFitScoreInfoScreen_Section_Example_ScoreScale.fromJSON(object.score_scale)
        : undefined,
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_Example): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.product_card !== undefined) {
      obj.product_card = ProductFitScoreInfoScreen_Section_Example_ProductCard.toJSON(message.product_card);
    }
    if (message.score_scale !== undefined) {
      obj.score_scale = ProductFitScoreInfoScreen_Section_Example_ScoreScale.toJSON(message.score_scale);
    }
    return obj;
  },
};

export const ProductFitScoreInfoScreen_Section_Example_ProductCard = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_Example_ProductCard {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      fit_score: isSet(object.fit_score) ? ProductPoraScore.fromJSON(object.fit_score) : undefined,
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      description_title: isSet(object.description_title)
        ? AttributedString.fromJSON(object.description_title)
        : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_Example_ProductCard): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.fit_score !== undefined) {
      obj.fit_score = ProductPoraScore.toJSON(message.fit_score);
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.description_title !== undefined) {
      obj.description_title = AttributedString.toJSON(message.description_title);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    return obj;
  },
};

export const ProductFitScoreInfoScreen_Section_Example_ScoreScale = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_Example_ScoreScale {
    return {
      segments: globalThis.Array.isArray(object?.segments)
        ? object.segments.map((e: any) => ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_Example_ScoreScale): unknown {
    const obj: any = {};
    if (message.segments?.length) {
      obj.segments = message.segments.map((e) =>
        ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment.toJSON(e)
      );
    }
    return obj;
  },
};

export const ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment {
    return {
      color: isSet(object.color) ? Color.fromJSON(object.color) : undefined,
      label: isSet(object.label) ? AttributedString.fromJSON(object.label) : undefined,
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment): unknown {
    const obj: any = {};
    if (message.color !== undefined) {
      obj.color = Color.toJSON(message.color);
    }
    if (message.label !== undefined) {
      obj.label = AttributedString.toJSON(message.label);
    }
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },
};

export const ProductPoraScoreInfoScreen = {
  fromJSON(object: any): ProductPoraScoreInfoScreen {
    return {
      nav_bar: isSet(object.nav_bar) ? ProductPoraScoreInfoScreen_NavBar.fromJSON(object.nav_bar) : undefined,
      pora_score: isSet(object.pora_score) ? ProductPoraScore.fromJSON(object.pora_score) : undefined,
      description_title: isSet(object.description_title) ? globalThis.String(object.description_title) : "",
      description: isSet(object.description)
        ? ProductPoraScoreInfoScreen_Description.fromJSON(object.description)
        : undefined,
    };
  },

  toJSON(message: ProductPoraScoreInfoScreen): unknown {
    const obj: any = {};
    if (message.nav_bar !== undefined) {
      obj.nav_bar = ProductPoraScoreInfoScreen_NavBar.toJSON(message.nav_bar);
    }
    if (message.pora_score !== undefined) {
      obj.pora_score = ProductPoraScore.toJSON(message.pora_score);
    }
    if (message.description_title !== "") {
      obj.description_title = message.description_title;
    }
    if (message.description !== undefined) {
      obj.description = ProductPoraScoreInfoScreen_Description.toJSON(message.description);
    }
    return obj;
  },
};

export const ProductPoraScoreInfoScreen_NavBar = {
  fromJSON(object: any): ProductPoraScoreInfoScreen_NavBar {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
    };
  },

  toJSON(message: ProductPoraScoreInfoScreen_NavBar): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    return obj;
  },
};

export const ProductPoraScoreInfoScreen_Description = {
  fromJSON(object: any): ProductPoraScoreInfoScreen_Description {
    return {
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductPoraScoreInfoScreen_Description_Item.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductPoraScoreInfoScreen_Description): unknown {
    const obj: any = {};
    if (message.items?.length) {
      obj.items = message.items.map((e) => ProductPoraScoreInfoScreen_Description_Item.toJSON(e));
    }
    return obj;
  },
};

export const ProductPoraScoreInfoScreen_Description_Item = {
  fromJSON(object: any): ProductPoraScoreInfoScreen_Description_Item {
    return {
      item: isSet(object.string)
        ? { $case: "string", string: AttributedString.fromJSON(object.string) }
        : isSet(object.scale)
        ? { $case: "scale", scale: ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale.fromJSON(object.scale) }
        : undefined,
    };
  },

  toJSON(message: ProductPoraScoreInfoScreen_Description_Item): unknown {
    const obj: any = {};
    if (message.item?.$case === "string") {
      obj.string = AttributedString.toJSON(message.item.string);
    }
    if (message.item?.$case === "scale") {
      obj.scale = ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale.toJSON(message.item.scale);
    }
    return obj;
  },
};

export const ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale = {
  fromJSON(object: any): ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale {
    return {
      segments: globalThis.Array.isArray(object?.segments)
        ? object.segments.map((e: any) =>
          ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment.fromJSON(e)
        )
        : [],
    };
  },

  toJSON(message: ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale): unknown {
    const obj: any = {};
    if (message.segments?.length) {
      obj.segments = message.segments.map((e) =>
        ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment.toJSON(e)
      );
    }
    return obj;
  },
};

export const ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment = {
  fromJSON(object: any): ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment {
    return {
      color: isSet(object.color) ? Color.fromJSON(object.color) : undefined,
      label: isSet(object.label) ? globalThis.String(object.label) : "",
    };
  },

  toJSON(message: ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment): unknown {
    const obj: any = {};
    if (message.color !== undefined) {
      obj.color = Color.toJSON(message.color);
    }
    if (message.label !== "") {
      obj.label = message.label;
    }
    return obj;
  },
};

export const ActionOpenProductScreenPayload = {
  fromJSON(object: any): ActionOpenProductScreenPayload {
    return {
      product_id: isSet(object.product_id) ? globalThis.String(object.product_id) : "",
      product_screen: isSet(object.product_screen) ? ProductScreen.fromJSON(object.product_screen) : undefined,
    };
  },

  toJSON(message: ActionOpenProductScreenPayload): unknown {
    const obj: any = {};
    if (message.product_id !== "") {
      obj.product_id = message.product_id;
    }
    if (message.product_screen !== undefined) {
      obj.product_screen = ProductScreen.toJSON(message.product_screen);
    }
    return obj;
  },
};

export const ProductScreenRequest = {
  fromJSON(object: any): ProductScreenRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      product_id: isSet(object.product_id) ? globalThis.String(object.product_id) : "",
    };
  },

  toJSON(message: ProductScreenRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.product_id !== "") {
      obj.product_id = message.product_id;
    }
    return obj;
  },
};

export const LazyProductSectionRequestData = {
  fromJSON(object: any): LazyProductSectionRequestData {
    return {
      data: isSet(object.info)
        ? { $case: "info", info: LazyProductSectionRequestData_InfoRequestData.fromJSON(object.info) }
        : isSet(object.key_functions)
        ? {
          $case: "key_functions",
          key_functions: LazyProductSectionRequestData_KeyFunctionsRequestData.fromJSON(object.key_functions),
        }
        : isSet(object.header_data)
        ? {
          $case: "header_data",
          header_data: LazyProductSectionRequestData_HeaderDataRequest.fromJSON(object.header_data),
        }
        : isSet(object.alternatives_data)
        ? {
          $case: "alternatives_data",
          alternatives_data: LazyProductSectionRequestData_AlternativesDataRequest.fromJSON(object.alternatives_data),
        }
        : undefined,
    };
  },

  toJSON(message: LazyProductSectionRequestData): unknown {
    const obj: any = {};
    if (message.data?.$case === "info") {
      obj.info = LazyProductSectionRequestData_InfoRequestData.toJSON(message.data.info);
    }
    if (message.data?.$case === "key_functions") {
      obj.key_functions = LazyProductSectionRequestData_KeyFunctionsRequestData.toJSON(message.data.key_functions);
    }
    if (message.data?.$case === "header_data") {
      obj.header_data = LazyProductSectionRequestData_HeaderDataRequest.toJSON(message.data.header_data);
    }
    if (message.data?.$case === "alternatives_data") {
      obj.alternatives_data = LazyProductSectionRequestData_AlternativesDataRequest.toJSON(
        message.data.alternatives_data,
      );
    }
    return obj;
  },
};

export const LazyProductSectionRequestData_InfoRequestData = {
  fromJSON(object: any): LazyProductSectionRequestData_InfoRequestData {
    return {
      section_title: isSet(object.section_title) ? globalThis.String(object.section_title) : "",
      prompt: isSet(object.prompt) ? globalThis.String(object.prompt) : "",
    };
  },

  toJSON(message: LazyProductSectionRequestData_InfoRequestData): unknown {
    const obj: any = {};
    if (message.section_title !== "") {
      obj.section_title = message.section_title;
    }
    if (message.prompt !== "") {
      obj.prompt = message.prompt;
    }
    return obj;
  },
};

export const LazyProductSectionRequestData_KeyFunctionsRequestData = {
  fromJSON(object: any): LazyProductSectionRequestData_KeyFunctionsRequestData {
    return { product_id: isSet(object.product_id) ? globalThis.String(object.product_id) : "" };
  },

  toJSON(message: LazyProductSectionRequestData_KeyFunctionsRequestData): unknown {
    const obj: any = {};
    if (message.product_id !== "") {
      obj.product_id = message.product_id;
    }
    return obj;
  },
};

export const LazyProductSectionRequestData_HeaderDataRequest = {
  fromJSON(object: any): LazyProductSectionRequestData_HeaderDataRequest {
    return { product_id: isSet(object.product_id) ? globalThis.String(object.product_id) : "" };
  },

  toJSON(message: LazyProductSectionRequestData_HeaderDataRequest): unknown {
    const obj: any = {};
    if (message.product_id !== "") {
      obj.product_id = message.product_id;
    }
    return obj;
  },
};

export const LazyProductSectionRequestData_AlternativesDataRequest = {
  fromJSON(object: any): LazyProductSectionRequestData_AlternativesDataRequest {
    return { product_id: isSet(object.product_id) ? globalThis.String(object.product_id) : "" };
  },

  toJSON(message: LazyProductSectionRequestData_AlternativesDataRequest): unknown {
    const obj: any = {};
    if (message.product_id !== "") {
      obj.product_id = message.product_id;
    }
    return obj;
  },
};

export const LazyProductStepInfoSectionRequestData = {
  fromJSON(object: any): LazyProductStepInfoSectionRequestData {
    return {
      data: isSet(object.info)
        ? { $case: "info", info: LazyProductStepInfoSectionRequestData_InfoRequestData.fromJSON(object.info) }
        : undefined,
    };
  },

  toJSON(message: LazyProductStepInfoSectionRequestData): unknown {
    const obj: any = {};
    if (message.data?.$case === "info") {
      obj.info = LazyProductStepInfoSectionRequestData_InfoRequestData.toJSON(message.data.info);
    }
    return obj;
  },
};

export const LazyProductStepInfoSectionRequestData_InfoRequestData = {
  fromJSON(object: any): LazyProductStepInfoSectionRequestData_InfoRequestData {
    return {
      section_title: isSet(object.section_title) ? globalThis.String(object.section_title) : "",
      prompt: isSet(object.prompt) ? globalThis.String(object.prompt) : "",
    };
  },

  toJSON(message: LazyProductStepInfoSectionRequestData_InfoRequestData): unknown {
    const obj: any = {};
    if (message.section_title !== "") {
      obj.section_title = message.section_title;
    }
    if (message.prompt !== "") {
      obj.prompt = message.prompt;
    }
    return obj;
  },
};

export const LazyProductSectionRequest = {
  fromJSON(object: any): LazyProductSectionRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      data: isSet(object.data) ? LazyProductSectionRequestData.fromJSON(object.data) : undefined,
    };
  },

  toJSON(message: LazyProductSectionRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.data !== undefined) {
      obj.data = LazyProductSectionRequestData.toJSON(message.data);
    }
    return obj;
  },
};

export const LazyProductStepInfoSectionRequest = {
  fromJSON(object: any): LazyProductStepInfoSectionRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      data: isSet(object.data) ? LazyProductStepInfoSectionRequestData.fromJSON(object.data) : undefined,
    };
  },

  toJSON(message: LazyProductStepInfoSectionRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.data !== undefined) {
      obj.data = LazyProductStepInfoSectionRequestData.toJSON(message.data);
    }
    return obj;
  },
};

export const LazyProductSectionResponse = {
  fromJSON(object: any): LazyProductSectionResponse {
    return {
      response: isSet(object.section)
        ? { $case: "section", section: ProductScreen_Section.fromJSON(object.section) }
        : isSet(object.error_message)
        ? { $case: "error_message", error_message: globalThis.String(object.error_message) }
        : undefined,
    };
  },

  toJSON(message: LazyProductSectionResponse): unknown {
    const obj: any = {};
    if (message.response?.$case === "section") {
      obj.section = ProductScreen_Section.toJSON(message.response.section);
    }
    if (message.response?.$case === "error_message") {
      obj.error_message = message.response.error_message;
    }
    return obj;
  },
};

export const LazyProductStepInfoSectionResponse = {
  fromJSON(object: any): LazyProductStepInfoSectionResponse {
    return {
      response: isSet(object.section)
        ? { $case: "section", section: ProductStepInfoScreen_Section.fromJSON(object.section) }
        : isSet(object.error_message)
        ? { $case: "error_message", error_message: globalThis.String(object.error_message) }
        : undefined,
    };
  },

  toJSON(message: LazyProductStepInfoSectionResponse): unknown {
    const obj: any = {};
    if (message.response?.$case === "section") {
      obj.section = ProductStepInfoScreen_Section.toJSON(message.response.section);
    }
    if (message.response?.$case === "error_message") {
      obj.error_message = message.response.error_message;
    }
    return obj;
  },
};

export const ProductScreenHeaderKeyFunctionsRequest = {
  fromJSON(object: any): ProductScreenHeaderKeyFunctionsRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      product_id: isSet(object.product_id) ? globalThis.String(object.product_id) : "",
    };
  },

  toJSON(message: ProductScreenHeaderKeyFunctionsRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.product_id !== "") {
      obj.product_id = message.product_id;
    }
    return obj;
  },
};

export const ProductScreenHeaderPoraScoreRequest = {
  fromJSON(object: any): ProductScreenHeaderPoraScoreRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      product_id: isSet(object.product_id) ? globalThis.String(object.product_id) : "",
    };
  },

  toJSON(message: ProductScreenHeaderPoraScoreRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.product_id !== "") {
      obj.product_id = message.product_id;
    }
    return obj;
  },
};

export const ProductScreenHeaderQuickSummaryRequest = {
  fromJSON(object: any): ProductScreenHeaderQuickSummaryRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      url: isSet(object.url) ? globalThis.String(object.url) : "",
    };
  },

  toJSON(message: ProductScreenHeaderQuickSummaryRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },
};

export const ProductScreenAlternativesRequest = {
  fromJSON(object: any): ProductScreenAlternativesRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      url: isSet(object.url) ? globalThis.String(object.url) : "",
    };
  },

  toJSON(message: ProductScreenAlternativesRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },
};

export const DeleteScanRequest = {
  fromJSON(object: any): DeleteScanRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      category: isSet(object.category) ? globalThis.String(object.category) : "",
    };
  },

  toJSON(message: DeleteScanRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.category !== "") {
      obj.category = message.category;
    }
    return obj;
  },
};

export const DeleteScanResponse = {
  fromJSON(object: any): DeleteScanResponse {
    return { deleted_id: isSet(object.deleted_id) ? globalThis.String(object.deleted_id) : "" };
  },

  toJSON(message: DeleteScanResponse): unknown {
    const obj: any = {};
    if (message.deleted_id !== "") {
      obj.deleted_id = message.deleted_id;
    }
    return obj;
  },
};

export const ProductsScanHistoryRequest = {
  fromJSON(object: any): ProductsScanHistoryRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      start_index: isSet(object.start_index) ? globalThis.Number(object.start_index) : 0,
      category: isSet(object.category) ? globalThis.String(object.category) : "",
    };
  },

  toJSON(message: ProductsScanHistoryRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.start_index !== 0) {
      obj.start_index = Math.round(message.start_index);
    }
    if (message.category !== "") {
      obj.category = message.category;
    }
    return obj;
  },
};

export const ProductsScanHistoryResponse = {
  fromJSON(object: any): ProductsScanHistoryResponse {
    return {
      history_items: globalThis.Array.isArray(object?.history_items)
        ? object.history_items.map((e: any) => ProductsScanHistoryItem.fromJSON(e))
        : [],
      next_page_start_index: isSet(object.next_page_start_index) ? globalThis.Number(object.next_page_start_index) : 0,
      has_more: isSet(object.has_more) ? globalThis.Boolean(object.has_more) : false,
      items_total_count: isSet(object.items_total_count) ? globalThis.String(object.items_total_count) : "",
      sections: globalThis.Array.isArray(object?.sections)
        ? object.sections.map((e: any) => ProductsScanHistoryResponse_Section.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductsScanHistoryResponse): unknown {
    const obj: any = {};
    if (message.history_items?.length) {
      obj.history_items = message.history_items.map((e) => ProductsScanHistoryItem.toJSON(e));
    }
    if (message.next_page_start_index !== 0) {
      obj.next_page_start_index = Math.round(message.next_page_start_index);
    }
    if (message.has_more !== false) {
      obj.has_more = message.has_more;
    }
    if (message.items_total_count !== "") {
      obj.items_total_count = message.items_total_count;
    }
    if (message.sections?.length) {
      obj.sections = message.sections.map((e) => ProductsScanHistoryResponse_Section.toJSON(e));
    }
    return obj;
  },
};

export const ProductsScanHistoryResponse_Section = {
  fromJSON(object: any): ProductsScanHistoryResponse_Section {
    return {
      section_title: isSet(object.section_title) ? globalThis.String(object.section_title) : "",
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductsScanHistoryItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductsScanHistoryResponse_Section): unknown {
    const obj: any = {};
    if (message.section_title !== "") {
      obj.section_title = message.section_title;
    }
    if (message.items?.length) {
      obj.items = message.items.map((e) => ProductsScanHistoryItem.toJSON(e));
    }
    return obj;
  },
};

export const ProductsScanHistoryItem = {
  fromJSON(object: any): ProductsScanHistoryItem {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      qualification: isSet(object.qualification)
        ? productQualificationFromJSON(object.qualification)
        : ProductQualification.PRODUCT_QUALIFICATION_NONE,
      qualification_title: isSet(object.qualification_title) ? globalThis.String(object.qualification_title) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
      time_ago: isSet(object.time_ago) ? globalThis.String(object.time_ago) : "",
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
      is_disabled: isSet(object.is_disabled) ? globalThis.Boolean(object.is_disabled) : false,
      footer_title: isSet(object.footer_title) ? globalThis.String(object.footer_title) : "",
      pora_score: isSet(object.pora_score) ? ProductPoraScore.fromJSON(object.pora_score) : undefined,
      image_content_mode: isSet(object.image_content_mode)
        ? imageContentModeFromJSON(object.image_content_mode)
        : ImageContentMode.MODE_NONE,
      tags: globalThis.Array.isArray(object?.tags)
        ? object.tags.map((e: any) => Tag.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductsScanHistoryItem): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.qualification !== ProductQualification.PRODUCT_QUALIFICATION_NONE) {
      obj.qualification = productQualificationToJSON(message.qualification);
    }
    if (message.qualification_title !== "") {
      obj.qualification_title = message.qualification_title;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    if (message.time_ago !== "") {
      obj.time_ago = message.time_ago;
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    if (message.is_disabled !== false) {
      obj.is_disabled = message.is_disabled;
    }
    if (message.footer_title !== "") {
      obj.footer_title = message.footer_title;
    }
    if (message.pora_score !== undefined) {
      obj.pora_score = ProductPoraScore.toJSON(message.pora_score);
    }
    if (message.image_content_mode !== ImageContentMode.MODE_NONE) {
      obj.image_content_mode = imageContentModeToJSON(message.image_content_mode);
    }
    if (message.tags?.length) {
      obj.tags = message.tags.map((e) => Tag.toJSON(e));
    }
    return obj;
  },
};

export const ProductScreen = {
  fromJSON(object: any): ProductScreen {
    return {
      sections: globalThis.Array.isArray(object?.sections)
        ? object.sections.map((e: any) => ProductScreen_Section.fromJSON(e))
        : [],
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      buttons: globalThis.Array.isArray(object?.buttons)
        ? object.buttons.map((e: any) => ProductScreen_Button.fromJSON(e))
        : [],
      is_go_home_available: isSet(object.is_go_home_available)
        ? globalThis.Boolean(object.is_go_home_available)
        : false,
      go_home_post_action: isSet(object.go_home_post_action)
        ? ActionType.fromJSON(object.go_home_post_action)
        : undefined,
      product_id: isSet(object.product_id) ? globalThis.String(object.product_id) : "",
      analytics_data: isSet(object.analytics_data)
        ? ProductScreen_AnalyticsData.fromJSON(object.analytics_data)
        : undefined,
      is_closable_by_swipe: isSet(object.is_closable_by_swipe)
        ? globalThis.Boolean(object.is_closable_by_swipe)
        : false,
      snack_bar: isSet(object.snack_bar) ? SnackBar.fromJSON(object.snack_bar) : undefined,
      assistant_button: isSet(object.assistant_button) ? ImageButton.fromJSON(object.assistant_button) : undefined,
      alternativesScreen: isSet(object.alternativesScreen)
        ? ProductAlternativesScreen.fromJSON(object.alternativesScreen)
        : undefined,
      report_quiz_id: isSet(object.report_quiz_id) ? globalThis.String(object.report_quiz_id) : "",
      report_action: isSet(object.report_action) ? ActionType.fromJSON(object.report_action) : undefined,
      generation_timestamp: isSet(object.generation_timestamp) ? globalThis.String(object.generation_timestamp) : "",
    };
  },

  toJSON(message: ProductScreen): unknown {
    const obj: any = {};
    if (message.sections?.length) {
      obj.sections = message.sections.map((e) => ProductScreen_Section.toJSON(e));
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.buttons?.length) {
      obj.buttons = message.buttons.map((e) => ProductScreen_Button.toJSON(e));
    }
    if (message.is_go_home_available !== false) {
      obj.is_go_home_available = message.is_go_home_available;
    }
    if (message.go_home_post_action !== undefined) {
      obj.go_home_post_action = ActionType.toJSON(message.go_home_post_action);
    }
    if (message.product_id !== "") {
      obj.product_id = message.product_id;
    }
    if (message.analytics_data !== undefined) {
      obj.analytics_data = ProductScreen_AnalyticsData.toJSON(message.analytics_data);
    }
    if (message.is_closable_by_swipe !== false) {
      obj.is_closable_by_swipe = message.is_closable_by_swipe;
    }
    if (message.snack_bar !== undefined) {
      obj.snack_bar = SnackBar.toJSON(message.snack_bar);
    }
    if (message.assistant_button !== undefined) {
      obj.assistant_button = ImageButton.toJSON(message.assistant_button);
    }
    if (message.alternativesScreen !== undefined) {
      obj.alternativesScreen = ProductAlternativesScreen.toJSON(message.alternativesScreen);
    }
    if (message.report_quiz_id !== "") {
      obj.report_quiz_id = message.report_quiz_id;
    }
    if (message.report_action !== undefined) {
      obj.report_action = ActionType.toJSON(message.report_action);
    }
    if (message.generation_timestamp !== "") {
      obj.generation_timestamp = message.generation_timestamp;
    }
    return obj;
  },
};

export const ProductScreen_Section = {
  fromJSON(object: any): ProductScreen_Section {
    return {
      section: isSet(object.header)
        ? { $case: "header", header: ProductScreen_Section_Header.fromJSON(object.header) }
        : isSet(object.ratings_and_prices)
        ? {
          $case: "ratings_and_prices",
          ratings_and_prices: ProductScreen_Section_RatingsAndPrices.fromJSON(object.ratings_and_prices),
        }
        : isSet(object.info)
        ? { $case: "info", info: ProductScreen_Section_Info.fromJSON(object.info) }
        : isSet(object.list_with_icons)
        ? {
          $case: "list_with_icons",
          list_with_icons: ProductScreen_Section_ListWithIcons.fromJSON(object.list_with_icons),
        }
        : isSet(object.skin_id_match)
        ? { $case: "skin_id_match", skin_id_match: ProductScreen_Section_SkinIDMatch.fromJSON(object.skin_id_match) }
        : isSet(object.video_tutorial)
        ? {
          $case: "video_tutorial",
          video_tutorial: ProductScreen_Section_VideoTutorial.fromJSON(object.video_tutorial),
        }
        : isSet(object.alternatives)
        ? { $case: "alternatives", alternatives: ProductScreen_Section_Alternatives.fromJSON(object.alternatives) }
        : isSet(object.tiles_header)
        ? { $case: "tiles_header", tiles_header: ProductScreen_Section_TilesHeader.fromJSON(object.tiles_header) }
        : isSet(object.key_functions)
        ? { $case: "key_functions", key_functions: ProductScreen_Section_KeyFunctions.fromJSON(object.key_functions) }
        : isSet(object.ingredients)
        ? { $case: "ingredients", ingredients: ProductScreen_Section_Ingredients.fromJSON(object.ingredients) }
        : isSet(object.DEPRECATED_header)
        ? {
          $case: "DEPRECATED_header",
          DEPRECATED_header: ProductScreen_Section_Header.fromJSON(object.DEPRECATED_header),
        }
        : isSet(object.people_say)
        ? { $case: "people_say", people_say: ProductScreen_Section_PeopleSay.fromJSON(object.people_say) }
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section): unknown {
    const obj: any = {};
    if (message.section?.$case === "header") {
      obj.header = ProductScreen_Section_Header.toJSON(message.section.header);
    }
    if (message.section?.$case === "ratings_and_prices") {
      obj.ratings_and_prices = ProductScreen_Section_RatingsAndPrices.toJSON(message.section.ratings_and_prices);
    }
    if (message.section?.$case === "info") {
      obj.info = ProductScreen_Section_Info.toJSON(message.section.info);
    }
    if (message.section?.$case === "list_with_icons") {
      obj.list_with_icons = ProductScreen_Section_ListWithIcons.toJSON(message.section.list_with_icons);
    }
    if (message.section?.$case === "skin_id_match") {
      obj.skin_id_match = ProductScreen_Section_SkinIDMatch.toJSON(message.section.skin_id_match);
    }
    if (message.section?.$case === "video_tutorial") {
      obj.video_tutorial = ProductScreen_Section_VideoTutorial.toJSON(message.section.video_tutorial);
    }
    if (message.section?.$case === "alternatives") {
      obj.alternatives = ProductScreen_Section_Alternatives.toJSON(message.section.alternatives);
    }
    if (message.section?.$case === "tiles_header") {
      obj.tiles_header = ProductScreen_Section_TilesHeader.toJSON(message.section.tiles_header);
    }
    if (message.section?.$case === "key_functions") {
      obj.key_functions = ProductScreen_Section_KeyFunctions.toJSON(message.section.key_functions);
    }
    if (message.section?.$case === "ingredients") {
      obj.ingredients = ProductScreen_Section_Ingredients.toJSON(message.section.ingredients);
    }
    if (message.section?.$case === "DEPRECATED_header") {
      obj.DEPRECATED_header = ProductScreen_Section_Header.toJSON(message.section.DEPRECATED_header);
    }
    if (message.section?.$case === "people_say") {
      obj.people_say = ProductScreen_Section_PeopleSay.toJSON(message.section.people_say);
    }
    return obj;
  },
};

export const ProductScreen_Section_Header = {
  fromJSON(object: any): ProductScreen_Section_Header {
    return {
      image_urls: globalThis.Array.isArray(object?.image_urls)
        ? object.image_urls.map((e: any) => globalThis.String(e))
        : [],
      brand: isSet(object.brand) ? globalThis.String(object.brand) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      subtitle: isSet(object.subtitle) ? ProductScreen_Section_Header_Subtitle.fromJSON(object.subtitle) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_Header): unknown {
    const obj: any = {};
    if (message.image_urls?.length) {
      obj.image_urls = message.image_urls;
    }
    if (message.brand !== "") {
      obj.brand = message.brand;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.subtitle !== undefined) {
      obj.subtitle = ProductScreen_Section_Header_Subtitle.toJSON(message.subtitle);
    }
    return obj;
  },
};

export const ProductScreen_Section_Header_Subtitle = {
  fromJSON(object: any): ProductScreen_Section_Header_Subtitle {
    return {
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductScreen_Section_Header_Subtitle_Item.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_Header_Subtitle): unknown {
    const obj: any = {};
    if (message.items?.length) {
      obj.items = message.items.map((e) => ProductScreen_Section_Header_Subtitle_Item.toJSON(e));
    }
    return obj;
  },
};

export const ProductScreen_Section_Header_Subtitle_Item = {
  fromJSON(object: any): ProductScreen_Section_Header_Subtitle_Item {
    return {
      item: isSet(object.text)
        ? { $case: "text", text: globalThis.String(object.text) }
        : isSet(object.rating)
        ? { $case: "rating", rating: globalThis.String(object.rating) }
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_Header_Subtitle_Item): unknown {
    const obj: any = {};
    if (message.item?.$case === "text") {
      obj.text = message.item.text;
    }
    if (message.item?.$case === "rating") {
      obj.rating = message.item.rating;
    }
    return obj;
  },
};

export const ProductScreen_Section_SkinIDMatch = {
  fromJSON(object: any): ProductScreen_Section_SkinIDMatch {
    return {
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductScreen_Section_SkinIDMatch_Item.fromJSON(e))
        : [],
      header: isSet(object.header) ? ProductScreen_Section_SkinIDMatch_Header.fromJSON(object.header) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_SkinIDMatch): unknown {
    const obj: any = {};
    if (message.items?.length) {
      obj.items = message.items.map((e) => ProductScreen_Section_SkinIDMatch_Item.toJSON(e));
    }
    if (message.header !== undefined) {
      obj.header = ProductScreen_Section_SkinIDMatch_Header.toJSON(message.header);
    }
    return obj;
  },
};

export const ProductScreen_Section_SkinIDMatch_Header = {
  fromJSON(object: any): ProductScreen_Section_SkinIDMatch_Header {
    return {
      qualification: isSet(object.qualification)
        ? productQualificationFromJSON(object.qualification)
        : ProductQualification.PRODUCT_QUALIFICATION_NONE,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      message: isSet(object.message) ? globalThis.String(object.message) : "",
    };
  },

  toJSON(message: ProductScreen_Section_SkinIDMatch_Header): unknown {
    const obj: any = {};
    if (message.qualification !== ProductQualification.PRODUCT_QUALIFICATION_NONE) {
      obj.qualification = productQualificationToJSON(message.qualification);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },
};

export const ProductScreen_Section_SkinIDMatch_Item = {
  fromJSON(object: any): ProductScreen_Section_SkinIDMatch_Item {
    return {
      qualification: isSet(object.qualification)
        ? productQualificationFromJSON(object.qualification)
        : ProductQualification.PRODUCT_QUALIFICATION_NONE,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      content: isSet(object.content)
        ? ProductScreen_Section_SkinIDMatch_Item_Content.fromJSON(object.content)
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_SkinIDMatch_Item): unknown {
    const obj: any = {};
    if (message.qualification !== ProductQualification.PRODUCT_QUALIFICATION_NONE) {
      obj.qualification = productQualificationToJSON(message.qualification);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.content !== undefined) {
      obj.content = ProductScreen_Section_SkinIDMatch_Item_Content.toJSON(message.content);
    }
    return obj;
  },
};

export const ProductScreen_Section_SkinIDMatch_Item_Content = {
  fromJSON(object: any): ProductScreen_Section_SkinIDMatch_Item_Content {
    return {
      content: isSet(object.list)
        ? { $case: "list", list: ProductScreen_Section_SkinIDMatch_Item_Content_List.fromJSON(object.list) }
        : isSet(object.banners)
        ? { $case: "banners", banners: ProductScreen_Section_SkinIDMatch_Item_Content_Banners.fromJSON(object.banners) }
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_SkinIDMatch_Item_Content): unknown {
    const obj: any = {};
    if (message.content?.$case === "list") {
      obj.list = ProductScreen_Section_SkinIDMatch_Item_Content_List.toJSON(message.content.list);
    }
    if (message.content?.$case === "banners") {
      obj.banners = ProductScreen_Section_SkinIDMatch_Item_Content_Banners.toJSON(message.content.banners);
    }
    return obj;
  },
};

export const ProductScreen_Section_SkinIDMatch_Item_Content_List = {
  fromJSON(object: any): ProductScreen_Section_SkinIDMatch_Item_Content_List {
    return {
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductScreen_Section_SkinIDMatch_Item_Content_List_Item.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_SkinIDMatch_Item_Content_List): unknown {
    const obj: any = {};
    if (message.items?.length) {
      obj.items = message.items.map((e) => ProductScreen_Section_SkinIDMatch_Item_Content_List_Item.toJSON(e));
    }
    return obj;
  },
};

export const ProductScreen_Section_SkinIDMatch_Item_Content_List_Item = {
  fromJSON(object: any): ProductScreen_Section_SkinIDMatch_Item_Content_List_Item {
    return {
      icon: isSet(object.icon) ? globalThis.String(object.icon) : "",
      text: isSet(object.text) ? globalThis.String(object.text) : "",
    };
  },

  toJSON(message: ProductScreen_Section_SkinIDMatch_Item_Content_List_Item): unknown {
    const obj: any = {};
    if (message.icon !== "") {
      obj.icon = message.icon;
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    return obj;
  },
};

export const ProductScreen_Section_SkinIDMatch_Item_Content_Banners = {
  fromJSON(object: any): ProductScreen_Section_SkinIDMatch_Item_Content_Banners {
    return {
      banners: globalThis.Array.isArray(object?.banners)
        ? object.banners.map((e: any) => ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_SkinIDMatch_Item_Content_Banners): unknown {
    const obj: any = {};
    if (message.banners?.length) {
      obj.banners = message.banners.map((e) => ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner.toJSON(e));
    }
    return obj;
  },
};

export const ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner = {
  fromJSON(object: any): ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.items?.length) {
      obj.items = message.items.map((e) =>
        ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item.toJSON(e)
      );
    }
    return obj;
  },
};

export const ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item = {
  fromJSON(object: any): ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item {
    return {
      icon: isSet(object.icon) ? globalThis.String(object.icon) : "",
      text: isSet(object.text) ? globalThis.String(object.text) : "",
    };
  },

  toJSON(message: ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item): unknown {
    const obj: any = {};
    if (message.icon !== "") {
      obj.icon = message.icon;
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    return obj;
  },
};

export const ProductScreen_Section_RatingsAndPrices = {
  fromJSON(object: any): ProductScreen_Section_RatingsAndPrices {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      average_rating: isSet(object.average_rating)
        ? ProductScreen_Section_RatingsAndPrices_AverageRating.fromJSON(object.average_rating)
        : undefined,
      stores: globalThis.Array.isArray(object?.stores)
        ? object.stores.map((e: any) => ProductScreen_Section_RatingsAndPrices_Store.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_RatingsAndPrices): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.average_rating !== undefined) {
      obj.average_rating = ProductScreen_Section_RatingsAndPrices_AverageRating.toJSON(message.average_rating);
    }
    if (message.stores?.length) {
      obj.stores = message.stores.map((e) => ProductScreen_Section_RatingsAndPrices_Store.toJSON(e));
    }
    return obj;
  },
};

export const ProductScreen_Section_RatingsAndPrices_AverageRating = {
  fromJSON(object: any): ProductScreen_Section_RatingsAndPrices_AverageRating {
    return {
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
    };
  },

  toJSON(message: ProductScreen_Section_RatingsAndPrices_AverageRating): unknown {
    const obj: any = {};
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    return obj;
  },
};

export const ProductScreen_Section_RatingsAndPrices_Store = {
  fromJSON(object: any): ProductScreen_Section_RatingsAndPrices_Store {
    return {
      image_url: isSet(object.image_url) ? globalThis.String(object.image_url) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      price: isSet(object.price) ? globalThis.String(object.price) : "",
      DEPRECATED_rating: isSet(object.DEPRECATED_rating) ? globalThis.String(object.DEPRECATED_rating) : "",
      DEPRECATED_rating_subtitle: isSet(object.DEPRECATED_rating_subtitle)
        ? globalThis.String(object.DEPRECATED_rating_subtitle)
        : "",
      web_page_url: isSet(object.web_page_url) ? globalThis.String(object.web_page_url) : "",
      qualification: isSet(object.rating)
        ? { $case: "rating", rating: ProductScreen_Section_RatingsAndPrices_Store_Rating.fromJSON(object.rating) }
        : isSet(object.text)
        ? { $case: "text", text: globalThis.String(object.text) }
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_RatingsAndPrices_Store): unknown {
    const obj: any = {};
    if (message.image_url !== "") {
      obj.image_url = message.image_url;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.price !== "") {
      obj.price = message.price;
    }
    if (message.DEPRECATED_rating !== "") {
      obj.DEPRECATED_rating = message.DEPRECATED_rating;
    }
    if (message.DEPRECATED_rating_subtitle !== "") {
      obj.DEPRECATED_rating_subtitle = message.DEPRECATED_rating_subtitle;
    }
    if (message.web_page_url !== "") {
      obj.web_page_url = message.web_page_url;
    }
    if (message.qualification?.$case === "rating") {
      obj.rating = ProductScreen_Section_RatingsAndPrices_Store_Rating.toJSON(message.qualification.rating);
    }
    if (message.qualification?.$case === "text") {
      obj.text = message.qualification.text;
    }
    return obj;
  },
};

export const ProductScreen_Section_RatingsAndPrices_Store_Rating = {
  fromJSON(object: any): ProductScreen_Section_RatingsAndPrices_Store_Rating {
    return {
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
      fade: isSet(object.fade) ? globalThis.Boolean(object.fade) : false,
    };
  },

  toJSON(message: ProductScreen_Section_RatingsAndPrices_Store_Rating): unknown {
    const obj: any = {};
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    if (message.fade !== false) {
      obj.fade = message.fade;
    }
    return obj;
  },
};

export const ProductScreen_Section_Info = {
  fromJSON(object: any): ProductScreen_Section_Info {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      DEPRECATED_info: isSet(object.DEPRECATED_info) ? globalThis.String(object.DEPRECATED_info) : "",
      attributed_info: isSet(object.attributed_info) ? AttributedString.fromJSON(object.attributed_info) : undefined,
      lazy_request_data: isSet(object.lazy_request_data)
        ? LazyProductSectionRequestData.fromJSON(object.lazy_request_data)
        : undefined,
      button: isSet(object.button) ? ProductScreen_Section_Info_Button.fromJSON(object.button) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_Info): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.DEPRECATED_info !== "") {
      obj.DEPRECATED_info = message.DEPRECATED_info;
    }
    if (message.attributed_info !== undefined) {
      obj.attributed_info = AttributedString.toJSON(message.attributed_info);
    }
    if (message.lazy_request_data !== undefined) {
      obj.lazy_request_data = LazyProductSectionRequestData.toJSON(message.lazy_request_data);
    }
    if (message.button !== undefined) {
      obj.button = ProductScreen_Section_Info_Button.toJSON(message.button);
    }
    return obj;
  },
};

export const ProductScreen_Section_Info_Button = {
  fromJSON(object: any): ProductScreen_Section_Info_Button {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      info_screen: isSet(object.info_screen) ? ProductInfoScreen.fromJSON(object.info_screen) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_Info_Button): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.info_screen !== undefined) {
      obj.info_screen = ProductInfoScreen.toJSON(message.info_screen);
    }
    return obj;
  },
};

export const ProductScreen_Section_ListWithIcons = {
  fromJSON(object: any): ProductScreen_Section_ListWithIcons {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductScreen_Section_ListWithIcons_Item.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_ListWithIcons): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.items?.length) {
      obj.items = message.items.map((e) => ProductScreen_Section_ListWithIcons_Item.toJSON(e));
    }
    return obj;
  },
};

export const ProductScreen_Section_ListWithIcons_Item = {
  fromJSON(object: any): ProductScreen_Section_ListWithIcons_Item {
    return {
      icon: isSet(object.icon) ? globalThis.String(object.icon) : "",
      text: isSet(object.text) ? globalThis.String(object.text) : "",
    };
  },

  toJSON(message: ProductScreen_Section_ListWithIcons_Item): unknown {
    const obj: any = {};
    if (message.icon !== "") {
      obj.icon = message.icon;
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    return obj;
  },
};

export const ProductScreen_Section_VideoTutorial = {
  fromJSON(object: any): ProductScreen_Section_VideoTutorial {
    return {
      section_title: isSet(object.section_title) ? globalThis.String(object.section_title) : "",
      tutorial_title: isSet(object.tutorial_title) ? globalThis.String(object.tutorial_title) : "",
      image_url: isSet(object.image_url) ? globalThis.String(object.image_url) : "",
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => Tag.fromJSON(e)) : [],
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_VideoTutorial): unknown {
    const obj: any = {};
    if (message.section_title !== "") {
      obj.section_title = message.section_title;
    }
    if (message.tutorial_title !== "") {
      obj.tutorial_title = message.tutorial_title;
    }
    if (message.image_url !== "") {
      obj.image_url = message.image_url;
    }
    if (message.tags?.length) {
      obj.tags = message.tags.map((e) => Tag.toJSON(e));
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    return obj;
  },
};

export const ProductScreen_Section_PeopleSay = {
  fromJSON(object: any): ProductScreen_Section_PeopleSay {
    return {
      section_title: isSet(object.section_title) ? globalThis.String(object.section_title) : "",
      rating: isSet(object.rating) ? AttributedString.fromJSON(object.rating) : undefined,
      rating_description: isSet(object.rating_description)
        ? AttributedString.fromJSON(object.rating_description)
        : undefined,
      description_title: isSet(object.description_title)
        ? AttributedString.fromJSON(object.description_title)
        : undefined,
      good_reviews: isSet(object.good_reviews)
        ? ProductScreen_Section_PeopleSay_Review.fromJSON(object.good_reviews)
        : undefined,
      bad_reviews: isSet(object.bad_reviews)
        ? ProductScreen_Section_PeopleSay_Review.fromJSON(object.bad_reviews)
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_PeopleSay): unknown {
    const obj: any = {};
    if (message.section_title !== "") {
      obj.section_title = message.section_title;
    }
    if (message.rating !== undefined) {
      obj.rating = AttributedString.toJSON(message.rating);
    }
    if (message.rating_description !== undefined) {
      obj.rating_description = AttributedString.toJSON(message.rating_description);
    }
    if (message.description_title !== undefined) {
      obj.description_title = AttributedString.toJSON(message.description_title);
    }
    if (message.good_reviews !== undefined) {
      obj.good_reviews = ProductScreen_Section_PeopleSay_Review.toJSON(message.good_reviews);
    }
    if (message.bad_reviews !== undefined) {
      obj.bad_reviews = ProductScreen_Section_PeopleSay_Review.toJSON(message.bad_reviews);
    }
    return obj;
  },
};

export const ProductScreen_Section_PeopleSay_Review = {
  fromJSON(object: any): ProductScreen_Section_PeopleSay_Review {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      reviews: globalThis.Array.isArray(object?.reviews)
        ? object.reviews.map((e: any) => AttributedString.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_PeopleSay_Review): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.reviews?.length) {
      obj.reviews = message.reviews.map((e) => AttributedString.toJSON(e));
    }
    return obj;
  },
};

export const ProductScreen_Section_Alternatives = {
  fromJSON(object: any): ProductScreen_Section_Alternatives {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      cards: globalThis.Array.isArray(object?.cards)
        ? object.cards.map((e: any) => ProductScreen_Section_Alternatives_Card.fromJSON(e))
        : [],
      lazy_request_data: isSet(object.lazy_request_data)
        ? LazyProductSectionRequestData.fromJSON(object.lazy_request_data)
        : undefined,
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
    };
  },

  toJSON(message: ProductScreen_Section_Alternatives): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.cards?.length) {
      obj.cards = message.cards.map((e) => ProductScreen_Section_Alternatives_Card.toJSON(e));
    }
    if (message.lazy_request_data !== undefined) {
      obj.lazy_request_data = LazyProductSectionRequestData.toJSON(message.lazy_request_data);
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    return obj;
  },
};

export const ProductScreen_Section_Alternatives_Card = {
  fromJSON(object: any): ProductScreen_Section_Alternatives_Card {
    return {
      brand: isSet(object.brand) ? globalThis.String(object.brand) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      price: isSet(object.price) ? globalThis.String(object.price) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      tags: globalThis.Array.isArray(object?.tags)
        ? object.tags.map((e: any) => Tag.fromJSON(e))
        : [],
      product_screen: isSet(object.product_screen) ? ProductScreen.fromJSON(object.product_screen) : undefined,
      pora_score: isSet(object.pora_score) ? ProductPoraScore.fromJSON(object.pora_score) : undefined,
      save_button: isSet(object.save_button) ? ImageButton.fromJSON(object.save_button) : undefined,
      should_show_save_loading: isSet(object.should_show_save_loading)
        ? globalThis.Boolean(object.should_show_save_loading)
        : false,
      should_disable_save_button: isSet(object.should_disable_save_button)
        ? globalThis.Boolean(object.should_disable_save_button)
        : false,
      product_id: isSet(object.product_id) ? globalThis.String(object.product_id) : "",
    };
  },

  toJSON(message: ProductScreen_Section_Alternatives_Card): unknown {
    const obj: any = {};
    if (message.brand !== "") {
      obj.brand = message.brand;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.price !== "") {
      obj.price = message.price;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.tags?.length) {
      obj.tags = message.tags.map((e) => Tag.toJSON(e));
    }
    if (message.product_screen !== undefined) {
      obj.product_screen = ProductScreen.toJSON(message.product_screen);
    }
    if (message.pora_score !== undefined) {
      obj.pora_score = ProductPoraScore.toJSON(message.pora_score);
    }
    if (message.save_button !== undefined) {
      obj.save_button = ImageButton.toJSON(message.save_button);
    }
    if (message.should_show_save_loading !== false) {
      obj.should_show_save_loading = message.should_show_save_loading;
    }
    if (message.should_disable_save_button !== false) {
      obj.should_disable_save_button = message.should_disable_save_button;
    }
    if (message.product_id !== "") {
      obj.product_id = message.product_id;
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader {
    return {
      images: isSet(object.images) ? ProductScreen_Section_TilesHeader_Images.fromJSON(object.images) : undefined,
      key_functions: isSet(object.key_functions)
        ? ProductScreen_Section_TilesHeader_KeyFunctions.fromJSON(object.key_functions)
        : undefined,
      title: isSet(object.title) ? ProductScreen_Section_TilesHeader_Title.fromJSON(object.title) : undefined,
      shortcuts: globalThis.Array.isArray(object?.shortcuts)
        ? object.shortcuts.map((e: any) => ProductScreen_Section_TilesHeader_Shortcut.fromJSON(e))
        : [],
      shortcut_rows: isSet(object.shortcut_rows)
        ? ProductScreen_Section_TilesHeader_Shortcuts.fromJSON(object.shortcut_rows)
        : undefined,
      product_id: isSet(object.product_id) ? globalThis.String(object.product_id) : "",
      pora_score: isSet(object.pora_score)
        ? ProductScreen_Section_TilesHeader_Score.fromJSON(object.pora_score)
        : undefined,
      text_review: isSet(object.text_review)
        ? ProductScreen_Section_TilesHeader_TextReview.fromJSON(object.text_review)
        : undefined,
      assistant_summary: isSet(object.assistant_summary)
        ? ProductScreen_Section_TilesHeader_AssistantSummary.fromJSON(object.assistant_summary)
        : undefined,
      grid: isSet(object.grid) ? ProductScreen_Section_TilesHeader_Grid.fromJSON(object.grid) : undefined,
      claim: isSet(object.claim) ? ProductScreen_Section_TilesHeader_ClaimBlock.fromJSON(object.claim) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader): unknown {
    const obj: any = {};
    if (message.images !== undefined) {
      obj.images = ProductScreen_Section_TilesHeader_Images.toJSON(message.images);
    }
    if (message.key_functions !== undefined) {
      obj.key_functions = ProductScreen_Section_TilesHeader_KeyFunctions.toJSON(message.key_functions);
    }
    if (message.title !== undefined) {
      obj.title = ProductScreen_Section_TilesHeader_Title.toJSON(message.title);
    }
    if (message.shortcuts?.length) {
      obj.shortcuts = message.shortcuts.map((e) => ProductScreen_Section_TilesHeader_Shortcut.toJSON(e));
    }
    if (message.shortcut_rows !== undefined) {
      obj.shortcut_rows = ProductScreen_Section_TilesHeader_Shortcuts.toJSON(message.shortcut_rows);
    }
    if (message.product_id !== "") {
      obj.product_id = message.product_id;
    }
    if (message.pora_score !== undefined) {
      obj.pora_score = ProductScreen_Section_TilesHeader_Score.toJSON(message.pora_score);
    }
    if (message.text_review !== undefined) {
      obj.text_review = ProductScreen_Section_TilesHeader_TextReview.toJSON(message.text_review);
    }
    if (message.assistant_summary !== undefined) {
      obj.assistant_summary = ProductScreen_Section_TilesHeader_AssistantSummary.toJSON(message.assistant_summary);
    }
    if (message.grid !== undefined) {
      obj.grid = ProductScreen_Section_TilesHeader_Grid.toJSON(message.grid);
    }
    if (message.claim !== undefined) {
      obj.claim = ProductScreen_Section_TilesHeader_ClaimBlock.toJSON(message.claim);
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_ClaimBlock = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_ClaimBlock {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      tags: globalThis.Array.isArray(object?.tags)
        ? object.tags.map((e: any) => ProductScreen_Section_TilesHeader_ClaimBlock_Tag.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_ClaimBlock): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.tags?.length) {
      obj.tags = message.tags.map((e) => ProductScreen_Section_TilesHeader_ClaimBlock_Tag.toJSON(e));
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_ClaimBlock_Tag = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_ClaimBlock_Tag {
    return {
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      should_decorate_with_leaves: isSet(object.should_decorate_with_leaves)
        ? globalThis.Boolean(object.should_decorate_with_leaves)
        : false,
      accent_color: isSet(object.accent_color) ? globalThis.String(object.accent_color) : "",
      details: isSet(object.details)
        ? ProductScreen_Section_TilesHeader_ClaimBlock_Tag_Details.fromJSON(object.details)
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_ClaimBlock_Tag): unknown {
    const obj: any = {};
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.should_decorate_with_leaves !== false) {
      obj.should_decorate_with_leaves = message.should_decorate_with_leaves;
    }
    if (message.accent_color !== "") {
      obj.accent_color = message.accent_color;
    }
    if (message.details !== undefined) {
      obj.details = ProductScreen_Section_TilesHeader_ClaimBlock_Tag_Details.toJSON(message.details);
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_ClaimBlock_Tag_Details = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_ClaimBlock_Tag_Details {
    return {
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      accent_color: isSet(object.accent_color) ? globalThis.String(object.accent_color) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      section_title: isSet(object.section_title) ? globalThis.String(object.section_title) : "",
      section_description: isSet(object.section_description) ? globalThis.String(object.section_description) : "",
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_ClaimBlock_Tag_Details): unknown {
    const obj: any = {};
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.accent_color !== "") {
      obj.accent_color = message.accent_color;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.section_title !== "") {
      obj.section_title = message.section_title;
    }
    if (message.section_description !== "") {
      obj.section_description = message.section_description;
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_TextReview = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_TextReview {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      text: isSet(object.text) ? globalThis.String(object.text) : "",
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
      lazy_request: isSet(object.lazy_request)
        ? ProductScreenHeaderQuickSummaryRequest.fromJSON(object.lazy_request)
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_TextReview): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    if (message.lazy_request !== undefined) {
      obj.lazy_request = ProductScreenHeaderQuickSummaryRequest.toJSON(message.lazy_request);
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_Score = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Score {
    return {
      DEPRECATED_score: isSet(object.DEPRECATED_score) ? ProductPoraScore.fromJSON(object.DEPRECATED_score) : undefined,
      info_screen: isSet(object.info_screen) ? ProductPoraScoreInfoScreen.fromJSON(object.info_screen) : undefined,
      should_load: isSet(object.should_load) ? globalThis.Boolean(object.should_load) : false,
      score_info_screen: isSet(object.score_info_screen)
        ? ProductFitScoreInfoScreen.fromJSON(object.score_info_screen)
        : undefined,
      score: isSet(object.score) ? ProductScreen_Section_TilesHeader_ProductScore.fromJSON(object.score) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Score): unknown {
    const obj: any = {};
    if (message.DEPRECATED_score !== undefined) {
      obj.DEPRECATED_score = ProductPoraScore.toJSON(message.DEPRECATED_score);
    }
    if (message.info_screen !== undefined) {
      obj.info_screen = ProductPoraScoreInfoScreen.toJSON(message.info_screen);
    }
    if (message.should_load !== false) {
      obj.should_load = message.should_load;
    }
    if (message.score_info_screen !== undefined) {
      obj.score_info_screen = ProductFitScoreInfoScreen.toJSON(message.score_info_screen);
    }
    if (message.score !== undefined) {
      obj.score = ProductScreen_Section_TilesHeader_ProductScore.toJSON(message.score);
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_ProductScore = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_ProductScore {
    return {
      fit_for_you_button: isSet(object.fit_for_you_button)
        ? ProductScreen_Section_TilesHeader_ProductScore_FitForYouScore.fromJSON(object.fit_for_you_button)
        : undefined,
      main_tags: isSet(object.main_tags)
        ? ProductScreen_Section_TilesHeader_ProductScore_MainTags.fromJSON(object.main_tags)
        : undefined,
      sub_tags: isSet(object.sub_tags)
        ? ProductScreen_Section_TilesHeader_ProductScore_SubTags.fromJSON(object.sub_tags)
        : undefined,
      background_color: isSet(object.background_color) ? globalThis.String(object.background_color) : "",
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_ProductScore): unknown {
    const obj: any = {};
    if (message.fit_for_you_button !== undefined) {
      obj.fit_for_you_button = ProductScreen_Section_TilesHeader_ProductScore_FitForYouScore.toJSON(
        message.fit_for_you_button,
      );
    }
    if (message.main_tags !== undefined) {
      obj.main_tags = ProductScreen_Section_TilesHeader_ProductScore_MainTags.toJSON(message.main_tags);
    }
    if (message.sub_tags !== undefined) {
      obj.sub_tags = ProductScreen_Section_TilesHeader_ProductScore_SubTags.toJSON(message.sub_tags);
    }
    if (message.background_color !== "") {
      obj.background_color = message.background_color;
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_ProductScore_FitForYouScore = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_ProductScore_FitForYouScore {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      foreground_color: isSet(object.foreground_color) ? globalThis.String(object.foreground_color) : "",
      background_color: isSet(object.background_color) ? globalThis.String(object.background_color) : "",
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_ProductScore_FitForYouScore): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.foreground_color !== "") {
      obj.foreground_color = message.foreground_color;
    }
    if (message.background_color !== "") {
      obj.background_color = message.background_color;
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_ProductScore_MainTags = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_ProductScore_MainTags {
    return {
      is_scrollable: isSet(object.is_scrollable) ? globalThis.Boolean(object.is_scrollable) : false,
      tags: globalThis.Array.isArray(object?.tags)
        ? object.tags.map((e: any) => ProductScreen_Section_TilesHeader_ProductScore_MainTags_Tag.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_ProductScore_MainTags): unknown {
    const obj: any = {};
    if (message.is_scrollable !== false) {
      obj.is_scrollable = message.is_scrollable;
    }
    if (message.tags?.length) {
      obj.tags = message.tags.map((e) => ProductScreen_Section_TilesHeader_ProductScore_MainTags_Tag.toJSON(e));
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_ProductScore_MainTags_Tag = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_ProductScore_MainTags_Tag {
    return {
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
      accent_color: isSet(object.accent_color) ? globalThis.String(object.accent_color) : "",
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_ProductScore_MainTags_Tag): unknown {
    const obj: any = {};
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    if (message.accent_color !== "") {
      obj.accent_color = message.accent_color;
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_ProductScore_SubTags = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_ProductScore_SubTags {
    return {
      is_scrollable: isSet(object.is_scrollable) ? globalThis.Boolean(object.is_scrollable) : false,
      tags: globalThis.Array.isArray(object?.tags)
        ? object.tags.map((e: any) => ProductScreen_Section_TilesHeader_ProductScore_SubTags_Tag.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_ProductScore_SubTags): unknown {
    const obj: any = {};
    if (message.is_scrollable !== false) {
      obj.is_scrollable = message.is_scrollable;
    }
    if (message.tags?.length) {
      obj.tags = message.tags.map((e) => ProductScreen_Section_TilesHeader_ProductScore_SubTags_Tag.toJSON(e));
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_ProductScore_SubTags_Tag = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_ProductScore_SubTags_Tag {
    return {
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      accent_color: isSet(object.accent_color) ? globalThis.String(object.accent_color) : "",
      background_color: isSet(object.background_color) ? globalThis.String(object.background_color) : "",
      title_color: isSet(object.title_color) ? globalThis.String(object.title_color) : "",
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_ProductScore_SubTags_Tag): unknown {
    const obj: any = {};
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.accent_color !== "") {
      obj.accent_color = message.accent_color;
    }
    if (message.background_color !== "") {
      obj.background_color = message.background_color;
    }
    if (message.title_color !== "") {
      obj.title_color = message.title_color;
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_Images = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Images {
    return {
      product_image: isSet(object.product_image) ? globalThis.String(object.product_image) : "",
      user_photo_id: isSet(object.user_photo_id) ? globalThis.String(object.user_photo_id) : "",
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Images): unknown {
    const obj: any = {};
    if (message.product_image !== "") {
      obj.product_image = message.product_image;
    }
    if (message.user_photo_id !== "") {
      obj.user_photo_id = message.user_photo_id;
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_KeyFunctions = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_KeyFunctions {
    return {
      be_aware_tags: globalThis.Array.isArray(object?.be_aware_tags)
        ? object.be_aware_tags.map((e: any) => globalThis.String(e))
        : [],
      good_for_tags: globalThis.Array.isArray(object?.good_for_tags)
        ? object.good_for_tags.map((e: any) => globalThis.String(e))
        : [],
      attributed_be_aware_tags: globalThis.Array.isArray(object?.attributed_be_aware_tags)
        ? object.attributed_be_aware_tags.map((e: any) => AttributedString.fromJSON(e))
        : [],
      attributed_good_for_tags: globalThis.Array.isArray(object?.attributed_good_for_tags)
        ? object.attributed_good_for_tags.map((e: any) => AttributedString.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_KeyFunctions): unknown {
    const obj: any = {};
    if (message.be_aware_tags?.length) {
      obj.be_aware_tags = message.be_aware_tags;
    }
    if (message.good_for_tags?.length) {
      obj.good_for_tags = message.good_for_tags;
    }
    if (message.attributed_be_aware_tags?.length) {
      obj.attributed_be_aware_tags = message.attributed_be_aware_tags.map((e) => AttributedString.toJSON(e));
    }
    if (message.attributed_good_for_tags?.length) {
      obj.attributed_good_for_tags = message.attributed_good_for_tags.map((e) => AttributedString.toJSON(e));
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_Title = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Title {
    return {
      product_type: isSet(object.product_type) ? AttributedString.fromJSON(object.product_type) : undefined,
      text: isSet(object.text) ? AttributedString.fromJSON(object.text) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Title): unknown {
    const obj: any = {};
    if (message.product_type !== undefined) {
      obj.product_type = AttributedString.toJSON(message.product_type);
    }
    if (message.text !== undefined) {
      obj.text = AttributedString.toJSON(message.text);
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_Grid = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Grid {
    return {
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductScreen_Section_TilesHeader_Grid_Item.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Grid): unknown {
    const obj: any = {};
    if (message.items?.length) {
      obj.items = message.items.map((e) => ProductScreen_Section_TilesHeader_Grid_Item.toJSON(e));
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_Grid_Item = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Grid_Item {
    return {
      item: isSet(object.shortcuts_row)
        ? {
          $case: "shortcuts_row",
          shortcuts_row: ProductScreen_Section_TilesHeader_Shortcuts_Row.fromJSON(object.shortcuts_row),
        }
        : isSet(object.assistant_summary)
        ? {
          $case: "assistant_summary",
          assistant_summary: ProductScreen_Section_TilesHeader_AssistantSummary.fromJSON(object.assistant_summary),
        }
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Grid_Item): unknown {
    const obj: any = {};
    if (message.item?.$case === "shortcuts_row") {
      obj.shortcuts_row = ProductScreen_Section_TilesHeader_Shortcuts_Row.toJSON(message.item.shortcuts_row);
    }
    if (message.item?.$case === "assistant_summary") {
      obj.assistant_summary = ProductScreen_Section_TilesHeader_AssistantSummary.toJSON(message.item.assistant_summary);
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_Shortcuts = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcuts {
    return {
      rows: globalThis.Array.isArray(object?.rows)
        ? object.rows.map((e: any) => ProductScreen_Section_TilesHeader_Shortcuts_Row.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcuts): unknown {
    const obj: any = {};
    if (message.rows?.length) {
      obj.rows = message.rows.map((e) => ProductScreen_Section_TilesHeader_Shortcuts_Row.toJSON(e));
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_Shortcuts_Row = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcuts_Row {
    return {
      shortcuts: globalThis.Array.isArray(object?.shortcuts)
        ? object.shortcuts.map((e: any) => ProductScreen_Section_TilesHeader_Shortcut.fromJSON(e))
        : [],
      style: isSet(object.style)
        ? productScreen_Section_TilesHeader_Shortcuts_Row_StyleFromJSON(object.style)
        : ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.STYLE_NONE,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcuts_Row): unknown {
    const obj: any = {};
    if (message.shortcuts?.length) {
      obj.shortcuts = message.shortcuts.map((e) => ProductScreen_Section_TilesHeader_Shortcut.toJSON(e));
    }
    if (message.style !== ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.STYLE_NONE) {
      obj.style = productScreen_Section_TilesHeader_Shortcuts_Row_StyleToJSON(message.style);
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_Shortcut = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut {
    return {
      shortcut: isSet(object.empty)
        ? { $case: "empty", empty: ProductScreen_Section_TilesHeader_Shortcut_Empty.fromJSON(object.empty) }
        : isSet(object.content)
        ? { $case: "content", content: ProductScreen_Section_TilesHeader_Shortcut_Content.fromJSON(object.content) }
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut): unknown {
    const obj: any = {};
    if (message.shortcut?.$case === "empty") {
      obj.empty = ProductScreen_Section_TilesHeader_Shortcut_Empty.toJSON(message.shortcut.empty);
    }
    if (message.shortcut?.$case === "content") {
      obj.content = ProductScreen_Section_TilesHeader_Shortcut_Content.toJSON(message.shortcut.content);
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_Shortcut_Empty = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut_Empty {
    return { message: isSet(object.message) ? globalThis.String(object.message) : "" };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut_Empty): unknown {
    const obj: any = {};
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_Shortcut_Content = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut_Content {
    return {
      content: isSet(object.rating)
        ? { $case: "rating", rating: ProductScreen_Section_TilesHeader_Shortcut_Content_Rating.fromJSON(object.rating) }
        : isSet(object.tutorial)
        ? {
          $case: "tutorial",
          tutorial: ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial.fromJSON(object.tutorial),
        }
        : isSet(object.prices)
        ? { $case: "prices", prices: ProductScreen_Section_TilesHeader_Shortcut_Content_Prices.fromJSON(object.prices) }
        : isSet(object.alternatives)
        ? {
          $case: "alternatives",
          alternatives: ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives.fromJSON(object.alternatives),
        }
        : isSet(object.ingredients)
        ? {
          $case: "ingredients",
          ingredients: ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients.fromJSON(object.ingredients),
        }
        : isSet(object.reviewSummary)
        ? {
          $case: "reviewSummary",
          reviewSummary: ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary.fromJSON(
            object.reviewSummary,
          ),
        }
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut_Content): unknown {
    const obj: any = {};
    if (message.content?.$case === "rating") {
      obj.rating = ProductScreen_Section_TilesHeader_Shortcut_Content_Rating.toJSON(message.content.rating);
    }
    if (message.content?.$case === "tutorial") {
      obj.tutorial = ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial.toJSON(message.content.tutorial);
    }
    if (message.content?.$case === "prices") {
      obj.prices = ProductScreen_Section_TilesHeader_Shortcut_Content_Prices.toJSON(message.content.prices);
    }
    if (message.content?.$case === "alternatives") {
      obj.alternatives = ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives.toJSON(
        message.content.alternatives,
      );
    }
    if (message.content?.$case === "ingredients") {
      obj.ingredients = ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients.toJSON(
        message.content.ingredients,
      );
    }
    if (message.content?.$case === "reviewSummary") {
      obj.reviewSummary = ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary.toJSON(
        message.content.reviewSummary,
      );
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_Shortcut_Content_Rating = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut_Content_Rating {
    return {
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      accented: isSet(object.accented) ? globalThis.Boolean(object.accented) : false,
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
      info_screen: isSet(object.info_screen) ? ProductStoresInfoScreen.fromJSON(object.info_screen) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut_Content_Rating): unknown {
    const obj: any = {};
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.accented !== false) {
      obj.accented = message.accented;
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    if (message.info_screen !== undefined) {
      obj.info_screen = ProductStoresInfoScreen.toJSON(message.info_screen);
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial {
    return {
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      tag: isSet(object.tag) ? Tag.fromJSON(object.tag) : undefined,
      video_title: isSet(object.video_title) ? globalThis.String(object.video_title) : "",
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial): unknown {
    const obj: any = {};
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.tag !== undefined) {
      obj.tag = Tag.toJSON(message.tag);
    }
    if (message.video_title !== "") {
      obj.video_title = message.video_title;
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_Shortcut_Content_Prices = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut_Content_Prices {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
      tag: isSet(object.tag) ? AttributedString.fromJSON(object.tag) : undefined,
      info_screen: isSet(object.info_screen) ? ProductStoresInfoScreen.fromJSON(object.info_screen) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut_Content_Prices): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    if (message.tag !== undefined) {
      obj.tag = AttributedString.toJSON(message.tag);
    }
    if (message.info_screen !== undefined) {
      obj.info_screen = ProductStoresInfoScreen.toJSON(message.info_screen);
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives {
    return {
      preview_images: globalThis.Array.isArray(object?.preview_images)
        ? object.preview_images.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives): unknown {
    const obj: any = {};
    if (message.preview_images?.length) {
      obj.preview_images = message.preview_images;
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item.fromJSON(e))
        : [],
      ingredients_list: isSet(object.ingredients_list)
        ? ProductScreen_Section_Ingredients.fromJSON(object.ingredients_list)
        : undefined,
      ingredients_list_info_screen: isSet(object.ingredients_list_info_screen)
        ? ProductIngredientsListInfoScreen.fromJSON(object.ingredients_list_info_screen)
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    if (message.items?.length) {
      obj.items = message.items.map((e) =>
        ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item.toJSON(e)
      );
    }
    if (message.ingredients_list !== undefined) {
      obj.ingredients_list = ProductScreen_Section_Ingredients.toJSON(message.ingredients_list);
    }
    if (message.ingredients_list_info_screen !== undefined) {
      obj.ingredients_list_info_screen = ProductIngredientsListInfoScreen.toJSON(message.ingredients_list_info_screen);
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      risk_color: isSet(object.risk_color) ? Color.fromJSON(object.risk_color) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.risk_color !== undefined) {
      obj.risk_color = Color.toJSON(message.risk_color);
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary {
    return {
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      subtitle: isSet(object.subtitle) ? AttributedString.fromJSON(object.subtitle) : undefined,
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary): unknown {
    const obj: any = {};
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.subtitle !== undefined) {
      obj.subtitle = AttributedString.toJSON(message.subtitle);
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_AssistantSummary = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_AssistantSummary {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      message: isSet(object.message) ? AttributedString.fromJSON(object.message) : undefined,
      buttons: globalThis.Array.isArray(object?.buttons)
        ? object.buttons.map((e: any) => ProductScreen_Section_TilesHeader_AssistantSummary_Button.fromJSON(e))
        : [],
      default_buttons_max_count: isSet(object.default_buttons_max_count)
        ? globalThis.Number(object.default_buttons_max_count)
        : 0,
      more_button_title: isSet(object.more_button_title)
        ? AttributedString.fromJSON(object.more_button_title)
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_AssistantSummary): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.message !== undefined) {
      obj.message = AttributedString.toJSON(message.message);
    }
    if (message.buttons?.length) {
      obj.buttons = message.buttons.map((e) => ProductScreen_Section_TilesHeader_AssistantSummary_Button.toJSON(e));
    }
    if (message.default_buttons_max_count !== 0) {
      obj.default_buttons_max_count = Math.round(message.default_buttons_max_count);
    }
    if (message.more_button_title !== undefined) {
      obj.more_button_title = AttributedString.toJSON(message.more_button_title);
    }
    return obj;
  },
};

export const ProductScreen_Section_TilesHeader_AssistantSummary_Button = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_AssistantSummary_Button {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      is_primary: isSet(object.is_primary) ? globalThis.Boolean(object.is_primary) : false,
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_AssistantSummary_Button): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.is_primary !== false) {
      obj.is_primary = message.is_primary;
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    return obj;
  },
};

export const ProductScreen_Section_KeyFunctions = {
  fromJSON(object: any): ProductScreen_Section_KeyFunctions {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
      sections: globalThis.Array.isArray(object?.sections)
        ? object.sections.map((e: any) => ProductScreen_Section_KeyFunctions_Section.fromJSON(e))
        : [],
      lazy_request_data: isSet(object.lazy_request_data)
        ? LazyProductSectionRequestData.fromJSON(object.lazy_request_data)
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_KeyFunctions): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    if (message.sections?.length) {
      obj.sections = message.sections.map((e) => ProductScreen_Section_KeyFunctions_Section.toJSON(e));
    }
    if (message.lazy_request_data !== undefined) {
      obj.lazy_request_data = LazyProductSectionRequestData.toJSON(message.lazy_request_data);
    }
    return obj;
  },
};

export const ProductScreen_Section_KeyFunctions_Section = {
  fromJSON(object: any): ProductScreen_Section_KeyFunctions_Section {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      functions_type: isSet(object.functions_type)
        ? productKeyFunctionTypeFromJSON(object.functions_type)
        : ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE,
      functions: globalThis.Array.isArray(object?.functions)
        ? object.functions.map((e: any) => ProductScreen_Section_KeyFunctions_Function.fromJSON(e))
        : [],
      is_always_visible: isSet(object.is_always_visible) ? globalThis.Boolean(object.is_always_visible) : false,
      content: isSet(object.tags)
        ? { $case: "tags", tags: ProductScreen_Section_KeyFunctions_Section_Tags.fromJSON(object.tags) }
        : isSet(object.scales)
        ? { $case: "scales", scales: ProductScreen_Section_KeyFunctions_Section_Scales.fromJSON(object.scales) }
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_KeyFunctions_Section): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.functions_type !== ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE) {
      obj.functions_type = productKeyFunctionTypeToJSON(message.functions_type);
    }
    if (message.functions?.length) {
      obj.functions = message.functions.map((e) => ProductScreen_Section_KeyFunctions_Function.toJSON(e));
    }
    if (message.is_always_visible !== false) {
      obj.is_always_visible = message.is_always_visible;
    }
    if (message.content?.$case === "tags") {
      obj.tags = ProductScreen_Section_KeyFunctions_Section_Tags.toJSON(message.content.tags);
    }
    if (message.content?.$case === "scales") {
      obj.scales = ProductScreen_Section_KeyFunctions_Section_Scales.toJSON(message.content.scales);
    }
    return obj;
  },
};

export const ProductScreen_Section_KeyFunctions_Section_Tags = {
  fromJSON(object: any): ProductScreen_Section_KeyFunctions_Section_Tags {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      functions_type: isSet(object.functions_type)
        ? productKeyFunctionTypeFromJSON(object.functions_type)
        : ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE,
      functions: globalThis.Array.isArray(object?.functions)
        ? object.functions.map((e: any) => ProductScreen_Section_KeyFunctions_Function.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_KeyFunctions_Section_Tags): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.functions_type !== ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE) {
      obj.functions_type = productKeyFunctionTypeToJSON(message.functions_type);
    }
    if (message.functions?.length) {
      obj.functions = message.functions.map((e) => ProductScreen_Section_KeyFunctions_Function.toJSON(e));
    }
    return obj;
  },
};

export const ProductScreen_Section_KeyFunctions_Section_Scales = {
  fromJSON(object: any): ProductScreen_Section_KeyFunctions_Section_Scales {
    return {
      scales: globalThis.Array.isArray(object?.scales)
        ? object.scales.map((e: any) => ProductScreen_Section_KeyFunctions_Section_Scales_Scale.fromJSON(e))
        : [],
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : 0,
    };
  },

  toJSON(message: ProductScreen_Section_KeyFunctions_Section_Scales): unknown {
    const obj: any = {};
    if (message.scales?.length) {
      obj.scales = message.scales.map((e) => ProductScreen_Section_KeyFunctions_Section_Scales_Scale.toJSON(e));
    }
    if (message.limit !== 0) {
      obj.limit = Math.round(message.limit);
    }
    return obj;
  },
};

export const ProductScreen_Section_KeyFunctions_Section_Scales_Scale = {
  fromJSON(object: any): ProductScreen_Section_KeyFunctions_Section_Scales_Scale {
    return {
      label: isSet(object.label) ? globalThis.String(object.label) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      steps_count: isSet(object.steps_count) ? globalThis.Number(object.steps_count) : 0,
      filled_steps_count: isSet(object.filled_steps_count) ? globalThis.Number(object.filled_steps_count) : 0,
      info_screen: isSet(object.info_screen) ? ProductStepInfoScreen.fromJSON(object.info_screen) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_KeyFunctions_Section_Scales_Scale): unknown {
    const obj: any = {};
    if (message.label !== "") {
      obj.label = message.label;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.steps_count !== 0) {
      obj.steps_count = Math.round(message.steps_count);
    }
    if (message.filled_steps_count !== 0) {
      obj.filled_steps_count = Math.round(message.filled_steps_count);
    }
    if (message.info_screen !== undefined) {
      obj.info_screen = ProductStepInfoScreen.toJSON(message.info_screen);
    }
    return obj;
  },
};

export const ProductScreen_Section_KeyFunctions_Function = {
  fromJSON(object: any): ProductScreen_Section_KeyFunctions_Function {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      info_screen: isSet(object.info_screen) ? ProductKeyFunctionInfoScreen.fromJSON(object.info_screen) : undefined,
      attributed_title: isSet(object.attributed_title) ? AttributedString.fromJSON(object.attributed_title) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_KeyFunctions_Function): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.info_screen !== undefined) {
      obj.info_screen = ProductKeyFunctionInfoScreen.toJSON(message.info_screen);
    }
    if (message.attributed_title !== undefined) {
      obj.attributed_title = AttributedString.toJSON(message.attributed_title);
    }
    return obj;
  },
};

export const ProductScreen_Section_Ingredients = {
  fromJSON(object: any): ProductScreen_Section_Ingredients {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      ingredients: globalThis.Array.isArray(object?.ingredients)
        ? object.ingredients.map((e: any) => ProductScreen_Section_Ingredients_Ingredient.fromJSON(e))
        : [],
      is_always_visible: isSet(object.is_always_visible) ? globalThis.Boolean(object.is_always_visible) : false,
      ingredients_count_to_show: isSet(object.ingredients_count_to_show)
        ? globalThis.Number(object.ingredients_count_to_show)
        : 0,
    };
  },

  toJSON(message: ProductScreen_Section_Ingredients): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.ingredients?.length) {
      obj.ingredients = message.ingredients.map((e) => ProductScreen_Section_Ingredients_Ingredient.toJSON(e));
    }
    if (message.is_always_visible !== false) {
      obj.is_always_visible = message.is_always_visible;
    }
    if (message.ingredients_count_to_show !== 0) {
      obj.ingredients_count_to_show = Math.round(message.ingredients_count_to_show);
    }
    return obj;
  },
};

export const ProductScreen_Section_Ingredients_Ingredient = {
  fromJSON(object: any): ProductScreen_Section_Ingredients_Ingredient {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      info_screen: isSet(object.info_screen) ? ProductIngredientInfoScreen.fromJSON(object.info_screen) : undefined,
      risk_title: isSet(object.risk_title) ? globalThis.String(object.risk_title) : "",
      risk_color: isSet(object.risk_color) ? Color.fromJSON(object.risk_color) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_Ingredients_Ingredient): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.info_screen !== undefined) {
      obj.info_screen = ProductIngredientInfoScreen.toJSON(message.info_screen);
    }
    if (message.risk_title !== "") {
      obj.risk_title = message.risk_title;
    }
    if (message.risk_color !== undefined) {
      obj.risk_color = Color.toJSON(message.risk_color);
    }
    return obj;
  },
};

export const ProductScreen_Button = {
  fromJSON(object: any): ProductScreen_Button {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      deprecated_action: isSet(object.deprecated_action) ? ActionType.fromJSON(object.deprecated_action) : undefined,
      is_active: isSet(object.is_active) ? globalThis.Boolean(object.is_active) : false,
      style: isSet(object.style)
        ? productScreen_Button_StyleFromJSON(object.style)
        : ProductScreen_Button_Style.PRIMARY,
      action: isSet(object.action) ? ProductScreen_Button_Action.fromJSON(object.action) : undefined,
      filled_button: isSet(object.filled_button) ? FilledButton.fromJSON(object.filled_button) : undefined,
      analytics_params: isObject(object.analytics_params)
        ? Object.entries(object.analytics_params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ProductScreen_Button): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.deprecated_action !== undefined) {
      obj.deprecated_action = ActionType.toJSON(message.deprecated_action);
    }
    if (message.is_active !== false) {
      obj.is_active = message.is_active;
    }
    if (message.style !== ProductScreen_Button_Style.PRIMARY) {
      obj.style = productScreen_Button_StyleToJSON(message.style);
    }
    if (message.action !== undefined) {
      obj.action = ProductScreen_Button_Action.toJSON(message.action);
    }
    if (message.filled_button !== undefined) {
      obj.filled_button = FilledButton.toJSON(message.filled_button);
    }
    if (message.analytics_params) {
      const entries = Object.entries(message.analytics_params);
      if (entries.length > 0) {
        obj.analytics_params = {};
        entries.forEach(([k, v]) => {
          obj.analytics_params[k] = v;
        });
      }
    }
    return obj;
  },
};

export const ProductScreen_Button_Action = {
  fromJSON(object: any): ProductScreen_Button_Action {
    return {
      action: isSet(object.close)
        ? { $case: "close", close: ProductScreen_Button_Action_Close.fromJSON(object.close) }
        : isSet(object.dynamic)
        ? { $case: "dynamic", dynamic: ProductScreen_Button_Action_Dynamic.fromJSON(object.dynamic) }
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Button_Action): unknown {
    const obj: any = {};
    if (message.action?.$case === "close") {
      obj.close = ProductScreen_Button_Action_Close.toJSON(message.action.close);
    }
    if (message.action?.$case === "dynamic") {
      obj.dynamic = ProductScreen_Button_Action_Dynamic.toJSON(message.action.dynamic);
    }
    return obj;
  },
};

export const ProductScreen_Button_Action_Close = {
  fromJSON(_: any): ProductScreen_Button_Action_Close {
    return {};
  },

  toJSON(_: ProductScreen_Button_Action_Close): unknown {
    const obj: any = {};
    return obj;
  },
};

export const ProductScreen_Button_Action_Dynamic = {
  fromJSON(object: any): ProductScreen_Button_Action_Dynamic {
    return {
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
      target_screen: isSet(object.target_screen)
        ? productScreen_Button_Action_TargetScreenFromJSON(object.target_screen)
        : ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_NONE,
    };
  },

  toJSON(message: ProductScreen_Button_Action_Dynamic): unknown {
    const obj: any = {};
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    if (message.target_screen !== ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_NONE) {
      obj.target_screen = productScreen_Button_Action_TargetScreenToJSON(message.target_screen);
    }
    return obj;
  },
};

export const ProductScreen_Button_AnalyticsParamsEntry = {
  fromJSON(object: any): ProductScreen_Button_AnalyticsParamsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ProductScreen_Button_AnalyticsParamsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },
};

export const ProductScreen_AnalyticsData = {
  fromJSON(object: any): ProductScreen_AnalyticsData {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      brand: isSet(object.brand) ? globalThis.String(object.brand) : "",
      expert_analysis_used: isSet(object.expert_analysis_used)
        ? globalThis.Boolean(object.expert_analysis_used)
        : false,
      extra_params: isObject(object.extra_params)
        ? Object.entries(object.extra_params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ProductScreen_AnalyticsData): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.brand !== "") {
      obj.brand = message.brand;
    }
    if (message.expert_analysis_used !== false) {
      obj.expert_analysis_used = message.expert_analysis_used;
    }
    if (message.extra_params) {
      const entries = Object.entries(message.extra_params);
      if (entries.length > 0) {
        obj.extra_params = {};
        entries.forEach(([k, v]) => {
          obj.extra_params[k] = v;
        });
      }
    }
    return obj;
  },
};

export const ProductScreen_AnalyticsData_ExtraParamsEntry = {
  fromJSON(object: any): ProductScreen_AnalyticsData_ExtraParamsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ProductScreen_AnalyticsData_ExtraParamsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },
};

export const ProductScreens = {
  fromJSON(object: any): ProductScreens {
    return {
      product_screens: globalThis.Array.isArray(object?.product_screens)
        ? object.product_screens.map((e: any) => ProductScreen.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreens): unknown {
    const obj: any = {};
    if (message.product_screens?.length) {
      obj.product_screens = message.product_screens.map((e) => ProductScreen.toJSON(e));
    }
    return obj;
  },
};

export const ProductsSearchScreen = {
  fromJSON(object: any): ProductsSearchScreen {
    return {
      promo: isSet(object.promo) ? ProductsSearchScreen_PromoText.fromJSON(object.promo) : undefined,
      productCount: isSet(object.productCount) ? globalThis.Number(object.productCount) : 0,
      evolutionActions: globalThis.Array.isArray(object?.evolutionActions)
        ? object.evolutionActions.map((e: any) => ProductsSearchScreen_Action.fromJSON(e))
        : [],
      video: isSet(object.video) ? globalThis.String(object.video) : "",
      buttonText: isSet(object.buttonText) ? globalThis.String(object.buttonText) : "",
    };
  },

  toJSON(message: ProductsSearchScreen): unknown {
    const obj: any = {};
    if (message.promo !== undefined) {
      obj.promo = ProductsSearchScreen_PromoText.toJSON(message.promo);
    }
    if (message.productCount !== 0) {
      obj.productCount = Math.round(message.productCount);
    }
    if (message.evolutionActions?.length) {
      obj.evolutionActions = message.evolutionActions.map((e) => ProductsSearchScreen_Action.toJSON(e));
    }
    if (message.video !== "") {
      obj.video = message.video;
    }
    if (message.buttonText !== "") {
      obj.buttonText = message.buttonText;
    }
    return obj;
  },
};

export const ProductsSearchScreen_PromoText = {
  fromJSON(object: any): ProductsSearchScreen_PromoText {
    return {
      header: isSet(object.header) ? AttributedString.fromJSON(object.header) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
    };
  },

  toJSON(message: ProductsSearchScreen_PromoText): unknown {
    const obj: any = {};
    if (message.header !== undefined) {
      obj.header = AttributedString.toJSON(message.header);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    return obj;
  },
};

export const ProductsSearchScreen_Particles = {
  fromJSON(object: any): ProductsSearchScreen_Particles {
    return {
      density: isSet(object.density) ? globalThis.Number(object.density) : 0,
      scale: isSet(object.scale) ? globalThis.Number(object.scale) : 0,
    };
  },

  toJSON(message: ProductsSearchScreen_Particles): unknown {
    const obj: any = {};
    if (message.density !== 0) {
      obj.density = message.density;
    }
    if (message.scale !== 0) {
      obj.scale = message.scale;
    }
    return obj;
  },
};

export const ProductsSearchScreen_ProductCounter = {
  fromJSON(object: any): ProductsSearchScreen_ProductCounter {
    return {
      count: isSet(object.count) ? globalThis.Number(object.count) : 0,
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
    };
  },

  toJSON(message: ProductsSearchScreen_ProductCounter): unknown {
    const obj: any = {};
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    if (message.duration !== 0) {
      obj.duration = message.duration;
    }
    return obj;
  },
};

export const ProductsSearchScreen_Action = {
  fromJSON(object: any): ProductsSearchScreen_Action {
    return {
      action: isSet(object.born)
        ? { $case: "born", born: ProductsSearchScreen_Action_Born.fromJSON(object.born) }
        : isSet(object.blast)
        ? { $case: "blast", blast: ProductsSearchScreen_Action_Blast.fromJSON(object.blast) }
        : isSet(object.finalMove)
        ? { $case: "finalMove", finalMove: ProductsSearchScreen_Action_FinalMove.fromJSON(object.finalMove) }
        : undefined,
    };
  },

  toJSON(message: ProductsSearchScreen_Action): unknown {
    const obj: any = {};
    if (message.action?.$case === "born") {
      obj.born = ProductsSearchScreen_Action_Born.toJSON(message.action.born);
    }
    if (message.action?.$case === "blast") {
      obj.blast = ProductsSearchScreen_Action_Blast.toJSON(message.action.blast);
    }
    if (message.action?.$case === "finalMove") {
      obj.finalMove = ProductsSearchScreen_Action_FinalMove.toJSON(message.action.finalMove);
    }
    return obj;
  },
};

export const ProductsSearchScreen_Action_Born = {
  fromJSON(object: any): ProductsSearchScreen_Action_Born {
    return {
      particles: isSet(object.particles) ? ProductsSearchScreen_Particles.fromJSON(object.particles) : undefined,
      delay: isSet(object.delay) ? globalThis.Number(object.delay) : 0,
      sleep: isSet(object.sleep) ? globalThis.Number(object.sleep) : 0,
    };
  },

  toJSON(message: ProductsSearchScreen_Action_Born): unknown {
    const obj: any = {};
    if (message.particles !== undefined) {
      obj.particles = ProductsSearchScreen_Particles.toJSON(message.particles);
    }
    if (message.delay !== 0) {
      obj.delay = message.delay;
    }
    if (message.sleep !== 0) {
      obj.sleep = message.sleep;
    }
    return obj;
  },
};

export const ProductsSearchScreen_Action_Blast = {
  fromJSON(object: any): ProductsSearchScreen_Action_Blast {
    return {
      promo: isSet(object.promo) ? ProductsSearchScreen_PromoText.fromJSON(object.promo) : undefined,
      particles: isSet(object.particles) ? ProductsSearchScreen_Particles.fromJSON(object.particles) : undefined,
      counter: isSet(object.counter) ? ProductsSearchScreen_ProductCounter.fromJSON(object.counter) : undefined,
      waveLifetime: isSet(object.waveLifetime) ? globalThis.Number(object.waveLifetime) : 0,
      sleep: isSet(object.sleep) ? globalThis.Number(object.sleep) : 0,
    };
  },

  toJSON(message: ProductsSearchScreen_Action_Blast): unknown {
    const obj: any = {};
    if (message.promo !== undefined) {
      obj.promo = ProductsSearchScreen_PromoText.toJSON(message.promo);
    }
    if (message.particles !== undefined) {
      obj.particles = ProductsSearchScreen_Particles.toJSON(message.particles);
    }
    if (message.counter !== undefined) {
      obj.counter = ProductsSearchScreen_ProductCounter.toJSON(message.counter);
    }
    if (message.waveLifetime !== 0) {
      obj.waveLifetime = message.waveLifetime;
    }
    if (message.sleep !== 0) {
      obj.sleep = message.sleep;
    }
    return obj;
  },
};

export const ProductsSearchScreen_Action_FinalMove = {
  fromJSON(object: any): ProductsSearchScreen_Action_FinalMove {
    return {
      promo: isSet(object.promo) ? ProductsSearchScreen_PromoText.fromJSON(object.promo) : undefined,
      productCount: isSet(object.productCount) ? globalThis.Number(object.productCount) : 0,
      particlesScale: isSet(object.particlesScale) ? globalThis.Number(object.particlesScale) : 0,
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
    };
  },

  toJSON(message: ProductsSearchScreen_Action_FinalMove): unknown {
    const obj: any = {};
    if (message.promo !== undefined) {
      obj.promo = ProductsSearchScreen_PromoText.toJSON(message.promo);
    }
    if (message.productCount !== 0) {
      obj.productCount = Math.round(message.productCount);
    }
    if (message.particlesScale !== 0) {
      obj.particlesScale = message.particlesScale;
    }
    if (message.duration !== 0) {
      obj.duration = message.duration;
    }
    return obj;
  },
};

export const SubscriptionManagementPageData = {
  fromJSON(object: any): SubscriptionManagementPageData {
    return {
      provider: isSet(object.provider) ? globalThis.String(object.provider) : "",
      subscription_id: isSet(object.subscription_id) ? globalThis.String(object.subscription_id) : "",
      user_id: isSet(object.user_id) ? globalThis.String(object.user_id) : "",
    };
  },

  toJSON(message: SubscriptionManagementPageData): unknown {
    const obj: any = {};
    if (message.provider !== "") {
      obj.provider = message.provider;
    }
    if (message.subscription_id !== "") {
      obj.subscription_id = message.subscription_id;
    }
    if (message.user_id !== "") {
      obj.user_id = message.user_id;
    }
    return obj;
  },
};

export const EncryptedSubscriptionManagementPageData = {
  fromJSON(object: any): EncryptedSubscriptionManagementPageData {
    return { encrypted_smp: isSet(object.encrypted_smp) ? globalThis.String(object.encrypted_smp) : "" };
  },

  toJSON(message: EncryptedSubscriptionManagementPageData): unknown {
    const obj: any = {};
    if (message.encrypted_smp !== "") {
      obj.encrypted_smp = message.encrypted_smp;
    }
    return obj;
  },
};

export const ImageButton = {
  fromJSON(object: any): ImageButton {
    return {
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
      resources: globalThis.Array.isArray(object?.resources)
        ? object.resources.map((e: any) => ImageButton_Resource.fromJSON(e))
        : [],
      before_animation_delay: isSet(object.before_animation_delay)
        ? globalThis.Number(object.before_animation_delay)
        : 0,
    };
  },

  toJSON(message: ImageButton): unknown {
    const obj: any = {};
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    if (message.resources?.length) {
      obj.resources = message.resources.map((e) => ImageButton_Resource.toJSON(e));
    }
    if (message.before_animation_delay !== 0) {
      obj.before_animation_delay = message.before_animation_delay;
    }
    return obj;
  },
};

export const ImageButton_Resource = {
  fromJSON(object: any): ImageButton_Resource {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      appear_probability: isSet(object.appear_probability) ? globalThis.Number(object.appear_probability) : 0,
    };
  },

  toJSON(message: ImageButton_Resource): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.appear_probability !== 0) {
      obj.appear_probability = Math.round(message.appear_probability);
    }
    return obj;
  },
};

export const ProductPageReport = {
  fromJSON(object: any): ProductPageReport {
    return {
      product_id: isSet(object.product_id) ? globalThis.String(object.product_id) : "",
      user_id: isSet(object.user_id) ? globalThis.String(object.user_id) : "",
      comment: isSet(object.comment) ? globalThis.String(object.comment) : "",
      problem_tags: globalThis.Array.isArray(object?.problem_tags)
        ? object.problem_tags.map((e: any) => productPageReport_ProblemTagFromJSON(e))
        : [],
      screenshot: isSet(object.screenshot) ? Image.fromJSON(object.screenshot) : undefined,
      reasons: globalThis.Array.isArray(object?.reasons)
        ? object.reasons.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ProductPageReport): unknown {
    const obj: any = {};
    if (message.product_id !== "") {
      obj.product_id = message.product_id;
    }
    if (message.user_id !== "") {
      obj.user_id = message.user_id;
    }
    if (message.comment !== "") {
      obj.comment = message.comment;
    }
    if (message.problem_tags?.length) {
      obj.problem_tags = message.problem_tags.map((e) => productPageReport_ProblemTagToJSON(e));
    }
    if (message.screenshot !== undefined) {
      obj.screenshot = Image.toJSON(message.screenshot);
    }
    if (message.reasons?.length) {
      obj.reasons = message.reasons;
    }
    return obj;
  },
};

export const ProductPageReportRequest = {
  fromJSON(object: any): ProductPageReportRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      report: isSet(object.report) ? ProductPageReport.fromJSON(object.report) : undefined,
    };
  },

  toJSON(message: ProductPageReportRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.report !== undefined) {
      obj.report = ProductPageReport.toJSON(message.report);
    }
    return obj;
  },
};

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
