import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import en from "~/locales/en.json"
import es from "~/locales/es.json"

const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
}

const params = new URLSearchParams(window ? window.location.search : {})

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: resources,
    saveMissing: true,
    missingKeyHandler: function (lng, ns, key) {
      console.warn(`Missing translation: ${key}`)
    },
    lng: params.get("lng") || "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

window.changeLanguage = (lng: string) => i18n.changeLanguage(lng)

export default i18n
