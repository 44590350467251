import React, { FC, useMemo, ReactNode } from "react"
import { Flex, FlexProps } from "@chakra-ui/react"

import type { AttributedStringType } from "~/components/shared/AttributedString"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"
import type { NextPageCb } from "~/hooks/useNextQuiz"
import { useProgress } from "~/hooks/useProgress"

import { noop } from "~/utils"
import { ProgressBarItemWithIcon } from "./ProgressBarItemWithIcon"

const ProgressBarItem: FC<{
  title: string
  duration: number
  delay: number
  onComplete: () => void
}> = ({ title, duration, delay, onComplete }) => {
  const { progress } = useProgress({ duration, delay, onComplete })
  return <ProgressBarItemWithIcon title={title} progress={progress} />
}

export type ProgressBarData = { title: string; seconds_to_complete: number }

const ProgressBarsList: FC<
  { list?: ProgressBarData[]; onComplete?: (i: number) => () => void } & FlexProps
> = ({ list = [], onComplete = () => noop, ...props }) => {
  let delay = 0

  return (
    <Flex direction="column" {...props}>
      {list.map(({ title, seconds_to_complete }, i) => {
        const currentDelay = delay
        delay = delay + seconds_to_complete
        return (
          <ProgressBarItem
            key={i}
            title={title}
            delay={currentDelay}
            duration={seconds_to_complete}
            onComplete={onComplete(i)}
          />
        )
      })}
    </Flex>
  )
}

export type ProgressBarsPageProps = {
  title: AttributedStringType
  progressBars: ProgressBarData[]
  description: string | undefined
  children?: ReactNode
  next: NextPageCb
}

export const ProgressBarsPage: FC<ProgressBarsPageProps> = ({
  title,
  description,
  progressBars,
  children,
  next,
}) => {
  const onComplete = useMemo(
    () => (i: number) => () => {
      if (i === progressBars.length - 1) {
        next()
      }
    },
    [progressBars, next]
  )

  return (
    <BasePageWrapper
      h="full"
      paddingX={6}
      paddingY={4}
      marginTop={12}
      direction="column"
      justifyContent="center"
    >
      {<BasePageHeader marginBottom={8} textAlign="center" title={title} subTitle={description} />}
      {progressBars && (
        <ProgressBarsList list={progressBars} onComplete={onComplete} gap={2} paddingBottom={4} />
      )}
      {children}
    </BasePageWrapper>
  )
}
