import { ApiError, ServerApiError, UnauthorizedApiError } from "~/errors"
import { getSmpLink, getTestQueryParams, fileToBase64, getQueryParams, getCookie } from "~/utils"
import { ResponseStatus } from "~/generated/networking"
import {
  InterviewServiceGetQuizResponse,
  InterviewServiceSaveAnswersResponse,
} from "~/generated/interview_service"
import { getUserId } from "~/hooks/useInitialization/initUtils"
import { getConfig } from "~/config"
import type { ColorString, CountryCode, QuestionId, QuizId, URLString } from "~/types"

import { getSaveBody } from "./getSaveBody"
import { getCountryInfo } from "~/utils/countryCode"
export type { QuestionV2Type, QuizType } from "./QuestionType"

const defaultParams = {
  format: "json",
}

type SimpleObject = Record<string, string | undefined>

export const getInterviewId = () => {
  const configInstance = getConfig()["instance"]
  if (configInstance === "SUBSCRIPTION") {
    return "smp"
  }
  if (configInstance === "INTERVIEW2") {
    return "golden_thread_new"
  }
  return undefined
}

const getInternalParams = (): Record<string, string> => {
  const result: Record<string, string> = {}
  const interview_id = getInterviewId()
  if (interview_id) {
    result["interview_id"] = interview_id
  }

  const country_code = getCountryInfo()
  if (country_code) {
    result["country_code"] = country_code
  }

  const smp_link = getSmpLink()
  if (smp_link) {
    result["smp_link"] = smp_link
  }

  return result
}

const appendParamsApi = (url = "", additionalParams = {}) => {
  const parsed = new URLSearchParams(window.location.search)
  const user_id = parsed.get("user_id")
  const client = parsed.get("client") || "web"
  const params: Record<string, string> = {
    client,
    ...defaultParams,
    ...getInternalParams(),
    ...additionalParams,
    ...getTestQueryParams(),
  }
  if (!params["user_id"] && user_id) {
    params["user_id"] = user_id
  }
  const result = new URL(url)
  result.search = String(new URLSearchParams(params))
  return String(result)
}

const getUrl = (url: string, params: SimpleObject) => appendParamsApi(url, params)

// Universal Api Methods

const validateResponse = (resp: Response) => {
  if (resp.status === 401) {
    throw new UnauthorizedApiError(`Unauthorized request to ${resp.url}`)
  }

  if (!resp.ok) {
    throw new ApiError(`Response from ${resp.url} with status ${resp.status}`)
  }
}

export const apiGet = async <T extends object>(
  url: URLString,
  params: SimpleObject = {}
): Promise<T> => {
  const resp = await fetch(getUrl(url, params), {
    method: "GET",
    headers: { Accept: "application/json" },
  })
  validateResponse(resp)

  return (await resp.json()) as T
}

const apiPost = async <T extends object>(
  url: URLString,
  body: object,
  params: SimpleObject = {}
) => {
  const resp = await fetch(getUrl(url, params), {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
  validateResponse(resp)

  return (await resp.json()) as T
}
// End Universal Api Methods

export const sendLog = (action: string, values: object = {}) => {
  if (getConfig().endpoints.log) {
    const params: Record<string, any> = { ...values }
    params["page_url"] = window.location.href
    params["user_id"] = getUserId()

    navigator.sendBeacon(getConfig().endpoints.log, JSON.stringify({ event: action, params }))
  }
}

export const sendMarketingAttributes = () => {
  const params: Record<string, string | undefined> = getQueryParams() ?? {}
  params["_fbc"] = getCookie("_fbc")
  params["_fbp"] = getCookie("_fbp")
  params["_ttp"] = getCookie("_ttp")
  params["_url"] = window.location.href
  if (Object.keys(params).length > 0) {
    sendLog("MARKETING_ATTRIBUTES", params)
    if (getConfig().endpoints.marketingAttributes && getUserId()) {
      const p = { userID: getUserId(), params }
      navigator.sendBeacon(getConfig().endpoints.marketingAttributes, JSON.stringify(p))
    }
  }
}

export const debugToLog = (values: Record<string, unknown> = {}) => sendLog("DEBUG", values)

export const apiGetQuiz = async (
  user_id: UserId,
  _quiz_id: QuizId,
  _question_id?: QuestionId
): Promise<InterviewServiceGetQuizResponse> => {
  const quiz_id = _question_id ? `${_quiz_id}@@${_question_id}` : _quiz_id

  const json = await apiGet(getConfig().endpoints.get_quiz, {
    quiz_id,
    user_id,
  })
  return InterviewServiceGetQuizResponse.fromJSON(json)
}

export const apiGetUser = async (): Promise<string> => {
  const json = await apiGet<{ userID?: string }>(getConfig().endpoints.get_user_id)
  const userId: string | undefined = json?.userID
  if (userId) {
    return userId
  }

  throw new Error("Unknown UserId")
}

export const apiGetConfig = async () => apiGet<ReturnType<typeof getConfig>>(getConfig().config_url)

export const apiSave = async (body: object): Promise<InterviewServiceSaveAnswersResponse> => {
  const json = await apiPost(getConfig().endpoints.save_answers, body)
  return InterviewServiceSaveAnswersResponse.fromJSON(json)
}

export const apiValidateEmail = async (
  userId: UserId,
  quizId: QuizId,
  questionId: QuestionId,
  email: string
): Promise<boolean> => {
  const body = getSaveBody(userId, quizId, { [questionId]: [email] })
  const url = getConfig().endpoints.check_email
  const resp = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
  validateResponse(resp)

  return true
}

export const apiUpdateUserId = async (
  oldUserId: UserId,
  newUserId: UserId
): Promise<InterviewServiceSaveAnswersResponse> => {
  // TODO use UpdateUserIDRequest here
  const body = {
    oldUserId,
    request: { client: "web", userID: newUserId },
  }
  const json = await apiPost(getConfig().endpoints.update_user_id, body)
  return InterviewServiceSaveAnswersResponse.fromJSON(json)
}

// TODO use proto/inference.proto
type MaskTuple = [ColorString, Base64String]
export type FaceScanAnalysisResponse = {
  scanId: ScanId
  userId: UserId
  masks: Record<string, MaskTuple>
}

// https://airtable.com/appmKgar7eo8WFO5M/tblQeCys3Bv6oSRGl/viwDTPiwhiL50VCmL?blocks=hide
const COLORS: Record<string, ColorString> = {
  "acne_masks.acne_blackhead": "#578FFF",
  "acne_masks.acne_whitehead": "#578FFF",
  "acne_masks.acne_papule": "#578FFF",
  "acne_masks.acne_pustule": "#578FFF",

  "acne_masks.pores": "#58FF45",

  "wrinkles_masks.wrinkles": "#29EDED",
} as unknown as Record<string, ColorString>

const transformMask =
  (prefix: string) =>
  (obj: Record<string, string>): Record<string, MaskTuple> => {
    const result: Record<string, [ColorString, Base64String]> = {}
    Object.entries(obj).forEach(([_name, value]) => {
      const name = `${prefix}${_name}`
      const color = COLORS[name]
      if (color) {
        result[name] = [color, value as Base64String]
      }
    })
    return result
  }

type FaceScanAnalysisApiResponse = {
  scan_id: string
  acne_masks: Record<string, string>
  wrinkles_masks: Record<string, string>
}
export const faceScanAnalysis = async (
  userId: UserId,
  image: Base64String
): Promise<FaceScanAnalysisResponse> => {
  const body = { image_central_b64_data: image, user_id: userId }
  const params = { raw_mask: "1", return_only_scan_id: "0" }
  const json = await apiPost<FaceScanAnalysisApiResponse>(
    getConfig().endpoints.face_scan_analysis,
    body,
    params
  )

  const { scan_id, acne_masks, wrinkles_masks } = json
  const masks: Record<string, MaskTuple> = {
    ...transformMask("acne_masks.")(acne_masks),
    ...transformMask("wrinkles_masks.")(wrinkles_masks),
  }

  const result = {
    scanId: scan_id as ScanId,
    userId,
    masks,
  }
  return result
}

const DEFAULT_OPTIONS = {
  instant: false,
  privateFlag: false,
  linkMinutesLength: 60 * 24,
  webp: true,
  maxDimension: 0,
}
type Opts = typeof DEFAULT_OPTIONS

export const sendFileToImageStorage = async (
  file: File | Blob,
  opts: Partial<Opts> = DEFAULT_OPTIONS
) => {
  let body: File | Blob | string = file
  const url = new URL("https://api.pora.ai/upload")
  const {
    instant = DEFAULT_OPTIONS.instant,
    webp = DEFAULT_OPTIONS.webp,
    maxDimension = DEFAULT_OPTIONS.maxDimension,
    privateFlag = DEFAULT_OPTIONS.privateFlag,
    linkMinutesLength = DEFAULT_OPTIONS.linkMinutesLength,
  } = opts
  if (maxDimension) {
    url.searchParams.append("max_dimension", maxDimension.toFixed(0))
  }
  if (instant) {
    url.searchParams.append("instant", "1")
  }
  if (webp) {
    url.searchParams.append("webp", "1")
  }

  if (privateFlag) {
    url.searchParams.append("private", "1")
    url.searchParams.append("link_minutes_length", linkMinutesLength.toString())
  }

  if (window.location.search.includes("utm_source=tiktok")) {
    /* see https://paltaskincare.atlassian.net/browse/GEN-5516
    TikTok doesn't support binary data in request (multipart too),
    but works correctly with base64 encoded data
    */
    const _body = await fileToBase64(file)
    if (_body) {
      body = _body
      url.searchParams.append("base64", "1")
    }
  }

  const resp = await fetch(String(url), {
    method: "POST",
    mode: "cors",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body,
  })

  validateResponse(resp)
  const result = await resp.text()
  return result as URLString
}

export const apiSaveFaceScanUrl = async (
  userId: UserId,
  quizId: QuizId,
  faceScanUrl: URLString,
  scanId: ScanId
): Promise<boolean> => {
  const resp = await apiSave(
    getSaveBody(userId, quizId, { face_scan_url: [faceScanUrl], scan_id: [scanId] })
  )
  if (resp.response?.status !== ResponseStatus.SUCCESS) {
    throw new ServerApiError(`Server ResponseStatus is ${resp.response?.status}`)
  }

  return true
}

export const apiSaveFacePhotoUrl = async (
  userId: UserId,
  quizId: QuizId,
  facePhotoUrl: URLString
): Promise<boolean> => {
  const resp = await apiSave(getSaveBody(userId, quizId, { face_photo_url: [facePhotoUrl] }))
  if (resp.response?.status !== ResponseStatus.SUCCESS) {
    throw new ServerApiError(`Server ResponseStatus is ${resp.response?.status}`)
  }

  return true
}

export const apiGetCountryCode = async () =>
  apiGet<{ country_code: CountryCode }>(getConfig().country_code_url).then(
    (response) => response.country_code
  )
