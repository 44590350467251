// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: personalization.proto

/* eslint-disable */
import { Timestamp } from "./google/protobuf/timestamp";
import { Request } from "./networking";
import { AttributedString } from "./presentation";

export interface GetPersonalizationScreenRequest {
  request: Request | undefined;
}

export interface SavePersonalizationScreenRequest {
  request: Request | undefined;
  screen: PersonalizationScreen | undefined;
}

export interface PersonalizationScreen {
  title: AttributedString | undefined;
  sections: PersonalizationScreen_Section[];
  button_title: string;
}

export interface PersonalizationScreen_Section {
  title: string;
  items: PersonalizationScreen_Section_Item[];
  is_collapsed: boolean;
  collapsed_subtitle: string;
}

export interface PersonalizationScreen_Section_Item {
  id: string;
  content: PersonalizationScreen_Section_Item_Content | undefined;
  error_message: string;
  is_required: boolean;
}

export interface PersonalizationScreen_Section_Item_Content {
  content?:
    | { $case: "single_select"; single_select: PersonalizationScreen_Section_Item_Content_SingleSelect }
    | { $case: "multi_select"; multi_select: PersonalizationScreen_Section_Item_Content_MultiSelect }
    | { $case: "time_picker"; time_picker: PersonalizationScreen_Section_Item_Content_TimePicker }
    | undefined;
}

export interface PersonalizationScreen_Section_Item_Content_SingleSelect {
  options: PersonalizationScreen_Section_Item_Content_SingleSelect_Option[];
  selected_option_id: string;
}

export interface PersonalizationScreen_Section_Item_Content_SingleSelect_Option {
  id: string;
  title: string;
  icon: string;
}

export interface PersonalizationScreen_Section_Item_Content_MultiSelect {
  options: PersonalizationScreen_Section_Item_Content_MultiSelect_Option[];
  selected_option_ids: string[];
}

export interface PersonalizationScreen_Section_Item_Content_MultiSelect_Option {
  id: string;
  title: string;
  is_exclusive: boolean;
}

export interface PersonalizationScreen_Section_Item_Content_TimePicker {
  selected_time: Date | undefined;
  title: string;
}

export const GetPersonalizationScreenRequest = {
  fromJSON(object: any): GetPersonalizationScreenRequest {
    return { request: isSet(object.request) ? Request.fromJSON(object.request) : undefined };
  },

  toJSON(message: GetPersonalizationScreenRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    return obj;
  },
};

export const SavePersonalizationScreenRequest = {
  fromJSON(object: any): SavePersonalizationScreenRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      screen: isSet(object.screen) ? PersonalizationScreen.fromJSON(object.screen) : undefined,
    };
  },

  toJSON(message: SavePersonalizationScreenRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.screen !== undefined) {
      obj.screen = PersonalizationScreen.toJSON(message.screen);
    }
    return obj;
  },
};

export const PersonalizationScreen = {
  fromJSON(object: any): PersonalizationScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      sections: globalThis.Array.isArray(object?.sections)
        ? object.sections.map((e: any) => PersonalizationScreen_Section.fromJSON(e))
        : [],
      button_title: isSet(object.button_title) ? globalThis.String(object.button_title) : "",
    };
  },

  toJSON(message: PersonalizationScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.sections?.length) {
      obj.sections = message.sections.map((e) => PersonalizationScreen_Section.toJSON(e));
    }
    if (message.button_title !== "") {
      obj.button_title = message.button_title;
    }
    return obj;
  },
};

export const PersonalizationScreen_Section = {
  fromJSON(object: any): PersonalizationScreen_Section {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => PersonalizationScreen_Section_Item.fromJSON(e))
        : [],
      is_collapsed: isSet(object.is_collapsed) ? globalThis.Boolean(object.is_collapsed) : false,
      collapsed_subtitle: isSet(object.collapsed_subtitle) ? globalThis.String(object.collapsed_subtitle) : "",
    };
  },

  toJSON(message: PersonalizationScreen_Section): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.items?.length) {
      obj.items = message.items.map((e) => PersonalizationScreen_Section_Item.toJSON(e));
    }
    if (message.is_collapsed !== false) {
      obj.is_collapsed = message.is_collapsed;
    }
    if (message.collapsed_subtitle !== "") {
      obj.collapsed_subtitle = message.collapsed_subtitle;
    }
    return obj;
  },
};

export const PersonalizationScreen_Section_Item = {
  fromJSON(object: any): PersonalizationScreen_Section_Item {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      content: isSet(object.content) ? PersonalizationScreen_Section_Item_Content.fromJSON(object.content) : undefined,
      error_message: isSet(object.error_message) ? globalThis.String(object.error_message) : "",
      is_required: isSet(object.is_required) ? globalThis.Boolean(object.is_required) : false,
    };
  },

  toJSON(message: PersonalizationScreen_Section_Item): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.content !== undefined) {
      obj.content = PersonalizationScreen_Section_Item_Content.toJSON(message.content);
    }
    if (message.error_message !== "") {
      obj.error_message = message.error_message;
    }
    if (message.is_required !== false) {
      obj.is_required = message.is_required;
    }
    return obj;
  },
};

export const PersonalizationScreen_Section_Item_Content = {
  fromJSON(object: any): PersonalizationScreen_Section_Item_Content {
    return {
      content: isSet(object.single_select)
        ? {
          $case: "single_select",
          single_select: PersonalizationScreen_Section_Item_Content_SingleSelect.fromJSON(object.single_select),
        }
        : isSet(object.multi_select)
        ? {
          $case: "multi_select",
          multi_select: PersonalizationScreen_Section_Item_Content_MultiSelect.fromJSON(object.multi_select),
        }
        : isSet(object.time_picker)
        ? {
          $case: "time_picker",
          time_picker: PersonalizationScreen_Section_Item_Content_TimePicker.fromJSON(object.time_picker),
        }
        : undefined,
    };
  },

  toJSON(message: PersonalizationScreen_Section_Item_Content): unknown {
    const obj: any = {};
    if (message.content?.$case === "single_select") {
      obj.single_select = PersonalizationScreen_Section_Item_Content_SingleSelect.toJSON(message.content.single_select);
    }
    if (message.content?.$case === "multi_select") {
      obj.multi_select = PersonalizationScreen_Section_Item_Content_MultiSelect.toJSON(message.content.multi_select);
    }
    if (message.content?.$case === "time_picker") {
      obj.time_picker = PersonalizationScreen_Section_Item_Content_TimePicker.toJSON(message.content.time_picker);
    }
    return obj;
  },
};

export const PersonalizationScreen_Section_Item_Content_SingleSelect = {
  fromJSON(object: any): PersonalizationScreen_Section_Item_Content_SingleSelect {
    return {
      options: globalThis.Array.isArray(object?.options)
        ? object.options.map((e: any) => PersonalizationScreen_Section_Item_Content_SingleSelect_Option.fromJSON(e))
        : [],
      selected_option_id: isSet(object.selected_option_id) ? globalThis.String(object.selected_option_id) : "",
    };
  },

  toJSON(message: PersonalizationScreen_Section_Item_Content_SingleSelect): unknown {
    const obj: any = {};
    if (message.options?.length) {
      obj.options = message.options.map((e) =>
        PersonalizationScreen_Section_Item_Content_SingleSelect_Option.toJSON(e)
      );
    }
    if (message.selected_option_id !== "") {
      obj.selected_option_id = message.selected_option_id;
    }
    return obj;
  },
};

export const PersonalizationScreen_Section_Item_Content_SingleSelect_Option = {
  fromJSON(object: any): PersonalizationScreen_Section_Item_Content_SingleSelect_Option {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      icon: isSet(object.icon) ? globalThis.String(object.icon) : "",
    };
  },

  toJSON(message: PersonalizationScreen_Section_Item_Content_SingleSelect_Option): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.icon !== "") {
      obj.icon = message.icon;
    }
    return obj;
  },
};

export const PersonalizationScreen_Section_Item_Content_MultiSelect = {
  fromJSON(object: any): PersonalizationScreen_Section_Item_Content_MultiSelect {
    return {
      options: globalThis.Array.isArray(object?.options)
        ? object.options.map((e: any) => PersonalizationScreen_Section_Item_Content_MultiSelect_Option.fromJSON(e))
        : [],
      selected_option_ids: globalThis.Array.isArray(object?.selected_option_ids)
        ? object.selected_option_ids.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: PersonalizationScreen_Section_Item_Content_MultiSelect): unknown {
    const obj: any = {};
    if (message.options?.length) {
      obj.options = message.options.map((e) => PersonalizationScreen_Section_Item_Content_MultiSelect_Option.toJSON(e));
    }
    if (message.selected_option_ids?.length) {
      obj.selected_option_ids = message.selected_option_ids;
    }
    return obj;
  },
};

export const PersonalizationScreen_Section_Item_Content_MultiSelect_Option = {
  fromJSON(object: any): PersonalizationScreen_Section_Item_Content_MultiSelect_Option {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      is_exclusive: isSet(object.is_exclusive) ? globalThis.Boolean(object.is_exclusive) : false,
    };
  },

  toJSON(message: PersonalizationScreen_Section_Item_Content_MultiSelect_Option): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.is_exclusive !== false) {
      obj.is_exclusive = message.is_exclusive;
    }
    return obj;
  },
};

export const PersonalizationScreen_Section_Item_Content_TimePicker = {
  fromJSON(object: any): PersonalizationScreen_Section_Item_Content_TimePicker {
    return {
      selected_time: isSet(object.selected_time) ? fromJsonTimestamp(object.selected_time) : undefined,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
    };
  },

  toJSON(message: PersonalizationScreen_Section_Item_Content_TimePicker): unknown {
    const obj: any = {};
    if (message.selected_time !== undefined) {
      obj.selected_time = message.selected_time.toISOString();
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    return obj;
  },
};

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
