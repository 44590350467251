import React, { FC } from "react"
import { Box, BoxProps, Flex, Icon, List, useDisclosure } from "@chakra-ui/react"
import { VFlex } from "~/components/shared/VFlex"
import { SkinIssues } from "~/generated/paywall"

import { IssuesListItem } from "./IssuesListItem"
import { MagicIcon } from "./Icons"

import lineBgSrc from "./assets/line.svg"
import arrowBgSrc from "./assets/arrow.svg"
import { useTranslation } from "react-i18next"

const IssuesListContent: FC<{ issues: SkinIssues[] }> = ({ issues }) => {
  const { isOpen, onToggle } = useDisclosure()
  const headIssues = issues.slice(0, 2)
  const tailIssues = issues.slice(2)
  const { t } = useTranslation()
  return (
    <>
      <Box textStyle="Subtitle/Secondary">{t("scan_result_page.you_are_here")}</Box>
      <List spacing={3}>
        {headIssues.map((issue) => (
          <IssuesListItem key={issue} issue={issue} />
        ))}
        {isOpen && tailIssues.map((issue) => <IssuesListItem key={issue} issue={issue} />)}
      </List>
      {tailIssues.length > 0 && (
        <Box
          textStyle="Subtitle/Tertiary"
          onClick={onToggle}
          cursor="pointer"
          color="Base/accentPrimary"
        >
          {isOpen
            ? t("scan_result_page.show_less")
            : t("scan_result_page.and_more", { more: tailIssues.length })}
        </Box>
      )}
    </>
  )
}

export const IssuesListWrapper: FC<BoxProps> = ({ children }) => {
  const { t } = useTranslation()
  return (
    <Flex gap={2} alignItems="stretch">
      <VFlex gap={3}>{children}</VFlex>
      <Box
        display="flex"
        w="48px"
        bgRepeat="no-repeat"
        bgPosition="center left"
        bgSize="7px 610px"
        bgImage={`url(${lineBgSrc})`}
        justifyContent="right"
        alignItems="center"
      >
        <img src={arrowBgSrc} width="36" height="18" />
      </Box>
      <VFlex alignItems="center" alignSelf="center">
        <Icon as={MagicIcon} h="64px" w="64px" margin={0} />
        <Box whiteSpace="pre-line" mt={2} textStyle="Subtitle/Secondary" textAlign="center">
          {t("scan_result_page.dream_skin")}
        </Box>
      </VFlex>
    </Flex>
  )
}

export const IssuesList: FC<{ issues: SkinIssues[] }> = ({ issues }) =>
  issues && issues.length > 0 ? (
    <IssuesListWrapper>
      <IssuesListContent issues={issues} />
    </IssuesListWrapper>
  ) : null
