// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: face_yoga.proto

/* eslint-disable */
import { ActionType } from "./actions";
import { Request } from "./networking";

export interface PlayerChaptersDataRequest {
  request: Request | undefined;
  player_id: string;
}

export interface VideoPlayerWithChapters {
  id: string;
  chapters: VideoPlayerWithChapters_Chapter[];
  title: string;
  purpose: VideoPlayerWithChapters_Purpose;
  action: ActionType | undefined;
  force_mute_on_start: boolean;
  close_finished_action: ActionType | undefined;
}

export enum VideoPlayerWithChapters_Purpose {
  RITUALS = "RITUALS",
  PRODUCT_TUTORIAL = "PRODUCT_TUTORIAL",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function videoPlayerWithChapters_PurposeFromJSON(object: any): VideoPlayerWithChapters_Purpose {
  switch (object) {
    case 0:
    case "RITUALS":
      return VideoPlayerWithChapters_Purpose.RITUALS;
    case 1:
    case "PRODUCT_TUTORIAL":
      return VideoPlayerWithChapters_Purpose.PRODUCT_TUTORIAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VideoPlayerWithChapters_Purpose.UNRECOGNIZED;
  }
}

export function videoPlayerWithChapters_PurposeToJSON(object: VideoPlayerWithChapters_Purpose): string {
  switch (object) {
    case VideoPlayerWithChapters_Purpose.RITUALS:
      return "RITUALS";
    case VideoPlayerWithChapters_Purpose.PRODUCT_TUTORIAL:
      return "PRODUCT_TUTORIAL";
    case VideoPlayerWithChapters_Purpose.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface VideoPlayerWithChapters_Chapter {
  id: string;
  title: string;
  description: string;
  intro_duration: number;
  video_url: string;
  tags: string[];
  subtitles: VideoPlayerWithChapters_Chapter_Subtitle[];
  video_duration: number;
}

export interface VideoPlayerWithChapters_Chapter_Subtitle {
  title: string;
  start_time: number;
  duration: number;
}

export const PlayerChaptersDataRequest = {
  fromJSON(object: any): PlayerChaptersDataRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      player_id: isSet(object.player_id) ? globalThis.String(object.player_id) : "",
    };
  },

  toJSON(message: PlayerChaptersDataRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.player_id !== "") {
      obj.player_id = message.player_id;
    }
    return obj;
  },
};

export const VideoPlayerWithChapters = {
  fromJSON(object: any): VideoPlayerWithChapters {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      chapters: globalThis.Array.isArray(object?.chapters)
        ? object.chapters.map((e: any) => VideoPlayerWithChapters_Chapter.fromJSON(e))
        : [],
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      purpose: isSet(object.purpose)
        ? videoPlayerWithChapters_PurposeFromJSON(object.purpose)
        : VideoPlayerWithChapters_Purpose.RITUALS,
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
      force_mute_on_start: isSet(object.force_mute_on_start) ? globalThis.Boolean(object.force_mute_on_start) : false,
      close_finished_action: isSet(object.close_finished_action)
        ? ActionType.fromJSON(object.close_finished_action)
        : undefined,
    };
  },

  toJSON(message: VideoPlayerWithChapters): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.chapters?.length) {
      obj.chapters = message.chapters.map((e) => VideoPlayerWithChapters_Chapter.toJSON(e));
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.purpose !== VideoPlayerWithChapters_Purpose.RITUALS) {
      obj.purpose = videoPlayerWithChapters_PurposeToJSON(message.purpose);
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    if (message.force_mute_on_start !== false) {
      obj.force_mute_on_start = message.force_mute_on_start;
    }
    if (message.close_finished_action !== undefined) {
      obj.close_finished_action = ActionType.toJSON(message.close_finished_action);
    }
    return obj;
  },
};

export const VideoPlayerWithChapters_Chapter = {
  fromJSON(object: any): VideoPlayerWithChapters_Chapter {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      intro_duration: isSet(object.intro_duration) ? globalThis.Number(object.intro_duration) : 0,
      video_url: isSet(object.video_url) ? globalThis.String(object.video_url) : "",
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [],
      subtitles: globalThis.Array.isArray(object?.subtitles)
        ? object.subtitles.map((e: any) => VideoPlayerWithChapters_Chapter_Subtitle.fromJSON(e))
        : [],
      video_duration: isSet(object.video_duration) ? globalThis.Number(object.video_duration) : 0,
    };
  },

  toJSON(message: VideoPlayerWithChapters_Chapter): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.intro_duration !== 0) {
      obj.intro_duration = message.intro_duration;
    }
    if (message.video_url !== "") {
      obj.video_url = message.video_url;
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    if (message.subtitles?.length) {
      obj.subtitles = message.subtitles.map((e) => VideoPlayerWithChapters_Chapter_Subtitle.toJSON(e));
    }
    if (message.video_duration !== 0) {
      obj.video_duration = message.video_duration;
    }
    return obj;
  },
};

export const VideoPlayerWithChapters_Chapter_Subtitle = {
  fromJSON(object: any): VideoPlayerWithChapters_Chapter_Subtitle {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      start_time: isSet(object.start_time) ? globalThis.Number(object.start_time) : 0,
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
    };
  },

  toJSON(message: VideoPlayerWithChapters_Chapter_Subtitle): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.start_time !== 0) {
      obj.start_time = message.start_time;
    }
    if (message.duration !== 0) {
      obj.duration = message.duration;
    }
    return obj;
  },
};

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
