import type { ComponentStyleConfig /* keyframes */ } from "@chakra-ui/react"
import i18n from "~/i18n"
/* FIXME
add in theme, else breaks build in paywall

const buttonKeyframes = keyframes`
0% { background-position: left; }
78% { background-position: left; }
100% { background-position: right; }
`

const buttonAnimation = `${buttonKeyframes} 3.9s ease-in infinite`
 */
const pora = {
  variants: {
    share: {
      bg: "Base/basePrimary",
      color: "white",
      borderRadius: "full",
      _active: { bg: "black" },
      _hover: { bg: "black" },
    },
    next: {
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "-0.5px",
      fontWeight: 500,

      bg: "Base/accentPrimary",
      color: "white",
      borderRadius: "norm",
      height: "auto",
      px: 3,
      py: 2,
      _active: { transform: "scale(.95)" },
      _hover: {
        transform: "scale(.95)",
        _disabled: {
          bg: "Base/neutralSecondary",
          color: "Base/baseDisabled",
          opacity: 1,
          transform: "scale(1)",
        },
      },
      _disabled: {
        bg: "Base/neutralSecondary",
        color: "Base/baseDisabled",
        opacity: 1,
      },
    },
    nextWhite: {
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "-0.5px",
      fontWeight: 500,

      bg: "white",
      color: "black",
      borderRadius: "norm",
      height: "auto",
      px: 3,
      py: 2,
      _active: { transform: "scale(.95)" },
      _hover: {
        transform: "scale(.95)",
        _disabled: {
          bg: "white",
          transform: "scale(1)",
        },
      },
      _disabled: {
        bg: "white",
      },
    },
    welcome: {
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "-0.5px",
      fontWeight: 500,

      bg: "Base/accentPrimary",
      color: "white",
      borderRadius: "norm",
      height: "auto",
      px: 3,
      py: 2,
      _active: { transform: "scale(.95)" },
      _hover: {
        transform: "scale(.95)",
        _disabled: {
          bg: "Base/neutralSecondary",
          color: "Base/baseDisabled",
          opacity: 1,
          transform: "scale(1)",
        },
      },
      _disabled: {
        bg: "Base/neutralSecondary",
        color: "Base/baseDisabled",
        opacity: 1,
      },
    },
    inverted: {
      fontSize: "16px",
      lineHeight: "22px",
      letterSpacing: "-0.5px",
      fontWeight: 500,

      bg: "white",
      color: "black",
      borderRadius: "norm",
      height: "auto",
      px: 3,
      py: 4,
      _active: { transform: "scale(.95)" },
      _hover: {
        transform: "scale(.95)",
        _disabled: {
          bg: "white",
          transform: "scale(1)",
        },
      },
      _disabled: {
        bg: "white",
      },
    },
    primary: {
      // Subtitle/Primary
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: 500,
      letterSpacing: "-0.5px",

      bg: "Base/accentPrimary",
      color: "white",
      borderRadius: "20px",
      height: "auto",
      px: 3,
      py: 4,
      _active: { transform: "scale(.95)" },
      _hover: {
        transform: "scale(.95)",
        _disabled: {
          bg: "Base/neutralSecondary",
          color: "Base/baseDisabled",
          opacity: 1,
          transform: "scale(1)",
        },
      },
      _disabled: {
        bg: "Base/neutralSecondary",
        color: "Base/baseDisabled",
        opacity: 1,
      },
    },
    secondary: {
      // Subtitle/Primary
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: 500,
      letterSpacing: "-0.5px",

      bg: "Base/neutralSecondary",
      color: "Base/basePrimary",
      borderRadius: "20px",
      height: "auto",
      px: 3,
      py: 4,
      _active: { transform: "scale(.95)" },
      _hover: {
        transform: "scale(.95)",
        _disabled: {
          bg: "Base/neutralSecondary",
          color: "Base/baseDisabled",
          opacity: 1,
          transform: "scale(1)",
        },
      },
      _disabled: {
        bg: "Base/neutralSecondary",
        color: "Base/baseDisabled",
        opacity: 1,
      },
    },
    nextSmall: {
      // TODO reuse next variant
      // Subtitle/Secondary
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 500,
      letterSpacing: "-0.5px",

      bg: "Base/accentPrimary",
      color: "white",
      borderRadius: "brandSmall",
      height: "auto",
      px: 4,
      py: 2,
      _active: { transform: "scale(.95)" },
      _hover: {
        transform: "scale(.95)",
        _disabled: { bg: "Base/accentPrimary", transform: "scale(1)" },
      },
      _disabled: { bg: "Base/accentPrimary" },

      minHeight: "48px",
      pX: 4,
      pY: "13px" /* FIXME in Figma */,
    },
    action: {
      // Subtitle/Primary
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: 500,
      letterSpacing: "-0.5px",

      color: "white",
      borderRadius: "norm",
      minHeight: "56px",
      px: 6,
      py: 4,
      bgGradient:
        "linear-gradient(95.95deg, #00C0EB 26.62%, #01EBF9 36.88%, #00C0EB 46.95%, #01EAF8 56.82%, #00C0EB 67.27%)",
      backgroundSize: "350%",
      backgroundPosition: "left",
      /* animation: buttonAnimation, */
      _active: { transform: "scale(.95)" },
      _hover: {
        transform: "scale(.95)",
        _disabled: { bg: "Base/accentPrimary", transform: "scale(1)" },
      },
      _disabled: { bg: "Base/accentPrimary" },
    },
    applepay: {
      // Subtitle/Primary
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: 500,
      letterSpacing: "-0.5px",

      color: "Base/neutralPrimary",
      bg: "black",
      borderRadius: "full",
      px: 10,
      py: 4,
      height: "56px",

      cursor: "pointer",
      appearance: "-apple-pay-button",
      "-apple-pay-button-style": "black",
      "-apple-pay-button-type": "buy",
      "-webkit-locale": i18n.language,

      _active: { transform: "scale(.95)" },
      _hover: {
        transform: "scale(.95)",
        _disabled: { bg: "black", transform: "scale(1)" },
      },
      _loading: {
        opacity: 1,
      },
    },
  },
}

const loviNext = {
  // Subtitle/Primary
  fontSize: "18px",
  lineHeight: "24px",
  fontWeight: 500,
  letterSpacing: "-0.5px",

  borderRadius: "60px",
  bg: "Base/accentPrimary",
  boxShadow: "LoviActionButton",
  color: "white",

  height: "auto",
  px: 8,
  py: "18px",
  _active: { transform: "scale(.95)" },
  _hover: {
    transform: "scale(.95)",
    _disabled: {
      color: "Base/baseDisabled",
      opacity: 1,
      bg: "Base/neutralDisabled",
      transform: "scale(1)",
    },
  },
  _disabled: {
    color: "Base/baseDisabled",
    opacity: 1,
    bg: "Base/neutralDisabled",
  },
}

const loviNextWhite = {
  ...pora["variants"]["nextWhite"],
  py: "18px",
  borderRadius: "60px",
}

const loviSecondary = {
  ...pora["variants"]["secondary"],
  py: "18px",
  borderRadius: "60px",
}

const loviInverted = {
  ...pora["variants"]["inverted"],
  py: "18px",
  borderRadius: "60px",
}

const loviSolidAction = {
  ...loviNext,
  boxShadow: "none",
}

const loviTimerFloatAction = {
  ...loviNext,
  py: 4,
  px: 6,
}

const loviSolidActionSmall = {
  ...loviNext,
  boxShadow: "none",
  py: "12px",
  px: "16px",
}

const loviTimerFloatSmall = {
  ...loviSecondary,
  bg: "Base/accentSecondary",
  color: "white",
  padding: "10px",

  // "Subtitle/Tertiary"
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: 500,
  letterSpacing: "-0.5px",
}

const lovi: ComponentStyleConfig = {
  variants: {
    ...pora.variants,
    next: loviNext,
    inverted: loviInverted,
    action: loviNext,
    solidAction: loviSolidAction,
    solidActionSmall: loviSolidActionSmall,
    nextWhite: loviNextWhite,
    secondary: loviSecondary,
    timerFloatAction: loviTimerFloatAction,
    timerFloatSmall: loviTimerFloatSmall,
  },
}

export const ButtonTheme = (theme: Theme): ComponentStyleConfig => (theme === "lovi" ? lovi : pora)

export const VARIANTS = Array.from(Object.keys(pora.variants ?? {}))
