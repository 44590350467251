// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: ios_paywall.proto

/* eslint-disable */
import { Request, Response } from "./networking";
import { Media } from "./presentation";

export enum NavigationBarStyle {
  NAVIGATION_BAR_STYLE_NONE = "NAVIGATION_BAR_STYLE_NONE",
  NAVIGATION_BAR_STYLE_LIGHT = "NAVIGATION_BAR_STYLE_LIGHT",
  NAVIGATION_BAR_STYLE_DARK = "NAVIGATION_BAR_STYLE_DARK",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function navigationBarStyleFromJSON(object: any): NavigationBarStyle {
  switch (object) {
    case 0:
    case "NAVIGATION_BAR_STYLE_NONE":
      return NavigationBarStyle.NAVIGATION_BAR_STYLE_NONE;
    case 1:
    case "NAVIGATION_BAR_STYLE_LIGHT":
      return NavigationBarStyle.NAVIGATION_BAR_STYLE_LIGHT;
    case 2:
    case "NAVIGATION_BAR_STYLE_DARK":
      return NavigationBarStyle.NAVIGATION_BAR_STYLE_DARK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return NavigationBarStyle.UNRECOGNIZED;
  }
}

export function navigationBarStyleToJSON(object: NavigationBarStyle): string {
  switch (object) {
    case NavigationBarStyle.NAVIGATION_BAR_STYLE_NONE:
      return "NAVIGATION_BAR_STYLE_NONE";
    case NavigationBarStyle.NAVIGATION_BAR_STYLE_LIGHT:
      return "NAVIGATION_BAR_STYLE_LIGHT";
    case NavigationBarStyle.NAVIGATION_BAR_STYLE_DARK:
      return "NAVIGATION_BAR_STYLE_DARK";
    case NavigationBarStyle.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SubscriptionPurchasedRequest {
  request: Request | undefined;
  original_transaction_id: string;
}

export interface IoSPaywallScreen {
  title: string;
  close_button: IoSPaywallScreen_CloseButton;
  header: IoSPaywallScreen_Header | undefined;
  trial_toggle: IoSPaywallScreen_TrialToggle | undefined;
  subscriptions: IoSPaywallScreen_Subscription[];
  selected_subscription_id: string;
  bottom_button:
    | IoSPaywallScreen_BottomButton
    | undefined;
  /**
   * use media_resource instead
   *
   * @deprecated
   */
  background_image: string;
  should_scale_background_image: boolean;
  navBarStyle: NavigationBarStyle;
  promo_code_title: string;
  media_resource: Media | undefined;
}

export enum IoSPaywallScreen_CloseButton {
  CLOSE_BUTTON_NONE = "CLOSE_BUTTON_NONE",
  CLOSE_BUTTON_CLOSE = "CLOSE_BUTTON_CLOSE",
  CLOSE_BUTTON_SKIP = "CLOSE_BUTTON_SKIP",
  CLOSE_BUTTON_CLOSE_PRONOUNCED = "CLOSE_BUTTON_CLOSE_PRONOUNCED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function ioSPaywallScreen_CloseButtonFromJSON(object: any): IoSPaywallScreen_CloseButton {
  switch (object) {
    case 0:
    case "CLOSE_BUTTON_NONE":
      return IoSPaywallScreen_CloseButton.CLOSE_BUTTON_NONE;
    case 1:
    case "CLOSE_BUTTON_CLOSE":
      return IoSPaywallScreen_CloseButton.CLOSE_BUTTON_CLOSE;
    case 2:
    case "CLOSE_BUTTON_SKIP":
      return IoSPaywallScreen_CloseButton.CLOSE_BUTTON_SKIP;
    case 3:
    case "CLOSE_BUTTON_CLOSE_PRONOUNCED":
      return IoSPaywallScreen_CloseButton.CLOSE_BUTTON_CLOSE_PRONOUNCED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return IoSPaywallScreen_CloseButton.UNRECOGNIZED;
  }
}

export function ioSPaywallScreen_CloseButtonToJSON(object: IoSPaywallScreen_CloseButton): string {
  switch (object) {
    case IoSPaywallScreen_CloseButton.CLOSE_BUTTON_NONE:
      return "CLOSE_BUTTON_NONE";
    case IoSPaywallScreen_CloseButton.CLOSE_BUTTON_CLOSE:
      return "CLOSE_BUTTON_CLOSE";
    case IoSPaywallScreen_CloseButton.CLOSE_BUTTON_SKIP:
      return "CLOSE_BUTTON_SKIP";
    case IoSPaywallScreen_CloseButton.CLOSE_BUTTON_CLOSE_PRONOUNCED:
      return "CLOSE_BUTTON_CLOSE_PRONOUNCED";
    case IoSPaywallScreen_CloseButton.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface IoSPaywallScreen_Header {
  header?:
    | { $case: "reviews"; reviews: IoSPaywallScreen_Header_Reviews }
    | { $case: "featuresV1"; featuresV1: IoSPaywallScreen_Header_FeaturesV1 }
    | { $case: "featuresV2"; featuresV2: IoSPaywallScreen_Header_FeaturesV2 }
    | undefined;
}

export interface IoSPaywallScreen_Header_Reviews {
  reviews: IoSPaywallScreen_Header_Reviews_Review[];
}

export interface IoSPaywallScreen_Header_Reviews_Review {
  text: string;
  author: string;
}

export interface IoSPaywallScreen_Header_FeaturesV1 {
  features: IoSPaywallScreen_Header_FeaturesV1_Feature[];
}

export interface IoSPaywallScreen_Header_FeaturesV1_Feature {
  image_name: string;
  title: string;
}

export interface IoSPaywallScreen_Header_FeaturesV2 {
  titles: string[];
}

export interface IoSPaywallScreen_Subscription {
  id: string;
  /** custom title for this subscription */
  custom_title: string;
  /** Promo is some discounted price for this subscription. E.g: $19.99 for the first month, Then $39.99 a month. $19.99 is Promo */
  promo:
    | IoSPaywallScreen_Subscription_Promo
    | undefined;
  /** most popular and etc. */
  tag: string;
}

export interface IoSPaywallScreen_Subscription_Promo {
  /** Each subscription can have max 10 promo keys. */
  subscriptions_keys: string[];
  /** The promo that will se used as main. If there is no selected_promo_key, the first promo_key will be used. */
  selected_key: string;
}

export interface IoSPaywallScreen_BottomButton {
  no_trial_title: string;
  /**
   * Template format: "Start {period} Free Trial"
   * {period} is replaced by the trial period of the selected subscription
   */
  trial_title_template: string;
  trial_subtitle: string;
}

export interface IoSPaywallScreen_TrialToggle {
  is_enabled: boolean;
  enabled_text: string;
  disabled_text: string;
}

export interface OneSubscriptionPaywallScreen {
  subscription: string;
  /**
   * use media_resource instead
   *
   * @deprecated
   */
  image: string;
  title: string;
  subtitle: string;
  in_focus_tag: string;
  button_title: string;
  tags: string[];
  navBarStyle: NavigationBarStyle;
  subscription_title: string;
  media_resource: Media | undefined;
}

export interface AppPaywallRequest {
  request: Request | undefined;
  user_id: string;
  paywall_feature: string;
}

export interface AppPaywallResponse {
  response: Response | undefined;
  paywall_id: string;
}

export const SubscriptionPurchasedRequest = {
  fromJSON(object: any): SubscriptionPurchasedRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      original_transaction_id: isSet(object.original_transaction_id)
        ? globalThis.String(object.original_transaction_id)
        : "",
    };
  },

  toJSON(message: SubscriptionPurchasedRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.original_transaction_id !== "") {
      obj.original_transaction_id = message.original_transaction_id;
    }
    return obj;
  },
};

export const IoSPaywallScreen = {
  fromJSON(object: any): IoSPaywallScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      close_button: isSet(object.close_button)
        ? ioSPaywallScreen_CloseButtonFromJSON(object.close_button)
        : IoSPaywallScreen_CloseButton.CLOSE_BUTTON_NONE,
      header: isSet(object.header) ? IoSPaywallScreen_Header.fromJSON(object.header) : undefined,
      trial_toggle: isSet(object.trial_toggle) ? IoSPaywallScreen_TrialToggle.fromJSON(object.trial_toggle) : undefined,
      subscriptions: globalThis.Array.isArray(object?.subscriptions)
        ? object.subscriptions.map((e: any) => IoSPaywallScreen_Subscription.fromJSON(e))
        : [],
      selected_subscription_id: isSet(object.selected_subscription_id)
        ? globalThis.String(object.selected_subscription_id)
        : "",
      bottom_button: isSet(object.bottom_button)
        ? IoSPaywallScreen_BottomButton.fromJSON(object.bottom_button)
        : undefined,
      background_image: isSet(object.background_image) ? globalThis.String(object.background_image) : "",
      should_scale_background_image: isSet(object.should_scale_background_image)
        ? globalThis.Boolean(object.should_scale_background_image)
        : false,
      navBarStyle: isSet(object.navBarStyle)
        ? navigationBarStyleFromJSON(object.navBarStyle)
        : NavigationBarStyle.NAVIGATION_BAR_STYLE_NONE,
      promo_code_title: isSet(object.promo_code_title) ? globalThis.String(object.promo_code_title) : "",
      media_resource: isSet(object.media_resource) ? Media.fromJSON(object.media_resource) : undefined,
    };
  },

  toJSON(message: IoSPaywallScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.close_button !== IoSPaywallScreen_CloseButton.CLOSE_BUTTON_NONE) {
      obj.close_button = ioSPaywallScreen_CloseButtonToJSON(message.close_button);
    }
    if (message.header !== undefined) {
      obj.header = IoSPaywallScreen_Header.toJSON(message.header);
    }
    if (message.trial_toggle !== undefined) {
      obj.trial_toggle = IoSPaywallScreen_TrialToggle.toJSON(message.trial_toggle);
    }
    if (message.subscriptions?.length) {
      obj.subscriptions = message.subscriptions.map((e) => IoSPaywallScreen_Subscription.toJSON(e));
    }
    if (message.selected_subscription_id !== "") {
      obj.selected_subscription_id = message.selected_subscription_id;
    }
    if (message.bottom_button !== undefined) {
      obj.bottom_button = IoSPaywallScreen_BottomButton.toJSON(message.bottom_button);
    }
    if (message.background_image !== "") {
      obj.background_image = message.background_image;
    }
    if (message.should_scale_background_image !== false) {
      obj.should_scale_background_image = message.should_scale_background_image;
    }
    if (message.navBarStyle !== NavigationBarStyle.NAVIGATION_BAR_STYLE_NONE) {
      obj.navBarStyle = navigationBarStyleToJSON(message.navBarStyle);
    }
    if (message.promo_code_title !== "") {
      obj.promo_code_title = message.promo_code_title;
    }
    if (message.media_resource !== undefined) {
      obj.media_resource = Media.toJSON(message.media_resource);
    }
    return obj;
  },
};

export const IoSPaywallScreen_Header = {
  fromJSON(object: any): IoSPaywallScreen_Header {
    return {
      header: isSet(object.reviews)
        ? { $case: "reviews", reviews: IoSPaywallScreen_Header_Reviews.fromJSON(object.reviews) }
        : isSet(object.featuresV1)
        ? { $case: "featuresV1", featuresV1: IoSPaywallScreen_Header_FeaturesV1.fromJSON(object.featuresV1) }
        : isSet(object.featuresV2)
        ? { $case: "featuresV2", featuresV2: IoSPaywallScreen_Header_FeaturesV2.fromJSON(object.featuresV2) }
        : undefined,
    };
  },

  toJSON(message: IoSPaywallScreen_Header): unknown {
    const obj: any = {};
    if (message.header?.$case === "reviews") {
      obj.reviews = IoSPaywallScreen_Header_Reviews.toJSON(message.header.reviews);
    }
    if (message.header?.$case === "featuresV1") {
      obj.featuresV1 = IoSPaywallScreen_Header_FeaturesV1.toJSON(message.header.featuresV1);
    }
    if (message.header?.$case === "featuresV2") {
      obj.featuresV2 = IoSPaywallScreen_Header_FeaturesV2.toJSON(message.header.featuresV2);
    }
    return obj;
  },
};

export const IoSPaywallScreen_Header_Reviews = {
  fromJSON(object: any): IoSPaywallScreen_Header_Reviews {
    return {
      reviews: globalThis.Array.isArray(object?.reviews)
        ? object.reviews.map((e: any) => IoSPaywallScreen_Header_Reviews_Review.fromJSON(e))
        : [],
    };
  },

  toJSON(message: IoSPaywallScreen_Header_Reviews): unknown {
    const obj: any = {};
    if (message.reviews?.length) {
      obj.reviews = message.reviews.map((e) => IoSPaywallScreen_Header_Reviews_Review.toJSON(e));
    }
    return obj;
  },
};

export const IoSPaywallScreen_Header_Reviews_Review = {
  fromJSON(object: any): IoSPaywallScreen_Header_Reviews_Review {
    return {
      text: isSet(object.text) ? globalThis.String(object.text) : "",
      author: isSet(object.author) ? globalThis.String(object.author) : "",
    };
  },

  toJSON(message: IoSPaywallScreen_Header_Reviews_Review): unknown {
    const obj: any = {};
    if (message.text !== "") {
      obj.text = message.text;
    }
    if (message.author !== "") {
      obj.author = message.author;
    }
    return obj;
  },
};

export const IoSPaywallScreen_Header_FeaturesV1 = {
  fromJSON(object: any): IoSPaywallScreen_Header_FeaturesV1 {
    return {
      features: globalThis.Array.isArray(object?.features)
        ? object.features.map((e: any) => IoSPaywallScreen_Header_FeaturesV1_Feature.fromJSON(e))
        : [],
    };
  },

  toJSON(message: IoSPaywallScreen_Header_FeaturesV1): unknown {
    const obj: any = {};
    if (message.features?.length) {
      obj.features = message.features.map((e) => IoSPaywallScreen_Header_FeaturesV1_Feature.toJSON(e));
    }
    return obj;
  },
};

export const IoSPaywallScreen_Header_FeaturesV1_Feature = {
  fromJSON(object: any): IoSPaywallScreen_Header_FeaturesV1_Feature {
    return {
      image_name: isSet(object.image_name) ? globalThis.String(object.image_name) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
    };
  },

  toJSON(message: IoSPaywallScreen_Header_FeaturesV1_Feature): unknown {
    const obj: any = {};
    if (message.image_name !== "") {
      obj.image_name = message.image_name;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    return obj;
  },
};

export const IoSPaywallScreen_Header_FeaturesV2 = {
  fromJSON(object: any): IoSPaywallScreen_Header_FeaturesV2 {
    return {
      titles: globalThis.Array.isArray(object?.titles) ? object.titles.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: IoSPaywallScreen_Header_FeaturesV2): unknown {
    const obj: any = {};
    if (message.titles?.length) {
      obj.titles = message.titles;
    }
    return obj;
  },
};

export const IoSPaywallScreen_Subscription = {
  fromJSON(object: any): IoSPaywallScreen_Subscription {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      custom_title: isSet(object.custom_title) ? globalThis.String(object.custom_title) : "",
      promo: isSet(object.promo) ? IoSPaywallScreen_Subscription_Promo.fromJSON(object.promo) : undefined,
      tag: isSet(object.tag) ? globalThis.String(object.tag) : "",
    };
  },

  toJSON(message: IoSPaywallScreen_Subscription): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.custom_title !== "") {
      obj.custom_title = message.custom_title;
    }
    if (message.promo !== undefined) {
      obj.promo = IoSPaywallScreen_Subscription_Promo.toJSON(message.promo);
    }
    if (message.tag !== "") {
      obj.tag = message.tag;
    }
    return obj;
  },
};

export const IoSPaywallScreen_Subscription_Promo = {
  fromJSON(object: any): IoSPaywallScreen_Subscription_Promo {
    return {
      subscriptions_keys: globalThis.Array.isArray(object?.subscriptions_keys)
        ? object.subscriptions_keys.map((e: any) => globalThis.String(e))
        : [],
      selected_key: isSet(object.selected_key) ? globalThis.String(object.selected_key) : "",
    };
  },

  toJSON(message: IoSPaywallScreen_Subscription_Promo): unknown {
    const obj: any = {};
    if (message.subscriptions_keys?.length) {
      obj.subscriptions_keys = message.subscriptions_keys;
    }
    if (message.selected_key !== "") {
      obj.selected_key = message.selected_key;
    }
    return obj;
  },
};

export const IoSPaywallScreen_BottomButton = {
  fromJSON(object: any): IoSPaywallScreen_BottomButton {
    return {
      no_trial_title: isSet(object.no_trial_title) ? globalThis.String(object.no_trial_title) : "",
      trial_title_template: isSet(object.trial_title_template) ? globalThis.String(object.trial_title_template) : "",
      trial_subtitle: isSet(object.trial_subtitle) ? globalThis.String(object.trial_subtitle) : "",
    };
  },

  toJSON(message: IoSPaywallScreen_BottomButton): unknown {
    const obj: any = {};
    if (message.no_trial_title !== "") {
      obj.no_trial_title = message.no_trial_title;
    }
    if (message.trial_title_template !== "") {
      obj.trial_title_template = message.trial_title_template;
    }
    if (message.trial_subtitle !== "") {
      obj.trial_subtitle = message.trial_subtitle;
    }
    return obj;
  },
};

export const IoSPaywallScreen_TrialToggle = {
  fromJSON(object: any): IoSPaywallScreen_TrialToggle {
    return {
      is_enabled: isSet(object.is_enabled) ? globalThis.Boolean(object.is_enabled) : false,
      enabled_text: isSet(object.enabled_text) ? globalThis.String(object.enabled_text) : "",
      disabled_text: isSet(object.disabled_text) ? globalThis.String(object.disabled_text) : "",
    };
  },

  toJSON(message: IoSPaywallScreen_TrialToggle): unknown {
    const obj: any = {};
    if (message.is_enabled !== false) {
      obj.is_enabled = message.is_enabled;
    }
    if (message.enabled_text !== "") {
      obj.enabled_text = message.enabled_text;
    }
    if (message.disabled_text !== "") {
      obj.disabled_text = message.disabled_text;
    }
    return obj;
  },
};

export const OneSubscriptionPaywallScreen = {
  fromJSON(object: any): OneSubscriptionPaywallScreen {
    return {
      subscription: isSet(object.subscription) ? globalThis.String(object.subscription) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
      in_focus_tag: isSet(object.in_focus_tag) ? globalThis.String(object.in_focus_tag) : "",
      button_title: isSet(object.button_title) ? globalThis.String(object.button_title) : "",
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [],
      navBarStyle: isSet(object.navBarStyle)
        ? navigationBarStyleFromJSON(object.navBarStyle)
        : NavigationBarStyle.NAVIGATION_BAR_STYLE_NONE,
      subscription_title: isSet(object.subscription_title) ? globalThis.String(object.subscription_title) : "",
      media_resource: isSet(object.media_resource) ? Media.fromJSON(object.media_resource) : undefined,
    };
  },

  toJSON(message: OneSubscriptionPaywallScreen): unknown {
    const obj: any = {};
    if (message.subscription !== "") {
      obj.subscription = message.subscription;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    if (message.in_focus_tag !== "") {
      obj.in_focus_tag = message.in_focus_tag;
    }
    if (message.button_title !== "") {
      obj.button_title = message.button_title;
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    if (message.navBarStyle !== NavigationBarStyle.NAVIGATION_BAR_STYLE_NONE) {
      obj.navBarStyle = navigationBarStyleToJSON(message.navBarStyle);
    }
    if (message.subscription_title !== "") {
      obj.subscription_title = message.subscription_title;
    }
    if (message.media_resource !== undefined) {
      obj.media_resource = Media.toJSON(message.media_resource);
    }
    return obj;
  },
};

export const AppPaywallRequest = {
  fromJSON(object: any): AppPaywallRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      user_id: isSet(object.user_id) ? globalThis.String(object.user_id) : "",
      paywall_feature: isSet(object.paywall_feature) ? globalThis.String(object.paywall_feature) : "",
    };
  },

  toJSON(message: AppPaywallRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.user_id !== "") {
      obj.user_id = message.user_id;
    }
    if (message.paywall_feature !== "") {
      obj.paywall_feature = message.paywall_feature;
    }
    return obj;
  },
};

export const AppPaywallResponse = {
  fromJSON(object: any): AppPaywallResponse {
    return {
      response: isSet(object.response) ? Response.fromJSON(object.response) : undefined,
      paywall_id: isSet(object.paywall_id) ? globalThis.String(object.paywall_id) : "",
    };
  },

  toJSON(message: AppPaywallResponse): unknown {
    const obj: any = {};
    if (message.response !== undefined) {
      obj.response = Response.toJSON(message.response);
    }
    if (message.paywall_id !== "") {
      obj.paywall_id = message.paywall_id;
    }
    return obj;
  },
};

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
