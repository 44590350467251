// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: face_camera.proto

/* eslint-disable */
import { Any } from "./google/protobuf/any";
import { Request } from "./networking";
import { Image } from "./presentation";
import { VideoInstructionScreen } from "./video_instruction";

export interface FaceCameraScreen {
  variant: FaceCameraScreen_ScannerVariant;
  allow_closing: boolean;
  allow_photo_retake: boolean;
  photo_attempts: number;
  analysis_steps: string[];
  target_positions: FaceCameraScreen_TargetPositions;
  allow_arkit_camera: boolean;
  scan_endpoint_params: { [key: string]: string };
  video_instruction: VideoInstructionScreen | undefined;
  should_show_instructions_on_appear: boolean;
  consent_action: Any | undefined;
  request: FaceScanUploadFrontImageRequest | undefined;
}

export enum FaceCameraScreen_TargetPositions {
  TARGET_POSITIONS_NONE = "TARGET_POSITIONS_NONE",
  TARGET_POSITIONS_FRONT = "TARGET_POSITIONS_FRONT",
  TARGET_POSITIONS_LEFT_FRONT_RIGHT = "TARGET_POSITIONS_LEFT_FRONT_RIGHT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function faceCameraScreen_TargetPositionsFromJSON(object: any): FaceCameraScreen_TargetPositions {
  switch (object) {
    case 0:
    case "TARGET_POSITIONS_NONE":
      return FaceCameraScreen_TargetPositions.TARGET_POSITIONS_NONE;
    case 1:
    case "TARGET_POSITIONS_FRONT":
      return FaceCameraScreen_TargetPositions.TARGET_POSITIONS_FRONT;
    case 2:
    case "TARGET_POSITIONS_LEFT_FRONT_RIGHT":
      return FaceCameraScreen_TargetPositions.TARGET_POSITIONS_LEFT_FRONT_RIGHT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FaceCameraScreen_TargetPositions.UNRECOGNIZED;
  }
}

export function faceCameraScreen_TargetPositionsToJSON(object: FaceCameraScreen_TargetPositions): string {
  switch (object) {
    case FaceCameraScreen_TargetPositions.TARGET_POSITIONS_NONE:
      return "TARGET_POSITIONS_NONE";
    case FaceCameraScreen_TargetPositions.TARGET_POSITIONS_FRONT:
      return "TARGET_POSITIONS_FRONT";
    case FaceCameraScreen_TargetPositions.TARGET_POSITIONS_LEFT_FRONT_RIGHT:
      return "TARGET_POSITIONS_LEFT_FRONT_RIGHT";
    case FaceCameraScreen_TargetPositions.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum FaceCameraScreen_ScannerVariant {
  SCANNER_VARIANT_NOTHING = "SCANNER_VARIANT_NOTHING",
  SCANNER_VARIANT_POINT_AIM_THREE_POSITIONS = "SCANNER_VARIANT_POINT_AIM_THREE_POSITIONS",
  SCANNER_VARIANT_HAPTIC_FEEDBACK_THREE_POSITIONS = "SCANNER_VARIANT_HAPTIC_FEEDBACK_THREE_POSITIONS",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function faceCameraScreen_ScannerVariantFromJSON(object: any): FaceCameraScreen_ScannerVariant {
  switch (object) {
    case 0:
    case "SCANNER_VARIANT_NOTHING":
      return FaceCameraScreen_ScannerVariant.SCANNER_VARIANT_NOTHING;
    case 1:
    case "SCANNER_VARIANT_POINT_AIM_THREE_POSITIONS":
      return FaceCameraScreen_ScannerVariant.SCANNER_VARIANT_POINT_AIM_THREE_POSITIONS;
    case 2:
    case "SCANNER_VARIANT_HAPTIC_FEEDBACK_THREE_POSITIONS":
      return FaceCameraScreen_ScannerVariant.SCANNER_VARIANT_HAPTIC_FEEDBACK_THREE_POSITIONS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FaceCameraScreen_ScannerVariant.UNRECOGNIZED;
  }
}

export function faceCameraScreen_ScannerVariantToJSON(object: FaceCameraScreen_ScannerVariant): string {
  switch (object) {
    case FaceCameraScreen_ScannerVariant.SCANNER_VARIANT_NOTHING:
      return "SCANNER_VARIANT_NOTHING";
    case FaceCameraScreen_ScannerVariant.SCANNER_VARIANT_POINT_AIM_THREE_POSITIONS:
      return "SCANNER_VARIANT_POINT_AIM_THREE_POSITIONS";
    case FaceCameraScreen_ScannerVariant.SCANNER_VARIANT_HAPTIC_FEEDBACK_THREE_POSITIONS:
      return "SCANNER_VARIANT_HAPTIC_FEEDBACK_THREE_POSITIONS";
    case FaceCameraScreen_ScannerVariant.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface FaceCameraScreen_ScanEndpointParamsEntry {
  key: string;
  value: string;
}

export interface FaceScanUploadFrontImageRequest {
  request: Request | undefined;
  url: string;
  frontal_image: Image | undefined;
}

export const FaceCameraScreen = {
  fromJSON(object: any): FaceCameraScreen {
    return {
      variant: isSet(object.variant)
        ? faceCameraScreen_ScannerVariantFromJSON(object.variant)
        : FaceCameraScreen_ScannerVariant.SCANNER_VARIANT_NOTHING,
      allow_closing: isSet(object.allow_closing) ? globalThis.Boolean(object.allow_closing) : false,
      allow_photo_retake: isSet(object.allow_photo_retake) ? globalThis.Boolean(object.allow_photo_retake) : false,
      photo_attempts: isSet(object.photo_attempts) ? globalThis.Number(object.photo_attempts) : 0,
      analysis_steps: globalThis.Array.isArray(object?.analysis_steps)
        ? object.analysis_steps.map((e: any) => globalThis.String(e))
        : [],
      target_positions: isSet(object.target_positions)
        ? faceCameraScreen_TargetPositionsFromJSON(object.target_positions)
        : FaceCameraScreen_TargetPositions.TARGET_POSITIONS_NONE,
      allow_arkit_camera: isSet(object.allow_arkit_camera) ? globalThis.Boolean(object.allow_arkit_camera) : false,
      scan_endpoint_params: isObject(object.scan_endpoint_params)
        ? Object.entries(object.scan_endpoint_params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      video_instruction: isSet(object.video_instruction)
        ? VideoInstructionScreen.fromJSON(object.video_instruction)
        : undefined,
      should_show_instructions_on_appear: isSet(object.should_show_instructions_on_appear)
        ? globalThis.Boolean(object.should_show_instructions_on_appear)
        : false,
      consent_action: isSet(object.consent_action) ? Any.fromJSON(object.consent_action) : undefined,
      request: isSet(object.request) ? FaceScanUploadFrontImageRequest.fromJSON(object.request) : undefined,
    };
  },

  toJSON(message: FaceCameraScreen): unknown {
    const obj: any = {};
    if (message.variant !== FaceCameraScreen_ScannerVariant.SCANNER_VARIANT_NOTHING) {
      obj.variant = faceCameraScreen_ScannerVariantToJSON(message.variant);
    }
    if (message.allow_closing !== false) {
      obj.allow_closing = message.allow_closing;
    }
    if (message.allow_photo_retake !== false) {
      obj.allow_photo_retake = message.allow_photo_retake;
    }
    if (message.photo_attempts !== 0) {
      obj.photo_attempts = Math.round(message.photo_attempts);
    }
    if (message.analysis_steps?.length) {
      obj.analysis_steps = message.analysis_steps;
    }
    if (message.target_positions !== FaceCameraScreen_TargetPositions.TARGET_POSITIONS_NONE) {
      obj.target_positions = faceCameraScreen_TargetPositionsToJSON(message.target_positions);
    }
    if (message.allow_arkit_camera !== false) {
      obj.allow_arkit_camera = message.allow_arkit_camera;
    }
    if (message.scan_endpoint_params) {
      const entries = Object.entries(message.scan_endpoint_params);
      if (entries.length > 0) {
        obj.scan_endpoint_params = {};
        entries.forEach(([k, v]) => {
          obj.scan_endpoint_params[k] = v;
        });
      }
    }
    if (message.video_instruction !== undefined) {
      obj.video_instruction = VideoInstructionScreen.toJSON(message.video_instruction);
    }
    if (message.should_show_instructions_on_appear !== false) {
      obj.should_show_instructions_on_appear = message.should_show_instructions_on_appear;
    }
    if (message.consent_action !== undefined) {
      obj.consent_action = Any.toJSON(message.consent_action);
    }
    if (message.request !== undefined) {
      obj.request = FaceScanUploadFrontImageRequest.toJSON(message.request);
    }
    return obj;
  },
};

export const FaceCameraScreen_ScanEndpointParamsEntry = {
  fromJSON(object: any): FaceCameraScreen_ScanEndpointParamsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: FaceCameraScreen_ScanEndpointParamsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },
};

export const FaceScanUploadFrontImageRequest = {
  fromJSON(object: any): FaceScanUploadFrontImageRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      frontal_image: isSet(object.frontal_image) ? Image.fromJSON(object.frontal_image) : undefined,
    };
  },

  toJSON(message: FaceScanUploadFrontImageRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.frontal_image !== undefined) {
      obj.frontal_image = Image.toJSON(message.frontal_image);
    }
    return obj;
  },
};

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
