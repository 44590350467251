// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: loading.proto

/* eslint-disable */

export interface LoadingScreen {
  background_image: string;
  screen: LoadingScreen_Screen | undefined;
}

export interface LoadingScreen_Screen {
  loading: LoadingScreen_Screen_Loading | undefined;
  loaded: LoadingScreen_Screen_Loaded | undefined;
}

export interface LoadingScreen_Screen_Loading {
  title: string;
  reviews: LoadingScreen_Screen_Loading_Reviews | undefined;
  scrollDelay: number;
}

export interface LoadingScreen_Screen_Loading_Reviews {
  reviews: LoadingScreen_Screen_Loading_Reviews_Review[];
}

export interface LoadingScreen_Screen_Loading_Reviews_Review {
  text: string;
  author: string;
}

export interface LoadingScreen_Screen_Loaded {
  title: string;
  background_image: string;
  bottom_button_title: string;
}

export const LoadingScreen = {
  fromJSON(object: any): LoadingScreen {
    return {
      background_image: isSet(object.background_image) ? globalThis.String(object.background_image) : "",
      screen: isSet(object.screen) ? LoadingScreen_Screen.fromJSON(object.screen) : undefined,
    };
  },

  toJSON(message: LoadingScreen): unknown {
    const obj: any = {};
    if (message.background_image !== "") {
      obj.background_image = message.background_image;
    }
    if (message.screen !== undefined) {
      obj.screen = LoadingScreen_Screen.toJSON(message.screen);
    }
    return obj;
  },
};

export const LoadingScreen_Screen = {
  fromJSON(object: any): LoadingScreen_Screen {
    return {
      loading: isSet(object.loading) ? LoadingScreen_Screen_Loading.fromJSON(object.loading) : undefined,
      loaded: isSet(object.loaded) ? LoadingScreen_Screen_Loaded.fromJSON(object.loaded) : undefined,
    };
  },

  toJSON(message: LoadingScreen_Screen): unknown {
    const obj: any = {};
    if (message.loading !== undefined) {
      obj.loading = LoadingScreen_Screen_Loading.toJSON(message.loading);
    }
    if (message.loaded !== undefined) {
      obj.loaded = LoadingScreen_Screen_Loaded.toJSON(message.loaded);
    }
    return obj;
  },
};

export const LoadingScreen_Screen_Loading = {
  fromJSON(object: any): LoadingScreen_Screen_Loading {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      reviews: isSet(object.reviews) ? LoadingScreen_Screen_Loading_Reviews.fromJSON(object.reviews) : undefined,
      scrollDelay: isSet(object.scrollDelay) ? globalThis.Number(object.scrollDelay) : 0,
    };
  },

  toJSON(message: LoadingScreen_Screen_Loading): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.reviews !== undefined) {
      obj.reviews = LoadingScreen_Screen_Loading_Reviews.toJSON(message.reviews);
    }
    if (message.scrollDelay !== 0) {
      obj.scrollDelay = message.scrollDelay;
    }
    return obj;
  },
};

export const LoadingScreen_Screen_Loading_Reviews = {
  fromJSON(object: any): LoadingScreen_Screen_Loading_Reviews {
    return {
      reviews: globalThis.Array.isArray(object?.reviews)
        ? object.reviews.map((e: any) => LoadingScreen_Screen_Loading_Reviews_Review.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LoadingScreen_Screen_Loading_Reviews): unknown {
    const obj: any = {};
    if (message.reviews?.length) {
      obj.reviews = message.reviews.map((e) => LoadingScreen_Screen_Loading_Reviews_Review.toJSON(e));
    }
    return obj;
  },
};

export const LoadingScreen_Screen_Loading_Reviews_Review = {
  fromJSON(object: any): LoadingScreen_Screen_Loading_Reviews_Review {
    return {
      text: isSet(object.text) ? globalThis.String(object.text) : "",
      author: isSet(object.author) ? globalThis.String(object.author) : "",
    };
  },

  toJSON(message: LoadingScreen_Screen_Loading_Reviews_Review): unknown {
    const obj: any = {};
    if (message.text !== "") {
      obj.text = message.text;
    }
    if (message.author !== "") {
      obj.author = message.author;
    }
    return obj;
  },
};

export const LoadingScreen_Screen_Loaded = {
  fromJSON(object: any): LoadingScreen_Screen_Loaded {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      background_image: isSet(object.background_image) ? globalThis.String(object.background_image) : "",
      bottom_button_title: isSet(object.bottom_button_title) ? globalThis.String(object.bottom_button_title) : "",
    };
  },

  toJSON(message: LoadingScreen_Screen_Loaded): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.background_image !== "") {
      obj.background_image = message.background_image;
    }
    if (message.bottom_button_title !== "") {
      obj.bottom_button_title = message.bottom_button_title;
    }
    return obj;
  },
};

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
