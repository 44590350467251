import React, { FC, ReactNode } from "react"
import { Box, Heading, SimpleGrid } from "@chakra-ui/react"

import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import { Scale } from "~/components/shared/Scale"
import { VFlex } from "~/components/shared/VFlex"
import { IfTTExists, TTString, TT } from "~/components/shared/AttributedString"
import { NextPageCb } from "~/hooks/useNextQuiz"
import { SkinIssues } from "~/generated/paywall"

import { FaceScanResultRenderContainer } from "./FaceScanResultRenderContainer"
import { SectionDivider } from "./SectionDivider"
import { NumberedTextList } from "./NumberedTextList"
import { IssuesList } from "./IssuesList"
import { getScaleValue } from "~/utils/getScaleValue"
import { useTranslation } from "react-i18next"

export const PageHeader: FC<{
  title: TTString
  description: TTString
}> = ({ title, description }) => (
  <VFlex gap={3}>
    <IfTTExists if={title}>
      <Heading as="h1" size="Header/Primary" whiteSpace="pre-line">
        <TT>{title}</TT>
      </Heading>
    </IfTTExists>
    <IfTTExists if={description}>
      <Box textStyle="Paragraph/Primary" whiteSpace="pre-line">
        <TT>{description}</TT>
      </Box>
    </IfTTExists>
  </VFlex>
)

const ScanResult: FC<{ children?: ReactNode }> = ({ children }) => {
  const { t } = useTranslation()
  return (
    <VFlex gap={6} paddingX={6}>
      {children}
      <SimpleGrid templateColumns="1fr 1fr" columnGap={6} rowGap={2} alignItems="center">
        <FaceScanResultRenderContainer
          sx={{ aspectRatio: "0.8" }}
          gridRow="1/5"
          borderRadius="brand24"
        />
        <Scale
          title={t("scales.hydration")}
          maxPercent={getScaleValue("HYDRATION", false)}
          color="Other/Info"
        />
        <Scale
          title={t("scales.elasticity")}
          maxPercent={getScaleValue("ELASTICITY", false)}
          color="Other/Green"
        />
        <Scale
          title={t("scales.complexion")}
          maxPercent={getScaleValue("COMPLEXION", false)}
          color="Other/New"
        />
        <Scale
          title={t("scales.texture")}
          maxPercent={getScaleValue("TEXTURE", false)}
          color="Other/Warning"
        />
      </SimpleGrid>
    </VFlex>
  )
}

export const FaceScannerResultContent: FC<{ children?: ReactNode; issues: SkinIssues[] }> = ({
  children,
  issues,
}) => (
  <>
    <ScanResult>{children}</ScanResult>

    <SectionDivider />

    <Box paddingX={6}>
      <IssuesList issues={issues} />
    </Box>

    <SectionDivider />

    <VFlex paddingX={6} mb={8}>
      <NumberedTextList />
    </VFlex>
  </>
)

export const FaceScannerResultPage: FC<{
  title: TTString
  description: TTString
  issues: SkinIssues[]
  next: NextPageCb
}> = ({ title, description, issues, next }) => (
  <BasePageWrapper minH="full" overflowY="auto" paddingX={0} bgColor="white">
    <FaceScannerResultContent issues={issues}>
      <PageHeader title={title} description={description} />
    </FaceScannerResultContent>

    <NextButtonContainer visible>
      <NextButton onClick={next} />
    </NextButtonContainer>
  </BasePageWrapper>
)
