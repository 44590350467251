// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: ios_presentation.proto

/* eslint-disable */

/** iOS-style detent for a sheet. */
export interface SheetDetent {
  detent?:
    | { $case: "large"; large: SheetDetent_Large }
    | { $case: "medium"; medium: SheetDetent_Medium }
    | { $case: "percent"; percent: SheetDetent_Percent }
    | { $case: "wraps_content"; wraps_content: SheetDetent_WrapsContent }
    | undefined;
}

export interface SheetDetent_Large {
}

export interface SheetDetent_Medium {
}

export interface SheetDetent_WrapsContent {
}

export interface SheetDetent_Percent {
  percent: number;
}

export const SheetDetent = {
  fromJSON(object: any): SheetDetent {
    return {
      detent: isSet(object.large)
        ? { $case: "large", large: SheetDetent_Large.fromJSON(object.large) }
        : isSet(object.medium)
        ? { $case: "medium", medium: SheetDetent_Medium.fromJSON(object.medium) }
        : isSet(object.percent)
        ? { $case: "percent", percent: SheetDetent_Percent.fromJSON(object.percent) }
        : isSet(object.wraps_content)
        ? { $case: "wraps_content", wraps_content: SheetDetent_WrapsContent.fromJSON(object.wraps_content) }
        : undefined,
    };
  },

  toJSON(message: SheetDetent): unknown {
    const obj: any = {};
    if (message.detent?.$case === "large") {
      obj.large = SheetDetent_Large.toJSON(message.detent.large);
    }
    if (message.detent?.$case === "medium") {
      obj.medium = SheetDetent_Medium.toJSON(message.detent.medium);
    }
    if (message.detent?.$case === "percent") {
      obj.percent = SheetDetent_Percent.toJSON(message.detent.percent);
    }
    if (message.detent?.$case === "wraps_content") {
      obj.wraps_content = SheetDetent_WrapsContent.toJSON(message.detent.wraps_content);
    }
    return obj;
  },
};

export const SheetDetent_Large = {
  fromJSON(_: any): SheetDetent_Large {
    return {};
  },

  toJSON(_: SheetDetent_Large): unknown {
    const obj: any = {};
    return obj;
  },
};

export const SheetDetent_Medium = {
  fromJSON(_: any): SheetDetent_Medium {
    return {};
  },

  toJSON(_: SheetDetent_Medium): unknown {
    const obj: any = {};
    return obj;
  },
};

export const SheetDetent_WrapsContent = {
  fromJSON(_: any): SheetDetent_WrapsContent {
    return {};
  },

  toJSON(_: SheetDetent_WrapsContent): unknown {
    const obj: any = {};
    return obj;
  },
};

export const SheetDetent_Percent = {
  fromJSON(object: any): SheetDetent_Percent {
    return { percent: isSet(object.percent) ? globalThis.Number(object.percent) : 0 };
  },

  toJSON(message: SheetDetent_Percent): unknown {
    const obj: any = {};
    if (message.percent !== 0) {
      obj.percent = message.percent;
    }
    return obj;
  },
};

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
