// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: video_instruction.proto

/* eslint-disable */

export interface VideoInstructionScreen {
  stories: VideoInstructionScreen_Story[];
  /** @deprecated */
  video: string;
  /** @deprecated */
  title: string;
  /** @deprecated */
  list_items: VideoInstructionScreen_ListItem[];
  /** @deprecated */
  list_preview_capacity?: number | undefined;
  close_is_availible: boolean;
  back_is_availible: boolean;
}

export interface VideoInstructionScreen_Story {
  video: string;
  title: string;
  subtitle: string;
}

export interface VideoInstructionScreen_ListItem {
  /** @deprecated */
  image: string;
  /** @deprecated */
  text: string;
}

export const VideoInstructionScreen = {
  fromJSON(object: any): VideoInstructionScreen {
    return {
      stories: globalThis.Array.isArray(object?.stories)
        ? object.stories.map((e: any) => VideoInstructionScreen_Story.fromJSON(e))
        : [],
      video: isSet(object.video) ? globalThis.String(object.video) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      list_items: globalThis.Array.isArray(object?.list_items)
        ? object.list_items.map((e: any) => VideoInstructionScreen_ListItem.fromJSON(e))
        : [],
      list_preview_capacity: isSet(object.list_preview_capacity)
        ? globalThis.Number(object.list_preview_capacity)
        : undefined,
      close_is_availible: isSet(object.close_is_availible) ? globalThis.Boolean(object.close_is_availible) : false,
      back_is_availible: isSet(object.back_is_availible) ? globalThis.Boolean(object.back_is_availible) : false,
    };
  },

  toJSON(message: VideoInstructionScreen): unknown {
    const obj: any = {};
    if (message.stories?.length) {
      obj.stories = message.stories.map((e) => VideoInstructionScreen_Story.toJSON(e));
    }
    if (message.video !== "") {
      obj.video = message.video;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.list_items?.length) {
      obj.list_items = message.list_items.map((e) => VideoInstructionScreen_ListItem.toJSON(e));
    }
    if (message.list_preview_capacity !== undefined) {
      obj.list_preview_capacity = Math.round(message.list_preview_capacity);
    }
    if (message.close_is_availible !== false) {
      obj.close_is_availible = message.close_is_availible;
    }
    if (message.back_is_availible !== false) {
      obj.back_is_availible = message.back_is_availible;
    }
    return obj;
  },
};

export const VideoInstructionScreen_Story = {
  fromJSON(object: any): VideoInstructionScreen_Story {
    return {
      video: isSet(object.video) ? globalThis.String(object.video) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
    };
  },

  toJSON(message: VideoInstructionScreen_Story): unknown {
    const obj: any = {};
    if (message.video !== "") {
      obj.video = message.video;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    return obj;
  },
};

export const VideoInstructionScreen_ListItem = {
  fromJSON(object: any): VideoInstructionScreen_ListItem {
    return {
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      text: isSet(object.text) ? globalThis.String(object.text) : "",
    };
  },

  toJSON(message: VideoInstructionScreen_ListItem): unknown {
    const obj: any = {};
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    return obj;
  },
};

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
