import { useCallback } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useHistory } from "react-router-dom"

import { useDispatch, useSelector } from "../redux"
import { addQuiz, setUserIdUpdated } from "../../store/survey/actions"
import {
  nextQuestion as nextQuestionSelector,
  selectUserIdUpdated,
} from "../../store/survey/selectors"
import { useQuizHistory } from "../useQuizHistory"
import type { Direction, QuizId } from "../../types"
import type { QuizType } from "../../api"
import { runCompletionAction } from "../useNextQuiz"
import { useAmplitude } from "../analytics/useAmplitude"

import { initUserId, initQuiz, refreshPage, NavigationDataType } from "./initUtils"
import i18n from "i18next"

// FIXME: Refactor this product of constant overengineering navigation logic
export const useInitialization = ({
  urlReplacer = refreshPage,
  // TODO: remove it. Split the hook
  direction = "forward",
}: {
  urlReplacer?: typeof refreshPage
  direction?: Direction
}) => {
  const browserHistory = useHistory()
  const userIdUpdated = useSelector(selectUserIdUpdated)
  const { user, loginWithRedirect } = useAuth0()
  const surveyData = useSelector((store) => store.survey.quiz)
  const {
    params: { quiz, question },
  } = useQuizHistory()
  const { status } = useSelector(nextQuestionSelector(quiz, question))
  const { logCompleteAction, logInterviewQuizCompleted } = useAmplitude()

  const dispatch = useDispatch()

  const getPreloadedQuiz = (quizId: QuizId) => surveyData[quizId]

  // TODO: create separate action for quiz downloading and call it if necessary
  const ifDownloadNextQuiz = (quizId: QuizId) => {
    if (direction === "backward" || status === "next_question") {
      return getPreloadedQuiz(quizId)
    }
    return undefined
  }
  const saveQuizRedux = (quiz: QuizType) => !getPreloadedQuiz(quiz.id) && dispatch(addQuiz(quiz))

  const fetchPage = useCallback(
    async (navigation: NavigationDataType): Promise<undefined> => {
      const userId = await initUserId(user?.sub, userIdUpdated)
      dispatch(setUserIdUpdated())
      let quiz
      if (navigation.quizId) {
        quiz = getPreloadedQuiz(navigation.quizId)
      }

      if (!quiz) {
        quiz = await initQuiz(navigation, userId, ifDownloadNextQuiz)
        i18n.changeLanguage(quiz.language)
        saveQuizRedux(quiz)
      }

      const questionId = navigation.questionId ?? quiz.questions_v2?.[0]?.parameters?.id
      if (quiz.id && questionId) {
        urlReplacer(navigation, browserHistory, { quizId: quiz.id, questionId })
      } else {
        const completionAction = quiz.on_completion_action
        const isEmptyQuiz = (quiz.questions_v2 ?? []).length === 0
        if (isEmptyQuiz && completionAction && userId) {
          logCompleteAction(Object.keys(completionAction))
          runCompletionAction({
            completionAction,
            userId,
            login: loginWithRedirect,
            navigate: browserHistory.push,
          })
          logInterviewQuizCompleted() // TODO remove this event
        }
      }

      return undefined
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [user, surveyData, userIdUpdated, dispatch]
  )

  return fetchPage
}
