// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: networking.proto

/* eslint-disable */

/** Response data shared by the other response types */
export enum ResponseStatus {
  /** RESPONSE_STATUS_NONE - So that every meaningful status is not zero, hence exported. */
  RESPONSE_STATUS_NONE = "RESPONSE_STATUS_NONE",
  SUCCESS = "SUCCESS",
  ERROR_RETRYABLE = "ERROR_RETRYABLE",
  ERROR_FATAL = "ERROR_FATAL",
  ERROR_VERSION = "ERROR_VERSION",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function responseStatusFromJSON(object: any): ResponseStatus {
  switch (object) {
    case 0:
    case "RESPONSE_STATUS_NONE":
      return ResponseStatus.RESPONSE_STATUS_NONE;
    case 1:
    case "SUCCESS":
      return ResponseStatus.SUCCESS;
    case 2:
    case "ERROR_RETRYABLE":
      return ResponseStatus.ERROR_RETRYABLE;
    case 3:
    case "ERROR_FATAL":
      return ResponseStatus.ERROR_FATAL;
    case 4:
    case "ERROR_VERSION":
      return ResponseStatus.ERROR_VERSION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ResponseStatus.UNRECOGNIZED;
  }
}

export function responseStatusToJSON(object: ResponseStatus): string {
  switch (object) {
    case ResponseStatus.RESPONSE_STATUS_NONE:
      return "RESPONSE_STATUS_NONE";
    case ResponseStatus.SUCCESS:
      return "SUCCESS";
    case ResponseStatus.ERROR_RETRYABLE:
      return "ERROR_RETRYABLE";
    case ResponseStatus.ERROR_FATAL:
      return "ERROR_FATAL";
    case ResponseStatus.ERROR_VERSION:
      return "ERROR_VERSION";
    case ResponseStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Available payment providers for our users */
export enum PaymentProvider {
  PAYMENT_PROVIDER_NONE = "PAYMENT_PROVIDER_NONE",
  PAYMENT_PROVIDER_PALTA = "PAYMENT_PROVIDER_PALTA",
  PAYMENT_PROVIDER_PAYPAL = "PAYMENT_PROVIDER_PAYPAL",
  PAYMENT_PROVIDER_STRIPE = "PAYMENT_PROVIDER_STRIPE",
  PAYMENT_PROVIDER_APPLE = "PAYMENT_PROVIDER_APPLE",
  PAYMENT_PROVIDER_RECURLY = "PAYMENT_PROVIDER_RECURLY",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function paymentProviderFromJSON(object: any): PaymentProvider {
  switch (object) {
    case 0:
    case "PAYMENT_PROVIDER_NONE":
      return PaymentProvider.PAYMENT_PROVIDER_NONE;
    case 1:
    case "PAYMENT_PROVIDER_PALTA":
      return PaymentProvider.PAYMENT_PROVIDER_PALTA;
    case 2:
    case "PAYMENT_PROVIDER_PAYPAL":
      return PaymentProvider.PAYMENT_PROVIDER_PAYPAL;
    case 3:
    case "PAYMENT_PROVIDER_STRIPE":
      return PaymentProvider.PAYMENT_PROVIDER_STRIPE;
    case 4:
    case "PAYMENT_PROVIDER_APPLE":
      return PaymentProvider.PAYMENT_PROVIDER_APPLE;
    case 5:
    case "PAYMENT_PROVIDER_RECURLY":
      return PaymentProvider.PAYMENT_PROVIDER_RECURLY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PaymentProvider.UNRECOGNIZED;
  }
}

export function paymentProviderToJSON(object: PaymentProvider): string {
  switch (object) {
    case PaymentProvider.PAYMENT_PROVIDER_NONE:
      return "PAYMENT_PROVIDER_NONE";
    case PaymentProvider.PAYMENT_PROVIDER_PALTA:
      return "PAYMENT_PROVIDER_PALTA";
    case PaymentProvider.PAYMENT_PROVIDER_PAYPAL:
      return "PAYMENT_PROVIDER_PAYPAL";
    case PaymentProvider.PAYMENT_PROVIDER_STRIPE:
      return "PAYMENT_PROVIDER_STRIPE";
    case PaymentProvider.PAYMENT_PROVIDER_APPLE:
      return "PAYMENT_PROVIDER_APPLE";
    case PaymentProvider.PAYMENT_PROVIDER_RECURLY:
      return "PAYMENT_PROVIDER_RECURLY";
    case PaymentProvider.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Request data shared by all the other request types */
export interface Request {
  userID: string;
  client: string;
  params: { [key: string]: string };
}

export interface Request_ParamsEntry {
  key: string;
  value: string;
}

export interface Response {
  status: ResponseStatus;
  message: string;
}

/** Subscription check request schema */
export interface SubscriptionCheckRequest {
  user_id: string;
  /** @deprecated */
  subscription_entity: string;
  subscription_entities: string[];
}

/** Subscription check response schema */
export interface SubscriptionEntityResult {
  entity: string;
  has_subscription: boolean;
  provider: PaymentProvider;
  subscription_id: string;
  payment_provider_user_id: string;
  product_id: string;
}

export interface SubscriptionCheckResponse {
  /**
   * Deprecated fields - use results field instead
   *
   * @deprecated
   */
  has_subscription: boolean;
  /** @deprecated */
  provider: PaymentProvider;
  user_id: string;
  /** @deprecated */
  subscription_id: string;
  /** @deprecated */
  palta_payments_user_id: string;
  /** @deprecated */
  product_id: string;
  results: SubscriptionEntityResult[];
}

export interface ParameterValues {
  values: string[];
}

export const Request = {
  fromJSON(object: any): Request {
    return {
      userID: isSet(object.userID) ? globalThis.String(object.userID) : "",
      client: isSet(object.client) ? globalThis.String(object.client) : "",
      params: isObject(object.params)
        ? Object.entries(object.params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: Request): unknown {
    const obj: any = {};
    if (message.userID !== "") {
      obj.userID = message.userID;
    }
    if (message.client !== "") {
      obj.client = message.client;
    }
    if (message.params) {
      const entries = Object.entries(message.params);
      if (entries.length > 0) {
        obj.params = {};
        entries.forEach(([k, v]) => {
          obj.params[k] = v;
        });
      }
    }
    return obj;
  },
};

export const Request_ParamsEntry = {
  fromJSON(object: any): Request_ParamsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: Request_ParamsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },
};

export const Response = {
  fromJSON(object: any): Response {
    return {
      status: isSet(object.status) ? responseStatusFromJSON(object.status) : ResponseStatus.RESPONSE_STATUS_NONE,
      message: isSet(object.message) ? globalThis.String(object.message) : "",
    };
  },

  toJSON(message: Response): unknown {
    const obj: any = {};
    if (message.status !== ResponseStatus.RESPONSE_STATUS_NONE) {
      obj.status = responseStatusToJSON(message.status);
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },
};

export const SubscriptionCheckRequest = {
  fromJSON(object: any): SubscriptionCheckRequest {
    return {
      user_id: isSet(object.user_id) ? globalThis.String(object.user_id) : "",
      subscription_entity: isSet(object.subscription_entity) ? globalThis.String(object.subscription_entity) : "",
      subscription_entities: globalThis.Array.isArray(object?.subscription_entities)
        ? object.subscription_entities.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: SubscriptionCheckRequest): unknown {
    const obj: any = {};
    if (message.user_id !== "") {
      obj.user_id = message.user_id;
    }
    if (message.subscription_entity !== "") {
      obj.subscription_entity = message.subscription_entity;
    }
    if (message.subscription_entities?.length) {
      obj.subscription_entities = message.subscription_entities;
    }
    return obj;
  },
};

export const SubscriptionEntityResult = {
  fromJSON(object: any): SubscriptionEntityResult {
    return {
      entity: isSet(object.entity) ? globalThis.String(object.entity) : "",
      has_subscription: isSet(object.has_subscription) ? globalThis.Boolean(object.has_subscription) : false,
      provider: isSet(object.provider)
        ? paymentProviderFromJSON(object.provider)
        : PaymentProvider.PAYMENT_PROVIDER_NONE,
      subscription_id: isSet(object.subscription_id) ? globalThis.String(object.subscription_id) : "",
      payment_provider_user_id: isSet(object.payment_provider_user_id)
        ? globalThis.String(object.payment_provider_user_id)
        : "",
      product_id: isSet(object.product_id) ? globalThis.String(object.product_id) : "",
    };
  },

  toJSON(message: SubscriptionEntityResult): unknown {
    const obj: any = {};
    if (message.entity !== "") {
      obj.entity = message.entity;
    }
    if (message.has_subscription !== false) {
      obj.has_subscription = message.has_subscription;
    }
    if (message.provider !== PaymentProvider.PAYMENT_PROVIDER_NONE) {
      obj.provider = paymentProviderToJSON(message.provider);
    }
    if (message.subscription_id !== "") {
      obj.subscription_id = message.subscription_id;
    }
    if (message.payment_provider_user_id !== "") {
      obj.payment_provider_user_id = message.payment_provider_user_id;
    }
    if (message.product_id !== "") {
      obj.product_id = message.product_id;
    }
    return obj;
  },
};

export const SubscriptionCheckResponse = {
  fromJSON(object: any): SubscriptionCheckResponse {
    return {
      has_subscription: isSet(object.has_subscription) ? globalThis.Boolean(object.has_subscription) : false,
      provider: isSet(object.provider)
        ? paymentProviderFromJSON(object.provider)
        : PaymentProvider.PAYMENT_PROVIDER_NONE,
      user_id: isSet(object.user_id) ? globalThis.String(object.user_id) : "",
      subscription_id: isSet(object.subscription_id) ? globalThis.String(object.subscription_id) : "",
      palta_payments_user_id: isSet(object.palta_payments_user_id)
        ? globalThis.String(object.palta_payments_user_id)
        : "",
      product_id: isSet(object.product_id) ? globalThis.String(object.product_id) : "",
      results: globalThis.Array.isArray(object?.results)
        ? object.results.map((e: any) => SubscriptionEntityResult.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SubscriptionCheckResponse): unknown {
    const obj: any = {};
    if (message.has_subscription !== false) {
      obj.has_subscription = message.has_subscription;
    }
    if (message.provider !== PaymentProvider.PAYMENT_PROVIDER_NONE) {
      obj.provider = paymentProviderToJSON(message.provider);
    }
    if (message.user_id !== "") {
      obj.user_id = message.user_id;
    }
    if (message.subscription_id !== "") {
      obj.subscription_id = message.subscription_id;
    }
    if (message.palta_payments_user_id !== "") {
      obj.palta_payments_user_id = message.palta_payments_user_id;
    }
    if (message.product_id !== "") {
      obj.product_id = message.product_id;
    }
    if (message.results?.length) {
      obj.results = message.results.map((e) => SubscriptionEntityResult.toJSON(e));
    }
    return obj;
  },
};

export const ParameterValues = {
  fromJSON(object: any): ParameterValues {
    return {
      values: globalThis.Array.isArray(object?.values) ? object.values.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: ParameterValues): unknown {
    const obj: any = {};
    if (message.values?.length) {
      obj.values = message.values;
    }
    return obj;
  },
};

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
