// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: presentation.proto

/* eslint-disable */

/**
 * Based on https://developer.apple.com/documentation/uikit/uikeyboardtype
 * Not all the types are used, only the most common ones
 */
export enum IOSKeyboardType {
  KEYBOARD_TYPE_NONE = "KEYBOARD_TYPE_NONE",
  KEYBOARD_TYPE_NUMBERS_AND_PUNCTUATION = "KEYBOARD_TYPE_NUMBERS_AND_PUNCTUATION",
  KEYBOARD_TYPE_NUMBER_PAD = "KEYBOARD_TYPE_NUMBER_PAD",
  KEYBOARD_TYPE_PHONE_PAD = "KEYBOARD_TYPE_PHONE_PAD",
  KEYBOARD_TYPE_EMAIL_ADDRESS = "KEYBOARD_TYPE_EMAIL_ADDRESS",
  KEYBOARD_TYPE_DECIMAL_PAD = "KEYBOARD_TYPE_DECIMAL_PAD",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function iOSKeyboardTypeFromJSON(object: any): IOSKeyboardType {
  switch (object) {
    case 0:
    case "KEYBOARD_TYPE_NONE":
      return IOSKeyboardType.KEYBOARD_TYPE_NONE;
    case 1:
    case "KEYBOARD_TYPE_NUMBERS_AND_PUNCTUATION":
      return IOSKeyboardType.KEYBOARD_TYPE_NUMBERS_AND_PUNCTUATION;
    case 2:
    case "KEYBOARD_TYPE_NUMBER_PAD":
      return IOSKeyboardType.KEYBOARD_TYPE_NUMBER_PAD;
    case 3:
    case "KEYBOARD_TYPE_PHONE_PAD":
      return IOSKeyboardType.KEYBOARD_TYPE_PHONE_PAD;
    case 4:
    case "KEYBOARD_TYPE_EMAIL_ADDRESS":
      return IOSKeyboardType.KEYBOARD_TYPE_EMAIL_ADDRESS;
    case 5:
    case "KEYBOARD_TYPE_DECIMAL_PAD":
      return IOSKeyboardType.KEYBOARD_TYPE_DECIMAL_PAD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return IOSKeyboardType.UNRECOGNIZED;
  }
}

export function iOSKeyboardTypeToJSON(object: IOSKeyboardType): string {
  switch (object) {
    case IOSKeyboardType.KEYBOARD_TYPE_NONE:
      return "KEYBOARD_TYPE_NONE";
    case IOSKeyboardType.KEYBOARD_TYPE_NUMBERS_AND_PUNCTUATION:
      return "KEYBOARD_TYPE_NUMBERS_AND_PUNCTUATION";
    case IOSKeyboardType.KEYBOARD_TYPE_NUMBER_PAD:
      return "KEYBOARD_TYPE_NUMBER_PAD";
    case IOSKeyboardType.KEYBOARD_TYPE_PHONE_PAD:
      return "KEYBOARD_TYPE_PHONE_PAD";
    case IOSKeyboardType.KEYBOARD_TYPE_EMAIL_ADDRESS:
      return "KEYBOARD_TYPE_EMAIL_ADDRESS";
    case IOSKeyboardType.KEYBOARD_TYPE_DECIMAL_PAD:
      return "KEYBOARD_TYPE_DECIMAL_PAD";
    case IOSKeyboardType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum HorizontalAlignment {
  HORIZONTAL_ALIGNMENT_NONE = "HORIZONTAL_ALIGNMENT_NONE",
  HORIZONTAL_ALIGNMENT_LEADING = "HORIZONTAL_ALIGNMENT_LEADING",
  HORIZONTAL_ALIGNMENT_CENTER = "HORIZONTAL_ALIGNMENT_CENTER",
  HORIZONTAL_ALIGNMENT_TRAILING = "HORIZONTAL_ALIGNMENT_TRAILING",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function horizontalAlignmentFromJSON(object: any): HorizontalAlignment {
  switch (object) {
    case 0:
    case "HORIZONTAL_ALIGNMENT_NONE":
      return HorizontalAlignment.HORIZONTAL_ALIGNMENT_NONE;
    case 1:
    case "HORIZONTAL_ALIGNMENT_LEADING":
      return HorizontalAlignment.HORIZONTAL_ALIGNMENT_LEADING;
    case 2:
    case "HORIZONTAL_ALIGNMENT_CENTER":
      return HorizontalAlignment.HORIZONTAL_ALIGNMENT_CENTER;
    case 3:
    case "HORIZONTAL_ALIGNMENT_TRAILING":
      return HorizontalAlignment.HORIZONTAL_ALIGNMENT_TRAILING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HorizontalAlignment.UNRECOGNIZED;
  }
}

export function horizontalAlignmentToJSON(object: HorizontalAlignment): string {
  switch (object) {
    case HorizontalAlignment.HORIZONTAL_ALIGNMENT_NONE:
      return "HORIZONTAL_ALIGNMENT_NONE";
    case HorizontalAlignment.HORIZONTAL_ALIGNMENT_LEADING:
      return "HORIZONTAL_ALIGNMENT_LEADING";
    case HorizontalAlignment.HORIZONTAL_ALIGNMENT_CENTER:
      return "HORIZONTAL_ALIGNMENT_CENTER";
    case HorizontalAlignment.HORIZONTAL_ALIGNMENT_TRAILING:
      return "HORIZONTAL_ALIGNMENT_TRAILING";
    case HorizontalAlignment.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ImageContentMode {
  MODE_NONE = "MODE_NONE",
  MODE_FIT = "MODE_FIT",
  MODE_FILL = "MODE_FILL",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function imageContentModeFromJSON(object: any): ImageContentMode {
  switch (object) {
    case 0:
    case "MODE_NONE":
      return ImageContentMode.MODE_NONE;
    case 1:
    case "MODE_FIT":
      return ImageContentMode.MODE_FIT;
    case 2:
    case "MODE_FILL":
      return ImageContentMode.MODE_FILL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ImageContentMode.UNRECOGNIZED;
  }
}

export function imageContentModeToJSON(object: ImageContentMode): string {
  switch (object) {
    case ImageContentMode.MODE_NONE:
      return "MODE_NONE";
    case ImageContentMode.MODE_FIT:
      return "MODE_FIT";
    case ImageContentMode.MODE_FILL:
      return "MODE_FILL";
    case ImageContentMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Represents formatted text */
export interface AttributedString {
  parts: AttributedString_AttributedStringPart[];
}

export interface AttributedString_AttributedStringPart {
  content: string;
  format: AttributedString_AttributedStringFormat[];
}

export interface AttributedString_AttributedStringFormat {
  format?:
    | { $case: "emphasis"; emphasis: boolean }
    | { $case: "color"; color: string }
    | { $case: "font_weight"; font_weight: number }
    | { $case: "sf_symbol"; sf_symbol: boolean }
    | { $case: "markdown"; markdown: AttributedString_AttributedStringFormat_Markdown }
    | undefined;
}

export interface AttributedString_AttributedStringFormat_Markdown {
}

/** Image data represented as either base64 string or URL address */
export interface Image {
  source?: { $case: "url"; url: string } | { $case: "base64_data"; base64_data: string } | undefined;
}

/** Validation rule for user text inputs. If the rule fails, show the error */
export interface ValidationRule {
  regex: string;
  error: string;
}

/** iOS-specific text field proto model */
export interface IOSTextField {
  placeholder: string;
  initial_input: string;
  keyboard_type: IOSKeyboardType;
  validation_rules: ValidationRule[];
}

/**
 * Tag view component model
 * Design: https://www.figma.com/file/N2gREqwEvhAYxN1ZFVwtQ4/iOS-Library?node-id=1565%3A5474&t=kXxTHs4ZfLeidAmU-1
 */
export interface Tag {
  title: string;
  /** Left icon [optional] */
  icon: Tag_Icon | undefined;
  style: Tag_Style | undefined;
}

export interface Tag_Icon {
  icon?: { $case: "image"; image: Tag_Icon_Image } | { $case: "sfSymbol"; sfSymbol: string } | undefined;
}

export interface Tag_Icon_Image {
  source: string;
  preserves_original_color: boolean;
}

export interface Tag_Style {
  /**
   * Style preset [optional]
   * If not set (`PRESET_NONE`), all the other fields should not be empty
   * If set, leave the other fields empty. Fill some of them only if required.
   * For instance, to have a shadow (`has_shadow: true`) in otherwise shadow-less preset (`PRESET_NORMAL_PRIMARY`)
   */
  preset: Tag_Style_Preset;
  properties: Tag_Style_Property[];
}

/** Predefined styles (see iOS library) */
export enum Tag_Style_Preset {
  PRESET_NONE = "PRESET_NONE",
  PRESET_NORMAL_PRIMARY = "PRESET_NORMAL_PRIMARY",
  PRESET_NORMAL_SECONDARY = "PRESET_NORMAL_SECONDARY",
  PRESET_HIGHLIGHT = "PRESET_HIGHLIGHT",
  PRESET_EMPHASIS = "PRESET_EMPHASIS",
  PRESET_PLAIN = "PRESET_PLAIN",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function tag_Style_PresetFromJSON(object: any): Tag_Style_Preset {
  switch (object) {
    case 0:
    case "PRESET_NONE":
      return Tag_Style_Preset.PRESET_NONE;
    case 1:
    case "PRESET_NORMAL_PRIMARY":
      return Tag_Style_Preset.PRESET_NORMAL_PRIMARY;
    case 2:
    case "PRESET_NORMAL_SECONDARY":
      return Tag_Style_Preset.PRESET_NORMAL_SECONDARY;
    case 3:
    case "PRESET_HIGHLIGHT":
      return Tag_Style_Preset.PRESET_HIGHLIGHT;
    case 4:
    case "PRESET_EMPHASIS":
      return Tag_Style_Preset.PRESET_EMPHASIS;
    case 5:
    case "PRESET_PLAIN":
      return Tag_Style_Preset.PRESET_PLAIN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Tag_Style_Preset.UNRECOGNIZED;
  }
}

export function tag_Style_PresetToJSON(object: Tag_Style_Preset): string {
  switch (object) {
    case Tag_Style_Preset.PRESET_NONE:
      return "PRESET_NONE";
    case Tag_Style_Preset.PRESET_NORMAL_PRIMARY:
      return "PRESET_NORMAL_PRIMARY";
    case Tag_Style_Preset.PRESET_NORMAL_SECONDARY:
      return "PRESET_NORMAL_SECONDARY";
    case Tag_Style_Preset.PRESET_HIGHLIGHT:
      return "PRESET_HIGHLIGHT";
    case Tag_Style_Preset.PRESET_EMPHASIS:
      return "PRESET_EMPHASIS";
    case Tag_Style_Preset.PRESET_PLAIN:
      return "PRESET_PLAIN";
    case Tag_Style_Preset.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Tag_Style_TitleSize {
  TITLE_SIZE_NONE = "TITLE_SIZE_NONE",
  TITLE_SIZE_MEDIUM = "TITLE_SIZE_MEDIUM",
  TITLE_SIZE_LARGE = "TITLE_SIZE_LARGE",
  TITLE_SIZE_CUSTOM = "TITLE_SIZE_CUSTOM",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function tag_Style_TitleSizeFromJSON(object: any): Tag_Style_TitleSize {
  switch (object) {
    case 0:
    case "TITLE_SIZE_NONE":
      return Tag_Style_TitleSize.TITLE_SIZE_NONE;
    case 1:
    case "TITLE_SIZE_MEDIUM":
      return Tag_Style_TitleSize.TITLE_SIZE_MEDIUM;
    case 2:
    case "TITLE_SIZE_LARGE":
      return Tag_Style_TitleSize.TITLE_SIZE_LARGE;
    case 3:
    case "TITLE_SIZE_CUSTOM":
      return Tag_Style_TitleSize.TITLE_SIZE_CUSTOM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Tag_Style_TitleSize.UNRECOGNIZED;
  }
}

export function tag_Style_TitleSizeToJSON(object: Tag_Style_TitleSize): string {
  switch (object) {
    case Tag_Style_TitleSize.TITLE_SIZE_NONE:
      return "TITLE_SIZE_NONE";
    case Tag_Style_TitleSize.TITLE_SIZE_MEDIUM:
      return "TITLE_SIZE_MEDIUM";
    case Tag_Style_TitleSize.TITLE_SIZE_LARGE:
      return "TITLE_SIZE_LARGE";
    case Tag_Style_TitleSize.TITLE_SIZE_CUSTOM:
      return "TITLE_SIZE_CUSTOM";
    case Tag_Style_TitleSize.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Tag_Style_TitleWeight {
  TITLE_WEIGHT_NONE = "TITLE_WEIGHT_NONE",
  TITLE_WEIGHT_NORMAL = "TITLE_WEIGHT_NORMAL",
  TITLE_WEIGHT_EMPHASIS = "TITLE_WEIGHT_EMPHASIS",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function tag_Style_TitleWeightFromJSON(object: any): Tag_Style_TitleWeight {
  switch (object) {
    case 0:
    case "TITLE_WEIGHT_NONE":
      return Tag_Style_TitleWeight.TITLE_WEIGHT_NONE;
    case 1:
    case "TITLE_WEIGHT_NORMAL":
      return Tag_Style_TitleWeight.TITLE_WEIGHT_NORMAL;
    case 2:
    case "TITLE_WEIGHT_EMPHASIS":
      return Tag_Style_TitleWeight.TITLE_WEIGHT_EMPHASIS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Tag_Style_TitleWeight.UNRECOGNIZED;
  }
}

export function tag_Style_TitleWeightToJSON(object: Tag_Style_TitleWeight): string {
  switch (object) {
    case Tag_Style_TitleWeight.TITLE_WEIGHT_NONE:
      return "TITLE_WEIGHT_NONE";
    case Tag_Style_TitleWeight.TITLE_WEIGHT_NORMAL:
      return "TITLE_WEIGHT_NORMAL";
    case Tag_Style_TitleWeight.TITLE_WEIGHT_EMPHASIS:
      return "TITLE_WEIGHT_EMPHASIS";
    case Tag_Style_TitleWeight.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Tag_Style_Background {
  value: Tag_Style_Background_Value;
  hex: string;
}

export enum Tag_Style_Background_Value {
  VALUE_NONE = "VALUE_NONE",
  VALUE_CLEAR = "VALUE_CLEAR",
  VALUE_PRIMARY = "VALUE_PRIMARY",
  VALUE_SECONDARY = "VALUE_SECONDARY",
  VALUE_CUSTOM = "VALUE_CUSTOM",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function tag_Style_Background_ValueFromJSON(object: any): Tag_Style_Background_Value {
  switch (object) {
    case 0:
    case "VALUE_NONE":
      return Tag_Style_Background_Value.VALUE_NONE;
    case 1:
    case "VALUE_CLEAR":
      return Tag_Style_Background_Value.VALUE_CLEAR;
    case 2:
    case "VALUE_PRIMARY":
      return Tag_Style_Background_Value.VALUE_PRIMARY;
    case 3:
    case "VALUE_SECONDARY":
      return Tag_Style_Background_Value.VALUE_SECONDARY;
    case 4:
    case "VALUE_CUSTOM":
      return Tag_Style_Background_Value.VALUE_CUSTOM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Tag_Style_Background_Value.UNRECOGNIZED;
  }
}

export function tag_Style_Background_ValueToJSON(object: Tag_Style_Background_Value): string {
  switch (object) {
    case Tag_Style_Background_Value.VALUE_NONE:
      return "VALUE_NONE";
    case Tag_Style_Background_Value.VALUE_CLEAR:
      return "VALUE_CLEAR";
    case Tag_Style_Background_Value.VALUE_PRIMARY:
      return "VALUE_PRIMARY";
    case Tag_Style_Background_Value.VALUE_SECONDARY:
      return "VALUE_SECONDARY";
    case Tag_Style_Background_Value.VALUE_CUSTOM:
      return "VALUE_CUSTOM";
    case Tag_Style_Background_Value.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Tag_Style_TitleColor {
  value: Tag_Style_TitleColor_Value;
  hex: string;
}

export enum Tag_Style_TitleColor_Value {
  VALUE_NONE = "VALUE_NONE",
  VALUE_PRIMARY = "VALUE_PRIMARY",
  VALUE_EMPHASIS = "VALUE_EMPHASIS",
  VALUE_CUSTOM = "VALUE_CUSTOM",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function tag_Style_TitleColor_ValueFromJSON(object: any): Tag_Style_TitleColor_Value {
  switch (object) {
    case 0:
    case "VALUE_NONE":
      return Tag_Style_TitleColor_Value.VALUE_NONE;
    case 1:
    case "VALUE_PRIMARY":
      return Tag_Style_TitleColor_Value.VALUE_PRIMARY;
    case 2:
    case "VALUE_EMPHASIS":
      return Tag_Style_TitleColor_Value.VALUE_EMPHASIS;
    case 3:
    case "VALUE_CUSTOM":
      return Tag_Style_TitleColor_Value.VALUE_CUSTOM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Tag_Style_TitleColor_Value.UNRECOGNIZED;
  }
}

export function tag_Style_TitleColor_ValueToJSON(object: Tag_Style_TitleColor_Value): string {
  switch (object) {
    case Tag_Style_TitleColor_Value.VALUE_NONE:
      return "VALUE_NONE";
    case Tag_Style_TitleColor_Value.VALUE_PRIMARY:
      return "VALUE_PRIMARY";
    case Tag_Style_TitleColor_Value.VALUE_EMPHASIS:
      return "VALUE_EMPHASIS";
    case Tag_Style_TitleColor_Value.VALUE_CUSTOM:
      return "VALUE_CUSTOM";
    case Tag_Style_TitleColor_Value.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Tag_Style_ContentPadding {
  horizontal: number;
  vertical: number;
}

export interface Tag_Style_Border {
  color: Color | undefined;
  width: number;
}

export interface Tag_Style_Property {
  property?:
    | { $case: "background"; background: Tag_Style_Background }
    | { $case: "title_size"; title_size: Tag_Style_TitleSize }
    | { $case: "title_weight"; title_weight: Tag_Style_TitleWeight }
    | { $case: "title_color"; title_color: Tag_Style_TitleColor }
    | { $case: "has_paddings"; has_paddings: boolean }
    | { $case: "has_shadow"; has_shadow: boolean }
    | { $case: "icon_color"; icon_color: Color }
    | { $case: "icon_size"; icon_size: number }
    | { $case: "height"; height: number }
    | { $case: "content_padding"; content_padding: Tag_Style_ContentPadding }
    | { $case: "typography"; typography: Typography }
    | { $case: "border"; border: Tag_Style_Border }
    | undefined;
}

export interface Color {
  color?: { $case: "library_color"; library_color: Color_LibraryColor } | { $case: "hex"; hex: string } | undefined;
}

/** https://www.figma.com/file/N2gREqwEvhAYxN1ZFVwtQ4/iOS-Library?type=design&node-id=1318%3A5444&t=lGoX56BoQh27rD4J-1 */
export enum Color_LibraryColor {
  /** LIBRARY_COLOR_NONE - BASE COLORS */
  LIBRARY_COLOR_NONE = "LIBRARY_COLOR_NONE",
  LIBRARY_COLOR_BASE_PRIMARY = "LIBRARY_COLOR_BASE_PRIMARY",
  LIBRARY_COLOR_BASE_ACTIVE = "LIBRARY_COLOR_BASE_ACTIVE",
  LIBRARY_COLOR_BASE_SECONDARY = "LIBRARY_COLOR_BASE_SECONDARY",
  LIBRARY_COLOR_BASE_DISABLED = "LIBRARY_COLOR_BASE_DISABLED",
  LIBRARY_COLOR_ACCENT_PRIMARY = "LIBRARY_COLOR_ACCENT_PRIMARY",
  LIBRARY_COLOR_ACCENT_ACTIVE = "LIBRARY_COLOR_ACCENT_ACTIVE",
  LIBRARY_COLOR_NEUTRAL_PRIMARY = "LIBRARY_COLOR_NEUTRAL_PRIMARY",
  LIBRARY_COLOR_NEUTRAL_SECONDARY = "LIBRARY_COLOR_NEUTRAL_SECONDARY",
  LIBRARY_COLOR_NEUTRAL_ACTIVE = "LIBRARY_COLOR_NEUTRAL_ACTIVE",
  LIBRARY_COLOR_NEUTRAL_DISABLED = "LIBRARY_COLOR_NEUTRAL_DISABLED",
  LIBRARY_COLOR_OTHER_ERROR = "LIBRARY_COLOR_OTHER_ERROR",
  LIBRARY_COLOR_OTHER_GREEN_DARK = "LIBRARY_COLOR_OTHER_GREEN_DARK",
  LIBRARY_COLOR_OTHER_GREEN_NEW = "LIBRARY_COLOR_OTHER_GREEN_NEW",
  LIBRARY_COLOR_OTHER_INFO = "LIBRARY_COLOR_OTHER_INFO",
  LIBRARY_COLOR_OTHER_LIGHT_GREEN = "LIBRARY_COLOR_OTHER_LIGHT_GREEN",
  LIBRARY_COLOR_OTHER_ORANGE = "LIBRARY_COLOR_OTHER_ORANGE",
  LIBRARY_COLOR_OTHER_RED = "LIBRARY_COLOR_OTHER_RED",
  LIBRARY_COLOR_OTHER_WARNING = "LIBRARY_COLOR_OTHER_WARNING",
  LIBRARY_COLOR_OTHER_YELLOW = "LIBRARY_COLOR_OTHER_YELLOW",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function color_LibraryColorFromJSON(object: any): Color_LibraryColor {
  switch (object) {
    case 0:
    case "LIBRARY_COLOR_NONE":
      return Color_LibraryColor.LIBRARY_COLOR_NONE;
    case 1:
    case "LIBRARY_COLOR_BASE_PRIMARY":
      return Color_LibraryColor.LIBRARY_COLOR_BASE_PRIMARY;
    case 2:
    case "LIBRARY_COLOR_BASE_ACTIVE":
      return Color_LibraryColor.LIBRARY_COLOR_BASE_ACTIVE;
    case 3:
    case "LIBRARY_COLOR_BASE_SECONDARY":
      return Color_LibraryColor.LIBRARY_COLOR_BASE_SECONDARY;
    case 4:
    case "LIBRARY_COLOR_BASE_DISABLED":
      return Color_LibraryColor.LIBRARY_COLOR_BASE_DISABLED;
    case 5:
    case "LIBRARY_COLOR_ACCENT_PRIMARY":
      return Color_LibraryColor.LIBRARY_COLOR_ACCENT_PRIMARY;
    case 6:
    case "LIBRARY_COLOR_ACCENT_ACTIVE":
      return Color_LibraryColor.LIBRARY_COLOR_ACCENT_ACTIVE;
    case 7:
    case "LIBRARY_COLOR_NEUTRAL_PRIMARY":
      return Color_LibraryColor.LIBRARY_COLOR_NEUTRAL_PRIMARY;
    case 8:
    case "LIBRARY_COLOR_NEUTRAL_SECONDARY":
      return Color_LibraryColor.LIBRARY_COLOR_NEUTRAL_SECONDARY;
    case 9:
    case "LIBRARY_COLOR_NEUTRAL_ACTIVE":
      return Color_LibraryColor.LIBRARY_COLOR_NEUTRAL_ACTIVE;
    case 10:
    case "LIBRARY_COLOR_NEUTRAL_DISABLED":
      return Color_LibraryColor.LIBRARY_COLOR_NEUTRAL_DISABLED;
    case 11:
    case "LIBRARY_COLOR_OTHER_ERROR":
      return Color_LibraryColor.LIBRARY_COLOR_OTHER_ERROR;
    case 12:
    case "LIBRARY_COLOR_OTHER_GREEN_DARK":
      return Color_LibraryColor.LIBRARY_COLOR_OTHER_GREEN_DARK;
    case 13:
    case "LIBRARY_COLOR_OTHER_GREEN_NEW":
      return Color_LibraryColor.LIBRARY_COLOR_OTHER_GREEN_NEW;
    case 14:
    case "LIBRARY_COLOR_OTHER_INFO":
      return Color_LibraryColor.LIBRARY_COLOR_OTHER_INFO;
    case 15:
    case "LIBRARY_COLOR_OTHER_LIGHT_GREEN":
      return Color_LibraryColor.LIBRARY_COLOR_OTHER_LIGHT_GREEN;
    case 16:
    case "LIBRARY_COLOR_OTHER_ORANGE":
      return Color_LibraryColor.LIBRARY_COLOR_OTHER_ORANGE;
    case 17:
    case "LIBRARY_COLOR_OTHER_RED":
      return Color_LibraryColor.LIBRARY_COLOR_OTHER_RED;
    case 18:
    case "LIBRARY_COLOR_OTHER_WARNING":
      return Color_LibraryColor.LIBRARY_COLOR_OTHER_WARNING;
    case 19:
    case "LIBRARY_COLOR_OTHER_YELLOW":
      return Color_LibraryColor.LIBRARY_COLOR_OTHER_YELLOW;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Color_LibraryColor.UNRECOGNIZED;
  }
}

export function color_LibraryColorToJSON(object: Color_LibraryColor): string {
  switch (object) {
    case Color_LibraryColor.LIBRARY_COLOR_NONE:
      return "LIBRARY_COLOR_NONE";
    case Color_LibraryColor.LIBRARY_COLOR_BASE_PRIMARY:
      return "LIBRARY_COLOR_BASE_PRIMARY";
    case Color_LibraryColor.LIBRARY_COLOR_BASE_ACTIVE:
      return "LIBRARY_COLOR_BASE_ACTIVE";
    case Color_LibraryColor.LIBRARY_COLOR_BASE_SECONDARY:
      return "LIBRARY_COLOR_BASE_SECONDARY";
    case Color_LibraryColor.LIBRARY_COLOR_BASE_DISABLED:
      return "LIBRARY_COLOR_BASE_DISABLED";
    case Color_LibraryColor.LIBRARY_COLOR_ACCENT_PRIMARY:
      return "LIBRARY_COLOR_ACCENT_PRIMARY";
    case Color_LibraryColor.LIBRARY_COLOR_ACCENT_ACTIVE:
      return "LIBRARY_COLOR_ACCENT_ACTIVE";
    case Color_LibraryColor.LIBRARY_COLOR_NEUTRAL_PRIMARY:
      return "LIBRARY_COLOR_NEUTRAL_PRIMARY";
    case Color_LibraryColor.LIBRARY_COLOR_NEUTRAL_SECONDARY:
      return "LIBRARY_COLOR_NEUTRAL_SECONDARY";
    case Color_LibraryColor.LIBRARY_COLOR_NEUTRAL_ACTIVE:
      return "LIBRARY_COLOR_NEUTRAL_ACTIVE";
    case Color_LibraryColor.LIBRARY_COLOR_NEUTRAL_DISABLED:
      return "LIBRARY_COLOR_NEUTRAL_DISABLED";
    case Color_LibraryColor.LIBRARY_COLOR_OTHER_ERROR:
      return "LIBRARY_COLOR_OTHER_ERROR";
    case Color_LibraryColor.LIBRARY_COLOR_OTHER_GREEN_DARK:
      return "LIBRARY_COLOR_OTHER_GREEN_DARK";
    case Color_LibraryColor.LIBRARY_COLOR_OTHER_GREEN_NEW:
      return "LIBRARY_COLOR_OTHER_GREEN_NEW";
    case Color_LibraryColor.LIBRARY_COLOR_OTHER_INFO:
      return "LIBRARY_COLOR_OTHER_INFO";
    case Color_LibraryColor.LIBRARY_COLOR_OTHER_LIGHT_GREEN:
      return "LIBRARY_COLOR_OTHER_LIGHT_GREEN";
    case Color_LibraryColor.LIBRARY_COLOR_OTHER_ORANGE:
      return "LIBRARY_COLOR_OTHER_ORANGE";
    case Color_LibraryColor.LIBRARY_COLOR_OTHER_RED:
      return "LIBRARY_COLOR_OTHER_RED";
    case Color_LibraryColor.LIBRARY_COLOR_OTHER_WARNING:
      return "LIBRARY_COLOR_OTHER_WARNING";
    case Color_LibraryColor.LIBRARY_COLOR_OTHER_YELLOW:
      return "LIBRARY_COLOR_OTHER_YELLOW";
    case Color_LibraryColor.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Typography {
  typography?: { $case: "library_typography"; library_typography: Typography_LibraryTypography } | {
    $case: "custom_emphasized";
    custom_emphasized: Typography_CustomEmphasized;
  } | undefined;
}

export enum Typography_LibraryTypography {
  /** LIBRARY_TYPOGRAPHY_NONE - BASE TYPOGRAPHY */
  LIBRARY_TYPOGRAPHY_NONE = "LIBRARY_TYPOGRAPHY_NONE",
  LIBRARY_TYPOGRAPHY_LEAD_PRIMARY = "LIBRARY_TYPOGRAPHY_LEAD_PRIMARY",
  LIBRARY_TYPOGRAPHY_HEADER_PRIMARY = "LIBRARY_TYPOGRAPHY_HEADER_PRIMARY",
  LIBRARY_TYPOGRAPHY_HEADER_SECONDARY = "LIBRARY_TYPOGRAPHY_HEADER_SECONDARY",
  LIBRARY_TYPOGRAPHY_SUBTITLE_LARGE = "LIBRARY_TYPOGRAPHY_SUBTITLE_LARGE",
  LIBRARY_TYPOGRAPHY_SUBTITLE_PRIMARY = "LIBRARY_TYPOGRAPHY_SUBTITLE_PRIMARY",
  LIBRARY_TYPOGRAPHY_SUBTITLE_SECONDARY = "LIBRARY_TYPOGRAPHY_SUBTITLE_SECONDARY",
  LIBRARY_TYPOGRAPHY_SUBTITLE_TERTIARY = "LIBRARY_TYPOGRAPHY_SUBTITLE_TERTIARY",
  LIBRARY_TYPOGRAPHY_PARAGRAPH_PRIMARY = "LIBRARY_TYPOGRAPHY_PARAGRAPH_PRIMARY",
  LIBRARY_TYPOGRAPHY_PARAGRAPH_SECONDARY = "LIBRARY_TYPOGRAPHY_PARAGRAPH_SECONDARY",
  LIBRARY_TYPOGRAPHY_PARAGRAPH_TERTIARY = "LIBRARY_TYPOGRAPHY_PARAGRAPH_TERTIARY",
  LIBRARY_TYPOGRAPHY_CAPTION_PRIMARY = "LIBRARY_TYPOGRAPHY_CAPTION_PRIMARY",
  LIBRARY_TYPOGRAPHY_CAPTION_ROUNDED = "LIBRARY_TYPOGRAPHY_CAPTION_ROUNDED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function typography_LibraryTypographyFromJSON(object: any): Typography_LibraryTypography {
  switch (object) {
    case 0:
    case "LIBRARY_TYPOGRAPHY_NONE":
      return Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_NONE;
    case 1:
    case "LIBRARY_TYPOGRAPHY_LEAD_PRIMARY":
      return Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_LEAD_PRIMARY;
    case 2:
    case "LIBRARY_TYPOGRAPHY_HEADER_PRIMARY":
      return Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_HEADER_PRIMARY;
    case 3:
    case "LIBRARY_TYPOGRAPHY_HEADER_SECONDARY":
      return Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_HEADER_SECONDARY;
    case 4:
    case "LIBRARY_TYPOGRAPHY_SUBTITLE_LARGE":
      return Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_SUBTITLE_LARGE;
    case 5:
    case "LIBRARY_TYPOGRAPHY_SUBTITLE_PRIMARY":
      return Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_SUBTITLE_PRIMARY;
    case 6:
    case "LIBRARY_TYPOGRAPHY_SUBTITLE_SECONDARY":
      return Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_SUBTITLE_SECONDARY;
    case 7:
    case "LIBRARY_TYPOGRAPHY_SUBTITLE_TERTIARY":
      return Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_SUBTITLE_TERTIARY;
    case 8:
    case "LIBRARY_TYPOGRAPHY_PARAGRAPH_PRIMARY":
      return Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_PARAGRAPH_PRIMARY;
    case 9:
    case "LIBRARY_TYPOGRAPHY_PARAGRAPH_SECONDARY":
      return Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_PARAGRAPH_SECONDARY;
    case 10:
    case "LIBRARY_TYPOGRAPHY_PARAGRAPH_TERTIARY":
      return Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_PARAGRAPH_TERTIARY;
    case 11:
    case "LIBRARY_TYPOGRAPHY_CAPTION_PRIMARY":
      return Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_CAPTION_PRIMARY;
    case 12:
    case "LIBRARY_TYPOGRAPHY_CAPTION_ROUNDED":
      return Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_CAPTION_ROUNDED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Typography_LibraryTypography.UNRECOGNIZED;
  }
}

export function typography_LibraryTypographyToJSON(object: Typography_LibraryTypography): string {
  switch (object) {
    case Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_NONE:
      return "LIBRARY_TYPOGRAPHY_NONE";
    case Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_LEAD_PRIMARY:
      return "LIBRARY_TYPOGRAPHY_LEAD_PRIMARY";
    case Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_HEADER_PRIMARY:
      return "LIBRARY_TYPOGRAPHY_HEADER_PRIMARY";
    case Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_HEADER_SECONDARY:
      return "LIBRARY_TYPOGRAPHY_HEADER_SECONDARY";
    case Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_SUBTITLE_LARGE:
      return "LIBRARY_TYPOGRAPHY_SUBTITLE_LARGE";
    case Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_SUBTITLE_PRIMARY:
      return "LIBRARY_TYPOGRAPHY_SUBTITLE_PRIMARY";
    case Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_SUBTITLE_SECONDARY:
      return "LIBRARY_TYPOGRAPHY_SUBTITLE_SECONDARY";
    case Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_SUBTITLE_TERTIARY:
      return "LIBRARY_TYPOGRAPHY_SUBTITLE_TERTIARY";
    case Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_PARAGRAPH_PRIMARY:
      return "LIBRARY_TYPOGRAPHY_PARAGRAPH_PRIMARY";
    case Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_PARAGRAPH_SECONDARY:
      return "LIBRARY_TYPOGRAPHY_PARAGRAPH_SECONDARY";
    case Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_PARAGRAPH_TERTIARY:
      return "LIBRARY_TYPOGRAPHY_PARAGRAPH_TERTIARY";
    case Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_CAPTION_PRIMARY:
      return "LIBRARY_TYPOGRAPHY_CAPTION_PRIMARY";
    case Typography_LibraryTypography.LIBRARY_TYPOGRAPHY_CAPTION_ROUNDED:
      return "LIBRARY_TYPOGRAPHY_CAPTION_ROUNDED";
    case Typography_LibraryTypography.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Typography_CustomEmphasized {
  size: number;
  line_height: number;
  tracking: number;
}

export interface FilledButton {
  style: FilledButton_Style | undefined;
  content: FilledButton_Content | undefined;
  size: FilledButton_Size;
  corner_rounding_mode: FilledButton_CornerRoundingMode;
}

export enum FilledButton_Size {
  SIZE_NONE = "SIZE_NONE",
  SIZE_MEDIUM = "SIZE_MEDIUM",
  SIZE_LARGE = "SIZE_LARGE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function filledButton_SizeFromJSON(object: any): FilledButton_Size {
  switch (object) {
    case 0:
    case "SIZE_NONE":
      return FilledButton_Size.SIZE_NONE;
    case 2:
    case "SIZE_MEDIUM":
      return FilledButton_Size.SIZE_MEDIUM;
    case 3:
    case "SIZE_LARGE":
      return FilledButton_Size.SIZE_LARGE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FilledButton_Size.UNRECOGNIZED;
  }
}

export function filledButton_SizeToJSON(object: FilledButton_Size): string {
  switch (object) {
    case FilledButton_Size.SIZE_NONE:
      return "SIZE_NONE";
    case FilledButton_Size.SIZE_MEDIUM:
      return "SIZE_MEDIUM";
    case FilledButton_Size.SIZE_LARGE:
      return "SIZE_LARGE";
    case FilledButton_Size.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum FilledButton_CornerRoundingMode {
  CORNER_ROUNDING_MODE_NONE = "CORNER_ROUNDING_MODE_NONE",
  CORNER_ROUNDING_MODE_SUPERELLIPSE = "CORNER_ROUNDING_MODE_SUPERELLIPSE",
  CORNER_ROUNDING_MODE_CIRCULAR = "CORNER_ROUNDING_MODE_CIRCULAR",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function filledButton_CornerRoundingModeFromJSON(object: any): FilledButton_CornerRoundingMode {
  switch (object) {
    case 0:
    case "CORNER_ROUNDING_MODE_NONE":
      return FilledButton_CornerRoundingMode.CORNER_ROUNDING_MODE_NONE;
    case 1:
    case "CORNER_ROUNDING_MODE_SUPERELLIPSE":
      return FilledButton_CornerRoundingMode.CORNER_ROUNDING_MODE_SUPERELLIPSE;
    case 2:
    case "CORNER_ROUNDING_MODE_CIRCULAR":
      return FilledButton_CornerRoundingMode.CORNER_ROUNDING_MODE_CIRCULAR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FilledButton_CornerRoundingMode.UNRECOGNIZED;
  }
}

export function filledButton_CornerRoundingModeToJSON(object: FilledButton_CornerRoundingMode): string {
  switch (object) {
    case FilledButton_CornerRoundingMode.CORNER_ROUNDING_MODE_NONE:
      return "CORNER_ROUNDING_MODE_NONE";
    case FilledButton_CornerRoundingMode.CORNER_ROUNDING_MODE_SUPERELLIPSE:
      return "CORNER_ROUNDING_MODE_SUPERELLIPSE";
    case FilledButton_CornerRoundingMode.CORNER_ROUNDING_MODE_CIRCULAR:
      return "CORNER_ROUNDING_MODE_CIRCULAR";
    case FilledButton_CornerRoundingMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface FilledButton_Style {
  style?: { $case: "preset"; preset: FilledButton_Style_Preset } | {
    $case: "custom";
    custom: FilledButton_Style_Custom;
  } | undefined;
}

export enum FilledButton_Style_Preset {
  PRESET_NONE = "PRESET_NONE",
  PRESET_PRIMARY = "PRESET_PRIMARY",
  PRESET_SECONDARY = "PRESET_SECONDARY",
  PRESET_PRIMARY_INVERTED = "PRESET_PRIMARY_INVERTED",
  /** PRESET_TERTIARY - Only for new features. Required iOS build: >= 1004 */
  PRESET_TERTIARY = "PRESET_TERTIARY",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function filledButton_Style_PresetFromJSON(object: any): FilledButton_Style_Preset {
  switch (object) {
    case 0:
    case "PRESET_NONE":
      return FilledButton_Style_Preset.PRESET_NONE;
    case 1:
    case "PRESET_PRIMARY":
      return FilledButton_Style_Preset.PRESET_PRIMARY;
    case 2:
    case "PRESET_SECONDARY":
      return FilledButton_Style_Preset.PRESET_SECONDARY;
    case 3:
    case "PRESET_PRIMARY_INVERTED":
      return FilledButton_Style_Preset.PRESET_PRIMARY_INVERTED;
    case 4:
    case "PRESET_TERTIARY":
      return FilledButton_Style_Preset.PRESET_TERTIARY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FilledButton_Style_Preset.UNRECOGNIZED;
  }
}

export function filledButton_Style_PresetToJSON(object: FilledButton_Style_Preset): string {
  switch (object) {
    case FilledButton_Style_Preset.PRESET_NONE:
      return "PRESET_NONE";
    case FilledButton_Style_Preset.PRESET_PRIMARY:
      return "PRESET_PRIMARY";
    case FilledButton_Style_Preset.PRESET_SECONDARY:
      return "PRESET_SECONDARY";
    case FilledButton_Style_Preset.PRESET_PRIMARY_INVERTED:
      return "PRESET_PRIMARY_INVERTED";
    case FilledButton_Style_Preset.PRESET_TERTIARY:
      return "PRESET_TERTIARY";
    case FilledButton_Style_Preset.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface FilledButton_Style_Custom {
  background_colors: FilledButton_Style_Custom_Colors | undefined;
  title_colors: FilledButton_Style_Custom_Colors | undefined;
}

export interface FilledButton_Style_Custom_Colors {
  default: Color | undefined;
  disabled: Color | undefined;
}

export interface FilledButton_Content {
  title: string;
  icon: string;
  icon_rendering_mode: FilledButton_Content_IconRenderingMode;
  is_showing_progress: boolean;
  is_disabled: boolean;
}

export enum FilledButton_Content_IconRenderingMode {
  ICON_RENDERING_MODE_NONE = "ICON_RENDERING_MODE_NONE",
  ICON_RENDERING_MODE_TEMPLATE = "ICON_RENDERING_MODE_TEMPLATE",
  ICON_RENDERING_MODE_ORIGINAL = "ICON_RENDERING_MODE_ORIGINAL",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function filledButton_Content_IconRenderingModeFromJSON(object: any): FilledButton_Content_IconRenderingMode {
  switch (object) {
    case 0:
    case "ICON_RENDERING_MODE_NONE":
      return FilledButton_Content_IconRenderingMode.ICON_RENDERING_MODE_NONE;
    case 1:
    case "ICON_RENDERING_MODE_TEMPLATE":
      return FilledButton_Content_IconRenderingMode.ICON_RENDERING_MODE_TEMPLATE;
    case 2:
    case "ICON_RENDERING_MODE_ORIGINAL":
      return FilledButton_Content_IconRenderingMode.ICON_RENDERING_MODE_ORIGINAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FilledButton_Content_IconRenderingMode.UNRECOGNIZED;
  }
}

export function filledButton_Content_IconRenderingModeToJSON(object: FilledButton_Content_IconRenderingMode): string {
  switch (object) {
    case FilledButton_Content_IconRenderingMode.ICON_RENDERING_MODE_NONE:
      return "ICON_RENDERING_MODE_NONE";
    case FilledButton_Content_IconRenderingMode.ICON_RENDERING_MODE_TEMPLATE:
      return "ICON_RENDERING_MODE_TEMPLATE";
    case FilledButton_Content_IconRenderingMode.ICON_RENDERING_MODE_ORIGINAL:
      return "ICON_RENDERING_MODE_ORIGINAL";
    case FilledButton_Content_IconRenderingMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SnackBar {
  title: string;
  image: string;
  show_after_delay: number;
  hide_after_delay: number;
}

export interface Media {
  type?: { $case: "image"; image: string } | { $case: "video"; video: string } | undefined;
}

export const AttributedString = {
  fromJSON(object: any): AttributedString {
    return {
      parts: globalThis.Array.isArray(object?.parts)
        ? object.parts.map((e: any) => AttributedString_AttributedStringPart.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AttributedString): unknown {
    const obj: any = {};
    if (message.parts?.length) {
      obj.parts = message.parts.map((e) => AttributedString_AttributedStringPart.toJSON(e));
    }
    return obj;
  },
};

export const AttributedString_AttributedStringPart = {
  fromJSON(object: any): AttributedString_AttributedStringPart {
    return {
      content: isSet(object.content) ? globalThis.String(object.content) : "",
      format: globalThis.Array.isArray(object?.format)
        ? object.format.map((e: any) => AttributedString_AttributedStringFormat.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AttributedString_AttributedStringPart): unknown {
    const obj: any = {};
    if (message.content !== "") {
      obj.content = message.content;
    }
    if (message.format?.length) {
      obj.format = message.format.map((e) => AttributedString_AttributedStringFormat.toJSON(e));
    }
    return obj;
  },
};

export const AttributedString_AttributedStringFormat = {
  fromJSON(object: any): AttributedString_AttributedStringFormat {
    return {
      format: isSet(object.emphasis)
        ? { $case: "emphasis", emphasis: globalThis.Boolean(object.emphasis) }
        : isSet(object.color)
        ? { $case: "color", color: globalThis.String(object.color) }
        : isSet(object.font_weight)
        ? { $case: "font_weight", font_weight: globalThis.Number(object.font_weight) }
        : isSet(object.sf_symbol)
        ? { $case: "sf_symbol", sf_symbol: globalThis.Boolean(object.sf_symbol) }
        : isSet(object.markdown)
        ? { $case: "markdown", markdown: AttributedString_AttributedStringFormat_Markdown.fromJSON(object.markdown) }
        : undefined,
    };
  },

  toJSON(message: AttributedString_AttributedStringFormat): unknown {
    const obj: any = {};
    if (message.format?.$case === "emphasis") {
      obj.emphasis = message.format.emphasis;
    }
    if (message.format?.$case === "color") {
      obj.color = message.format.color;
    }
    if (message.format?.$case === "font_weight") {
      obj.font_weight = Math.round(message.format.font_weight);
    }
    if (message.format?.$case === "sf_symbol") {
      obj.sf_symbol = message.format.sf_symbol;
    }
    if (message.format?.$case === "markdown") {
      obj.markdown = AttributedString_AttributedStringFormat_Markdown.toJSON(message.format.markdown);
    }
    return obj;
  },
};

export const AttributedString_AttributedStringFormat_Markdown = {
  fromJSON(_: any): AttributedString_AttributedStringFormat_Markdown {
    return {};
  },

  toJSON(_: AttributedString_AttributedStringFormat_Markdown): unknown {
    const obj: any = {};
    return obj;
  },
};

export const Image = {
  fromJSON(object: any): Image {
    return {
      source: isSet(object.url)
        ? { $case: "url", url: globalThis.String(object.url) }
        : isSet(object.base64_data)
        ? { $case: "base64_data", base64_data: globalThis.String(object.base64_data) }
        : undefined,
    };
  },

  toJSON(message: Image): unknown {
    const obj: any = {};
    if (message.source?.$case === "url") {
      obj.url = message.source.url;
    }
    if (message.source?.$case === "base64_data") {
      obj.base64_data = message.source.base64_data;
    }
    return obj;
  },
};

export const ValidationRule = {
  fromJSON(object: any): ValidationRule {
    return {
      regex: isSet(object.regex) ? globalThis.String(object.regex) : "",
      error: isSet(object.error) ? globalThis.String(object.error) : "",
    };
  },

  toJSON(message: ValidationRule): unknown {
    const obj: any = {};
    if (message.regex !== "") {
      obj.regex = message.regex;
    }
    if (message.error !== "") {
      obj.error = message.error;
    }
    return obj;
  },
};

export const IOSTextField = {
  fromJSON(object: any): IOSTextField {
    return {
      placeholder: isSet(object.placeholder) ? globalThis.String(object.placeholder) : "",
      initial_input: isSet(object.initial_input) ? globalThis.String(object.initial_input) : "",
      keyboard_type: isSet(object.keyboard_type)
        ? iOSKeyboardTypeFromJSON(object.keyboard_type)
        : IOSKeyboardType.KEYBOARD_TYPE_NONE,
      validation_rules: globalThis.Array.isArray(object?.validation_rules)
        ? object.validation_rules.map((e: any) => ValidationRule.fromJSON(e))
        : [],
    };
  },

  toJSON(message: IOSTextField): unknown {
    const obj: any = {};
    if (message.placeholder !== "") {
      obj.placeholder = message.placeholder;
    }
    if (message.initial_input !== "") {
      obj.initial_input = message.initial_input;
    }
    if (message.keyboard_type !== IOSKeyboardType.KEYBOARD_TYPE_NONE) {
      obj.keyboard_type = iOSKeyboardTypeToJSON(message.keyboard_type);
    }
    if (message.validation_rules?.length) {
      obj.validation_rules = message.validation_rules.map((e) => ValidationRule.toJSON(e));
    }
    return obj;
  },
};

export const Tag = {
  fromJSON(object: any): Tag {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      icon: isSet(object.icon) ? Tag_Icon.fromJSON(object.icon) : undefined,
      style: isSet(object.style) ? Tag_Style.fromJSON(object.style) : undefined,
    };
  },

  toJSON(message: Tag): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.icon !== undefined) {
      obj.icon = Tag_Icon.toJSON(message.icon);
    }
    if (message.style !== undefined) {
      obj.style = Tag_Style.toJSON(message.style);
    }
    return obj;
  },
};

export const Tag_Icon = {
  fromJSON(object: any): Tag_Icon {
    return {
      icon: isSet(object.image)
        ? { $case: "image", image: Tag_Icon_Image.fromJSON(object.image) }
        : isSet(object.sfSymbol)
        ? { $case: "sfSymbol", sfSymbol: globalThis.String(object.sfSymbol) }
        : undefined,
    };
  },

  toJSON(message: Tag_Icon): unknown {
    const obj: any = {};
    if (message.icon?.$case === "image") {
      obj.image = Tag_Icon_Image.toJSON(message.icon.image);
    }
    if (message.icon?.$case === "sfSymbol") {
      obj.sfSymbol = message.icon.sfSymbol;
    }
    return obj;
  },
};

export const Tag_Icon_Image = {
  fromJSON(object: any): Tag_Icon_Image {
    return {
      source: isSet(object.source) ? globalThis.String(object.source) : "",
      preserves_original_color: isSet(object.preserves_original_color)
        ? globalThis.Boolean(object.preserves_original_color)
        : false,
    };
  },

  toJSON(message: Tag_Icon_Image): unknown {
    const obj: any = {};
    if (message.source !== "") {
      obj.source = message.source;
    }
    if (message.preserves_original_color !== false) {
      obj.preserves_original_color = message.preserves_original_color;
    }
    return obj;
  },
};

export const Tag_Style = {
  fromJSON(object: any): Tag_Style {
    return {
      preset: isSet(object.preset) ? tag_Style_PresetFromJSON(object.preset) : Tag_Style_Preset.PRESET_NONE,
      properties: globalThis.Array.isArray(object?.properties)
        ? object.properties.map((e: any) => Tag_Style_Property.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Tag_Style): unknown {
    const obj: any = {};
    if (message.preset !== Tag_Style_Preset.PRESET_NONE) {
      obj.preset = tag_Style_PresetToJSON(message.preset);
    }
    if (message.properties?.length) {
      obj.properties = message.properties.map((e) => Tag_Style_Property.toJSON(e));
    }
    return obj;
  },
};

export const Tag_Style_Background = {
  fromJSON(object: any): Tag_Style_Background {
    return {
      value: isSet(object.value)
        ? tag_Style_Background_ValueFromJSON(object.value)
        : Tag_Style_Background_Value.VALUE_NONE,
      hex: isSet(object.hex) ? globalThis.String(object.hex) : "",
    };
  },

  toJSON(message: Tag_Style_Background): unknown {
    const obj: any = {};
    if (message.value !== Tag_Style_Background_Value.VALUE_NONE) {
      obj.value = tag_Style_Background_ValueToJSON(message.value);
    }
    if (message.hex !== "") {
      obj.hex = message.hex;
    }
    return obj;
  },
};

export const Tag_Style_TitleColor = {
  fromJSON(object: any): Tag_Style_TitleColor {
    return {
      value: isSet(object.value)
        ? tag_Style_TitleColor_ValueFromJSON(object.value)
        : Tag_Style_TitleColor_Value.VALUE_NONE,
      hex: isSet(object.hex) ? globalThis.String(object.hex) : "",
    };
  },

  toJSON(message: Tag_Style_TitleColor): unknown {
    const obj: any = {};
    if (message.value !== Tag_Style_TitleColor_Value.VALUE_NONE) {
      obj.value = tag_Style_TitleColor_ValueToJSON(message.value);
    }
    if (message.hex !== "") {
      obj.hex = message.hex;
    }
    return obj;
  },
};

export const Tag_Style_ContentPadding = {
  fromJSON(object: any): Tag_Style_ContentPadding {
    return {
      horizontal: isSet(object.horizontal) ? globalThis.Number(object.horizontal) : 0,
      vertical: isSet(object.vertical) ? globalThis.Number(object.vertical) : 0,
    };
  },

  toJSON(message: Tag_Style_ContentPadding): unknown {
    const obj: any = {};
    if (message.horizontal !== 0) {
      obj.horizontal = message.horizontal;
    }
    if (message.vertical !== 0) {
      obj.vertical = message.vertical;
    }
    return obj;
  },
};

export const Tag_Style_Border = {
  fromJSON(object: any): Tag_Style_Border {
    return {
      color: isSet(object.color) ? Color.fromJSON(object.color) : undefined,
      width: isSet(object.width) ? globalThis.Number(object.width) : 0,
    };
  },

  toJSON(message: Tag_Style_Border): unknown {
    const obj: any = {};
    if (message.color !== undefined) {
      obj.color = Color.toJSON(message.color);
    }
    if (message.width !== 0) {
      obj.width = message.width;
    }
    return obj;
  },
};

export const Tag_Style_Property = {
  fromJSON(object: any): Tag_Style_Property {
    return {
      property: isSet(object.background)
        ? { $case: "background", background: Tag_Style_Background.fromJSON(object.background) }
        : isSet(object.title_size)
        ? { $case: "title_size", title_size: tag_Style_TitleSizeFromJSON(object.title_size) }
        : isSet(object.title_weight)
        ? { $case: "title_weight", title_weight: tag_Style_TitleWeightFromJSON(object.title_weight) }
        : isSet(object.title_color)
        ? { $case: "title_color", title_color: Tag_Style_TitleColor.fromJSON(object.title_color) }
        : isSet(object.has_paddings)
        ? { $case: "has_paddings", has_paddings: globalThis.Boolean(object.has_paddings) }
        : isSet(object.has_shadow)
        ? { $case: "has_shadow", has_shadow: globalThis.Boolean(object.has_shadow) }
        : isSet(object.icon_color)
        ? { $case: "icon_color", icon_color: Color.fromJSON(object.icon_color) }
        : isSet(object.icon_size)
        ? { $case: "icon_size", icon_size: globalThis.Number(object.icon_size) }
        : isSet(object.height)
        ? { $case: "height", height: globalThis.Number(object.height) }
        : isSet(object.content_padding)
        ? { $case: "content_padding", content_padding: Tag_Style_ContentPadding.fromJSON(object.content_padding) }
        : isSet(object.typography)
        ? { $case: "typography", typography: Typography.fromJSON(object.typography) }
        : isSet(object.border)
        ? { $case: "border", border: Tag_Style_Border.fromJSON(object.border) }
        : undefined,
    };
  },

  toJSON(message: Tag_Style_Property): unknown {
    const obj: any = {};
    if (message.property?.$case === "background") {
      obj.background = Tag_Style_Background.toJSON(message.property.background);
    }
    if (message.property?.$case === "title_size") {
      obj.title_size = tag_Style_TitleSizeToJSON(message.property.title_size);
    }
    if (message.property?.$case === "title_weight") {
      obj.title_weight = tag_Style_TitleWeightToJSON(message.property.title_weight);
    }
    if (message.property?.$case === "title_color") {
      obj.title_color = Tag_Style_TitleColor.toJSON(message.property.title_color);
    }
    if (message.property?.$case === "has_paddings") {
      obj.has_paddings = message.property.has_paddings;
    }
    if (message.property?.$case === "has_shadow") {
      obj.has_shadow = message.property.has_shadow;
    }
    if (message.property?.$case === "icon_color") {
      obj.icon_color = Color.toJSON(message.property.icon_color);
    }
    if (message.property?.$case === "icon_size") {
      obj.icon_size = message.property.icon_size;
    }
    if (message.property?.$case === "height") {
      obj.height = message.property.height;
    }
    if (message.property?.$case === "content_padding") {
      obj.content_padding = Tag_Style_ContentPadding.toJSON(message.property.content_padding);
    }
    if (message.property?.$case === "typography") {
      obj.typography = Typography.toJSON(message.property.typography);
    }
    if (message.property?.$case === "border") {
      obj.border = Tag_Style_Border.toJSON(message.property.border);
    }
    return obj;
  },
};

export const Color = {
  fromJSON(object: any): Color {
    return {
      color: isSet(object.library_color)
        ? { $case: "library_color", library_color: color_LibraryColorFromJSON(object.library_color) }
        : isSet(object.hex)
        ? { $case: "hex", hex: globalThis.String(object.hex) }
        : undefined,
    };
  },

  toJSON(message: Color): unknown {
    const obj: any = {};
    if (message.color?.$case === "library_color") {
      obj.library_color = color_LibraryColorToJSON(message.color.library_color);
    }
    if (message.color?.$case === "hex") {
      obj.hex = message.color.hex;
    }
    return obj;
  },
};

export const Typography = {
  fromJSON(object: any): Typography {
    return {
      typography: isSet(object.library_typography)
        ? {
          $case: "library_typography",
          library_typography: typography_LibraryTypographyFromJSON(object.library_typography),
        }
        : isSet(object.custom_emphasized)
        ? {
          $case: "custom_emphasized",
          custom_emphasized: Typography_CustomEmphasized.fromJSON(object.custom_emphasized),
        }
        : undefined,
    };
  },

  toJSON(message: Typography): unknown {
    const obj: any = {};
    if (message.typography?.$case === "library_typography") {
      obj.library_typography = typography_LibraryTypographyToJSON(message.typography.library_typography);
    }
    if (message.typography?.$case === "custom_emphasized") {
      obj.custom_emphasized = Typography_CustomEmphasized.toJSON(message.typography.custom_emphasized);
    }
    return obj;
  },
};

export const Typography_CustomEmphasized = {
  fromJSON(object: any): Typography_CustomEmphasized {
    return {
      size: isSet(object.size) ? globalThis.Number(object.size) : 0,
      line_height: isSet(object.line_height) ? globalThis.Number(object.line_height) : 0,
      tracking: isSet(object.tracking) ? globalThis.Number(object.tracking) : 0,
    };
  },

  toJSON(message: Typography_CustomEmphasized): unknown {
    const obj: any = {};
    if (message.size !== 0) {
      obj.size = message.size;
    }
    if (message.line_height !== 0) {
      obj.line_height = message.line_height;
    }
    if (message.tracking !== 0) {
      obj.tracking = message.tracking;
    }
    return obj;
  },
};

export const FilledButton = {
  fromJSON(object: any): FilledButton {
    return {
      style: isSet(object.style) ? FilledButton_Style.fromJSON(object.style) : undefined,
      content: isSet(object.content) ? FilledButton_Content.fromJSON(object.content) : undefined,
      size: isSet(object.size) ? filledButton_SizeFromJSON(object.size) : FilledButton_Size.SIZE_NONE,
      corner_rounding_mode: isSet(object.corner_rounding_mode)
        ? filledButton_CornerRoundingModeFromJSON(object.corner_rounding_mode)
        : FilledButton_CornerRoundingMode.CORNER_ROUNDING_MODE_NONE,
    };
  },

  toJSON(message: FilledButton): unknown {
    const obj: any = {};
    if (message.style !== undefined) {
      obj.style = FilledButton_Style.toJSON(message.style);
    }
    if (message.content !== undefined) {
      obj.content = FilledButton_Content.toJSON(message.content);
    }
    if (message.size !== FilledButton_Size.SIZE_NONE) {
      obj.size = filledButton_SizeToJSON(message.size);
    }
    if (message.corner_rounding_mode !== FilledButton_CornerRoundingMode.CORNER_ROUNDING_MODE_NONE) {
      obj.corner_rounding_mode = filledButton_CornerRoundingModeToJSON(message.corner_rounding_mode);
    }
    return obj;
  },
};

export const FilledButton_Style = {
  fromJSON(object: any): FilledButton_Style {
    return {
      style: isSet(object.preset)
        ? { $case: "preset", preset: filledButton_Style_PresetFromJSON(object.preset) }
        : isSet(object.custom)
        ? { $case: "custom", custom: FilledButton_Style_Custom.fromJSON(object.custom) }
        : undefined,
    };
  },

  toJSON(message: FilledButton_Style): unknown {
    const obj: any = {};
    if (message.style?.$case === "preset") {
      obj.preset = filledButton_Style_PresetToJSON(message.style.preset);
    }
    if (message.style?.$case === "custom") {
      obj.custom = FilledButton_Style_Custom.toJSON(message.style.custom);
    }
    return obj;
  },
};

export const FilledButton_Style_Custom = {
  fromJSON(object: any): FilledButton_Style_Custom {
    return {
      background_colors: isSet(object.background_colors)
        ? FilledButton_Style_Custom_Colors.fromJSON(object.background_colors)
        : undefined,
      title_colors: isSet(object.title_colors)
        ? FilledButton_Style_Custom_Colors.fromJSON(object.title_colors)
        : undefined,
    };
  },

  toJSON(message: FilledButton_Style_Custom): unknown {
    const obj: any = {};
    if (message.background_colors !== undefined) {
      obj.background_colors = FilledButton_Style_Custom_Colors.toJSON(message.background_colors);
    }
    if (message.title_colors !== undefined) {
      obj.title_colors = FilledButton_Style_Custom_Colors.toJSON(message.title_colors);
    }
    return obj;
  },
};

export const FilledButton_Style_Custom_Colors = {
  fromJSON(object: any): FilledButton_Style_Custom_Colors {
    return {
      default: isSet(object.default) ? Color.fromJSON(object.default) : undefined,
      disabled: isSet(object.disabled) ? Color.fromJSON(object.disabled) : undefined,
    };
  },

  toJSON(message: FilledButton_Style_Custom_Colors): unknown {
    const obj: any = {};
    if (message.default !== undefined) {
      obj.default = Color.toJSON(message.default);
    }
    if (message.disabled !== undefined) {
      obj.disabled = Color.toJSON(message.disabled);
    }
    return obj;
  },
};

export const FilledButton_Content = {
  fromJSON(object: any): FilledButton_Content {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      icon: isSet(object.icon) ? globalThis.String(object.icon) : "",
      icon_rendering_mode: isSet(object.icon_rendering_mode)
        ? filledButton_Content_IconRenderingModeFromJSON(object.icon_rendering_mode)
        : FilledButton_Content_IconRenderingMode.ICON_RENDERING_MODE_NONE,
      is_showing_progress: isSet(object.is_showing_progress) ? globalThis.Boolean(object.is_showing_progress) : false,
      is_disabled: isSet(object.is_disabled) ? globalThis.Boolean(object.is_disabled) : false,
    };
  },

  toJSON(message: FilledButton_Content): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.icon !== "") {
      obj.icon = message.icon;
    }
    if (message.icon_rendering_mode !== FilledButton_Content_IconRenderingMode.ICON_RENDERING_MODE_NONE) {
      obj.icon_rendering_mode = filledButton_Content_IconRenderingModeToJSON(message.icon_rendering_mode);
    }
    if (message.is_showing_progress !== false) {
      obj.is_showing_progress = message.is_showing_progress;
    }
    if (message.is_disabled !== false) {
      obj.is_disabled = message.is_disabled;
    }
    return obj;
  },
};

export const SnackBar = {
  fromJSON(object: any): SnackBar {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      show_after_delay: isSet(object.show_after_delay) ? globalThis.Number(object.show_after_delay) : 0,
      hide_after_delay: isSet(object.hide_after_delay) ? globalThis.Number(object.hide_after_delay) : 0,
    };
  },

  toJSON(message: SnackBar): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.show_after_delay !== 0) {
      obj.show_after_delay = message.show_after_delay;
    }
    if (message.hide_after_delay !== 0) {
      obj.hide_after_delay = message.hide_after_delay;
    }
    return obj;
  },
};

export const Media = {
  fromJSON(object: any): Media {
    return {
      type: isSet(object.image)
        ? { $case: "image", image: globalThis.String(object.image) }
        : isSet(object.video)
        ? { $case: "video", video: globalThis.String(object.video) }
        : undefined,
    };
  },

  toJSON(message: Media): unknown {
    const obj: any = {};
    if (message.type?.$case === "image") {
      obj.image = message.type.image;
    }
    if (message.type?.$case === "video") {
      obj.video = message.type.video;
    }
    return obj;
  },
};

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
