import React, { FC } from "react"
import { Box, BoxProps, Flex } from "@chakra-ui/react"

import { VFlex } from "~/components/shared/VFlex"
import { getScaleValue } from "~/utils/getScaleValue"
import { useTranslation } from "react-i18next"

const ProgressBar: FC<{ value: number } & BoxProps> = ({ value, color, ...props }) => (
  <Box w="full" borderRadius="full" h={3} {...props}>
    <Box h="full" bgColor={color} w={`${value}%`} borderRadius="full" />
  </Box>
)

const ProgressNumber: FC<{
  color: string
  bgColor: string
  value: number
  title: string
}> = ({ color, bgColor, value, title }) => (
  <VFlex gap={0.5} justifyContent="stretch" w="full">
    <Box fontFamily="heading" textStyle="Header/Secondary">
      {value}%
    </Box>
    <ProgressBar bgColor={bgColor} color={color} value={value} />
    <Box textStyle="Paragraph/Primary">{title}</Box>
  </VFlex>
)

export const FakeNumbers: FC = () => {
  const { t } = useTranslation()
  return (
    <Flex gap={4} overflow="hidden">
      <ProgressNumber
        color="Other/Info"
        bgColor="Other/Info/20"
        value={getScaleValue("HYDRATION", false)}
        title={t("scales.hydration")}
      />
      <ProgressNumber
        color="Other/Green"
        bgColor="Other/Green/20"
        value={getScaleValue("ELASTICITY", false)}
        title={t("scales.elasticity")}
      />
      <ProgressNumber
        color="Other/New"
        bgColor="Other/New/20"
        value={getScaleValue("COMPLEXION", false)}
        title={t("scales.complexion")}
      />
    </Flex>
  )
}
