// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: skin_diary_checklist.proto

/* eslint-disable */
import { AttributedString } from "./presentation";

export interface SkinDiaryScreen {
  blocks: SkinDiaryScreen_Block[];
  title: AttributedString | undefined;
  avatar_placeholder: string;
  skin_feeling_color: string;
  /** put here file path from client. Otherwise is empty. */
  image: string;
}

export interface SkinDiaryScreen_Block {
  id: string;
  image_url: string;
  title: string;
  is_optional: boolean;
  block_content?:
    | { $case: "tags_cloud"; tags_cloud: SkinDiaryScreen_Block_TagCloud }
    | { $case: "slider"; slider: SkinDiaryScreen_Block_Slider }
    | { $case: "water_selector"; water_selector: SkinDiaryScreen_Block_WaterSelector }
    | undefined;
}

export interface SkinDiaryScreen_Block_TagCloud {
  /** Contains all possible tags, including tags for TextInput and ShowMore */
  tags: SkinDiaryScreen_Block_TagCloud_Tag[];
  text_input?: SkinDiaryScreen_Block_TagCloud_TextInput | undefined;
  is_single_select: boolean;
  blast_confetti_on_fill: boolean;
  show_more?: SkinDiaryScreen_Block_TagCloud_ShowMore | undefined;
}

export interface SkinDiaryScreen_Block_TagCloud_Tag {
  value: string;
  image_url: string;
  background_color: string;
  highlight_color: string;
  is_selected: boolean;
}

export interface SkinDiaryScreen_Block_TagCloud_TextInput {
  /** If `bind_with_tag` is empty, the input is always visible. */
  bind_with_tag: string;
  /** Visible when `text` is empty. */
  placeholder: string;
  /** Pre-filled text. */
  text: string;
}

export interface SkinDiaryScreen_Block_TagCloud_ShowMore {
  bind_with_tag: string;
  tags_limit: number;
}

export interface SkinDiaryScreen_Block_Slider {
  values: SkinDiaryScreen_Block_Slider_Value[];
  selected_index: number;
  animation_type: SkinDiaryScreen_Block_Slider_AnimationType;
}

export enum SkinDiaryScreen_Block_Slider_AnimationType {
  NONE = "NONE",
  ROTATE = "ROTATE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function skinDiaryScreen_Block_Slider_AnimationTypeFromJSON(
  object: any,
): SkinDiaryScreen_Block_Slider_AnimationType {
  switch (object) {
    case 0:
    case "NONE":
      return SkinDiaryScreen_Block_Slider_AnimationType.NONE;
    case 1:
    case "ROTATE":
      return SkinDiaryScreen_Block_Slider_AnimationType.ROTATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SkinDiaryScreen_Block_Slider_AnimationType.UNRECOGNIZED;
  }
}

export function skinDiaryScreen_Block_Slider_AnimationTypeToJSON(
  object: SkinDiaryScreen_Block_Slider_AnimationType,
): string {
  switch (object) {
    case SkinDiaryScreen_Block_Slider_AnimationType.NONE:
      return "NONE";
    case SkinDiaryScreen_Block_Slider_AnimationType.ROTATE:
      return "ROTATE";
    case SkinDiaryScreen_Block_Slider_AnimationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SkinDiaryScreen_Block_Slider_Value {
  text: string;
  image_url: string;
}

export interface SkinDiaryScreen_Block_WaterSelector {
  /** Minimum count of water glasses */
  min_value: number;
  /** Maximum count of water glasses */
  max_value: number;
  selected_value: number;
  /** Used for converting glasses count to ml / oz */
  measurement: SkinDiaryScreen_Block_WaterSelector_Measurement;
  /** optional, 250 ml by default */
  ml_in_glass: number;
}

export enum SkinDiaryScreen_Block_WaterSelector_Measurement {
  NONE = "NONE",
  METRIC = "METRIC",
  IMPERIAL = "IMPERIAL",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function skinDiaryScreen_Block_WaterSelector_MeasurementFromJSON(
  object: any,
): SkinDiaryScreen_Block_WaterSelector_Measurement {
  switch (object) {
    case 0:
    case "NONE":
      return SkinDiaryScreen_Block_WaterSelector_Measurement.NONE;
    case 1:
    case "METRIC":
      return SkinDiaryScreen_Block_WaterSelector_Measurement.METRIC;
    case 2:
    case "IMPERIAL":
      return SkinDiaryScreen_Block_WaterSelector_Measurement.IMPERIAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SkinDiaryScreen_Block_WaterSelector_Measurement.UNRECOGNIZED;
  }
}

export function skinDiaryScreen_Block_WaterSelector_MeasurementToJSON(
  object: SkinDiaryScreen_Block_WaterSelector_Measurement,
): string {
  switch (object) {
    case SkinDiaryScreen_Block_WaterSelector_Measurement.NONE:
      return "NONE";
    case SkinDiaryScreen_Block_WaterSelector_Measurement.METRIC:
      return "METRIC";
    case SkinDiaryScreen_Block_WaterSelector_Measurement.IMPERIAL:
      return "IMPERIAL";
    case SkinDiaryScreen_Block_WaterSelector_Measurement.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SkinDiarySaveRequest {
  block_responses: SkinDiarySaveRequest_BlockResponse[];
  user_id: string;
}

export interface SkinDiarySaveRequest_BlockResponse {
  id: string;
  answers: string[];
}

export const SkinDiaryScreen = {
  fromJSON(object: any): SkinDiaryScreen {
    return {
      blocks: globalThis.Array.isArray(object?.blocks)
        ? object.blocks.map((e: any) => SkinDiaryScreen_Block.fromJSON(e))
        : [],
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      avatar_placeholder: isSet(object.avatar_placeholder) ? globalThis.String(object.avatar_placeholder) : "",
      skin_feeling_color: isSet(object.skin_feeling_color) ? globalThis.String(object.skin_feeling_color) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
    };
  },

  toJSON(message: SkinDiaryScreen): unknown {
    const obj: any = {};
    if (message.blocks?.length) {
      obj.blocks = message.blocks.map((e) => SkinDiaryScreen_Block.toJSON(e));
    }
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.avatar_placeholder !== "") {
      obj.avatar_placeholder = message.avatar_placeholder;
    }
    if (message.skin_feeling_color !== "") {
      obj.skin_feeling_color = message.skin_feeling_color;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    return obj;
  },
};

export const SkinDiaryScreen_Block = {
  fromJSON(object: any): SkinDiaryScreen_Block {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      image_url: isSet(object.image_url) ? globalThis.String(object.image_url) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      is_optional: isSet(object.is_optional) ? globalThis.Boolean(object.is_optional) : false,
      block_content: isSet(object.tags_cloud)
        ? { $case: "tags_cloud", tags_cloud: SkinDiaryScreen_Block_TagCloud.fromJSON(object.tags_cloud) }
        : isSet(object.slider)
        ? { $case: "slider", slider: SkinDiaryScreen_Block_Slider.fromJSON(object.slider) }
        : isSet(object.water_selector)
        ? {
          $case: "water_selector",
          water_selector: SkinDiaryScreen_Block_WaterSelector.fromJSON(object.water_selector),
        }
        : undefined,
    };
  },

  toJSON(message: SkinDiaryScreen_Block): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.image_url !== "") {
      obj.image_url = message.image_url;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.is_optional !== false) {
      obj.is_optional = message.is_optional;
    }
    if (message.block_content?.$case === "tags_cloud") {
      obj.tags_cloud = SkinDiaryScreen_Block_TagCloud.toJSON(message.block_content.tags_cloud);
    }
    if (message.block_content?.$case === "slider") {
      obj.slider = SkinDiaryScreen_Block_Slider.toJSON(message.block_content.slider);
    }
    if (message.block_content?.$case === "water_selector") {
      obj.water_selector = SkinDiaryScreen_Block_WaterSelector.toJSON(message.block_content.water_selector);
    }
    return obj;
  },
};

export const SkinDiaryScreen_Block_TagCloud = {
  fromJSON(object: any): SkinDiaryScreen_Block_TagCloud {
    return {
      tags: globalThis.Array.isArray(object?.tags)
        ? object.tags.map((e: any) => SkinDiaryScreen_Block_TagCloud_Tag.fromJSON(e))
        : [],
      text_input: isSet(object.text_input)
        ? SkinDiaryScreen_Block_TagCloud_TextInput.fromJSON(object.text_input)
        : undefined,
      is_single_select: isSet(object.is_single_select) ? globalThis.Boolean(object.is_single_select) : false,
      blast_confetti_on_fill: isSet(object.blast_confetti_on_fill)
        ? globalThis.Boolean(object.blast_confetti_on_fill)
        : false,
      show_more: isSet(object.show_more)
        ? SkinDiaryScreen_Block_TagCloud_ShowMore.fromJSON(object.show_more)
        : undefined,
    };
  },

  toJSON(message: SkinDiaryScreen_Block_TagCloud): unknown {
    const obj: any = {};
    if (message.tags?.length) {
      obj.tags = message.tags.map((e) => SkinDiaryScreen_Block_TagCloud_Tag.toJSON(e));
    }
    if (message.text_input !== undefined) {
      obj.text_input = SkinDiaryScreen_Block_TagCloud_TextInput.toJSON(message.text_input);
    }
    if (message.is_single_select !== false) {
      obj.is_single_select = message.is_single_select;
    }
    if (message.blast_confetti_on_fill !== false) {
      obj.blast_confetti_on_fill = message.blast_confetti_on_fill;
    }
    if (message.show_more !== undefined) {
      obj.show_more = SkinDiaryScreen_Block_TagCloud_ShowMore.toJSON(message.show_more);
    }
    return obj;
  },
};

export const SkinDiaryScreen_Block_TagCloud_Tag = {
  fromJSON(object: any): SkinDiaryScreen_Block_TagCloud_Tag {
    return {
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      image_url: isSet(object.image_url) ? globalThis.String(object.image_url) : "",
      background_color: isSet(object.background_color) ? globalThis.String(object.background_color) : "",
      highlight_color: isSet(object.highlight_color) ? globalThis.String(object.highlight_color) : "",
      is_selected: isSet(object.is_selected) ? globalThis.Boolean(object.is_selected) : false,
    };
  },

  toJSON(message: SkinDiaryScreen_Block_TagCloud_Tag): unknown {
    const obj: any = {};
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.image_url !== "") {
      obj.image_url = message.image_url;
    }
    if (message.background_color !== "") {
      obj.background_color = message.background_color;
    }
    if (message.highlight_color !== "") {
      obj.highlight_color = message.highlight_color;
    }
    if (message.is_selected !== false) {
      obj.is_selected = message.is_selected;
    }
    return obj;
  },
};

export const SkinDiaryScreen_Block_TagCloud_TextInput = {
  fromJSON(object: any): SkinDiaryScreen_Block_TagCloud_TextInput {
    return {
      bind_with_tag: isSet(object.bind_with_tag) ? globalThis.String(object.bind_with_tag) : "",
      placeholder: isSet(object.placeholder) ? globalThis.String(object.placeholder) : "",
      text: isSet(object.text) ? globalThis.String(object.text) : "",
    };
  },

  toJSON(message: SkinDiaryScreen_Block_TagCloud_TextInput): unknown {
    const obj: any = {};
    if (message.bind_with_tag !== "") {
      obj.bind_with_tag = message.bind_with_tag;
    }
    if (message.placeholder !== "") {
      obj.placeholder = message.placeholder;
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    return obj;
  },
};

export const SkinDiaryScreen_Block_TagCloud_ShowMore = {
  fromJSON(object: any): SkinDiaryScreen_Block_TagCloud_ShowMore {
    return {
      bind_with_tag: isSet(object.bind_with_tag) ? globalThis.String(object.bind_with_tag) : "",
      tags_limit: isSet(object.tags_limit) ? globalThis.Number(object.tags_limit) : 0,
    };
  },

  toJSON(message: SkinDiaryScreen_Block_TagCloud_ShowMore): unknown {
    const obj: any = {};
    if (message.bind_with_tag !== "") {
      obj.bind_with_tag = message.bind_with_tag;
    }
    if (message.tags_limit !== 0) {
      obj.tags_limit = Math.round(message.tags_limit);
    }
    return obj;
  },
};

export const SkinDiaryScreen_Block_Slider = {
  fromJSON(object: any): SkinDiaryScreen_Block_Slider {
    return {
      values: globalThis.Array.isArray(object?.values)
        ? object.values.map((e: any) => SkinDiaryScreen_Block_Slider_Value.fromJSON(e))
        : [],
      selected_index: isSet(object.selected_index) ? globalThis.Number(object.selected_index) : 0,
      animation_type: isSet(object.animation_type)
        ? skinDiaryScreen_Block_Slider_AnimationTypeFromJSON(object.animation_type)
        : SkinDiaryScreen_Block_Slider_AnimationType.NONE,
    };
  },

  toJSON(message: SkinDiaryScreen_Block_Slider): unknown {
    const obj: any = {};
    if (message.values?.length) {
      obj.values = message.values.map((e) => SkinDiaryScreen_Block_Slider_Value.toJSON(e));
    }
    if (message.selected_index !== 0) {
      obj.selected_index = Math.round(message.selected_index);
    }
    if (message.animation_type !== SkinDiaryScreen_Block_Slider_AnimationType.NONE) {
      obj.animation_type = skinDiaryScreen_Block_Slider_AnimationTypeToJSON(message.animation_type);
    }
    return obj;
  },
};

export const SkinDiaryScreen_Block_Slider_Value = {
  fromJSON(object: any): SkinDiaryScreen_Block_Slider_Value {
    return {
      text: isSet(object.text) ? globalThis.String(object.text) : "",
      image_url: isSet(object.image_url) ? globalThis.String(object.image_url) : "",
    };
  },

  toJSON(message: SkinDiaryScreen_Block_Slider_Value): unknown {
    const obj: any = {};
    if (message.text !== "") {
      obj.text = message.text;
    }
    if (message.image_url !== "") {
      obj.image_url = message.image_url;
    }
    return obj;
  },
};

export const SkinDiaryScreen_Block_WaterSelector = {
  fromJSON(object: any): SkinDiaryScreen_Block_WaterSelector {
    return {
      min_value: isSet(object.min_value) ? globalThis.Number(object.min_value) : 0,
      max_value: isSet(object.max_value) ? globalThis.Number(object.max_value) : 0,
      selected_value: isSet(object.selected_value) ? globalThis.Number(object.selected_value) : 0,
      measurement: isSet(object.measurement)
        ? skinDiaryScreen_Block_WaterSelector_MeasurementFromJSON(object.measurement)
        : SkinDiaryScreen_Block_WaterSelector_Measurement.NONE,
      ml_in_glass: isSet(object.ml_in_glass) ? globalThis.Number(object.ml_in_glass) : 0,
    };
  },

  toJSON(message: SkinDiaryScreen_Block_WaterSelector): unknown {
    const obj: any = {};
    if (message.min_value !== 0) {
      obj.min_value = Math.round(message.min_value);
    }
    if (message.max_value !== 0) {
      obj.max_value = Math.round(message.max_value);
    }
    if (message.selected_value !== 0) {
      obj.selected_value = Math.round(message.selected_value);
    }
    if (message.measurement !== SkinDiaryScreen_Block_WaterSelector_Measurement.NONE) {
      obj.measurement = skinDiaryScreen_Block_WaterSelector_MeasurementToJSON(message.measurement);
    }
    if (message.ml_in_glass !== 0) {
      obj.ml_in_glass = Math.round(message.ml_in_glass);
    }
    return obj;
  },
};

export const SkinDiarySaveRequest = {
  fromJSON(object: any): SkinDiarySaveRequest {
    return {
      block_responses: globalThis.Array.isArray(object?.block_responses)
        ? object.block_responses.map((e: any) => SkinDiarySaveRequest_BlockResponse.fromJSON(e))
        : [],
      user_id: isSet(object.user_id) ? globalThis.String(object.user_id) : "",
    };
  },

  toJSON(message: SkinDiarySaveRequest): unknown {
    const obj: any = {};
    if (message.block_responses?.length) {
      obj.block_responses = message.block_responses.map((e) => SkinDiarySaveRequest_BlockResponse.toJSON(e));
    }
    if (message.user_id !== "") {
      obj.user_id = message.user_id;
    }
    return obj;
  },
};

export const SkinDiarySaveRequest_BlockResponse = {
  fromJSON(object: any): SkinDiarySaveRequest_BlockResponse {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      answers: globalThis.Array.isArray(object?.answers) ? object.answers.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: SkinDiarySaveRequest_BlockResponse): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.answers?.length) {
      obj.answers = message.answers;
    }
    return obj;
  },
};

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
